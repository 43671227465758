import React from "react";
import Sig from "../../../../api/Sig";
import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import DefaultLoader from "../../../tools/DefaultLoader";
import CustomTooltip from "../../../tools/CustomTooltip";
import CardIndicadorPainel from "./CardIndicadorPainel";
import EssentialStyle from "../../../../style/EssentialStyle";
import Colors from "../../../../constants/Colors";
import DataHelper from "../../../../helper/DataHelper";
import LayoutHelper from "../../../../helper/LayoutHelper";

export default class IndicadoresObjetivo extends React.Component {
    state = {
        loading: true,
        indicadores: [],
        objetivo: {
            nome: '',
        },
        resultado: {
            'periodos': {},
            'performanceRealizado': '--%',
            'realizadoRealizado': '',
            "metaPeriodo": '',
            'cor': '#1371cf',
            'processoNome': 'Processo não encontrado'
        },

        viewDetails: false,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            await this.loadData();
        }
    }

    async loadData() {
        this.setState({ loading: true });

        let reqBody = {
            id: this.props.id,
            model: this.props.model
        };

        if (this.props.id_indicador) reqBody.id_indicador = this.props.id_indicador;

        const req = await Sig.request('POST', `pe/diagrama/getAcompanhamentoObjetivoElemento`, reqBody);

        this.setState({ resultado: req.media, indicadores: req.indicadores, objetivo: req.objetivo }, () => { this.setState({ loading: false }) });
    }

    getMonthName(monthNumber) {
        let months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        return months[monthNumber - 1];
    }

    makeStringData(periodos) {
        if (!periodos) return '';

        let chaves = Object.keys(periodos);
        let primeiraChave = chaves[0];
        let ultimaChave = chaves[chaves.length - 1];

        let anoInicio = periodos[primeiraChave].ano;
        let mesInicio = periodos[primeiraChave].mes;
        let anoFim = periodos[ultimaChave].ano;
        let mesFim = periodos[ultimaChave].mes;

        return `De ${this.getMonthName(mesInicio)}/${anoInicio} até ${this.getMonthName(mesFim)}/${anoFim}`;
    }

    renderLoadingModal() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                <DefaultLoader title={"Carregando..."}/>
            </div>
        )
    }

    renderHeaderModal() {
        return (
            <div style={styles.headerModal.content}>
                <div style={styles.headerModal.titleObjetivo}>{DataHelper.removeHtmlTags(this.state.objetivo.valor)}</div>
                <div style={styles.headerModal.title}>{this.makeStringData(this.state.indicadores[0].periodos)}</div>
                <div style={styles.line}></div>
                <div style={styles.headerModal.title}>{this.state.indicadores[0].painelNome}</div>
            </div>
        )
    }

    renderCardsIndicadores() {
        return (
            <div style={{...styles.contentModal.content, justifyContent: this.state.indicadores.length > 1 ? 'flex-start': 'center'}}>
                {this.state.indicadores.map((indicador, index) => (
                    <CardIndicadorPainel key={`key-indicador-painel-${indicador.id}-${index}`} id={this.props.id} indicador={indicador.idIndicador} painel={indicador.idPainel} />
                ))}
            </div>
        )
    }

    renderFooterModal() {
        return (
            <div style={styles.footerModal.content}>
                <DefaultButton
                    style={{ padding: 5, marginTop: 5, width: "100%", backgroundColor: this.state.resultado.cor, color: Colors.white, borderColor: 'transparent' }}
                    title={"Ver Painel de controle"}
                    leftIcon={<FontAwesomeIcon icon={faEye} />}
                    onClick={() => window.parent.postMessage({ type: '_blank', url: `/acompanhamentoResultados/gerenciar/${this.state.indicadores[0].idPainel}` }, '*')}
                />
                
                <div style={styles.footerModal.resultado}>Média da Performance: <span style={{ color: this.state.resultado.cor, marginLeft: 5 }}>●</span> {this.state.resultado.performanceRealizado}</div>
            </div>
        )
    }

    renderDetailing() {
        return (
            <div style={ LayoutHelper.isSmallScreen() ? styles.modalMobile : styles.modal }>
                <div style={styles.modalContent}>
                    <div 
                        onClick={() => { this.props.hendleViewDetails(this.props.id) }} 
                        style={{ zIndex: 5, position: 'absolute', left: 5, top: 5, padding: 5, cursor: 'pointer', backgroundColor: 'white', width: 30, height: 30, borderRadius: 30 / 2, boxShadow: Colors.boxShadow, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <FontAwesomeIcon style={{ fontSize: 20, color: Colors.dark }} icon={faTimes} />
                    </div>

                    { this.state.loading && this.renderLoadingModal()}
                    {!this.state.loading && this.renderHeaderModal()}
                    {!this.state.loading && this.renderCardsIndicadores()}
                    {!this.state.loading && this.renderFooterModal()}

                </div>
            </div>
        )
    }

    render() {
        return this.renderDetailing();
    }
}

const styles = {
    modalMobile:{
        position: 'fixed', 
        right: 10, 
        top: 75, 
        bottom: 100, 
        zIndex: 999, 
        boxShadow: Colors.boxShadow, 
        width: '50vh', 
        padding: "20px 20px 10px 20px", 
        border: '0.8px solid lightgrey', 
        borderRadius: 5, 
        backgroundColor: 'white', 
        overflow: 'hidden'
    },
    modal:{
        position: 'fixed', 
        right: 10, 
        top: 75, 
        bottom: 5, 
        zIndex: 999, 
        boxShadow: Colors.boxShadow, 
        width: '80vh', 
        padding: "20px 20px 10px 20px", 
        border: '0.8px solid lightgrey', 
        borderRadius: 5, 
        backgroundColor: 'white', 
        overflow: 'hidden'
    },
    modalContent:{
        ...EssentialStyle.columnSpaceBetween,
        height: '100%',
        width: '100%',
        overflowX: 'hidden', 
        padding: "10px 10px 0px 10px",
    },
    headerModal: {
        content: {
            width: '100%',
            ...EssentialStyle.columnCenter,
            padding: 0,
            margin: 0,
        },
        titleObjetivo: {
            ...EssentialStyle.rowFlexCenter,
            textAlign: 'center',
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 5,
        },
        title: {
            ...EssentialStyle.rowFlexCenter,
            textAlign: 'center',
            fontSize: 14,
        },
    },
    contentModal: {
        content: {
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'auto',
            width: '100%',
        },
    },
    footerModal: {
        content: {
            width: '100%',
            ...EssentialStyle.columnCenter,
            padding: 0,
            margin: 0,
        },
        resultado: {
            ...EssentialStyle.rowFlexCenter,
            fontSize: 14,
            fontWeight: 'bold',
            marginTop: 15,

        }
    },
    line: {
        width: '100%',
        height: 1,
        backgroundColor: Colors.homePage.line,
        margin: "10px 0px",
    }
}
