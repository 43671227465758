import React from 'react';
import { forwardRef } from 'react';
import ResizableTimelineElement from '../../../tools/ResizableTimelineElement/ResizableTimelineElement';
import 'moment/locale/pt-br'
import moment from 'moment';
import Colors from "../../../../constants/Colors";
import EllipsisText from "../../../tools/EllipsisText";
import DatePicker from "react-datepicker";
import { Form, FormGroup } from 'react-bootstrap';
import EssentialStyle from "../../../../style/EssentialStyle";
import DataHelper from "../../../../helper/DataHelper";
import { InputGroup } from 'react-bootstrap';
import LayoutHelper from '../../../../helper/LayoutHelper';
import { faTrash, faAdd, faClose} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from '../../../tools/DefaultButton';
import Sig from "../../../../api/Sig";
import ReactDOM from 'react-dom';

const DAY_SIZE = 3;

export default class TimelineMarcos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            marcos: [...props.marcos],
            timelineStart: 0,
            timelineEnd: 0,
            edit: this.props.edit,
            editId: null,
            selectedMarco: null,
            isSmallScreen: LayoutHelper.isSmallScreen(),
            showDeleteModal: false,
            id: this.props.id,
            isFocusedInside: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);

        var marcos = [...this.props.marcos];

        var timelineStart = Math.min(...marcos.map(m => moment(m.data_inicio).toDate().getTime()));
        timelineStart = moment(new Date(timelineStart)).subtract(2, 'months').startOf('month').startOf('day').toDate().getTime();
        var timelineEnd = Math.max(...marcos.map(m => moment(m.data_fim).toDate().getTime()));
        timelineEnd = moment(new Date(timelineEnd)).add(2, 'months').endOf('month').endOf('day').toDate().getTime();

        this.setState({
            timelineStart,
            timelineEnd,
            marcos,
            edit: this.props.edit,
            selectedMarco: null,
            showDeleteModal: false,
        });

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen(), windowWidth: window.innerWidth});
    }

    componentDidUpdate(prevProps, prevState) {
        
        if(prevState.editId !== this.state.editId && this.state.showDeleteModal === true) {
            this.setState({  showDeleteModal: false, });
        }
            
        if(prevProps.edit !== this.props.edit) {
            this.setState({ edit: this.props.edit });
        }

        if (prevState.marcos != this.state.marcos) {

            var marcos = [...this.state.marcos];

            var timelineStart = Math.min(...marcos.map(m => moment(m.data_inicio).toDate().getTime()));
            timelineStart = moment(new Date(timelineStart)).subtract(2, 'months').startOf('month').startOf('day').toDate().getTime();
            var timelineEnd = Math.max(...marcos.map(m => moment(m.data_fim).toDate().getTime()));
            timelineEnd = moment(new Date(timelineEnd)).add(2, 'months').endOf('month').endOf('day').toDate().getTime();

            this.setState({
                timelineStart,
                timelineEnd,
            });
        }

        if(prevProps.marcos !== this.props.marcos) {

            var timelineStart = Math.min(...this.props.marcos.map(m => moment(m.data_inicio).toDate().getTime()));
            timelineStart = moment(new Date(timelineStart)).subtract(2, 'months').startOf('month').startOf('day').toDate().getTime();
            var timelineEnd = Math.max(...this.props.marcos.map(m => moment(m.data_fim).toDate().getTime()));
            timelineEnd = moment(new Date(timelineEnd)).add(2, 'months').endOf('month').endOf('day').toDate().getTime();

            this.setState({ 
                marcos: [...this.props.marcos], 
                timelineStart,
                timelineEnd, 
            });
        }
    }

    changeMarcoCallback(updatedMarco) {
        var marcos = [...this.state.marcos];
        const index = marcos.findIndex(m => m.id === updatedMarco.id);
        marcos[index] = updatedMarco;
        this.setState({marcos}, this.props.changeMarcosCallback(marcos));
    }

    addMarco(newMarco) {
        var marcos = [...this.state.marcos];
        marcos.unshift(newMarco);
        this.setState({ marcos, editId: newMarco.id }, this.props.changeMarcosCallback(marcos));
    }

    setEditId(id) {
        this.setState({ editId: id });
    }

    async removeMarcosItem(id) {
        var itemDeletado = await Sig.request('POST', 'pe/canvas/removeMarcosItem',
            {
                id: id,
            });
        
        if(itemDeletado) {
            var marcos = this.state.marcos.filter(m => m.id != id);
            this.setState({ marcos, showDeleteModal: false, editId: null }, this.props.changeMarcosCallback(marcos));
        }
    }

    renderRemoveItemModal(id) {
        var marco = this.state.marcos.find(m => m.id == id);
        return (
            <div style={{ padding: 30, position: "absolute", backgroundColor: Colors.white, borderRadius: "10px", zIndex: 20 }} >
                <h1 style={{ marginTop: 0, fontWeight: 'normal', color: Colors.disabled, fontSize: 25 }}>Remover marco</h1>
                <p style={{ color: Colors.dark, fontSize: 15 }}>Tem certeza que deseja remover o marco "{marco.descricao}"?</p>
                <div style={EssentialStyle.rowReverseFlexStart}>
                    <DefaultButton title="Cancelar" color={Colors.notSelected} textColor={Colors.dark} onClick={() => this.setState({ showDeleteModal: false })} style={{ marginLeft: 5 }} />
                    <DefaultButton title="Remover" color={Colors.danger} textColor={Colors.dark} onClick={() => this.removeMarcosItem(marco.id) } style={{ marginLeft: 5 }} />
                </div>
            </div>
        );
    }

    calendarContainer = ({ children }) => {
        const el = document.getElementById("root");
        
        return ReactDOM.createPortal(
            children,
            el
        );
    };

    renderDatePicker(value, onChangeCallback, minDate = moment().subtract(100, 'years').toDate(), maxDate = moment().add(100, 'years').toDate()) {
        const CustomInput = forwardRef(({ value, onClick }, ref) => (
            <Form.Control onClick={onClick} ref={ref} value={value} s>
            </Form.Control>
        ));

        let date = moment().toDate();
        if (value) date = moment(value).toDate();

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={date}
                type="date"
                minDate={minDate}
                maxDate={maxDate}
                onChange={(event) => {
                    let changedDate = moment(event);
                    onChangeCallback(changedDate);
                }}
                dateFormat="dd/MM/YYYY"
                popperContainer={this.calendarContainer}
                customInput={<CustomInput/>}
            />
        )
    };

    renderEditForm() {
        if(!this.state.editId) return null;
        
        var pesoAtual = this.state.marcos.filter( m => m.id != this.state.editId ).reduce((acc, marco) => acc + Number(marco.peso || 0), 0);
        var id = this.state.marcos.findIndex(m => m.id == this.state.editId); 
        var marco = this.state.marcos[id];

        return (
            <FormGroup>
                <div style={{ ...EssentialStyle.columnCenter, paddingBottom: 10, paddingTop: 10, minHeight: "150px", maxWidth:"600px", width: "100%"}}>
                    { this.state.showDeleteModal  ? this.renderRemoveItemModal(marco.id) : null}
                    <div style={{ ...EssentialStyle.rowSpaceEvenly }}>
                        <div style={{ ...EssentialStyle.rowSpaceBetween, width: `${this.state.isSmallScreen ? "100%" : "calc(100%-80px)"}`, marginBottom: 16 }}>
                            <Form.Group className={this.state.isSmallScreen ? "w-40" : "w-50"} style={{ marginRight: 8, justifyContent: "center" }}>
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control placeholder="Descrição" value={marco.descricao}
                                    onBlur={(event) => {
                                        var marcos = this.state.marcos;
                                        marcos[id].descricao = event.target.value;
                                        this.changeMarcoCallback(marcos[id]);
                                    }}
                                    onChange={(event) => {
                                        var marcos = [...this.state.marcos];
                                        marcos[id].descricao = event.target.value;
                                        this.changeMarcoCallback(marcos[id]);
                                    }}
                                />
                            </Form.Group>
                            <InputGroup className={this.state.isSmallScreen ? "w-30" : "w-25"} style={{ marginRight: 8, justifyContent: "center", zIndex: 15, position: "relative" }}>
                                <Form.Label>{this.state.isSmallScreen ? "Início" : "Data de Início"}</Form.Label>
                                {this.renderDatePicker(
                                    marco.data_inicio,
                                    (value) => { 
                                        var marcos = [...this.state.marcos];
                                        marcos[id].data_inicio = DataHelper.getDefaultDbDateFormat(value);
                                        this.changeMarcoCallback(marcos[id]);
                                    },
                                    moment().subtract(100, 'years'),
                                    marco.data_fim ? moment(marco.data_fim).toDate() : moment().add(100, 'years').toDate(),
                                )}
                            </InputGroup>
                            <InputGroup className={this.state.isSmallScreen ? "w-30" : "w-25"} style={{ marginRight: 8, justifyContent: "center", zIndex: 15, position: "relative"}}>
                                <Form.Label>{this.state.isSmallScreen ? "Fim" : "Data de Fim"}</Form.Label>
                                {this.renderDatePicker(
                                    marco.data_fim,
                                    (value) => { 
                                        var marcos = [...this.state.marcos];
                                        marcos[id].data_fim = DataHelper.getDefaultDbDateFormat(value);
                                        this.changeMarcoCallback(marcos[id]);       
                                    },
                                    marco.data_inicio ? moment(marco.data_inicio).toDate() : moment().subtract(100, 'years').toDate(),
                                )}
                            </InputGroup>

                        </div>
                        <div style={{ ...EssentialStyle.columnSpaceBetween, marginLeft: 10, minHeight: "100%", gap: "10px" }}>
                            <DefaultButton
                                tooltip={'Fechar'}
                                tooltipPlacement={'top'}
                                leftIcon={<FontAwesomeIcon icon={faClose} />}
                                style={{ fontSize: "20px", width: "35px", height: "35px", padding: "0px", margin: "0px"}}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                loading={this.state.loading}
                                onClick={ () => this.setState({ editId: null, showDeleteModal: false }) }
                            />
                            <DefaultButton
                                tooltip={'Remover'}
                                tooltipPlacement={'top'}
                                leftIcon={<FontAwesomeIcon icon={faTrash} />}
                                style={{ fontSize: "14px", width: "35px", height: "35px", padding: "0px", margin: "0px"}}
                                color={Colors.secondaryButton}
                                textColor={Colors.dark}
                                loading={this.state.loading}
                                onClick={ () => this.setState({ showDeleteModal: true }) }
                            />
                        </div>
                    </div>

                    <div style={{ ...EssentialStyle.rowSpaceEvenly, width: '100%' }}>
                        <Form.Label style={{ marginTop: 5, width: 110 }}>% do projeto</Form.Label>
                        <InputGroup style={{ minWidth: 120, width: 'calc(100% - 170px)', marginRight: 10 }}>
                            <Form.Control
                                type="range"
                                min="0"
                                max={100 - pesoAtual} 
                                step="1"
                                value={Number(marco.peso || 0)}
                                onMouseUp={(event) => {
                                    var marcos = [...this.state.marcos];
                                    marcos[id].peso = Number(event.target.value|| 0);
                                    this.changeMarcoCallback(marcos[id]);
                                }}
                                onTouchEnd={(event) => {
                                    var marcos = [...this.state.marcos];
                                    marcos[id].peso = Number(event.target.value|| 0);
                                    this.changeMarcoCallback(marcos[id]);
                                }}
                                onChange={(event) => {
                                    var marcos = [...this.state.marcos];
                                        marcos[id].peso = Number(event.target.value|| 0);
                                        this.setState(marcos);
                                }}
                            />
                        </InputGroup>
                        <Form.Label style={{ marginTop: 5, width: 50 }}>{Number(marco.peso || 0)}%</Form.Label>
                    </div>
                </div>
            </FormGroup>
        );
    }
    

    render() {

        var { timelineStart, timelineEnd } = this.state;
        
        var offsetLeft = 150;
        if(this.state.isSmallScreen) 
            offsetLeft = 100;
        
        var marcos = this.state.marcos;

        var totalDuration = timelineEnd - timelineStart;
        var quantMonths = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'months', true));
        var quantDias = Math.round(moment(new Date(timelineEnd)).diff(moment(new Date(timelineStart)), 'days', true));
        
        return (
            <div style={{ minHeight: '30vh', height: '60vh', padding: 10, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
                {marcos && marcos.length > 0 ?
                    <div style={{ display: "flex", flexDirection: "column", width: '100%', height: "max-content", alignItems: 'center', justifyContent: 'flex-start', overflowY: "auto" }}>
                        {this.state.edit ? 
                            this.state.editId !== null ?
                                this.renderEditForm()
                            : 
                                <div style={{ ...EssentialStyle.columnCenter, width: '100%', maxWidth:"600px", fontSize: "1.5rem", minHeight: "150px", position: 'relative' }}>
                                    <span style={{height: "50px"}}> Nenhum marco selecionado </span>
                                    <div>
                                        <DefaultButton
                                            leftIcon={<FontAwesomeIcon icon={faAdd} />}
                                            color={Colors.success}
                                            textColor={Colors.white}
                                            title={'Adicionar Marco'}
                                            loading={this.state.loading}
                                            onClick={async () => {
                                                var data = await Sig.request('POST', 'pe/canvas/addMarcosItem',
                                                    {
                                                        id_pe_projeto_canvas: this.props.id_pe_projeto_canvas
                                                    }
                                                );

                                                if(data){
                                                    this.addMarco(data["item orcamento"]);
                                                }
                                            }}
                                            style={{ marginRight: 8, marginTop: 16 }}
                                        />
                                    </div>
                                </div>
                        : null}

                            <div style={{ width: "max-content", maxWidth: "100%", height: "max-content", maxHeight: "100%", position: "relative", minHeight: "150px", display: "flex", flexDirection: "column", justifyContent: "flex-start", overflow: "auto" }}>
                                <div style={{ width: `${(DAY_SIZE * quantDias) + offsetLeft }px`, minHeight: "35px", display: "flex", flexDirection: "row", backgroundColor: "rgb(247, 248, 249)", position: "sticky", top: 0, zIndex: 5, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}` }} >
                                    <div style={{
                                        width: offsetLeft, lineHeight: "35px", position: "sticky", left: 0,
                                        minHeight: "35px", display: "flex", zIndex: 10, backgroundColor: "rgb(247, 248, 249)",
                                        flexDirection: "column", justifyContent: "center", alignItems: "center", borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`
                                    }}>
                                        Marco
                                    </div>
                                    {
                                        Array.from({ length: quantMonths }, (_, i) => i).map((_, index) => {
                                            var daysInMonth = moment(new Date(timelineStart)).add(index, 'months').daysInMonth();
                                            return (
                                                <div key={index} style={{
                                                    width: `${daysInMonth * DAY_SIZE}px`, lineHeight: "35px",
                                                    minHeight: "35px", display: "flex", position: "relative",
                                                    flexDirection: "column", justifyContent: "center", alignItems: "center",
                                                }}>
                                                    {moment(new Date(timelineStart)).add(index, 'months').format('MMM/YYYY')}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <div style={{ width: "max-content", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", position: "relative", flexDirection: "column" }}>

                                    {marcos.map((marco, index) => {
                                        
                                        const marcoStart = moment(marco.data_inicio).startOf('day').toDate().getTime() - timelineStart;
                                        const marcoDuration = moment(marco.data_fim).endOf('day').toDate().getTime() - moment(marco.data_inicio).startOf('day').toDate().getTime();
                                        const marcoPosition = Math.round((marcoStart / totalDuration) * quantDias) * DAY_SIZE;
                                        const marcoLength = Math.round((marcoDuration / totalDuration) * quantDias) * DAY_SIZE;
                                        
                                        return (
                                            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ height: "35px", left: 0, zIndex: 4, width: offsetLeft, display: "flex", justifyContent: "center", padding: "0px 5px", alignItems: "center", backgroundColor: `${index % 2 == 0 ? Colors.white : "rgb(247, 248, 249)"}`, borderRight: `1px solid ${Colors.homePage.extraLightGrey}`, position: "sticky" }}>
                                                    <EllipsisText text={marco.descricao} />
                                                </div>
                                                <div style={{ left: offsetLeft, width: `${DAY_SIZE * quantDias}px`, paddingTop: '5px', paddingBottom: '5px', display: 'flex', flexDirection: 'column', backgroundColor: `${index % 2 == 1 ? "rgb(247, 248, 249)" : Colors.white}` }}>
                                                    <ResizableTimelineElement
                                                        element={{...marco}}
                                                        elementPosition={marcoPosition}
                                                        elementLength={marcoLength}
                                                        width={DAY_SIZE * quantDias}
                                                        changeElementCallback={(marco) => this.changeMarcoCallback(marco)}
                                                        timelineStart={timelineStart}
                                                        timelineEnd={timelineEnd}
                                                        edit={this.state.edit}
                                                        setEditId={(id) => this.setEditId(id)}
                                                        isSmallScreen={this.state.isSmallScreen}
                                                        daySize={DAY_SIZE}
                                                        type={'marco'}
                                                        />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                       
                    </div>
                    : 
                        this.state.edit ?
                            <DefaultButton
                            leftIcon={<FontAwesomeIcon icon={faAdd} />}
                            color={Colors.success}
                            textColor={Colors.white}
                            title={'Adicionar Marco'}
                            loading={this.state.loading}
                            onClick={async () => {
                                var data = await Sig.request('POST', 'pe/canvas/addMarcosItem',
                                    {
                                        id_pe_projeto_canvas: this.props.id_pe_projeto_canvas
                                    }
                                );

                                if(data){
                                    this.addMarco(data["item orcamento"]);
                                }
                            }}
                            style={{ marginRight: 8, marginTop: 16 }}
                            />
                        :
                            <p>Sem marcos para exibir.</p>
                    }
            </div>
        )
    }
    
}