import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DefaultLoader from "./DefaultLoader";
import Sig from "../../api/Sig";
import CustomTooltip from "./CustomTooltip";
import EssentialStyle from "../../style/EssentialStyle";

export default class DefaultIndicador extends React.Component {

    state = {
        loading: true,
        indicador: null,
    }

    async componentDidMount() {
        await this.loadIndicador();
    }

    

    async loadIndicador() {
        this.setState({ loading: true });
        
        if(this.props.id) {
            const indicador = await Sig.request('GET', 'indicador/getIndicador', { id: this.props.id, mapaEstrategico: true });
            
            if(indicador && indicador.status === 200) {
                this.setState({ indicador }, () => { this.setState({ loading: false }) });
            }
        }
    }

    renderDadosfaltantes() {
        return (

        <div style={{ ...EssentialStyle.rowFlexCenter, minHeight: '5em', width: '100%' }}>
            <div style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: '#ffc107', display: 'inline-block', marginRight: 5, padding: 3 }}>
                    <FontAwesomeIcon icon={faExclamation} color="white"/>
                </div>
                Indicador Faltante
            </div>
        )
    }

    render() {
        return !this.state.loading ? (
            <CustomTooltip placement={'bottom'} tooltip={this.state.indicador ? "Descrição: "+this.state.indicador.descricao : 'Sem Descrição'}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    {this.state.indicador ? this.state.indicador.nome : this.renderDadosfaltantes()}
                </div>
            </CustomTooltip>
        ) : !this.props.noLoading ? <DefaultLoader size={20} title={'Carregando dados..'} /> : null;
    }
}