import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faFile, faChevronLeft, faCheck, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import SessionHelper from "../../../../helper/SessionHelper";
import Sig from "../../../../api/Sig";
import DataHelper from "../../../../helper/DataHelper";
import AddTag from "../../../tools/AddTag";
import { toast } from "react-toastify";
import LoadingPage from "../../../../pages/LoadingPage";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../tools/CustomConfirm";
import SelectVisaoTemplate from "../../../modules/pe/visao/SelectVisaoTemplate";

export default class EditVisao extends React.Component {
    state = {
        loading: true,
        colabs: [],
        name: '',
        tags: [],
        responsable: null,
        participants: [],
        description: '',
        previousData: {},
        templateImage: null,
        templateId: null,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        const colabs = await Sig.request('GET', 'config/colaborador/getColaboradores');
        const participants = await Sig.request('POST','pe/visao/getParticipantes', { id: this.props.id });
        const visao = await Sig.request('POST', 'pe/visao/getVisao', { id: this.props.id });

        if (visao.visao) {
            if (visao.visao.nome) this.setState({ name: visao.visao.nome });
            if (visao.visao.descricao) this.setState({ description: visao.visao.descricao });

            if (colabs && colabs.length) {
                let selectedParticipantsIds = participants;
                let selectedParticipants = [];
                let responsable = null;

                colabs.forEach(participant => {
                    if (selectedParticipantsIds.includes(participant.id)) {
                        selectedParticipants.push(participant);
                    }
                    if (visao.visao.id_responsavel === participant.id) {
                        responsable = DataHelper.formatSelectData([participant], 'id', 'nome')[0];
                    }
                });

                this.setState({ templateImage: visao.visao.template, templateId: visao.visao.id_template, responsable, participants: DataHelper.formatSelectData(selectedParticipants, 'id', 'nome') });
            }
        }

        this.setState({ colabs }, () => { this.setState({ loading: false }) });
    }

    edit = async () => {
        if (!this.state.name || !this.state.responsable) return toast.warn('Informe todos os campos obrigatórios (*)');

        this.setState({ loading: true });

        await Sig.request('POST', 'pe/visao/update', 
            { 
                id: this.props.id, 
                nome: this.state.name, 
                id_responsavel: this.state.responsable.value, 
                template: this.state.templateImage, 
                id_template: this.state.templateId,
                participantes: this.state.participants.map(participant => participant.value)
            }
        );

        toast.success('Editado com sucesso');

        await this.loadData();
    }

    renderForm() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form className="w-75">
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome da visão de futuro*</Form.Label>
                            <Form.Control value={this.state.name} type="text" placeholder="Visão X..." onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Responsável*</Form.Label>
                            <Select value={this.state.responsable} options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione o responsável'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable onChange={(value) => { this.setState({ responsable: value }) }} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Participantes*</Form.Label>
                            <Select value={this.state.participants} options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione os participantes'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable isMulti onChange={(value) => { this.setState({ participants: value }) }} />
                        </Form.Group>
                    </Row>
                    <SelectVisaoTemplate selectedTemplateImage={this.state.templateImage} onTemplateSelect={(id, image) => { this.setState({ templateImage: image, templateId: id }) }}/>
                    <DefaultButton className="ms-1" color={Colors.success} leftIcon={<FontAwesomeIcon icon={faSave} />} title={'Salvar Alterações'} loading={this.state.loading} onClick={this.edit} />
                </Form>

            </div>
        );
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.props.settingsCallback() }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    render() {
        return this.state.loading ? <LoadingPage /> : (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderStepBack()}
                {this.renderForm()}
            </div>
        );
    }
}