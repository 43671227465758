import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faMagic, faSave, faHatWizard } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Sig from "../../../../api/Sig";
import TextEditor from "../../../tools/TextEditor/TextEditor";
import CustomConfirm from "../../../tools/CustomConfirm";
import SessionHelper from "../../../../helper/SessionHelper";
import DefaultLoader from "../../../tools/DefaultLoader";
import EssentialStyle from "../../../../style/EssentialStyle";
import LoadingPage from "../../../../pages/LoadingPage";

export default class EditAtualizacao extends React.Component {
    state = {
        step: 0,
        loading: true,
        loadingGpt: false,
        title: '',
        tituloAntigo: '',
        textoAntigo: '',
        description: ''
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        let request = await Sig.request('POST', 'ajuda/atualizacoes/getAtualizacao', { id: this.props.id });

        if(!request?.data) {
            toast.error('Erro ao carregar a nota de atualização.');
            return this.props.addCallback();
        }

        let { titulo, valor } = request.data;

        this.setState({ title: titulo, description: valor }, () => { this.setState({ loading: false }) });
    }

    edit = async () => {
        if (!this.state.title) return toast.warn('Informe o título.');
        if (!this.state.description) return toast.warn('Informe a descrição.');

        this.setState({ loading: true });

        let req = await Sig.request(
            'POST',
            'ajuda/atualizacoes/edit',
            {
                id: this.props.id,
                titulo: this.state.title,
                valor: this.state.description
            }
        );

        if (req?.success) {
            this.props.addCallback();
            toast.success('Nota de Atualização editada com sucesso!');
        }

        this.setState({ loading: false });
    }

    textReview = async () => {
        this.setState({ loadingGpt: true, textoAntigo: this.state.description });

        let req = await Sig.request(
            'POST',
            'ajuda/atualizacoes/revisarTexto',
            {
                valor: this.state.description
            }
        );

        if (req?.valor) {
            this.setState({ description: req.valor });
        }

        this.setState({ loadingGpt: false });
    }

    titleReview = async () => {
        this.setState({ loadingGpt: true, tituloAntigo: this.state.title });

        let req = await Sig.request(
            'POST',
            'ajuda/atualizacoes/revisarTitulo',
            {
                valor: this.state.description
            }
        );

        if (req?.valor) {
            this.setState({ title: req.valor });
        }

        this.setState({ loadingGpt: false });
    }

    renderForm() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form className="w-75">

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Título da Atualização*</Form.Label>
                            <Form.Control disabled={this.state.loadingGpt} value={this.state.title} type="text" placeholder="Digite o título..." onChange={(event) => { this.setState({ title: event.target.value }) }} />
                        </Form.Group>

                        {this.state.tituloAntigo &&
                            <Form.Group as={Col}>
                                <Form.Label>Título Anterior</Form.Label>
                                <div dangerouslySetInnerHTML={{ __html: this.state.tituloAntigo }} style={{ maxWidth: '100%', overflowWrap: 'break-word', wordBreak: 'break-word' }} />
                            </Form.Group>
                        }
                    </Row>


                    <Row className="mb-3">
                        {!this.state.loadingGpt ?
                            <Form.Group as={Col}>
                                <Form.Label>Detalhes da Atualização*</Form.Label>
                                <TextEditor
                                    onChange={(value) => { this.setState({ description: value }) }}
                                    defaultValue={this.state.description} height={'auto'} width={'100%'} />
                            </Form.Group>
                            :
                            <Form.Group as={Col}>
                                <div style={{ ...EssentialStyle.columnCenter, height: '100%', width: '100%', minHeight: 100 }}>
                                    <DefaultLoader />
                                </div>
                            </Form.Group>
                        }

                        {this.state.textoAntigo &&
                            <Form.Group as={Col}>
                                <Form.Label>Texto Anterior</Form.Label>
                                <div dangerouslySetInnerHTML={{ __html: this.state.textoAntigo }} style={{ maxWidth: '100%', overflowWrap: 'break-word', wordBreak: 'break-word' }}/>
                            </Form.Group>
                        }
                    </Row>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton disabled={!this.state.description.length} style={{ marginRight: 8 }} color={Colors.info} leftIcon={<FontAwesomeIcon icon={faHatWizard} />} title={'Sugerir Título'} loading={this.state.loadingGpt} onClick={this.titleReview} />
                            <DefaultButton disabled={!this.state.description.length} style={{ marginRight: 8 }} color={Colors.info} leftIcon={<FontAwesomeIcon icon={faMagic} />} title={'Revisão do Texto'} loading={this.state.loadingGpt} onClick={this.textReview} />
                            <DefaultButton disabled={!this.state.description.length || !this.state.title.length} color={Colors.success} leftIcon={<FontAwesomeIcon icon={faSave} />} title={'Salvar'} loading={this.state.loading} onClick={this.edit} />
                        </Form.Group>
                    </div>
                </Form>
            </div>
        );
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.step(false) }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    step(forward) {
        this.setState({ loading: true });

        if (this.state.step < 1 && !forward) {
            this.props.addCallback();
        } else {
            let step = forward ? ++this.state.step : --this.state.step;
            this.setState({ step });
        }

        this.setState({ loading: false });
    }


    renderSteps() {
        let stepContent;

        stepContent = this.renderForm();

        return stepContent;
    }

    render() {
        return this.state.loading ? <LoadingPage /> : (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderStepBack()}
                {this.renderForm()}
            </div>
        );
    }
}