import { faSearch, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Form, InputGroup, Col } from "react-bootstrap";
import DataHelper from "../../helper/DataHelper";
import Colors from "../../constants/Colors";
import EssentialStyle from "../../style/EssentialStyle";

export default class DefaultTableSearch extends React.Component {

    state = {
        typingTimeout: null,
        data: this.props.data,
        auxData: this.props.data,
        advancedFilter: false,
        text: '',
        isHovered: false,
    }

    searchObject(object, text, valid = false) {

        let keys = Object.keys(object);

        keys.forEach((item, key) => {

            if (object[keys[key]]) {

                if (typeof object[keys[key]] === 'object') {

                    if (!valid) {

                        valid = this.searchObject(object[keys[key]], text, valid);
                    }

                } else {

                    if (!valid) {

                        valid = object[keys[key]].toString().toUpperCase().indexOf(text) > -1;

                        if (valid) { return };
                    }
                }
            }
        });

        return valid;
    }

    handleSearch(text) {

        this.setState({ text: text });

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({

            typingTimeout: setTimeout(() => {

                text = text.toUpperCase();

                if (this.props.handleSearch) {
                    this.props.handleSearch(text);
                    return;
                }

                if (text.length) {

                    if (text.length >= 1) {

                        let data = this.state.data;
                        let result = [];

                        result = data.filter((item, key) => {

                            let valid = false;

                            this.props.fields.forEach((field) => {

                                if (item[field] && !valid) {

                                    if (typeof item[field] === 'object') {

                                        if (this.props.searchObjects) {

                                            valid = this.searchObject(item[field], text);
                                            return;
                                        }

                                    } else {

                                        let value = item[field].toString().toUpperCase();

                                        if (DataHelper.isDateString(value)) {

                                            let date = moment(value).format('DD/MM/YYYY HH:mm');

                                            if (date.indexOf(text) > -1) {

                                                valid = true;
                                                return;
                                            }

                                        } else {

                                            if (value.indexOf(text) > -1) {

                                                valid = true;
                                                return;
                                            }
                                        }
                                    }
                                }
                            });

                            return valid;
                        });

                        this.props.onDataUpdate(result);
                    }

                } else {

                    this.props.onEmpty();
                }

            }, 600)
        });
    }

    addAdvancedFilter = () => {
        this.props.toggleExpand(!this.state.advancedFilter);

        this.setState({ advancedFilter: !this.state.advancedFilter });

        if (!this.state.advancedFilter) {
            this.props.onEmpty();
        }
    }

    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    }

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    }

    render() {
        const iconStyle = this.state.isHovered ? { transform: 'scale(1.1)' } : {};
        let searchIconStyle = { borderRight: 'none', color: Colors.disabled, width: 30 };
        let inputGroupStyle = { width: this.props.width ? this.props.width : 275 };

        if(!this.state.advancedFilter) {
            searchIconStyle = { ...searchIconStyle, backgroundColor: Colors.white };
        }

        if(this.props.cardStyle) {
            inputGroupStyle = { ...inputGroupStyle, ...EssentialStyle.card };
        }

        return (
            <InputGroup style={inputGroupStyle}>
                <InputGroup.Text style={searchIconStyle}>
                    <FontAwesomeIcon
                        icon={faSearch}
                    />
                </InputGroup.Text>
                <Form.Control type="text"
                    style={{ borderLeft: 'none' }}
                    placeholder={this.state.advancedFilter ? "Utilize a Busca Avançada" : "Buscar..."}
                    onChange={(event) => { this.handleSearch(event.target.value) }}
                    disabled={this.state.advancedFilter}
                    value={this.state.advancedFilter ? "" : this.state.text}
                />
                {!this.props.hideExpand &&
                    <InputGroup.Text onClick={() => this.addAdvancedFilter()} style={{cursor: 'pointer'}}>
                        <FontAwesomeIcon
                            icon={faSlidersH}
                            style={iconStyle}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave} />
                        {this.props.appliedFilters ? 
                            <span style={{ backgroundColor: Colors.error, position: "absolute", width: 12, height: 12, borderRadius: "50%", top: 5, right: 5, fontSize: 11, lineHeight: "100%", color: Colors.white, fontWeight: 500 }}>{this.props.appliedFilters}</span>
                        : null
                        }
                    </InputGroup.Text>
                }
            </InputGroup>
        )
    }
}