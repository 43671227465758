import React from "react";
import Colors from "../../constants/Colors";
import LayoutHelper from "../../helper/LayoutHelper";
import EssentialStyle from "../../style/EssentialStyle";
import DefaultButton from "./DefaultButton";

export default class CustomConfirm extends React.Component {
    state = {
        isSmallScreen: LayoutHelper.isSmallScreen(),
        windowWidth: window.innerWidth
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen(), windowWidth: window.innerWidth});
    }

    render() {
        return (
            <div style={{
                padding: 30,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderRadius: 8,
                fontFamily: 'Arial, Helvetica, sans-serif',
                boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)',
                color: '#666',
                animation: 'react-confirm-alert-fadeIn 0.5s 0.2s forwards',
                width: !this.state.isSmallScreen ? 
                            this.props.widthPercentage ? 
                                (this.props.widthPercentage/100) * this.state.windowWidth 
                            : 
                                this.state.windowWidth * 0.5 
                        : 
                            this.props.widthPercentageMobile ? 
                                (this.props.widthPercentageMobile/100) * this.state.windowWidth 
                            :
                                this.state.windowWidth * 0.9
            }}>
                <h1 style={{ marginTop: 0, fontWeight: 'normal', color: Colors.disabled, fontSize: 30 }}>{this.props.title}</h1>
                {this.props.message || null}

                <div style={EssentialStyle.rowReverseFlexStart}>
                    {this.props.buttons.reverse().map(button => (
                        <DefaultButton key={`${button.label}-key`} title={button.label} color={button.color || Colors.notSelected} textColor={button.textColor || Colors.dark} onClick={button.onClick} style={{ marginLeft: 5 }} loading={this.props.loading} />
                    ))}
                </div>
            </div>
        );
    }
}