import React, { Component } from 'react';
import SwotHelper from '../../helper/pe/SwotHelper';
import ProjetoHelper from '../../helper/pe/ProjetoHelper';
import MapaHelper from '../../helper/pe/MapaHelper';
import LoadingComponent from './LoadingComponent';

export default class GutMatrixCalculus extends Component {

    state = {
        loading: true,
        pontuacao: [],
        value: null,
    }

    async componentDidMount() {
        await this.getValue();

        this.setState({ loading: false });
    }

    async getValue() {
        
        if(this.props.idSwot){
            let value = await SwotHelper.getGutItemValue(this.props.idSwot, this.props.item);
            this.setState({ value });
        } else if(this.props.idPeMapa) {
            let value = await MapaHelper.getGutItemValue(this.props.idPeMapa, this.props.item);
            this.setState({ value });
        } else if(this.props.projetos) {
            let value = await ProjetoHelper.getGutItemValue(this.props.item);
            this.setState({ value });
        }
        
    }

    render() {
        return this.state.loading ? <LoadingComponent/> : (
            <div style={{ fontWeight: 'bold' }}>{this.state.value}</div>
        );
    }
}
