import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faDownload, faFile, faFileAlt, faImage, faPlus, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import SessionHelper from "../../../../helper/SessionHelper";
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import Constants from "../../../../constants/Api";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../tools/CustomConfirm";
import Sig from "../../../../api/Sig";
import EssentialStyle from "../../../../style/EssentialStyle";


export default class UploadCanvaFiles extends React.Component {
    state = {
        loading: true,
        loadingUpload: false,
        files: [],
        fileToUpload: null,
        fileToUploadPreview: null,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        const files = (await Sig.request("POST", "pe/canvas/getFiles", { id: this.props.id }))?.arquivos || [];

        this.setState({ files: files }, () => { this.setState({ loading: false }) });
    }

    removeFile = async (id, url) => {
        this.setState({ loadingUpload: true });

        const req = await Sig.request("POST", "pe/canvas/removeFile", { id });

        if (req.arquivo) {
            toast.success('Arquivo removido!');
            this.loadData();
        } else {
            toast.error('Ocorreu um erro ao remover o arquivo, tente novamente mais tarde.')
        }

        this.setState({ loadingUpload: false });
    }

    canRemoveTemplate(id_colaborador) {

        let canRemove = false;

        if (id_colaborador == SessionHelper.getData().id_usuario) {

            canRemove = true;

        } else {

            let allowedPermissions = ['Administrador', 'Consultor', 'Gerente'];

            if (allowedPermissions.includes(SessionHelper.getPermissionType())) {

                canRemove = true;
            }
        }

        return canRemove;
    }

    renderFile(file) {
        const prefix = `${Constants.getSigEndPoint()}/upload/geral`;

        return (
            <div style={{ margin: 10, display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ paddingLeft: 10, paddingRight: 10, borderRadius: 5, cursor: 'pointer', display: 'flex', flexDirection: 'row', height: 64, width: '100%', marginBottom: 10, backgroundColor: Colors.secondaryButton, alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100% - 90px)', alignContent: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <FontAwesomeIcon icon={faFileAlt} color={Colors.dark} fontSize={20} style={{ marginRight: 8 }} />
                        <a title={file.name} style={{ fontWeight: 'bold', color: Colors.dark, fontSize: 14, maxWidth: '', marginRight: 20 }}>{file.name.length > 30 ? `${(file.name).slice(0, 27)}...` : file.name}</a>
                    </div>
                    <FontAwesomeIcon
                        title={'Baixar Arquivo'}
                        style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', width: 40 }}
                        onClick={() => {
                            window.parent.postMessage({ type: 'download', file, prefix }, '*');
                        }}
                        icon={faDownload}
                        color={Colors.info}
                        fontSize={22}
                    />
                    {!this.props.hideAdd &&
                        <FontAwesomeIcon
                            title={'Remover Arquivo'}
                            style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', width: 40 }}
                            onClick={() => {
                                confirmAlert({
                                    customUI: ({ onClose }) => (
                                        <CustomConfirm
                                            title={'Remover este Anexo?'}
                                            message={'Essa ação é irreversível'}
                                            buttons={[
                                                {
                                                    label: 'Remover',
                                                    color: SessionHelper.getColor(),
                                                    textColor: Colors.light,
                                                    onClick: () => { this.removeFile(file.id, file.url); onClose(); }
                                                },
                                                {
                                                    label: 'Cancelar',
                                                    onClick: () => { onClose(); }
                                                },
                                            ]}
                                        />
                                    )
                                });
                            }}
                            icon={faTimesCircle}
                            color={Colors.error}
                            fontSize={22}
                        />
                    }
                </div>
            </div>
        );
    }

    handleFileUpload = async (v) => {
        this.setState({ loadingUpload: true });

        const file = v.target.files[0];
        const maxSize = 10000000;

        if (file && file.size <= maxSize) {
            this.setState({ fileToUpload: file, fileToUploadPreview: URL.createObjectURL(file), name: !this.state.name ? file.name.replace(/[\W_]+/g, "") : this.state.name });
        } else {
            if (file && file.size >= maxSize) {
                toast.warn('O arquivo é muito grande, use um de menos de 10 MB');
            } else {
                toast.warn('O arquivo não cumpre os requisitos necessários.');
            }
        }

        this.setState({ loadingUpload: false });
    }

    doUploadFile = async () => {
        this.setState({ loadingUpload: true });

        const formData = new FormData();
        formData.append('file', this.state.fileToUpload);
        formData.append('nome', this.state.name);
        formData.append('campo', this.props.field);
        formData.append('id_projeto_canvas', this.props.id);

        const req = await Sig.request("POST", "pe/canvas/uploadFile", formData, 'multipart/form-data');

        if (req.id) {
            toast.success('Arquivo inserido com sucesso!');
            this.setState({ fileToUpload: null, fileToUploadPreview: null, name: '', addingNew: false });
            this.loadData();
        } else {
            toast.error('Ocorreu um erro ao fazer o upload, tente novamente mais tarde.');
            this.setState({ fileToUpload: null, fileToUploadPreview: null, name: '', addingNew: false });
        }

        this.setState({ loadingUpload: false });
    }

    renderAddNew() {
        return this.state.addingNew ?
            (
                <div style={{ margin: 10, display: 'flex', flexDirection: 'column' }}>
                    <input
                        multiple={false}
                        accept="image/*, .pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xlsx, .xls, .pptx, .ppt"
                        style={{ display: 'none' }}
                        id="upload-template"
                        name="upload-template"
                        type="file"
                        onChange={this.handleFileUpload}
                    />

                    {this.state.fileToUploadPreview ?
                        <div style={{ paddingLeft: 10, paddingRight: 10, borderRadius: 5, cursor: 'pointer', display: 'flex', flexDirection: 'row', height: 100, width: '100%', marginBottom: 10, backgroundColor: Colors.secondaryButton, alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '70%', minWidth: '70%', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon icon={faFileAlt} color={Colors.dark} fontSize={20} style={{ marginRight: 6, marginTop: 10 }} />
                                <a style={{ marginTop: 10, fontWeight: 'bold', color: Colors.dark, fontSize: 14, maxWidth: '' }}>{(this.state.fileToUpload.name).slice(0, 14)}...</a>
                            </div>
                            <FontAwesomeIcon
                                style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)' }}
                                onClick={() => { this.setState({ fileToUploadPreview: null, fileToUpload: null, name: `` }) }}
                                icon={faTimesCircle}
                                color={Colors.error}
                                fontSize={22}
                            />
                        </div>
                        :
                        <label htmlFor="upload-template">
                            <div onClick={() => { this.setState({ addingNew: true }) }} style={{ borderRadius: 5, cursor: 'pointer', display: 'flex', flexDirection: 'row', height: 80, width: '100%', marginBottom: 10, backgroundColor: Colors.secondaryButton, alignContent: 'center', alignItems: 'center', justifyContent: 'center', border: `1.5px ${Colors.dark} dashed` }}>
                                <FontAwesomeIcon icon={faFileAlt} color={Colors.dark} fontSize={40} />
                                <div style={{ ...EssentialStyle.columnStart, marginLeft: 10 }}>
                                    <a style={{ fontWeight: 'bold', color: Colors.dark, fontSize: 14 }}>Clique aqui para escolher um arquivo</a>
                                    <a style={{ marginTop: 2, fontWeight: 'bold', color: Colors.dark, fontSize: 12 }}>Tamanho máximo (10 MB)</a>
                                </div>
                            </div>
                        </label>
                    }


                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome do Arquivo</Form.Label>
                            <Form.Control type="text" placeholder="Arquivo x..." defaultValue={this.state.name || ``} onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <DefaultButton loading={this.state.loadingUpload} color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} style={{ width: '49%' }} title={'Cancelar'} onClick={() => { this.setState({ fileToUpload: null, fileToUploadPreview: null, name: '', addingNew: false }) }} />
                        <DefaultButton loading={this.state.loadingUpload} disabled={!this.state.fileToUpload || !this.state.name} color={Colors.success} leftIcon={<FontAwesomeIcon icon={faImage} />} style={{ width: '49%' }} title={'Salvar'} onClick={this.doUploadFile} />
                    </div>
                </div>
            ) :
            (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DefaultButton
                        title={'Anexar Arquivo'}
                        color={Colors.success}
                        style={{ marginBottom: 20, width: 260 }}
                        disabled={(this.props.limit || 1) <= this.state.files.length}
                        loading={this.state.loading || this.state.loadingUpload}
                        leftIcon={<FontAwesomeIcon icon={faFileAlt} />}
                        onClick={() => {
                            this.setState({ addingNew: true })
                        }}
                    />
                </div>
            );
    }

    renderFiles() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {!this.props.hideAdd && this.renderAddNew()}
                {!this.props.print && <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', marginBottom: 10 }}>
                    {this.state.files.map(template => (this.renderFile(template)))}
                </div>}
            </div>

        );
    }

    render() {
        return (
            <div style={{ minWidth: '100%', marginBottom: 10 }}>
                {this.renderFiles()}
            </div>
        );
    }
}