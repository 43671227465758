import React from "react";

import LayoutHelper from "../../../../../helper/LayoutHelper";
import LoadingPage from "../../../../../pages/LoadingPage";
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultButton from "../../../../tools/DefaultButton";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import './RelatorioGrafico.css';
import EssentialStyle from "../../../../../style/EssentialStyle";
import DefaultLoader from "../../../../tools/DefaultLoader";

import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";

export default class RelatorioGrafico extends React.Component {
    state = {
        loading: true,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        lang: this.props.lang,
        resultado: [],
        coluna: ''
    }

    async componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.verifyScreen);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.lang !== this.props.lang) {
            this.setState({ lang: this.props.lang });
        }
    }

    async loadData() {
        this.setState({ loading: true });

        let resultadoGrafico = await DiagnosticoHelper.getResultadoGrafico(this.props.grafico.id);

        this.setState({ resultado: resultadoGrafico.resultado || [], coluna: resultadoGrafico.coluna, loading: false });
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    renderGrafico() {
        const { resultado, lang } = this.state;

        const originalCategories = resultado.map(item => item.texto[lang]);
        const categories = resultado.map(item => item.texto[lang].substring(0, 100));
        const seriesData = resultado.map(item => ({
            y: item.resultado.valor,
            color: item.resultado.cor,
            countRespostas: item.resultado.respostas.length,
            respostas: item.resultado.respostas
        }));
        
        // Filter out items where respostas.length is 0
        const filteredSeriesData = seriesData.filter(item => item.countRespostas > 0);
        
        // Flatten the array of respostas
        const allRespostas = filteredSeriesData.flatMap(item => item.respostas);
        
        // Calculate the total and average
        const total = allRespostas.reduce((sum, value) => sum + value, 0);
        const generalScore = allRespostas.length > 0 ? (total / allRespostas.length).toFixed(3) : 0;

        let turnToInitals = false;

        if (this.props.grafico.largura == 3) {
            if (categories.length > 20) turnToInitals = true;
        } else if (this.props.grafico.largura == 2) {
            if (categories.length > 12) turnToInitals = true;
        } else {
            if (categories.length > 10) turnToInitals = true;
        }

        let resumo = [
            { id: 1, text: ' - Resumo' },
            { id: 2, text: ' - Summary'  },
            { id: 3, text: ' - Resumen'  },
        ];

        var renderer = null;

        const options = {
            chart: {
                type: 'column',
                events: {
                    load: function () {
                        const chart = this;
                        const generalScoreHtml = `
                            <div style="backgroundColor: white; color: #000000; font-size: 12px; border: 1px solid black; padding: 3px; display: flex; flex-direction: column; justify-content: center; text-align: center;">
                                <div style="font-size: 16px; font-weight: 600;">${Math.round(generalScore)}%</div>
                                <div style="font-size: 12px; font-weight: 200; margin-top: 2px;">Score</div>
                            </div>
                        `;

                        renderer = chart.renderer.html(
                            generalScoreHtml,
                            0,
                            0
                        );

                        renderer.align({
                            align: 'right',
                            verticalAlign: 'top',
                            x: -`${Math.round(generalScore)}%`.length * 10,
                            y: -30
                        }, false, 'plotBox');

                        renderer.add();

                    },
                }
            },
            title: {
                text: this.state.coluna.texto[lang] + (this.state.coluna.resumo ? resumo.find(item => item.id === lang).text : ''),
            },
            xAxis: {
                categories: categories,
                title: {
                    text: ''
                },
                labels: {
                    rotation: turnToInitals ? -90 : 0,
                    formatter: function () {
                        if (turnToInitals) {
                            return this.value.trim().split(' ').map(word => word[0] ? word[0].toUpperCase() : '').join('').substring(0, 5);
                        }

                        const text = this.value;
                        const words = text.split(' ');
                        const maxCombinedLength = 11;
                        const maxRows = 3;

                        let formattedText = '';
                        let currentRow = 1;

                        for (let i = 0; i < words.length; i++) {
                            if (i > 0) {
                                const combinedLength = words[i - 1].length + words[i].length;
                                if (combinedLength > maxCombinedLength) {
                                    if (currentRow < maxRows) {
                                        formattedText += '<br/>';
                                        currentRow++;
                                    } else {
                                        formattedText += ' ';
                                    }
                                } else {
                                    formattedText += ' ';
                                }
                            }
                            formattedText += words[i];
                        }

                        return formattedText;
                    },
                    useHTML: true,
                    style: {
                        whiteSpace: 'nowrap'
                    },
                }
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: ''
                },
                max: 100,
                labels: {
                    enabled: false
                },
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    const index = this.point.index;
                    const originalText = originalCategories[index];
                    return `
                        <div style="color: #000000; font-size: 12px; display: flex; flex-direction: column; justify-content: center; text-align: center;">
                            <div style="font-size: 14px; font-weight: 600;">${this.y}%</div>
                            <div style="font-size: 12px; font-weight: 200;">${originalText}</div>
                        </div>
                    `;
                },
                useHTML: true
            },
            series: [{
                name: 'Media',
                data: seriesData,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return Math.round(this.y) + '%';
                    },
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#000000'
                    }
                },
            }]
        };

        return (
            <div className="relatorio-grafico">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        );
    }

    render() {
        return (
            <div className={'relatorio-grafico'} style={{ ...EssentialStyle.card }}>
                {this.state.loading ? <DefaultLoader /> : this.renderGrafico()}
            </div>
        );
    }
}