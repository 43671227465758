import React from 'react';
import './PlanoAcaoRelatorio.css';

import EssentialStyle from '../../../../style/EssentialStyle';
import DefaultLoader from '../../../tools/DefaultLoader';
import { Collapse, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../../../constants/Colors';
import SessionHelper from '../../../../helper/SessionHelper';
import ChartHelper from '../../../../helper/inicio/ChartHelper';
import UserAvatar from '../../../tools/UserAvatar';
import moment from 'moment';
import DefaultButton from '../../../tools/DefaultButton';
import PlanoAcaoRelatorioTabela from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioTabela';
import PlanoAcaoRelatorioLista from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioLista';
import PlanoAcaoRelatorioStatus from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioStatus';
import PlanoAcaoRelatorioDetails from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioDetails';
import PlanoAcaoRelatorioColaboradores from './PlanoAcaoRelatorioComponents/PlanoAcaoRelatorioColaboradores';

export default class PlanoAcaoRelatorio extends React.Component {
    
    state = {
        displayContent: true,
        activeTipoVisaoTab: 'lista',
        tipoVisaoTabs: [
            { name: 'lista', title: 'Lista' },
            { name: 'tabela', title: 'Tabela' },
        ],
        tableSort: {
            sort: false,
            sortAsc: true,
            sortField: "",
        },
        filter: {
            view: 'planos',
            values: [],
        }, 
        filteredElements: [],
        view: 'planos',
        status: [
            "Não Iniciado",
            "Em Desenvolvimento",
            "Concluído",
            "Atrasado",
            "Cancelado",
        ],
        loading: false,
    }

    componentDidMount() {
        this.setState({ filteredElements: [...this.props.planos] });
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.planos !== prevProps.planos) {
            this.setState({ view: 'planos', filteredElements: [...this.props.planos], filter: { view: 'planos', values: [] }, tableSort: { sort: false, sortAsc: true, sortField: "" } });
        }

        if(this.state.filter !== prevState.filter || this.state.tableSort !== prevState.tableSort) {
            this.filterElements();
        }
    }

    filterElements = async () => {
        if(this.state.filter.view === 'planos') {
            var planos = await this.sortPlanosAcao(this.filterPlanos());
            this.setState({  filteredElements: [...planos], view: this.state.filter.view, loading: false });
        }
        if(this.state.filter.view === 'etapas') {
            this.setState({ loading: false, view: this.state.filter.view, filteredElements: this.filterEtapas(this.props.planos.reduce((acc, plano) => {
                if(plano.etapas && plano.etapas.length > 0) {
                    return acc = [...acc, ...plano.etapas];
                }
                return acc;
            }, []))});
        }
    }
    
    filterEtapas = (etapas) => {
        if(this.state.filter.values && this.state.filter.values.length > 0) {
            return [...etapas].filter(etapa => {
                return !this.state.filter.values.some((filter) => {
                    var field;

                    if(filter.field === 'responsavel') {
                        field = etapa.colaborador.id;
                    } else {
                        field = etapa[filter.field];
                    }

                    if(field === undefined || field === null) return true;

                    if(filter.value == 'Atrasada'){
                        if(etapa.atrasada && etapa.status != 2 && etapa.status != 3){
                            return false;
                        }
                        return true;
                    }

                    if(filter.operation == '>') {
                        return !(field > filter.value)
                    } else if(filter.operation == '<') {
                        return !(field < filter.value)
                    } else 
                        return !(field == filter.value)
                }   
            )}
        )}
    }

    filterPlanos = () => {
        if(this.state.filter.values && this.state.filter.values.length > 0) {
            return [...this.props.planos].filter(plano => {
                return !this.state.filter.values.some((filter) => {
                    var field;

                    switch(filter.field) {
                        case 'vinculo':
                            if(!plano.vinculos || plano.vinculos.length == 0) return true;
                            return !plano.vinculos.some(vinculo => vinculo.tipo == filter.value);
                        case 'superior':
                            if(plano.superior && plano.aprovacao) 
                                return false;
                            else 
                                return true;
                        case 'aprovacao':
                            if(!plano.aprovacao) 
                                return true;
                            else 
                                field = plano.aprovacao.status_text;
                            break;
                        case 'vinculos':
                            if(!plano.vinculos) 
                                field = 0;
                            else
                                field =  plano.vinculos.length;
                            break;
                        case 'tendencia':
                            if((plano.status != 'Concluído' && plano.status != 'Cancelado') && !plano.verificacao){
                                if(!plano.tendencia) 
                                    field = 'Sem Informação';
                                else
                                    field = plano.tendencia.msg;
                            } else {
                                return true;
                            }
                            break;
                        case 'verificacao':
                            if(!plano.verificacao) 
                                return true;
                            else
                                field = plano.verificacao.eficaz;
                            break;
                        case 'responsavel':
                            if(!plano.responsavel) 
                                return true;
                            else
                                field = plano.responsavel.id;
                            break;
                        default:
                            field = plano[filter.field];
                            break;
                    }

                    if(field === undefined || field === null) return true;

                    if(filter.operation == '>') {
                        return !(field > filter.value)
                    } else if(filter.operation == '<') {
                        return !(field < filter.value)
                    } else 
                        return !(field == filter.value)
                })
            })
        }
        return [...this.props.planos];
    }
    
    sortPlanoAcao = (a, b) => {
        if(this.state.tableSort.sortField === "nome") {
            return a[this.state.tableSort.sortField].localeCompare(b[this.state.tableSort.sortField]) * (this.state.tableSort.sortAsc ? 1 : -1);
        }
        if(this.state.tableSort.sortField === "responsavel") {
            return a[this.state.tableSort.sortField].nome.localeCompare(b[this.state.tableSort.sortField].nome) * (this.state.tableSort.sortAsc ? 1 : -1);
        }
        if(this.state.tableSort.sortField === "status") {
            if(this.state.status.indexOf(a[this.state.tableSort.sortField]) < this.state.status.indexOf(b[this.state.tableSort.sortField])) {
                return this.state.tableSort.sortAsc ? -1 : 1;
            } 
            if(this.state.status.indexOf(a[this.state.tableSort.sortField]) > this.state.status.indexOf(b[this.state.tableSort.sortField])) {
                return this.state.tableSort.sortAsc ? 1 : -1;
            }
            return 0;
        }

        if (a[this.state.tableSort.sortField] < b[this.state.tableSort.sortField]) {
            return this.state.tableSort.sortAsc ? -1 : 1;
        }
        if (a[this.state.tableSort.sortField] > b[this.state.tableSort.sortField]) {
            return this.state.tableSort.sortAsc ? 1 : -1;
        }
        return 0;
    }

    sortPlanosAcao = async (planos) => {
        return new Promise((resolve, reject) => {
            if(this.state.tableSort.sort && this.state.tableSort.sortField !== "" && planos.length > 0) {
                planos = planos.sort(this.sortPlanoAcao);
            }
            resolve(planos);
        });
    }

    renderList = () => {
        if(!this.props.planos || this.props.planos.length === 0) return null;
        return (
            <PlanoAcaoRelatorioLista elements={this.state.filteredElements} isSmallScreen={this.props.isSmallScreen} view={this.state.view} loading={this.state.loading}/>
        )
    }

    filter = (filter) => {
        this.setState({ filter, loading: true });   
    }

    sortTable = (sort) => {
        this.setState({ tableSort: sort, loading: true });
    }

    renderTable = () => {
        if(!this.props.planos || this.props.planos.length === 0) return null;

        return (
            <PlanoAcaoRelatorioTabela elements={this.state.filteredElements} isSmallScreen={this.props.isSmallScreen} sortCallback={this.sortTable} view={this.state.view} sort={this.state.tableSort} loading={this.state.loading}/>	
        )
    }

    renderTipoVisaoTabs() {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, backgroundColor: Colors.secondaryButton, placeSelf: "center", borderRadius: 5}}>
                {this.state.tipoVisaoTabs.map((tab, index) => {
                    return (
                        <DefaultButton
                            key={`tab-${index}`}
                            title={tab.title}
                            color={this.state.activeTipoVisaoTab === tab.name ? Colors.dark : Colors.secondaryButton}
                            textColor={this.state.activeTipoVisaoTab === tab.name ? Colors.white : Colors.dark}
                            onClick={() => { this.handleTipoVisaoTabs(tab) }}
                            style={{ width: this.props.isSmallScreen ? '40vw' : 90, maxWidth: 150, height: 30, padding: 0, alignItems: "center", justifyContent: "center" }}
                        />
                    );
                })}
            </div>
        )
    }

    handleTipoVisaoTabs = (tab) => {
        if(this.state.activeTipoVisaoTab === tab.name) return;
        this.setState({ activeTipoVisaoTab: tab.name });
    }

    renderContent = () => {
        return(
        <>
            <div style={{...EssentialStyle.rowSpaceBetween, height: 40, minHeight: 40, backgroundColor: SessionHelper.getBackgroundColor(), width: "100%", position: "sticky", left: 0}}>
                {this.state.displayContent ? this.renderTipoVisaoTabs() : <div></div>}
                <DefaultButton 
                    leftIcon={!this.state.displayContent ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
                    color={"transparent"}
                    textColor={Colors.dark}
                    onClick={() => this.setState({ displayContent: !this.state.displayContent })}
                    style={{ width: 30, height: 30, alignItems: "center", justifyContent: "center", padding: 0, marginRight: 10, position: 'sticky', left: "calc(100%)" }}
                    />
            </div>
            <div style={{...EssentialStyle.columnStart, borderRadius: 8, maxHeight: this.state.displayContent ? "unset" : 40 , flex: 1, backgroundColor: SessionHelper.getBackgroundColor(), transition: "all 0.5s ease"}}>
                <Collapse in={this.state.displayContent} style={{transition: "all 0.5s ease", height: "calc(100% - 40px)", maxHeight: "calc(100% - 40px)", width: this.state.activeTipoVisaoTab == 'lista' ? "100%" : "unset" }}>
                    <div>
                        {!this.props.loading ? 
                            <div>
                                {this.state.activeTipoVisaoTab == 'lista' ? this.renderList() : this.renderTable()}
                            </div>
                        :   
                            <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10, height: "calc(100% - 40px)" }}>
                                <DefaultLoader />
                            </div>
                        }
                    </div>
                </Collapse>
            </div>
        </>
        )
    }

    render() {
        if(!this.props.planos || this.props.planos.length === 0 && !this.props.loading) {
            return (
                <div style={{...EssentialStyle.rowSpaceBetween}}>
                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10 }}>
                        <h2 style={{ color: Colors.homePage.grey, fontSize: 20, fontWeight: 500, textAlign: 'center' }}>Nenhum plano de ação encontrado</h2>
                    </div>
                </div>
            );
        }

        return (
            <div className={"relatorio-pa"} style={{...EssentialStyle.columnStart, height: `calc(100vh - ${this.props.headerSize}px)`, width: "100%", gap: 2}}>
                {this.props.loading ? 
                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', marginTop: 10, paddingTop: 15, height: "100%" }}>
                        <DefaultLoader />
                    </div> 
                : 
                <>
                    <div style={{maxHeight:"100%", width: "100%", overflowX: "auto", paddingTop: 15}}>
                        <PlanoAcaoRelatorioStatus planos={this.props.planos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter}/>
                        <PlanoAcaoRelatorioDetails planos={this.props.planos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter}/>
                        <PlanoAcaoRelatorioColaboradores planos={this.props.planos} isSmallScreen={this.props.isSmallScreen} filterCallback={this.filter}/>
                        {this.renderContent()}
                    </div>
                </>
                }
            </div>
        );
    }
}