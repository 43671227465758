import React from "react";
import DiagnosticoHelper from "../../helper/diagnostico/DiagnosticoHelper";

export default class DiagnosticoProcessosPage extends React.Component {

    state = {
        construction: true,
        loading: true,
    }

    async componentDidMount() {
    }


    render() {
        if (this.state.construction) return DiagnosticoHelper.renderConstruction();
    }
}