import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import QuillEditor from 'react-quill';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.css';
import Colors from '../../../constants/Colors';

import "quill-mention";
import "quill-mention/dist/quill.mention.min.css";
import UserAvatar from '../UserAvatar';

let Image = Quill.import('formats/image');
class ResizableImage extends Image {
    static create(value) {
        let node = super.create(value);
        node.setAttribute('width', '100%');
        node.setAttribute('height', 'auto');
        node.setAttribute('draggable', 'false');
        return node;
    }
}

ResizableImage.blotName = 'image';
ResizableImage.tagName = 'img';

Quill.register({
    'formats/image': ResizableImage
});

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue || '',
            mentions: this.props.mentions || []
        };
        this.quill = React.createRef();
        this.modules = {
            toolbar: {
                container: [
                    [{ header: [6, 5, false, 4, 3, 2, 1] }],
                    ['bold', 'italic', 'underline', 'blockquote'],
                    [{ color: [] }],
                    [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' },
                    ],
                    ['link', 'image'],
                    ['clean'],
                ],
                handlers: {
                    image: this.imageHandler,
                },
            },
            clipboard: {
                matchVisual: true,
            },
            mention: {
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                mentionDenotationChars: ["@"],
                positioningStrategy: 'fixed',
                defaultMenuOrientation: 'bottom',
                renderItem: (item, searchTerm) => {
                    const container = document.createElement('div');
                    const root = createRoot(container);
                    root.render(<UserAvatar user={item.original} showName showNameAdaptative onClick={(e) => { e.preventDefault() }} />);

                    return container;
                },
                source: (searchTerm, renderList, mentionChar) => {
                    let values;

                    if (mentionChar === "@") {
                        values = this.state.mentions || [];
                    }

                    if (searchTerm.length === 0) {
                        renderList(values, searchTerm);
                    } else {
                        const matches = [];
                        for (let i = 0; i < values.length; i++) {
                            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                        }
                        renderList(matches, searchTerm);
                    }
                }
            }
        };
        this.formats = [
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'color',
            'clean',
            'mention'
        ];
    }

    componentDidMount() {
        if (this.props.defaultValue) this.setState({ value: this.props.defaultValue });
        if (this.quill?.current?.editor) {
            var keyboard = this.quill.current.editor.getModule('keyboard');
            var bimdings = keyboard.bindings[13];
            if (this.props.disabledEnterEvent) {
                delete keyboard.bindings[13];
                keyboard.addBinding({ key: 13, metaKey: null, ctrlKey: null, altKey: null }, function () { return false; });
            }
            const toolbar = this.quill.current.editor.container.parentElement.querySelector(".ql-toolbar");
            if (this.props.height) {
                var toolbarHeight = toolbar?.clientHeight ? (toolbar.clientHeight + 2) : 0;
                this.quill.current.editor.container.style.height = this.props.height + toolbarHeight + "px";
            }

            if (this.props.maxHeight) {
                this.quill.current.editor.container.style.maxHeight = this.props.maxHeight + "px";
                this.quill.current.editor.container.style.overflowY = 'auto';
            }

            if (this.props.minHeight) {
                this.quill.current.editor.container.style.minHeight = this.props.minHeight + "px";
            }

            this.quill.current.editor.container.style.borderTop = '1px solid #ccc';
            this.quill.current.editor.container.style.borderRadius = '8px';
            if (toolbar) {
                toolbar.style.display = 'none';
                toolbar.style.borderRadius = '8px 8px 0px 0px';
            }
        }

        // Eventos do quill-mention
        // window.addEventListener('mention-hovered', (event) => {console.log('hovered: ', event)}, false);
        // window.addEventListener('mention-clicked', (event) => {console.log('clicked: ', event)}, false);
    }

    imageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const imageUrl = reader.result;
                const quillEditor = this.quill.current.getEditor();

                const range = quillEditor.getSelection(true);
                quillEditor.insertEmbed(range.index, 'image', imageUrl, 'user');
            };

            reader.readAsDataURL(file);
        };
    };

    handleValueChange = (value) => {
        this.setState({ value });

        if (this.props.onChange) this.props.onChange(value);
    }

    handleOnBlur = (value) => {
        if (this.props.onBlur) this.props.onBlur(this.state.value);
    }

    handleChangeSelection = (range, source, editor) => {
        if (!this.quill.current) return;
        if (range && !this.props.hideToolbar) {
            const toolbar = this.quill.current.editor.container.parentElement.querySelector(".ql-toolbar");
            this.quill.current.editor.container.style.borderTop = '0px';
            this.quill.current.editor.container.style.borderRadius = '0px 0px 8px 8px';
            if (toolbar)
                toolbar.style.display = 'block';
            if (this.props.height) {
                this.quill.current.editor.container.style.height = this.props.height + "px";
            }
        } else {
            if (!this.quill.current.editor.container.parentElement.contains(document.activeElement)) {
                const toolbar = this.quill.current.editor.container.parentElement.querySelector(".ql-toolbar");
                if (this.props.height) {
                    var toolbarHeight = toolbar?.clientHeight ? (toolbar.clientHeight + 2) : 0;
                    this.quill.current.editor.container.style.height = (this.props.height + toolbarHeight) + "px";
                }

                if (this.props.maxHeight) {
                    this.quill.current.editor.container.style.maxHeight = (this.props.maxHeight) + "px";
                    this.quill.current.editor.container.style.overflowY = 'auto';
                }

                if (this.props.minHeight) {
                    this.quill.current.editor.container.style.minHeight = this.props.minHeight + "px";
                }

                this.quill.current.editor.container.style.borderTop = '1px solid #ccc';
                this.quill.current.editor.container.style.borderRadius = '8px';
                if (toolbar)
                    toolbar.style.display = 'none';
            }
        }
    }

    render() {
        return (
            <div style={{ borderRadius: 8, backgroundColor: this.props.backgroundColor || Colors.secondary, marginBottom: this.props.noMargin ? 0 : 15, height: this.props.height || 'auto', width: this.props.width || '100%', maxWidth: "100%", maxHeight: this.props.maxHeight || "unset", minHeight: this.props.minHeight || "unset" }}>
                <QuillEditor
                    ref={this.quill}
                    className={{}}
                    style={{ height: this.props.height || 'auto', width: this.props.width || 'auto', margin: 0, maxHeight: this.props.maxHeight || "unset", minHeight: this.props.minHeight || "unset" }}
                    placeholder={this.props.placeholder || `Escreva o texto aqui...`}
                    theme='snow'
                    value={this.state.value}
                    formats={this.formats}
                    modules={this.props.hideToolbar ? { toolbar: false } : this.modules}
                    onChange={this.handleValueChange}
                    onBlur={this.handleOnBlur}
                    onChangeSelection={this.handleChangeSelection}
                    onKeyDown={this.props.onKeyDown}
                />
            </div>
        );
    }
}

export default forwardRef((props, ref) => {
    const innerRef = useRef();
    useImperativeHandle(ref, () => innerRef.current.quill.current, []);
    return <TextEditor {...props} ref={innerRef} />
});