/*
    lib: react-data-table-component
    code: https://github.com/jbetancur/react-data-table-component
    docs: https://react-data-table-component.netlify.app/?path=/docs/api-columns--page#column-definitions-api
*/

import React, { Component, forwardRef } from 'react';
import DataTable from 'react-data-table-component';

const paginationComponentOptions = {
    rowsPerPageText: 'Linhas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todas',
};

export default class DefaultTable extends Component {
    state = {

    };

    render() {
        return (
            <DataTable
                theme="default"
                title={this.props.title || ``}
                columns={this.props.columns || {}}
                data={this.props.data || []}
                paginationComponentOptions={paginationComponentOptions}
                noDataComponent={<div style={{ padding: 20, color: 'grey' }}>Nenhum registro encontrado.</div>}
                {...this.props}
            />
        )
    }
}