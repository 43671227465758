import React from "react";
import Sig from "../../../../api/Sig";
import ObjetivoEstrategicoMeta from "./ObjetivoEstrategicoMeta";
import EssentialStyle from "../../../../style/EssentialStyle";
import Colors from "../../../../constants/Colors";

export default class CardIndicadorPainel extends React.Component {
    state = {
        loading: true,
        id_elemento: this.props.id,
        indicador: this.props.indicador,
        indicadorInfo: {
            indicadorNome: "Buscando...",
        },
        painel: this.props.painel,
        objetivo: null,
        resultado: {
            'periodos': {},
            'performanceRealizado': '--%',
            'realizadoRealizado': '',
            "metaPeriodo": '',
            'cor': Colors.homePage.extraLightGrey,
            'processoNome': 'Processo não encontrado'
        },
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        let reqBody = {
            id: this.state.id_elemento,
            id_indicador: this.props.indicador,
            id_gmr_painel: this.props.painel
        };

        const req = await Sig.request('POST', `pe/diagrama/getAcompanhamentoIndicador`, reqBody);

        this.setState({ resultado: req.resultado, indicadorInfo: req.indicador[0], objetivo: req.objetivo }, () => { this.setState({ loading: false }) });
    }
    
    renderHeaderCard() {
        return (
            <div style={{ ...styles.headerCard, backgroundColor: this.state.resultado.cor}}>
                {this.state.resultado.performanceRealizado}
            </div>
        )
    }

    renderContentCard() {
        return (
            <div style={styles.contentCard}>
                <div style={{...EssentialStyle.columnCenter, height: 'calc(100% - 50px)'}}>
                    <div style={styles.indicadorNome}>{this.state.indicadorInfo.indicadorNome}</div>
                </div>
                <ObjetivoEstrategicoMeta
                    justifyContent={'center'}
                    id={this.props.id}
                    id_indicador={this.props.indicador}
                    minHeight={30}
                    height={50}
                    grafico={"resultado"}
                    model={"PeMapaElemento"}
                />
            </div>
        )
    }

    renderCard() {
        return (
            <div style={styles.card}>
                {this.renderHeaderCard()}
                {this.renderContentCard()}
            </div>
        )
    }

    render() {
        return this.renderCard();
    }
}

const styles = {
    card:{
        display: 'flex',
        flexDirection: 'column',
        height: '20vh',
        maxHeight: 200,
        minHeight: 120,
        width: '60%',
        minWidth: '60%',
        maxWidth: 300,
        backgroundColor: "transparent",
        borderRadius: 5,
        border: `1px solid ${Colors.homePage.line}`,
        marginRight: 10,
        marginBottom: 10,
        overflow: 'hidden',
    },
    headerCard: {
        ...EssentialStyle.rowFlexStart,
        height: "20%",
        backgroundColor: Colors.homePage.extraLightGrey,
        color: Colors.white,
        fontSize: 14,
        fontWeight: 'bold',
        paddingLeft: 10,

    },
    contentCard: {
        ...EssentialStyle.columnSpaceBetween,
        height: "80%",
        padding: "0px 10px",
        width: '100%',
        backgroundColor: Colors.background,
    },
    indicadorNome: {
        fontSize: 12,
        color: Colors.homePage.text,
        textAlign: 'center',
        marginBottom: 5,
    }
}
