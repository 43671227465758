import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faPlusSquare, faTableList, faTableCells, faSearch } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import LoadingPage from "../LoadingPage";
import Sig from "../../api/Sig";
import EssentialStyle from "../../style/EssentialStyle";
import ProjetoTableView from "../../components/modules/pe/projeto/ProjetoTableView";
import DefaultLoader from "../../components/tools/DefaultLoader";
import ProjetoCard from "../../components/modules/pe/projeto/ProjetoCard";
import GutMatrix from "../../components/tools/GutMatrix";
import DataHelper from "../../helper/DataHelper";
import Select from 'react-select'
import { toast } from 'react-toastify';
import { InputGroup, Form, Row, Col } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import Constants from "../../constants/Api";
import LayoutHelper from "../../helper/LayoutHelper";
import AddProjeto from "../../components/forms/pe/add/AddProjeto";

const gateway = Constants.getSigEndPoint();
export default class ProjetoPageDetalhar extends React.Component {

    state = {
        loading: true,
        loadingBody: false,
        projetos: [],
        projetosOriginals: [], 
        preSelectedMapa: null,
        mapasUser: [],
        type: 'star', // or 'prioritization',
        view: 'normal', // or 'table',
        showSettings: false,
        acesso: null,
        permissao: null,
        temRestricoes: null,
        restricoes: null,
        podeEditarPriorizacao: false,
        isSmallScreen: LayoutHelper.isSmallScreen(),
        addProjetoSemVinculo: false,

        // Buscador
        viewSearch: false,
        searchText: '',
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        await this.loadData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    async loadData(mapaId = null) {
        this.setState({ loading: true });

        let { mapasUser, preSelectedMapa, type } = await Sig.request('GET', 'pe/mapa/getMapaUser', { mapaId: mapaId ? mapaId.value : null, select: true });

        mapasUser.unshift({"label": "Projetos Sem Vinculos", "value": null});

        if (mapaId && mapaId.value == null) {
            preSelectedMapa = {"label": "Projetos Sem Vinculos", "value": null};
        }
        
        this.setState({ mapasUser, preSelectedMapa, type: type == 0 ? 'star' : 'prioritization', loading: false }, () => {
            this.state.preSelectedMapa != null && this.loadProjetos();
        });
    }

    async loadProjetos(){

        let view = this.state.view;

        if (this.state.preSelectedMapa.value == null) {
            view = 'normal';
        }

        this.setState({ loadingBody: true, view });
        let { projetos, podeEditarPriorizacao, type } = await Sig.request('GET', 'pe/projeto/getProjetosMapa', { id_pe_mapa: this.state.preSelectedMapa.value});
        this.setState({ projetos, projetosOriginals: projetos, podeEditarPriorizacao, type: type == 0 ? 'star' : 'prioritization', loadingBody: false });
    }
    
    gutCallback = async () => {
        this.setState({ showSettings: false });
        await this.loadData(this.state.preSelectedMapa);
    }

    handleView() {

        let view = this.state.view;
        view = view === 'normal' ? 'table' : 'normal';

        this.setState({ view });
    }

    async saveType() {

        let { response } = await Sig.request('POST', 'pe/mapa/setPortfolioConfig', { type: this.state.type == 'star' ? 0 : 1, id_pe_mapa: this.state.preSelectedMapa.value});
        if (!response) {
            toast.error('Não foi possível savar o ultimo estado do tipo de priorização selecionado');
        }
    }

    renderLoadingBody() {
        return (
            <div style={{ ...EssentialStyle.rowFlexCenter, width: '100%', height: 'calc(100vh - 80px)' }}>
                <DefaultLoader size={32} title={"Carregando ..."} />
            </div>
        )
    }

    renderSwitch() {

        return (

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faTableList} />
                <Form.Switch
                    type="switch"
                    disabled
                    checked={this.state.view === 'normal' ? false : true}
                    style={{ marginLeft: 3, marginRight: 3 }}
                />
                <FontAwesomeIcon icon={faTableCells} />
            </div>
        )
    }

    renderBody() {
        if (this.state.loadingBody) return this.renderLoadingBody();
        if (this.state.projetosOriginals?.length <= 0) return this.renderEmpty();
        if (this.state.projetos?.length <= 0) return this.renderEmptySearch();
        if(this.state.view === 'table') return <ProjetoTableView id={this.state.preSelectedMapa.value} loading={this.state.loadingBody}/>
        
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, margin: 0, marginBottom: this.state.isSmallScreen ? 30 : 0, padding: 0, backgroundColor: 'transparent', boxShadow: 'none', height: `calc(100vh - ${this.state.viewSearch ? 133 :  70}px - ${this.state.isSmallScreen ? 102 : 0}px)` }}>
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    overflowY: 'auto',
                    flexDirection: 'column',
                    padding: "10px 10px 0px 10px",
                }}>
                    {this.state.projetos.map((projeto) => (<ProjetoCard key={`${projeto.id}-projeto-key`} id={projeto?.id} priorization={this.state.type} />))}
                </div>
            </div>
        )
    }

    async filterObjetivos(searchText) {

        this.setState({ searchText });

        if (searchText && searchText.length && searchText.length > 0) {

            searchText = searchText.toLowerCase();

            let projetos = JSON.parse(JSON.stringify(this.state.projetosOriginals));

            projetos = projetos.filter((projeto) => {
                return projeto.nome.toLowerCase().includes(searchText)
            });

            this.setState({ projetos });

        } else {
            let projetos = JSON.parse(JSON.stringify(this.state.projetosOriginals));
            this.setState({ projetos });
        }
    }

    renderSearch() {
        return (
            <InputGroup>
                <Form.Control
                    placeholder={"Buscar projetos..."}
                    type="text"
                    onChange={(event) => { this.filterObjetivos(event.target.value); }}
                    value={this.state.searchText}
                />
            </InputGroup>
        )
    }

    hendleSearch() {
        this.setState({ viewSearch: !this.state.viewSearch }, () => { if (!this.state.viewSearch) this.filterObjetivos(''); });
    }

    renderHeader() {

        let localStyle = {
            margin: "10px 10px 0px 10px", 
            padding: 10, 
            backgroundColor: 'white', 
            boxShadow: Colors.boxShadow, 
            border: '0.3px solid lightgrey', 
            borderRadius: 8,
            ...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceBetween)
        }

        return (
            <div>
                <div style={localStyle}>
                    <div style={{...EssentialStyle.rowFlexStart, width: this.state.isSmallScreen ? '100%' : ''}}>
                        {this.renderSelectMapa()}
                    </div>
                    
                    <div style={{...EssentialStyle.rowFlexEnd, width: this.state.isSmallScreen ? '100%' : ''}}>
                        
                        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto', justifyContent: 'flex-end' }}>
                            <span style={{ marginRight: 7 }}>Marcar com Estrela</span>
                            <Form.Check
                                type="switch"
                                onChange={(event) => { this.setState({ loadingBody: true, type: event.target.checked ? 'prioritization' : 'star' }, () => { this.setState({ loadingBody: false }); this.saveType(); }) }}
                                disabled={!this.state.podeEditarPriorizacao}
                                checked={this.state.type === 'prioritization'}
                            />
                            <span className="teste">Matriz de Priorização</span>
                            
                            <DefaultButton 
                                tooltip={this.state.preSelectedMapa == null ? 'Sem Projetos para configurar Matriz de Priorização' : (this.state.podeEditarPriorizacao ? 'Configurações da Matriz de Priorização' : 'Sem Permissão Para Editar Matriz de Priorização')} 
                                tooltipPlacement={'bottom'} 
                                leftIcon={<FontAwesomeIcon icon={faLayerGroup} />} 
                                color={Colors.secondaryButton} 
                                textColor={Colors.dark} 
                                loading={this.state.loading}
                                disabled={!this.state.podeEditarPriorizacao}
                                onClick={() => { this.setState({ showSettings: true }) }} 
                                style={{ marginRight: 8, marginLeft: 8 }} 
                            />

                        </div>

                        { this.state.view !== 'table' && <DefaultButton style={{marginRight: 8}} tooltip={"Buscar Projeto"} tooltipPlacement={'left'} leftIcon={<FontAwesomeIcon icon={faSearch} />} color={Colors.secondaryButton} textColor={Colors.dark} disabled={this.state.preSelectedMapa == null} loading={this.state.loading} onClick={() => { this.hendleSearch(); }} />}

                        { !this.state.isSmallScreen && <DefaultButton disabled={this.state.preSelectedMapa.value == null} tooltip={this.state.view === 'normal' ? 'Visualizar em Tabela' : 'Visualizar em Lista'} tooltipPlacement={'left'} leftIcon={this.renderSwitch()} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.handleView() }} />}
                        <DefaultButton 
                            style={{ width: 38, height: 38, marginLeft: 8 }}
                            fontSize={14}
                            color={Colors.secondaryButton}
                            textColor={Colors.dark} 
                            link={"https://advis.freshdesk.com/support/solutions/articles/63000283268-portf%C3%B3lio-de-projetos"}/>
                    </div>
                </div>

                <Collapse in={this.state.viewSearch && this.state.view !== 'table'}>
                    <div style={{ margin: "1px 10px 0px 10px", padding: 10, backgroundColor: 'white', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 8 }}>
                        { this.renderSearch() }
                    </div>
                </Collapse>
            </div>
        )
    }

    renderSelectMapa() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                <Form.Group>
                    <Select
                        styles={{ control: (base) => ({...base, border: 'none', minWidth: '40vh',}) }}
                        options={ this.state.mapasUser }
                        value={ this.state.preSelectedMapa }
                        onChange={ (valueSelected) => { 
                            this.setState({ preSelectedMapa: valueSelected }, () => {
                                this.loadProjetos();
                            });
                        } }
                        placeholder={'Selecione um Mapa Estratégico'} 
                        noOptionsMessage={DataHelper.getSelectEmptyMessage}
                        isSearchable 
                        menuPortalTarget={document.body}
                    />
                </Form.Group>
            </div>
        )
    }

    renderButtonAddProjeto() {

        return (
            <DefaultButton 
                color={Colors.success} 
                title={'Adicionar Projeto'} 
                leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} 
                loading={this.state.loading} 
                onClick={() => { 
                    if (this.state.preSelectedMapa.value == null) {
                        this.setState({ addProjetoSemVinculo: true });
                    } else {
                        window.parent.postMessage({ type: 'navigate', url: '/pe/projeto/criar' }, '*'); 
                    }
                }} />
        )
    }

    renderDetails() {
        return (
            <div>
                { this.renderHeader() }
                { this.renderBody() }
            </div>
        )
    }

    renderSettings() {
        return (
            <GutMatrix idPeProjeto={this.state.preSelectedMapa.value} gutProjeto gutCallback={this.gutCallback} />
        );
    }

    renderEmptySearch() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', margin: "20px 10px 0px 10px", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5 }}>
                <img
                    src={`${gateway}/img/undraw/undraw_search_engines.svg`}
                    alt="Imagem de um buscador sem resultados"
                    style={{
                        height: '40vh',
                        maxHeight: 120,
                        marginBottom: 10,
                    }}
                />
                <span style={{ ...EssentialStyle.titleBoxHomePage, marginBottom: 5 }}>
                    Nenhum <strong>Projeto</strong> encontrado para esse Mapa Estratégico!
                </span>
            </div>
        )
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', margin: "20px 10px 0px 10px", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5 }}>
                <h5>Sua empresa ainda não criou nenhum <strong>Projeto</strong> para o que você selecionou, ou você não tem acesso a eles!</h5>
                <div style={{ marginTop: 20 }} />
                {this.renderButtonAddProjeto()}
            </div>
        )
    }

    render() {
        if (this.state.loading) return <LoadingPage />
        if (this.state.addProjetoSemVinculo) return <AddProjeto addCallback={() => {this.setState({addProjetoSemVinculo: false}, () => {this.loadProjetos()})}} backButton={() => { this.setState({addProjetoSemVinculo: false}) }}/>
        if (this.state.showSettings) return this.renderSettings();
        return this.renderDetails();
    }
}

let style = {
    projetoCard: {
        ...EssentialStyle.rowSpaceBetween,
        ...EssentialStyle.card,
        width: '100%',
        minWidth: '535px',
        height: 160,
        padding: '10px 20px 10px 20px',
        marginBottom: 10
    }
}