import React from "react";

import './ListTiposResultados.css';
import SessionHelper from "../../../../../helper/SessionHelper";

const HEATMAP = 1;

export default class ListTiposResultados extends React.Component {

    state = {
        tipos: [
            { id: HEATMAP, nome: [
                { id: 1, texto: 'Mapa de Calor' },
                { id: 2, texto: 'Heat Map' },
                { id: 3, texto: 'Mapa de Calor' },
            ] },
        ],
    }

    render() {
        return (
            <div className="listTiposContainer">
                <div className="listTipos">
                    {this.state.tipos.map((tipo, index) => (
                        <div 
                            key={`tipo-list-${tipo.id}`} 
                            className={`tipo`}
                            style={{ backgroundColor: this.props.selected == tipo.id ? SessionHelper.getColor() : '#808080' }}
                            onClick={() => this.props.onSelectTipo(tipo.id)}
                        >
                            {tipo.nome.find(n => n.id === this.props.lang).texto}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}