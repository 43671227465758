import React from "react";

import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faChevronLeft, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import Select from "react-select";
import EssentialStyle from "../../../../style/EssentialStyle";
import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import SessionHelper from "../../../../helper/SessionHelper";
import LayoutHelper from "../../../../helper/LayoutHelper";
import DataHelper from "../../../../helper/DataHelper";
import Colors from "../../../../constants/Colors";

import './CriacaoRelatorio.css';
import DefaultLoader from "../../../tools/DefaultLoader";
import CustomTooltip from "../../../tools/CustomTooltip";



export default class CriacaoRelatorio extends React.Component {

    state = {
        loading: false,
        addingModelo: false,
        addingGrupoGrafico: false,
        editingModelo: false,

        idPeriodoAvaliacao: this.props.idPeriodoAvaliacao,
        modelos: [],
        idModelo: null,

        newModelo: {
            nome: '',
            graficos: [],
        },

        colunas: [],
        colunaSelected: null,
        nodes: [],
        nodesSelected: [],
        widthSelected: 1,

        lang: this.props.lang,
        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        await this.loadData();
        window.addEventListener('resize', this.verifyScreen);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.idPeriodoAvaliacao !== this.props.idPeriodoAvaliacao) {
            this.setState({ idPeriodoAvaliacao: this.props.idPeriodoAvaliacao }, async () => {
                await this.loadData();
            });
        }

        if (prevProps.lang !== this.props.lang) {
            this.setState({ lang: this.props.lang }, () => {
                this.loadData();
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    async loadData() {
        this.setState({ loading: true });

        await this.loadModelos();
        await this.loadColunas();

        this.setState({ loading: false });
    }

    async loadModelos() {
        let modelos = await DiagnosticoHelper.getModelosRelatorio(this.state.idPeriodoAvaliacao, this.state.lang);
        this.setState({ modelos });
    }

    async loadColunas() {
        let colunas = await DiagnosticoHelper.getColunasQuestionario(this.state.idPeriodoAvaliacao, this.state.lang);
        this.setState({ colunas });
    }

    async setNodeColuna(idColuna) {
        let nodesColuna = await DiagnosticoHelper.getItensColuna(idColuna, true, this.state.lang);
        let nodesSelected = []

        this.setState({ colunaSelected: idColuna, nodes: nodesColuna, nodesSelected });
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    cancelEditModelo() {
        this.setState({ editingModelo: false, addingModelo: false, newModelo: { nome: '', graficos: [] } });
    }

    addGrupoGrafico() {

        let message = [
            { id: 1, text: 'Preencha todos os campos!' },
            { id: 2, text: 'Fill in all fields!' },
            { id: 3, text: '¡Complete todos los campos!' },
        ]

        if (!this.state.colunaSelected || !this.state.widthSelected ) {
            return toast.error(message.find((item) => item.id == this.state.lang).text);
        }

        let nodes = this.state.nodesSelected.map((item) => {
            return this.state.nodes.find((node) => node.id_aux == item.value);
        });

        let grupoGrafico = {
            id: this.state.newModelo.graficos.length + 1,
            largura: this.state.widthSelected,
            id_diagnostico_periodo_questionario_coluna: this.state.colunaSelected,
            nodes: nodes,
            edit: true,
            isNew: true,
        }

        this.setState({ newModelo: { ...this.state.newModelo, graficos: [...this.state.newModelo.graficos, grupoGrafico] } }, () => {
            this.setState({ colunaSelected: null, nodes: [], nodesSelected: [], widthSelected: 1, addingGrupoGrafico: false });
        });
    }

    async seveEditModelo() {
            
            let message = [
                { id: 1, textNome: 'Preencha o nome deste Modelo', textCampos: 'Adicione pelo menos um Grupo de Gráficos' },
                { id: 2, textNome: 'Fill in the name of this Model', textCampos: 'Add at least one Chart Group' },
                { id: 3, textNome: 'Complete el nombre de este Modelo', textCampos: 'Agregue al menos un Grupo de Gráficos' },
            ]
    
            if (!this.state.newModelo.nome) {
                return toast.error(message.find((item) => item.id == this.state.lang).textNome);
            }

            if (this.state.newModelo.graficos.length == 0) {
                return toast.error(message.find((item) => item.id == this.state.lang).textCampos);
            }
    
            let modelo = await DiagnosticoHelper.saveModeloRelatorio(this.state.newModelo, this.state.idPeriodoAvaliacao);

            this.setState({ addingModelo: false, newModelo: { nome: '', graficos: [] } }, async () => {
                await this.loadModelos();
                this.setState({ idModelo: modelo.id });
            });
    }

    async deleteModelo(idModelo) {
        await DiagnosticoHelper.deleteModeloRelatorio(idModelo);

        this.setState({ idModelo: null }, () => { this.loadModelos() });
    }

    async createRelatorio(idModelo) {

        let message = [
            { id: 1, textSuccess: 'Relatório criado com sucesso!', textError: 'Erro ao criar relatório!' },
            { id: 2, textSuccess: 'Report created successfully!', textError: 'Error creating report!' },
            { id: 3, textextSuccesst: 'Informe creado con éxito!', textError: '¡Error al crear informe!' },
        ];

        let createRelatorio = await DiagnosticoHelper.createRelatorioPorModelo(idModelo);

        if (createRelatorio) {
            toast.success(message.find((item) => item.id == this.state.lang).textSuccess);
        } else {
            toast.error(message.find((item) => item.id == this.state.lang).textError);
        }
    }

    renderLoad() {

        return (
            <div className="criacaoRelatorio" style={{ boxShadow: Colors.boxShadow }}>
                <DefaultLoader />
            </div>
        )
    }

    renderBody() {

        return (
            <div className="criacaoRelatorio" style={{ boxShadow: Colors.boxShadow }}>
                <div className="top">
                    {this.renderButtomAddModelo()}
                    {this.renderListModels()}
                </div>
                <div className="bottom">
                    {this.renderModel()}
                </div>
            </div>
        )
    }

    renderButtomAddModelo() {
        let message = [
            { id: 1, title: 'Criar Modelo' },
            { id: 2, title: 'Create Model' },
            { id: 3, title: 'Crear Modelo' },
        ]

        return (
            <DefaultButton
                style={{ height: 38 }}
                color={Colors.success}
                title={message.find((item) => item.id == this.state.lang).title}
                leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                loading={this.state.loading}
                onClick={() => { this.setState({ addingModelo: !this.state.addingModelo, idModelo: null }) }}
            />
        )
    }

    renderListModels() {
        return (
            <div className="listaModelos">
                {this.state.modelos.map((modelo, index) => {
                    return (
                        <div
                            key={index}
                            className="modelo"
                            style={{ backgroundColor: this.state.idModelo == modelo.id ? SessionHelper.getColor() : '' }}
                            onClick={() => this.setState({ idModelo: modelo.id == this.state.idModelo ? null : modelo.id, addingModelo: false })}
                        >
                            <div className="title">{modelo.nome}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    renderModel() {
        
        const addNewModelo = this.state.addingModelo;
        const viewModelo = this.state.idModelo && !addNewModelo;
        const editingModelo = this.state.editingModelo && viewModelo;

        const renderAddGrupoGrafico = () => {

            let message = [
                { id: 1, title: 'Adicionar Gráfico', back: 'Voltar', selectColuna: 'Selecione uma coluna', selectItens: 'Todos Itens Selecionados', adicionar: 'Adicionar', nome: 'Nome Gráfico' },
                { id: 2, title: 'Add Chart', back: 'Back', selectColuna: 'Select a column', selectItens: 'All Selected Items', adicionar: 'Add', nome: 'Chart Name' },
                { id: 3, title: 'Agregar Gráfico', back: 'Volver', selectColuna: 'Seleccione una columna', selectItens: 'Todos los elementos seleccionados', adicionar: 'Agregar', nome: 'Nombre del Gráfico' },
            ];
    
            return (
                <div className="addGrupoGrafico">
                    <div className="titulo">{message.find((item) => item.id == this.state.lang).title}</div>
                    <div className="content">
                        <DefaultButton
                            leftIcon={this.state.addingGrupoGrafico ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                            tooltip={this.state.addingGrupoGrafico ? message.find((item) => item.id == this.state.lang).back : message.find((item) => item.id == this.state.lang).title}
                            color={Colors.success}
                            onClick={() => { this.setState({ addingGrupoGrafico: this.state.addingGrupoGrafico ? false : true }) }}
                        />
    
                        {this.state.addingGrupoGrafico &&
                            <Select
                                options={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao')}
                                value={DataHelper.formatSelectData(this.state.colunas, 'id', 'descricao').find((coluna) => coluna.value == this.state.colunaSelected)}
                                onChange={(e) => { this.setNodeColuna(e.value) }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: '18rem', // Define a largura mínima
                                    }),
                                }}
                                menuPortalTarget={document.body}
                                placeholder={message.find((item) => item.id == this.state.lang).selectColuna} 
                                menuPlacement="auto"
                                menuPosition="fixed"    
                            />
                        }
    
                        {this.state.addingGrupoGrafico &&
                            <Select
                                options={DataHelper.formatSelectData(this.state.nodes, 'id_aux', 'descricao')}
                                value={this.state.nodesSelected}
                                onChange={(e) => { this.setState({ nodesSelected: e }) }}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        minWidth: '35rem', // Define a largura mínima
                                    }),
                                }}
                                isMulti
                                isClearable
                                menuPortalTarget={document.body}
                                placeholder={message.find((item) => item.id == this.state.lang).selectItens} 
                                menuPlacement="auto"
                                menuPosition="fixed"
                            />
                        }
    
                        {this.state.addingGrupoGrafico && this.renderSelectWidth()}
    
                        {this.state.addingGrupoGrafico &&
                            <DefaultButton
                                title={message.find((item) => item.id == this.state.lang).adicionar}
                                color={Colors.success}
                                onClick={() => { this.addGrupoGrafico() }}
                            />
                        }
    
                    </div>
                </div>
            )
        }

        const renderGrupoGraficoInEditing = () => {

            let grupos = this.state.newModelo.graficos;
    
            let message = [
                { id: 1, textColuna: 'Coluna', textItens: 'Itens', textLargura: 'Largura', selectItens: 'Todos Itens Selecionados', number: 'Iten(s) Selecionado(s)' },
                { id: 2, textColuna: 'Column', textItens: 'Items', textLargura: 'Width', selectItens: 'All Selected Items', number: 'Selected Item(s)' },
                { id: 3, textColuna: 'Columna', textItens: 'Elementos', textLargura: 'Ancho', selectItens: 'Todos los elementos seleccionados', number: 'Elemento(s) seleccionado(s)' },
            ];
    
            let array = [
                { id: 1, largura: '1/3' },
                { id: 2, largura: '1/2' },
                { id: 3, largura: '1' },
            ];
    
            if (grupos && grupos.length > 0) return (
                <Form style={{ width: "calc(100% - 15px)"}} >
                    {grupos.map((grupo, index) => {
                        return (
                            <Row key={index} className="mb-3 graficoItem">
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>{message.find((item) => item.id == this.state.lang).textColuna}</Form.Label>
                                        <div>{this.state.colunas.find((coluna) => coluna.id == grupo.id_diagnostico_periodo_questionario_coluna).descricao}</div>
                                    </Form.Group>
                                </Col>
    
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>{message.find((item) => item.id == this.state.lang).textItens}</Form.Label>
    
                                        {grupo.nodes && grupo.nodes.length > 0 &&
                                            <CustomTooltip tooltip={ grupo.nodes.length + " " + message.find((item) => item.id == this.state.lang).number}>
                                                {'[' + grupo.nodes.length + ']'} - {grupo.nodes.map((item) => item.descricao).join(', ').length > 150 ? grupo.nodes.map((item) => item.descricao).join(', ').slice(0, 200) + '...' : grupo.nodes.map((item) => item.descricao).join(', ')}
                                            </CustomTooltip>
                                        }
    
                                        {(!grupo.nodes || grupo.nodes.length == 0) &&
                                            <div>{message.find((item) => item.id == this.state.lang).selectItens}</div>
                                        }
                                    </Form.Group>
                                </Col>
    
                                <Col md={2}>
                                    <Form.Group>
                                        <Form.Label>{message.find((item) => item.id == this.state.lang).textLargura}</Form.Label>
                                        <div>{array.find((item) => item.id == grupo.largura).largura}</div>
                                    </Form.Group>
                                </Col>
    
                                <Col md={2} style={EssentialStyle.columnCenter}>
                                    <div style={EssentialStyle.columnCenter}>
                                        <FontAwesomeIcon 
                                            icon={faTimesCircle}
                                            style={{ cursor: 'pointer', marginLeft: 5, display: !grupo.edit ? 'none' : '' }}
                                            onClick={() => { 
                                                let graficos = this.state.newModelo.graficos.filter((item) => item.id != grupo.id);
                                                this.setState({ newModelo: {...this.state.newModelo, graficos} })
                                            }} 
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )
                    })}
                </Form>
            )
        }

        const renderEditingModelo = (idModelo) => {

            let message = [
                { id: 1, title: 'Nome do Modelo', cancelar: 'Cancelar', salvar: 'Salvar' },
                { id: 2, title: 'Model Name', cancelar: 'Cancel', salvar: 'Save' },
                { id: 3, title: 'Nombre del Modelo', cancelar: 'Cancelar', salvar: 'Guardar' },
            ]


            return (
                <div className="editModel">
                    <Form style={{ width: '100%' }} >
                        <Row className="mb-3">
                            <Form.Group md={12}>
                                <Form.Label>{message.find((item) => item.id == this.state.lang).title}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={message.find((item) => item.id == this.state.lang).title}
                                    value={this.state.newModelo.nome}
                                    onChange={(e) => this.setState({ newModelo: { ...this.state.newModelo, nome: e.target.value } })}
                                />
                            </Form.Group>
                        </Row>
                    </Form>

                    <div style={{ height: "calc(100% - 40px)", width: '100%', overflowY: 'auto'}}>
                        {renderGrupoGraficoInEditing()}
                        {renderAddGrupoGrafico()}
                    </div>
                    
                    <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', gap: 5 }}>
                        <DefaultButton
                            title={message.find((item) => item.id == this.state.lang).cancelar}
                            color={Colors.error}
                            onClick={() => this.cancelEditModelo()}
                        />
                        <DefaultButton
                            title={message.find((item) => item.id == this.state.lang).salvar}
                            color={Colors.success}
                            onClick={() => this.seveEditModelo()}
                        />
                    </div>
                </div>
            )
        };

        const renderViewModelo = (idModelo) => {

            let textActions = [
                { 
                    id: 1, 
                    textEdit: 'Editar Modelo', 
                    textDelete: 'Excluir Modelo', 
                    textCriaRelatorio: 'Criar Relatório', 
                    textCriaRelatorioTooltip: 'Cria um relatório com base neste modelo. Os relatórios criados ficarão na aba "Em Elaboração" até serem concluídos.' },
                { 
                    id: 2, 
                    textEdit: 'Edit Model', 
                    textDelete: 'Delete Model', 
                    textCriaRelatorio: 'Create Report', 
                    textCriaRelatorioTooltip: 'Create a report based on this model. The reports created will be in the "In Elaboration" tab until they are completed.' },
                { 
                    id: 3, 
                    textEdit: 'Editar Modelo', 
                    textDelete: 'Eliminar Modelo', 
                    textCriaRelatorio: 'Crear Informe', 
                    textCriaRelatorioTooltip: 'Cree un informe basado en este modelo. Los informes creados estarán en la pestaña "En Elaboración" hasta que se completen.' },
            ]

            let textLabels = [
                { id: 1, textName: 'Gráficos', textNameGrupo: 'Nome do Gráfio', textColuna: 'Coluna', textItens: 'Itens', textLargura: 'Largura', all: 'Todos Itens Selecionados', number: 'Iten(s) Selecionado(s)' },
                { id: 2, textName: 'Chart', textNameGrupo: 'Chart Name', textColuna: 'Column', textItens: 'Items', textLargura: 'Width', all: 'All Selected Items', number: 'Selected Item(s)' },
                { id: 3, textName: 'Gráficos', textNameGrupo: 'Nombre del Gráficos', textColuna: 'Columna', textItens: 'Elementos', textLargura: 'Ancho', all: 'Todos los elementos seleccionados', number: 'Elemento(s) seleccionado(s)' },
            ]
    
            let array = [
                { id: 1, largura: '1/3' },
                { id: 2, largura: '1/2' },
                { id: 3, largura: '1' },
            ]

            let modelo = this.state.modelos.find((item) => item.id == this.state.idModelo);
            let grupos = modelo.graficos;

            return (
                <div className="viewModel">
                    <Form style={{ height: "calc(100% - 40px)", width: '100%', padding: '0 15px', overflowY: 'auto' }}>
                        <Row className="mb-3 graficoItem">
                            <Form.Group md={12}>
                                <Form.Label>{textLabels.find((item) => item.id == this.state.lang).textName}</Form.Label>
                                <div>{modelo.nome}</div>
                            </Form.Group>
                        </Row>

                        {
                            grupos.length > 0 &&
                            grupos.map((grupo, index) => {
                                return (
                                    <Row key={grupo.id} className="mb-3 graficoItem">
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{textLabels.find((item) => item.id == this.state.lang).textColuna}</Form.Label>
                                                <div>{this.state.colunas.find((coluna) => coluna.id == grupo.id_diagnostico_periodo_questionario_coluna).descricao}</div>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>{textLabels.find((item) => item.id == this.state.lang).textItens}</Form.Label>
                                                {grupo.nodes && grupo.nodes.length > 0 &&
                                                    <CustomTooltip tooltip={ grupo.nodes.length + " " + textLabels.find((item) => item.id == this.state.lang).number}>
                                                        {'[' + grupo.nodes.length + ']'} - {grupo.nodes.map((item) => item.descricao).join(', ').length > 150 ? grupo.nodes.map((item) => item.descricao).join(', ').slice(0, 200) + '...' : grupo.nodes.map((item) => item.descricao).join(', ')}
                                                    </CustomTooltip>
                                                }

                                                {(!grupo.nodes || grupo.nodes.length == 0) &&
                                                    <div>{textLabels.find((item) => item.id == this.state.lang).all}</div>
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>{textLabels.find((item) => item.id == this.state.lang).textLargura}</Form.Label>
                                                <div>{array.find((item) => item.id == grupo.largura).largura}</div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Form>

                    <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%', gap: 5 }}>
                        <DefaultButton
                            title={textActions.find((item) => item.id == this.state.lang).textCriaRelatorio}
                            tooltip={textActions.find((item) => item.id == this.state.lang).textCriaRelatorioTooltip}
                            color={Colors.danger}
                            onClick={() => { this.createRelatorio(idModelo) }}
                        />
                        <DefaultButton
                            title={textActions.find((item) => item.id == this.state.lang).textDelete}
                            color={Colors.error}
                            onClick={() => { this.deleteModelo(idModelo) }}
                        />
                    </div>
                </div>
            )
        };

        if (addNewModelo) return renderEditingModelo(this.state.idModelo);
        if (viewModelo && !editingModelo) return renderViewModelo(this.state.idModelo);
        return DiagnosticoHelper.renderNotSelected(
            [
                {id: 1, text: 'Selecione ou Crie um Modelo de Relatório para Continuar.'},
                {id: 2, text: 'Select or Create a Report Model to Continue.'},
                {id: 3, text: 'Seleccione o Cree un Modelo de Informe para Continuar.'},
            ],
            this.state.lang);
    }

    renderSelectWidth() {
        let array = [
            { id: 1, largura: '1/3' },
            { id: 2, largura: '1/2' },
            { id: 3, largura: '1' },
        ]

        let tooltip = [
            { id: 1, text: 'Largura do Gráfico' },
            { id: 2, text: 'Chart Width' },
            { id: 3, text: 'Ancho del gráfico' },
        ]

        return (
            <CustomTooltip
                tooltip={tooltip.find((item) => item.id == this.state.lang).text}
                placement="top"
            >
                <div className="selectWidth">
                    {array.map((item, index) => {
                        return (
                            <div
                                key={item.id}
                                className={`itemWidth ${item.id <= this.state.hoverItemWidth ? 'itemWidth-hover' : ''} ${item.id <= this.state.widthSelected ? 'itemWidth-selected' : ''}`}
                                style={{ backgroundColor: item.id <= this.state.widthSelected ? SessionHelper.getColor() : '' }}
                                onClick={() => this.setState({ widthSelected: item.id })}
                                onMouseEnter={() => { this.setState({ hoverItemWidth: item.id }) }}
                                onMouseLeave={() => { this.setState({ hoverItemWidth: null }) }}
                            >
                                {this.state.widthSelected == item.id && item.largura}
                            </div>
                        )
                    })}
                </div>
            </CustomTooltip>
        )
    }

    render() {
        return this.state.loading ? this.renderLoad() : this.renderBody();
    }
}