import React from "react";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faChevronLeft, faEdit, faTrash, faStar } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import Sig from "../../../../api/Sig";
import DiagramHelper from "../../../../helper/pe/DiagramHelper";
import DefaultLoader from "../../../tools/DefaultLoader";
import DataHelper from "../../../../helper/DataHelper";
import SessionHelper from "../../../../helper/SessionHelper";

export default class MapaHistory extends React.Component {
    state = {
        loading: true,
        history: [],
    }

    async componentDidMount() {
        await this.loadData();
        this.setState({ loading: false });
    }

    async loadData() {
        this.setState({ loading: true });

        let history = (await Sig.request('POST', `pe/mapa/getHistorico`, {id: this.props.id}))?.historico || [];

        if (history && history.length) {
            this.setState({ history, loading: false });
        }
    }

    renderHeader() {

        return (

            <div style={{ ...EssentialStyle.rowSpaceBetween, padding: 10, marginBottom: 30 }}>
                <DefaultButton tooltip={'Voltar'} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.props.historyCallback(); }} style={{ marginRight: 8 }} />
                <div style={{ fontWeight: 'bold', fontSize: 22 }}>{'Histórico de Alterações'}</div>
                <div></div>
            </div>
        )
    }

    renderIcon(operacao) {

        let icon = operacao === 'insert' ? faAdd : operacao === 'update' ? faEdit : faTrash;
        let color = operacao === 'insert' ? Colors.success : operacao === 'update' ? Colors.danger : Colors.error;

        return (
            <div>
                <div style={{ backgroundColor: color, marginRight: 10, padding: 10, height: 40, width: 40, borderRadius: 40 / 2, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', boxShadow: Colors.boxShadow }}>
                    <FontAwesomeIcon icon={icon}/>
                </div>
            </div>
        )
    }

    renderOperation(operacao) {

        let label = operacao === 'insert' ? 'Inseriu' : operacao === 'update' ? 'Editou' : 'Removeu';
        let color = operacao === 'insert' ? Colors.success : operacao === 'update' ? Colors.danger : Colors.error;

        return (
            <div style={{ marginLeft: 15, fontWeight: 'bold', color }}>
                {label}
            </div>
        )
    }

    renderValue(tipo, valor, campo) {

        let text = '';

        if (tipo === 'text') {

            text = valor;

        } else if (tipo === 'icon') {

            text = <FontAwesomeIcon style={{ fontSize: 20, marginLeft: 5 }} color={'#000000'} icon={DiagramHelper.getIcons()[valor]}/>;

        } else if (tipo === 'perspectiva') {

            text = valor;

            if (!campo) {

                text = 'Perspectiva';
            }

        } else if (tipo === 'objetivo_estrategico') {

            text = valor;

            if (campo === 'prioritario') {

                text = <FontAwesomeIcon icon={faStar} color={valor == 0 ? Colors.dark : Colors.favorite }/>;
            } 
        }

        if (valor == 'null') {

            text = 'Vazio';
        }

        return text;
    }

    limitarCaracteres(str, limite) {
        if (str.length > limite) {
            return str.substring(0, limite) + "...";
        } else {
            return str;
        }
    }

    renderData(item) {       
        
            if(item.historico.operacao == "update" ) {
                return <p style={{ textAlign: 'justify', marginBottom:0}}>
                    Editou o item
                    <b>
                        {DataHelper.removeHtmlTags(item.historico.tipo == "perspectiva" ? " Perspectiva " : '')}
                        {DataHelper.removeHtmlTags(item.historico.tipo == "text" ? " Texto " : '')}
                        {DataHelper.removeHtmlTags(item.historico.tipo == "icon" ? " Ícone " : '')}                    
                        {DataHelper.removeHtmlTags(item.historico.tipo == "objetivo_estrategico" ? " Objetivo Estratégico " : '')}
                    </b>
                    {` de `}
                    <b>
                        {DataHelper.removeHtmlTags(item.historico.valor_anterior)[0] === '#' 
                                ? <div style={{ display:'inline-block', height: 15, width: 15, margin:0, backgroundColor: DataHelper.removeHtmlTags(item.historico.valor_anterior) }}></div>
                                :  this.renderValue(DataHelper.removeHtmlTags(item.historico.tipo), DataHelper.removeHtmlTags(item.historico.valor_anterior), DataHelper.removeHtmlTags(item.historico.campo))
                        }
                    </b>
                    {` para `}
                    <b>
                        {DataHelper.removeHtmlTags(item.historico.valor)[0] === '#' 
                            ? <div style={{ display:'inline-block', height: 15, width: 15, margin:0, backgroundColor: DataHelper.removeHtmlTags(item.historico.valor) }}></div>
                            : this.renderValue(DataHelper.removeHtmlTags(item.historico.tipo), DataHelper.removeHtmlTags(item.historico.valor), DataHelper.removeHtmlTags(item.historico.campo))}
                    </b>
                            {item.historico.campo == null ? '' : ''}
                            {item.historico.campo == 'cor_fundo' ? ' (cor de fundo)' : ''}
                            {item.historico.campo == 'cor_texto' ? ' (cor do texto)' : ''}
                            {item.historico.campo == 'cor_borda' ? ' (cor da borda)' : ''}
                            {item.historico.campo == 'x' ? ' (posição do eixo x)' : ''}
                            {item.historico.campo == 'y' ? ' (posição do eixo y)' : ''} 
                            {item.historico.campo == 'width' ? ' (largura)' : ''}
                            {item.historico.campo == 'height' ? ' (altura)' : ''}
                </p>
    
            }else if(item.historico.operacao == "insert" ){
    
                    return <p style={{ textAlign: 'justify', marginBottom:0}}>Inseriu um item do tipo 
                    <b>
                        {DataHelper.removeHtmlTags(item.historico.tipo == "perspectiva" ? " Perspectiva" : '')}
                        {DataHelper.removeHtmlTags(item.historico.tipo == "text" ? " Texto" : '')}
                        {DataHelper.removeHtmlTags(item.historico.tipo == "icon" ? " Ícone" : '')}                    
                        {DataHelper.removeHtmlTags(item.historico.tipo == "objetivo_estrategico" ? " Objetivo Estratégico" : '')}
                    </b></p>
    
            }else if(item.historico.operacao == "delete" ){
                    return <p style={{ textAlign: 'justify', marginBottom:0}}>Removeu um item do tipo 
                    <b>
                        {DataHelper.removeHtmlTags(item.historico.tipo == "perspectiva" ? " Perspectiva" : '')}
                        {DataHelper.removeHtmlTags(item.historico.tipo == "text" ? " Texto" : '')}
                        {DataHelper.removeHtmlTags(item.historico.tipo == "icon" ? " Ícone" : '')}                    
                        {DataHelper.removeHtmlTags(item.historico.tipo == "objetivo_estrategico" ? " Objetivo Estratégico" : '')}
                    </b></p>
            }
    }
    

    renderHistory() {

        return this.state.history.map((item, key) => {

            return (

                <tr>
                    <td style={{ backgroundColor: key % 2 === 0 ? 'white' : '#F6F6F6' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {this.renderIcon(item.historico.operacao)}
                            {this.renderData(item)}
                        </div>
                    </td>
                    <td style={{ backgroundColor: key % 2 === 0 ? 'white' : '#F6F6F6', textAlign: 'center', wordWrap: 'break-word' }}>
                        {item?.colaborador?.nome}
                    </td>
                    <td style={{ backgroundColor: key % 2 === 0 ? 'white' : '#F6F6F6', textAlign: 'center', wordWrap: 'break-word' }}>
                        {moment(item.historico.data).format('DD/MM/YYYY HH:mm')}
                    </td>
                </tr>
            )
        });
    }

    renderTable() {

        return (
            <div style={{ padding: 10 }}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '65%', color: SessionHelper.getColor() }}>O quê</th>
                            <th style={{ color: SessionHelper.getColor() }}>Quem</th>
                            <th style={{ color: SessionHelper.getColor() }}>Quando</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.history.length ? this.renderHistory() : this.renderEmpty() }
                    </tbody>
                </table>
            </div>
        )
    }

    renderEmpty() {

        return (
            <tr>
                <td colSpan={3} style={{ color: 'grey' }}>{this.state.loading ? <DefaultLoader/> : "Nenhum registro de histórico encontrado."}</td>
            </tr>
        )
    }

    render() {
        return (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderHeader()}
                {this.renderTable()}
            </div>
        );
    }
}