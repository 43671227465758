import React from "react";
import { Image } from 'react-bootstrap'
import DefaultLoader from "./DefaultLoader";
import Colors from "../../constants/Colors";
import Sig from "../../api/Sig";
import CustomTooltip from "./CustomTooltip";
import SessionHelper from "../../helper/SessionHelper";
import DataHelper from "../../helper/DataHelper";
import CacheHelper from "../../helper/CacheHelper";

export default class UserAvatar extends React.Component {
    state = {
        loading: true,
        user: {},
        imageFailedToLoad: false
    }

    async componentDidMount() {
        await this.loadUser();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.user != this.props.user) {
            await this.loadUser();
        }
    }

    getUserImage() {
        let size = this.props.size ? this.props.size : 38;
        return (
            <Image
                style={{
                    height: size, width: size,
                    border: this.props.selected ? '2px solid ' + SessionHelper.getColor() : '2px solid white',
                    borderRadius: '50%',
                    opacity: (this.props.editing && this.props.oculto) ? 0.5 : 1
                }}
                roundedCircle
                src={`${document.referrer}/upload/avatar/${this.state.user.avatar}`}
                onError={() => this.setState({ imageFailedToLoad: true })}
            />
        );
    }

    async loadUser() {
        this.setState({ loading: true });

        let cache = CacheHelper.getUserAvatar(this.props.user ? this.props.user.id : this.props.id);

        if(cache) {
            this.setState({ user: cache }, () => { this.setState({ loading: false }) });
            return;
        }

        if (this.props.user && (this.props.user.id && this.props.user.nome)) {
            CacheHelper.addUserAvatar(this.props.user);
            this.setState({ user: this.props.user }, () => { this.setState({ loading: false }) });
            return;
        }

        if (this.props.id) {
            const user = await Sig.request('GET', 'config/colaborador/getColaborador', { id: this.props.id });

            if (user && user.status === 200) {
                CacheHelper.addUserAvatar(user);
                this.setState({ user }, () => { this.setState({ loading: false }) });
            }
        }

        if (!this.props.user && !this.props.id) {
            this.setState({ user: { nome: this.props.nome ? this.props.nome : 'Usuário Excluído' }, loading: false, imageFailedToLoad: true });
        }
    }

    renderPlaceholder() {

        let split = this.state.user.nome.split(' ');
        let size = this.props.size ? this.props.size : 38;
        let fontSize = this.props.font ? this.props.font : 15;

        return (

            <div style={{
                height: size,
                width: size,
                minWidth: size,
                border: this.props.selected ? '2px solid ' + SessionHelper.getColor() : '2px solid white',
                borderRadius: '50%',
                backgroundColor: this.props.editing && this.props.oculto ? Colors.homePage.extraLightGrey : 'grey',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontWeight: 'bold',
                fontSize: fontSize
            }}>
                {(`${split[0][0]}${split[split.length - 1] ? split[split.length - 1][0] : ''}`).toUpperCase()}
            </div>
        )
    }

    renderLoadingPlaceholder() {
        let size = this.props.size ? this.props.size : 38;
        let fontSize = this.props.font ? this.props.font : 15;

        return (
            <div style={{ height: size, width: size, border: '2px solid white', borderRadius: '100%', backgroundColor: 'grey', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontWeight: 'bold', fontSize: fontSize }}>
                <DefaultLoader size={fontSize} color={'white'} />
            </div>
        )
    }

    render() {

        if (this.state.loading && !this.props.noLoading) return this.renderLoadingPlaceholder();
        if (this.state.loading) return null;

        let userAvatar = (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: this.props.onClick ? 'pointer' : 'default',
                    maxWidth: '100%',
                }}
                onClick={this.props.onClick ? this.props.onClick : () => { }}
            >
                {!this.state.imageFailedToLoad && this.state.user.avatar ? this.getUserImage() : this.renderPlaceholder()}
                {this.props.showName ? <div style={{ marginLeft: 8, width: `calc(100% - ${this.props.size ? this.props.size : 38}px - 8px)`, color: Colors.dark }}>{
                    this.props.showNameLen ?
                        (DataHelper.limitarCaracteres(this.state.user.nome, this.props.showNameLen))
                        :
                        this.props.showNameAdaptative ?
                            <div style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{this.state.user.nome}</div>
                            :
                            this.state.user.nome
                }</div>
                    :
                    null}
            </div>
        );

        if(this.props.showName) return userAvatar;

        return (
            <CustomTooltip
                placement={this.props.placement ? this.props.placement : 'bottom'}
                tooltip={!this.props.editing ? this.state.user.nome : (this.props.oculto ? ('Visualizar: ' + this.state.user.nome) : ('Ocultar: ' + this.state.user.nome))}
                style={{ maxWidth: '100%' }}
            >
                {userAvatar}
            </CustomTooltip>
        );
    }
}