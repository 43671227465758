import { faSitemap, faUnlink, faAward, faBolt, faBook, faBookmark, faBriefcase, faCamera, faChartSimple, faCheck, faCircleDown, faCircleUp, faCrosshairs, faCrown, faDollarSign, faEarthAmericas, faFlag, faFont, faGift, faHandshake, faHeart, faHistory, faHourglass, faIcons, faLightbulb, faPen, faPlus, faMinus, faRocket, faStar, faTableCells, faThumbsDown, faThumbsUp, faTimes, faTrash, faUsers, faCog, faEye, faArrowLeft, faAd, faAdd, faInfoCircle, faLink, faArrowsAlt, faArrowsLeftRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class DiagramHelper {
    static getIcons() {
        return [faCheck, faStar, faHeart, faBolt, faGift, faCircleUp, faCircleDown, faBook, faBookmark, faThumbsUp, faThumbsDown, faBriefcase, faHandshake, faEarthAmericas, faUsers, faChartSimple, faFlag, faRocket, faAward, faCrown, faCrosshairs, faLightbulb, faDollarSign, faHourglass];
    }

    static getColorPickerColors() {
        return [
            'rgb(77, 77, 77)', 'rgb(153, 153, 153)', 'rgb(255, 255, 255)', 'rgb(244, 78, 59)', 'rgb(254, 146, 0)', 'rgb(252, 220, 0)', 'rgb(219, 223, 0)', 'rgb(164, 221, 0)', 'rgb(104, 204, 202)', 'rgb(115, 216, 255)', 'rgb(174, 161, 255)', 'rgb(253, 161, 255)',
            'rgb(51, 51, 51)', 'rgb(128, 128, 128)', 'rgb(204, 204, 204)', 'rgb(211, 49, 21)', 'rgb(226, 115, 0)', 'rgb(252, 196, 0)', 'rgb(176, 188, 0)', 'rgb(104, 188, 0)', 'rgb(22, 165, 165)', 'rgb(0, 156, 224)', 'rgb(123, 100, 255)', 'rgb(171, 20, 158)',
            'rgb(0, 0, 0)', 'rgb(102, 102, 102)', 'rgb(179, 179, 179)', 'rgb(159, 5, 0)', 'rgb(196, 81, 0)', 'rgb(251, 158, 0)', 'rgb(128, 137, 0)', 'rgb(25, 77, 51)', 'rgb(12, 121, 125)', 'rgb(0, 98, 177)', 'rgb(101, 50, 148)', 'rgb(171, 20, 159)',
            'transparent',
        ];
    }

    static getScaledProportions(w, h, considerEditingInterface) {
        let contentSizeWidth = w;
        let contentSizeHeight = h;
        if(considerEditingInterface === null){
            considerEditingInterface = true;
        }

        if (contentSizeHeight > 0 && contentSizeWidth > 0) {
            const width = window.innerWidth - (considerEditingInterface ? DiagramHelper.scaleSizeByPercent(200, 100) : 0);
            const height = window.innerHeight - (considerEditingInterface ? DiagramHelper.scaleSizeByPercent(100, 100) : 0);

            let hCalc = (height * 100) / contentSizeHeight, wCalc = (width * 100) / contentSizeWidth;
            let desiredZoom = hCalc > wCalc ? wCalc : hCalc;

            return { width: parseInt(contentSizeWidth), height: parseInt(contentSizeHeight), zoom: parseInt(desiredZoom) };
        }

        return { width: parseInt(contentSizeWidth), height: parseInt(contentSizeHeight), zoom: 100 };
    }


    static scaleSizeByPercent(size, zoom) {
        return (size / (zoom / 100));
    }

    static getDiagramResolution() {
        return { width: 2580, height: 1150 };
    }

    static ordenaSwots(strs){
        let arr1=[]; 
        let arr2=[];
        let arr3=[];
        let arr4=[];

        let arrayFinal = [];

        strs.forEach((item) => {
        if (item.label[0] === 'F' && item.label[1] === 'o') {
            arr1.push(item);
        }else if(item.label[0] === 'F' && item.label[1] === 'r'){
            arr2.push(item);   
        }else if(item.label[0] === 'O'){
            arr3.push(item);
        }else{
            arr4.push(item);
        }});

        arr1 = arr1.sort((a, b) => a.label.localeCompare(b.label)); 
        arr2 = arr2.sort((a, b) => a.label.localeCompare(b.label));
        arr3 = arr3.sort((a, b) => a.label.localeCompare(b.label));
        arr4 = arr4.sort((a, b) => a.label.localeCompare(b.label));            
       
        arrayFinal = arrayFinal.concat(arr1);
        arrayFinal = arrayFinal.concat(arr2);
        arrayFinal = arrayFinal.concat(arr3);
        arrayFinal = arrayFinal.concat(arr4);

        return arrayFinal;  
    
    }
}
