import React from "react";
import Colors from "../../../constants/Colors";

export default class EmptyDashboard extends React.Component {
    render() {
        return ( 
            <div style={{ height: this.props.height ? this.props.height : 'calc(100vh - 80px)', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: Colors.dark, fontWeight: 'bold' }}>
                <img src={`${document.referrer}/img/data-not-found4.png`} alt="404" style={{ height: 200 }} />
                <div style={{ fontSize: 25, marginTop: 20 }}>Nenhuma Dashboard selecionada</div>
            </div>
        );
    }
}