import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import LoadingPage from "../LoadingPage";
import AddMapa from "../../components/forms/pe/add/AddMapa";
import MapaList from "../../components/forms/pe/list/MapaList";
import { toast } from "react-toastify";
import MapaView from '../../components/modules/pe/mapa/MapaView';
import Sig from "../../api/Sig";

export default class MapaEstrategicoPage extends React.Component {
    state = {
        mapa: null,
        loading: true,
        showMapas: false,
        showWizard: false,
        modeView: 'read-only'
    }

    async componentDidMount() {
       await this.loadData();

       this.setState({ loading: false });
    }

    async loadData() {
        this.setState({ loading: true });

        const favoriteOrLastSwot = await Sig.request('GET', 'pe/mapa/getFavorite');

        this.setState({ mapa: favoriteOrLastSwot?.mapa, loading: false });
    }

    remove = async (id) => {
        this.setState({ loading: true });

        try {
            await Sig.request('POST', 'pe/mapa/delete', { id });
        } catch (e) {
            toast.error('Erro ao remover');
        }

        this.loadData();
    }

    addMapaCallback = async () => {
        this.setState({ showMapas: false, showWizard: false, modeView: 'edit' });
        await this.loadData();
    }

    showMapasCallback = async () => {
        this.setState({ showMapas: true });
        await this.loadData();
    }

    renderDefaultMapa() {
        return <MapaView 
                    remove={this.remove} 
                    callback={this.showMapasCallback} 
                    id={this.state.mapa.id} 
                    modeView={this.state.modeView}
                />
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5, textAlign: 'center' }}>
                <DefaultButton style={{ position: 'absolute', top: 30, right: 10 }} link={"https://advis.freshdesk.com/support/solutions/articles/63000218509-mapa-estrat%C3%A9gico"}/>
                <h5>Até o momento, sua empresa ainda não criou nenhum <strong>Mapa Estratégico</strong>.</h5>
                <div style={{ marginTop: 20 }} />
                <DefaultButton color={Colors.success} title={'Adicionar Mapa Estratégico'} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} loading={this.state.loading} onClick={() => { this.setState({ showWizard: true }) }} />
            </div>
        )
    }

    render() {
        if(this.state.loading) return <LoadingPage />
        if (this.state.showWizard) return <AddMapa addCallback={this.addMapaCallback} />
        if(this.state.showMapas) return <MapaList callback={this.addMapaCallback}/>

        return this.state.mapa ? this.renderDefaultMapa() : this.renderEmpty();
    }
}