import React from "react";
import Sig from "../../../../api/Sig";
import DefaultLoader from "../../../tools/DefaultLoader";
import { ProgressBar, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faEye, faStar, faCheck, faTimes, faEdit } from '@fortawesome/free-solid-svg-icons'
import { toast } from "react-toastify";
import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import UserAvatar from "../../../tools/UserAvatar";
import DataHelper from "../../../../helper/DataHelper";
import ProjetoHelper from "../../../../helper/pe/ProjetoHelper";
import DefaultButton from "../../../tools/DefaultButton";
import CustomTooltip from "../../../tools/CustomTooltip";
import LayoutHelper from "../../../../helper/LayoutHelper";

export default class ProjetoCard extends React.Component {
    state = {
        loading: true,
        projeto: {},
        iniciativa: {},
        objetivo: {},
        canvas: {},
        creatingCanvas: false,
        escala: 100,
        progress: 0,
        podeFavoritarProjeto: false,
        podeCriaCanvas: false,
        podeVisualizarCanvas: false,
        hoverOn: false,
        blockHoverOn: false,
        nameEditing: '',
        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
        await this.loadProjeto();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    setProgressBarColor() {
        if (this.progressBar) {
            const inner = this.progressBar.querySelector(".progress-bar");
            if (inner) inner.style.backgroundColor = Colors.dark;
        }
    }

    async loadProjeto() {
        this.setState({ loading: true });

        let { projeto, iniciativa, objetivo, canvas, podeFavoritarProjeto, podeCriaCanvas, podeVisualizarCanvas } = (await Sig.request('GET', 'pe/projeto/getProjeto', { id: this.props.id, relations: 1 }));
        let { escala } = await Sig.request('GET', `pe/projeto/getEscala`);
        let progress = await ProjetoHelper.getGutItemValue(this.props.id);

        canvas = canvas?.length > 0 ? canvas[0] : false;

        this.setState({ escala, progress, projeto, iniciativa, objetivo, canvas, podeFavoritarProjeto, podeCriaCanvas, podeVisualizarCanvas, loading: false }, () => { this.setProgressBarColor(); });
    }

    renderInfos() {

        let localStyle = {
            ...style.leftInfo,
            width: (this.state.isSmallScreen ? '100%' : '35%')
        }

        return (
            <div style={localStyle}>
                <div 
                    style={{...EssentialStyle.rowFlexStart, width: '100%', alignItems: 'center'}}
                    onMouseOver={ () => { this.setState({ hoverOn: true }) } } 
                    onMouseOut= { () => { this.setState({ hoverOn: false }) } }
                >
                    <div style={{ transition: 'width 0.3s linear', width: this.state.hoverOn || this.state.blockHoverOn ? '50px' : '0px', height: "100%" }}>
                        { this.state.hoverOn || this.state.blockHoverOn ? this.renderOpcoes() : null }
                    </div>
                    {this.state.editing ? 
                        <input 
                            type="text"
                            maxLength={100}
                            value={this.state.nameEditing} 
                            autoFocus={true}
                            onChange={ (e) => { this.setState({ nameEditing: e.target.value }) } } 
                            onKeyDown={ (e) => { 
                                e.stopPropagation();
                                if(e.key === 'Enter') this.confirmEdit(); 
                            } }
                            style={{...style.title, border: 'none', marginLeft: 8, flexGrow: 1, height: "30px"}}
                        /> 
                        : 
                        <div style={{...style.title, paddingLeft: this.state.hoverOn || this.state.blockHoverOn ? "10px" : "0px"}} >
                            {this.state.projeto?.nome}
                        </div>
                    }
                </div>
                <div style={style.subTitle}>
                    {this.state.iniciativa && this.state.objetivo ?
                        `${this.state.iniciativa?.nome} | ${DataHelper.removeHtmlTags(this.state.objetivo?.valor)}`
                        :
                        'Sem vínculo com Mapa Estratégico'
                    }
                </div>
                <UserAvatar id={this.state.projeto?.id_responsavel} showName={true} />
            </div>
        );
    }

    toggleFavorite = async () => {
        let { projeto } = await Sig.request('POST', 'pe/projeto/toggleFavorito', { id: this.state.projeto.id });

        if (projeto.id) {
            this.setState({ projeto });
        } else {
            toast.error('Erro ao marcar projeto como favorito');
        }
    }

    renderOpcoes(){
        
        if(!this.state.editing){
            return (
                <div style={{...EssentialStyle.rowFlexCenter, overflow: 'hidden', backgroundColor: Colors.homePage.extraLightGrey, height: "100%", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>
                    <FontAwesomeIcon className={"icon-container"} style={{margin: 3}} icon={faEdit} size="10" onClick={() => { this.editTitleOn() } }/>
                </div>
            )
        } else {
            return (
                <div style={{...EssentialStyle.rowFlexCenter, backgroundColor: Colors.homePage.extraLightGrey, height: "100%", borderTopRightRadius: "5px", borderBottomRightRadius: "5px", gap: "5px"}}>
                    <FontAwesomeIcon className={"icon-container"} style={{margin: 3}} icon={faTimes} size="10" onClick={() => { this.cancelEdit()  } }/>
                    <FontAwesomeIcon className={"icon-container"} style={{margin: 3}} icon={faCheck} size="10" onClick={() => { this.confirmEdit() } }/>
                </div>
            )
        }
    }

    editTitleOn() {
        this.setState({ editing: true, nameEditing: this.state.projeto.nome, blockHoverOn: true });
    }

    cancelEdit(){
         this.setState({ editing: false, blockHoverOn: false, nameEditing: this.state.projeto.nome });
    }

    async confirmEdit() {
        this.setState({ loading: true });
       
        if (this.state.nameEditing != this.state.projeto.nome) {
            let { projeto } = await Sig.request('POST', `pe/projeto/editNomeProjeto`, { id: this.state.projeto.id, nome: this.state.nameEditing });
            
            if(projeto) {
                this.setState({ projeto, nameEditing: projeto.nome });
            } else {
                toast.error('Erro ao editar projeto');
            }
        }
        
        this.setState({ loading: false, editing: false, blockHoverOn: false, hoverOn: false});
    }

    addCanvas = async () => {
        let { canvas } = await Sig.request('POST', 'pe/canvas/add',
            {
                nome: 'remover',
                id_pe_projeto: this.state.projeto.id,
                id_responsavel: this.state.projeto.id_responsavel
            }
        );

        if (canvas.id) {
            this.setState({ canvas, creatingCanvas: false, canvasName: '' }, () => {
                window.parent.postMessage({ type: '_blank', url: `/pe/canvas?id=${canvas.id}` }, '*');
            });
        } else {
            toast.error('Erro ao criar canvas');
        }
    }

    renderTools() {

        let localStyle = {
            ...style.rightInfo,
            width: (this.state.isSmallScreen ? '100%' : '65%')
        }

        return (
            <div style={localStyle}>
                {this.state.creatingCanvas ?
                    <div style={{ ...EssentialStyle.rowFlexStart, marginBottom: 10, }}>
                        <Form.Label>Criar canvas?</Form.Label>
                        <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 20, width: 85, justifyContent: 'space-between' }}>
                            <DefaultButton
                                tooltip={'Adicionar'}
                                color={Colors.success}
                                leftIcon={<FontAwesomeIcon icon={faCheck} />}
                                onClick={this.addCanvas}
                            />
                            <DefaultButton
                                tooltip={'Cancelar'}
                                textColor={Colors.dark}
                                color={Colors.secondaryButton}
                                leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                onClick={() => { this.setState({ creatingCanvas: false }) }}
                            />
                        </div>
                    </div>
                    :
                    <DefaultButton
                        style={{  marginBottom: 10 }}
                        color={this.state.canvas ? Colors.dark : Colors.success}
                        title={this.state.canvas ? "Ver Canvas" : "Criar Canvas"}
                        tooltip={this.state.canvas ? (!this.state.podeVisualizarCanvas ? "Sem Permissão para Visualizar Canvas do Projeto" : "") : (!this.state.podeCriaCanvas ?  "Sem Permissão para Criar Canvas de Projeto" : "")}
                        leftIcon={this.state.canvas ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                        disabled={this.state.canvas ? !this.state.podeVisualizarCanvas : !this.state.podeCriaCanvas}
                        onClick={() => {
                            if (this.state.canvas) {
                                window.parent.postMessage({ type: 'navigate', url: `/pe/canvas?id=${this.state.canvas.id}` }, '*');
                            } else {
                                this.setState({ creatingCanvas: true });
                            }
                        }}
                    />
                }


                {this.props.priorization === 'star' &&
                    <CustomTooltip placement={"left"} tooltip={!this.state.podeFavoritarProjeto ? "Sem Permissão Para Favoritar Projetos" : (this.state.projeto?.favorito == 1 ? 'Remover prioridade' : 'Marcar como prioritário') }>
                        <FontAwesomeIcon
                            icon={faStar}
                            onClick={this.state.podeFavoritarProjeto ? this.toggleFavorite : null}
                            style={{
                                color: this.state.projeto?.favorito == 1 ? Colors.danger : Colors.disabled,
                                cursor: 'pointer',
                                fontSize: 24
                            }}
                        />
                    </CustomTooltip>
                }
                {this.props.priorization === 'prioritization' &&
                    <ProgressBar 
                        min={0} 
                        max={this.state.escala} 
                        animated={this.state.loading}
                        now={this.state.progress} 
                        label={this.state.progress}
                        style={{ height: '4vh', borderRadius: 5, width: '100%'}}
                        variant="dark"
                        />
                }
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{ ...style.projetoCard, ...EssentialStyle.columnCenter }}>
                <DefaultLoader size={32} />
            </div>
        );
    }

    render() {
        if (this.state.loading) return this.renderLoading();

        let localStyle = {
            ...style.projetoCard,
            ...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceBetween),
        }

        return (
            <div style={localStyle}>
                {this.renderInfos()}
                {this.renderTools()}
            </div>
        );
    }
}

let style = {
    projetoCard: {
        ...EssentialStyle.card,
        width: '100%',
        padding: '10px 20px 10px 20px',
        marginBottom: 10
    },
    title: {
        fontSize: 20,
        color: Colors.dark
    },
    subTitle: {
        fontSize: 14,
        color: Colors.disabled
    },
    leftInfo: {
        ...EssentialStyle.columnStart,
        justifyContent: 'space-evenly',
        height: '100%',
        alignItems: 'flex-start',
        minWidth: '320px',
    },
    rightInfo: {
        ...EssentialStyle.columnEnd,
        justifyContent: 'space-evenly',
        height: '100%',
        alignItems: 'flex-end',
    }
}