import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faFile, faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import Sig from "../../../../api/Sig";
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import moment from "moment";
import AddNorteadores from "../../../modules/pe/mapa/AddNorteadores";
import AddPerspectivas from "../../../modules/pe/mapa/AddPerspectivas";
import IntervalSelector from "../../../tools/IntervalSelector";
import DatePicker from "react-datepicker";
import { forwardRef } from 'react';
import DiagramHelper from "../../../../helper/pe/DiagramHelper";


export default class AddMapa extends React.Component {
    state = {
        step: 0,
        loading: true,
        colabs: [],
        visoes: [],
        norteadores: [],
        intervalos: [],
        perspectivas: [],
        name: '',
        responsable: null,
        id_pe_visao: null,
        data_inicio: moment().startOf('year').format('YYYY-MM-DD'),
        data_fim: moment().endOf('year').format('YYYY-MM-DD'),
        participants: [],
        description: '',
        templateImage: null,
        templateId: null,
        newMapaId: null,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        let visoes = (await Sig.request('GET', 'pe/visao/list', { relations: 0 }))?.visoes || [];
        let colabs = await Sig.request('GET', 'config/colaborador/getColaboradores') || [];

        this.setState({ colabs, visoes }, () => { this.setState({ loading: false }) });
    }

    intervalosIsValid() {
        let intervalos =[];
        let haveInfinito = false, response = true;

        for (let intervalo of this.state.intervalos) {

            intervalos.push(parseInt(intervalo.from));
            intervalos.push(intervalo.infinito == 1 ? 2147483647 : parseInt(intervalo.to));
            if (intervalo.infinito) haveInfinito = true;
        }

        intervalos.sort((a, b) => a - b);
        
        // Verificar se começa em ZERO
        if (intervalos[0] != 0) response = false;
        
        // Retira o menor e maior valor do array
        intervalos.shift();
        intervalos.pop();

        // Valida se todos elementos passuem duas repetiçoes e não sobra nenhum com menos ou mais de duas
        for (let i = 0; i < intervalos.length; i += 2) {
            if (intervalos[i] !== intervalos[i + 1]) response = false;;
        }

        return response;
    }

    addNew = async () => {
        if (!this.state.name || !this.state.responsable) return toast.warn('Informe todos os campos obrigatórios (*)');

        this.setState({ loading: true });

        if (!this.intervalosIsValid()) {
            this.setState({ loading: false });
            return toast.warn('As faixas definidas não cobrem todo intervalo.');
        }

        let mapa = (await Sig.request('POST', `pe/mapa/add`, {
            nome: this.state.name,
            id_responsavel: this.state.responsable,
            id_pe_visao: this.state.id_pe_visao || null,
            data_inicio: DataHelper.getDefaultDbDateFormat(this.state.data_inicio),
            data_fim: DataHelper.getDefaultDbDateFormat(this.state.data_fim),
            colaboradores: this.state.participants.map((participant) => { return participant.value }) || []
        }))?.mapa || null;

        if (mapa && mapa.id) {
            if (this.state.norteadores.length) {
                for (let norteador of this.state.norteadores) {
                    await Sig.request('POST', 'pe/mapa/addNorteador', {
                        id_pe_mapa: mapa.id,
                        nome: norteador.name,
                        content: norteador.content
                    });
                }
            }

            if (this.state.intervalos.length) {
                for (let intervalo of this.state.intervalos) {
                    await Sig.request('POST', 'pe/mapa/addIntervalo', {
                        id_pe_mapa: mapa.id,
                        minimo: parseInt(intervalo.from),
                        maximo: parseInt(intervalo.to),
                        cor: intervalo.color,
                        infinito: intervalo.infinito
                    });
                }
            }

            if (this.state.perspectivas.length) {
                for (let perspectiva of this.state.perspectivas) {
                    await Sig.request('POST', 'pe/mapa/addPerspectiva', {
                        id_pe_mapa: mapa.id,
                        nome: perspectiva.name
                    });
                }

                await Sig.request('POST', 'pe/mapa/generatePerspectivas', { id: mapa.id, width: DiagramHelper.getDiagramResolution().width });
            }

            if (mapa) {
                this.setState({ newMapaId: mapa.id }, this.step(mapa.id));
                toast.success('Mapa inserido com sucesso!');

            }
           
        }

        this.setState({ loading: false });
    }

    renderDatePickerInicio() {

        const ExampleCustomInput1 = forwardRef(({ value, onClick }, ref) => (
            <Form.Control onClick={onClick} ref={ref} value={value} >
            </Form.Control>
        ));
        let dataMoment1 = this.state.data_inicio;
        let momentConvertidoData1 = moment(dataMoment1).toDate();

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={momentConvertidoData1}
                type="date"
                onChange={(event) => {
                    let novoInicio = moment(event);
                    this.setState({ data_inicio: novoInicio });
                    this.setState({ data_fim: novoInicio });
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<ExampleCustomInput1 />}
            />
        )
    };

    renderDatePickerFim() {

        const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
            <Form.Control onClick={onClick} ref={ref} value={value} className="w-100">
            </Form.Control>
        ));
        let dataMoment2 = this.state.data_inicio;
        let momentConvertidoDataInicio = moment(dataMoment2).toDate();

        let dataMoment3 = this.state.data_fim;
        let momentConvertidoDataFim = moment(dataMoment3).toDate();

        return (
            <DatePicker
                wrapperClassName="w-100"
                selected={momentConvertidoDataFim}
                type="date"
                onChange={(event) => {

                    let novoFimMoment = moment(event);

                    if (novoFimMoment >= momentConvertidoDataInicio) {
                        this.setState({ data_fim: novoFimMoment })
                    } else {
                        toast.info('Data de fim deve ser maior ou igual à data de início');
                    }
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                customInput={<ExampleCustomInput2 />}
            />
        )
    };





    renderForm() {


        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form className="w-75">
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome do mapa estratégico*</Form.Label>
                            <Form.Control type="text" placeholder="Mapa X..." onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Responsável*</Form.Label>
                            <Select options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione o responsável'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable onChange={(value) => { this.setState({ responsable: value ? value.value : null }) }} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Participantes*</Form.Label>
                            <Select
                            isDisabled={this.state.responsable ? false : true}                                
                            options={DataHelper.removeColaborador(DataHelper.formatSelectData(this.state.colabs, 'id', 'nome'), this.state.responsable)} placeholder={'Selecione os participantes'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable isMulti onChange={(value) => { this.setState({ participants: value }) }} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Inicio do período*</Form.Label>
                            <br></br>
                            {this.renderDatePickerInicio()}
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Fim do período*</Form.Label>
                            <br></br>
                            {this.renderDatePickerFim()}
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Vincular com visão de futuro</Form.Label>
                            <Select options={DataHelper.formatSelectData(this.state.visoes, 'id', 'nome')} placeholder={'Selecione a visão'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable onChange={(value) => { this.setState({ id_pe_visao: value ? value.value : null }) }} />
                        </Form.Group>
                    </Row>

                    <AddNorteadores callback={(norteadores) => { this.setState({ norteadores }) }} />
                    <AddPerspectivas callback={(perspectivas) => { this.setState({ perspectivas }) }} />
                    <IntervalSelector callback={(intervalos) => { this.setState({ intervalos }) }} />

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} title={'Adicionar'} loading={this.state.loading} onClick={this.addNew} />
                        </Form.Group>
                    </div>
                </Form>

            </div>
        );
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.step() }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    step(id = 0) {
        this.setState({ loading: true });
        this.props.addCallback(id);
        this.setState({ loading: false });
    }


    renderSteps() {
        let stepContent;

        stepContent = this.renderForm();

        return stepContent;
    }

    render() {
        return (
            <div style={{ minWidth: '100%' }}>
                {this.renderStepBack()}
                {this.renderSteps()}
            </div>
        );
    }
}