import React from 'react';
import Sig from "../../../api/Sig";
import EssentialStyle from "../../../style/EssentialStyle";
import DefaultLoader from "../../tools/DefaultLoader";
import Colors from "../../../constants/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCheck, faTimes, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Form } from 'react-bootstrap'
import DefaultButton from "../../tools/DefaultButton";
import PlanoAcaoHelper from "../../../helper/planoAcao/PlanoAcaoHelper";
import { toast } from "react-toastify";
import KeyboardHelper from "../../../helper/KeyboardHelper";
import PlanoAcaoQuadroCard from './PlanoAcaoQuadroCard/PlanoAcaoQuadroCard';
import LayoutHelper from '../../../helper/LayoutHelper';
import DragAndDropHelper from '../../../helper/DragAndDropHelper';

export default class PlanoAcaoQuadro extends React.Component {
    constructor(props) {
        super(props);
        this.inputRefs = {};
    }

    state = {
        loading: true,
        loadingEtapas: false,
        adding: false,
        atividade: '',
        justificativa: '',
        descricao: '',
        isSmallScreen: this.props.isSmallScreen,
        etapas: [],
        draggingOverColumn: null,
        draggingItem: null,
        draggingOverItem: null,
        draggingOverItemTop: false
    }

    componentDidMount = async () => {
        await this.loadData();
    }

    componentDidUpdate = async (prevProps) => {
        if (
            (prevProps.filter.people !== this.props.filter.people)
            || (prevProps.filter.search !== this.props.filter.search)
            || (prevProps.filter.status !== this.props.filter.status)
            || (prevProps.filter.prioridades !== this.props.filter.prioridades)
            || (prevProps.filter.data_inicio !== this.props.filter.data_inicio)
            || (prevProps.filter.data_fim !== this.props.filter.data_fim)
        ) {
            this.setState({ loadingEtapas: true });

            await this.loadData(true);

            this.setState({ loadingEtapas: false });
        }

        if(this.props.shouldUpdateEtapas && prevProps.shouldUpdateEtapas !== this.props.shouldUpdateEtapas) {
            this.setState({ loadingEtapas: true });

            await this.loadData(true);

            this.setState({ loadingEtapas: false });
        }

        if(this.props.isSmallScreen !== prevProps.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    getHeaderHeight = () => {
        let headerComponents = document.getElementsByClassName('pa-header-component');
        let headerHeight = 0;

        for (let headerComponent of headerComponents) {
            if(headerComponent.classList.contains('optional')){
                // Inverter o comentário para mostrar os campos optional sempre
                // headerHeight += 39;
                headerHeight += 0;
            } else {
                headerHeight += headerComponent.clientHeight;
            }
        }

        return headerHeight;
    }

    loadData = async (quiet = false) => {
        if (!quiet) this.setState({ loading: true });


        let etapas = await Sig.request('POST', 'planoAcao/listEtapasPlano', {
            codigo: this.props.codigo,
            orderBy: 'quadro_index',
            people: this.props.filter.people || [],
            search: this.props.filter.search || '',
            status: this.props.filter.status || [],
            prioridades: this.props.filter.prioridades || [],
            data_inicio: this.props.filter.data_inicio || '',
            data_fim: this.props.filter.data_fim || ''
        });

        let etapasByStatus = [];

        for (let etapa of etapas) {
            if (!etapasByStatus[etapa.status_value]) etapasByStatus[etapa.status_value] = [];
            etapasByStatus[etapa.status_value].push(etapa);
        }

        this.setState({ etapas: etapasByStatus || [] }, () => { this.setState({ loading: false }); this.props.updateEtapas(); });
    }

    renderEmptyColumn(status, index) {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                color: Colors.disabled,
                padding: 20,
                textAlign: 'center',
                width: '100%'
            }}>
                <span>Arraste etapas ou adicione clicando em "<FontAwesomeIcon icon={faAdd} /> Adicionar"</span>
            </div>
        );
    }

    addEtapa = async (status, index) => {
        if (this.props.detailed) {
            if (!this.state.atividade || !this.state.descricao || !this.state.justificativa) {
                if (!this.state.atividade) {
                    toast.info('O que será feito não pode ser vazio');
                    return;
                }
                if (!this.state.descricao) {
                    toast.info('Como será feito não pode ser vazio');
                    return;
                }
                if (!this.state.justificativa) {
                    toast.info('Por que será feito não pode ser vazio');
                    return;
                }
            }
        } else {
            if (!this.state.atividade) {
                toast.info('Atividade não pode ser vazia');
                return;
            }
        }

        let atividade = await Sig.request(
            'POST',
            'planoAcao/addEtapa',
            {
                codigo: this.props.codigo,
                atividade: this.state.atividade,
                justificativa: this.state.justificativa,
                status: status.value,
                descricao: this.state.descricao
            }
        );

        if (atividade) {
            let etapas = this.state.etapas;
            if (!etapas[status.value]) etapas[status.value] = [];
            etapas[status.value].push(atividade);

            this.setState({
                etapas,
                atividade: '',
                justificativa: '',
                descricao: '',
                adding: false
            }, () => { window.scrollTo(0, document.body.scrollHeight); this.props.updateEtapas(); });
        }
    }

    renderCreateAtividade(status, index) {
        let cardStyle = {
            ...EssentialStyle.rowFlexStart,
            marginTop: 10,
            fontSize: 16,
            width: '100%',
            borderTop: `none`,
            fontWeight: 500,
            padding: 10
        };

        return this.state.adding === status.value ?
            (
                <div style={{
                    ...cardStyle,
                    cursor: 'default'
                }}>
                    <Form.Control
                        ref={ref => this.inputRefs[status.value] = ref}
                        className="w-100 me-1"
                        type="text"
                        value={this.state.atividade}
                        placeholder="Descrição da Atividade..."
                        onChange={(event) => {
                            this.setState({ atividade: event.target.value })
                        }}
                        onKeyDown={(evt) => {
                            KeyboardHelper.handleShortcut(
                                evt,
                                ["Enter", "Escape"],
                                [() => { this.addEtapa(status, index) }, (e) => { e.preventDefault(); this.setState({ atividade: '', adding: false }); }]
                            )
                        }} />
                    <DefaultButton
                        width={48}
                        className="me-1"
                        leftIcon={<FontAwesomeIcon icon={faTimes} />}
                        color={Colors.dark}
                        loading={this.state.loading}
                        onClick={() => { this.setState({ atividade: '', adding: false }) }}
                    />
                    <DefaultButton
                        width={48}
                        leftIcon={<FontAwesomeIcon icon={faCheck} />}
                        color={Colors.dark}
                        loading={this.state.loading}
                        onClick={() => { this.addEtapa(status, index) }}
                    />
                </div>
            )
            :
            (
                <div
                    onClick={() => { this.setState({ adding: status.value }, () => { this.inputRefs[status.value].focus() }); }}
                    style={{
                        ...cardStyle,
                        cursor: 'pointer',
                        borderTop: `.1px solid ${Colors.dark}`,
                    }}>
                    <FontAwesomeIcon icon={faAdd} />
                    <span style={{ marginLeft: 8 }}>Adicionar</span>
                </div>
            );
    }

    onDragStartItem = async (e, item) => {
        this.setState({ draggingItem: item, draggingOverItem: null });
        setTimeout(() => { e.target.style.display = "none" }, 10);
    }

    onDragEndItem = async (e, item) => {
        this.setState({ draggingOverColumn: null, draggingItem: null, draggingOverItem: null });
        setTimeout(() => { e.target.style.display = "block" }, 200);
    }

    onDragEnterItem = async (e, item) => {
        let top = DragAndDropHelper.checkTop(e);

        if (item.id !== this.state.draggingItem.id || top !== this.state.draggingOverItemTop) {
            this.setState({ draggingOverItemTop: top }, () => { this.setState({ draggingOverItem: item }) });
        }
    }

    renderEtapasColumn(status, index) {
        if (!this.state.etapas[status.value]) {
            return this.renderEmptyColumn(status, index);
        }

        return this.state.etapas[status.value].map(etapa => {
            return (
                <PlanoAcaoQuadroCard
                    key={`board-column-card-${etapa.id}`}
                    dragControl={{ 
                        draggingOverQuadrant: this.state.draggingOverQuadrant, 
                        draggingItem: this.state.draggingItem, 
                        draggingOverItem: this.state.draggingOverItem,
                        draggingOverItemTop: this.state.draggingOverItemTop
                    }}
                    onDragStart={(e) => { this.onDragStartItem(e, etapa.id); }}
                    onDragEnd={(e) => { this.onDragEndItem(e, etapa.id); }}
                    onDragEnter={(e) => { this.onDragEnterItem(e, etapa); }}
                    detailed={this.props.detailed}
                    etapa={etapa}
                    updateEtapas={() => {this.props.updateEtapas()}}
                    participantes={this.props.participantes}
                    detailsModalCallback={this.props.detailsModalCallback}
                />
            )
        });
    }

    onDragOverColumn = (e, status) => {
        this.setState({ draggingOverColumn: status });
        e.stopPropagation();
        e.preventDefault();
    }

    onDropColumn = async (e, status, top) => {
        if (!this.state.draggingOverColumn && this.state.draggingItem) return;


        await Sig.request('POST', 'planoAcao/updateEtapaStatus', {
            id: this.state.draggingItem,
            dragging_over: this.state.draggingOverItem ? this.state.draggingOverItem.id : 0,
            top: top ? 1 : 0,
            status: status
        });

        this.loadData(true);
    }

    renderColumn(status, index) {
        return (
            <div
                onDragOver={(e) => { this.onDragOverColumn(e, status); }}
                onDrop={(e) => {
                    e.preventDefault();
                    let top = DragAndDropHelper.checkTop(e);

                    this.onDropColumn(e, status.value, top);
                }}
                key={`board-column-${status.value}`}
                style={{
                    ...EssentialStyle.columnStart,
                    alignSelf: 'flex-start',
                    backgroundColor: Colors.secondaryButton,
                    height: 'auto',
                    borderRadius: 5,
                    padding: 2,
                    marginRight: index === PlanoAcaoHelper.getEtapaStatus().length - 1 ? 0 : 8,
                    minWidth: 370,
                    maxWidth: 370,
                }}
            >
                <div style={{
                    ...EssentialStyle.rowFlexStart,
                    color: Colors.disabled,
                    fontSize: 14,
                    fontWeight: 600,
                    padding: 8,
                    width: '100%'
                }}>
                    {status.label.toUpperCase()} • {this.state.etapas[status.value] ? this.state.etapas[status.value].length : 0}
                </div>
                <div style={{
                    ...EssentialStyle.columnStart,
                    width: '100%'
                }}>
                    {this.renderEtapasColumn(status, index)}
                </div>
                {this.renderCreateAtividade(status, index)}
            </div>
        );
    }

    renderColumns() {
        return (
            <div style={{
                width: '100%',
                height: this.state.isSmallScreen ? `auto` : `calc(100vh - ${this.getHeaderHeight()}px)`,
                overflowY: this.state.isSmallScreen ? `hidden` : 'auto',
                overflowX: 'auto'
            }}>
                <div
                    style={{
                        ...EssentialStyle.rowFlexStart,
                        marginTop: 10,
                        paddingBottom: 20
                    }}
                >
                    {PlanoAcaoHelper.getEtapaStatus().map((status, index) => {
                        return this.renderColumn(status, index);
                    })}
                </div>
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: `calc(100vh - ${this.getHeaderHeight()}px)` }}>
                <DefaultLoader />
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderColumns();
    }
}