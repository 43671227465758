import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NotFoundPage from "../pages/NotFoundPage";
import SwotPage from '../pages/pe/SwotPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import VisaoPage from "../pages/pe/VisaoPage";
import MapaEstrategicoPage from "../pages/pe/MapaEstrategicoPage";
import ProjetoPageCriar from "../pages/pe/ProjetoPageCriar";
import ProjetoPageDetalhar from "../pages/pe/ProjetoPageDetalhar";
import CanvasPage from "../pages/pe/CanvasPage";
import HomePage from "../pages/inicio/HomePage";
import AtualizacoesPage from "../pages/atualizacoes/AtualizacoesPage";
import Sig from "../api/Sig";
import Constants from "../constants/Api";
import PlanoAcaoPage from "../pages/planoAcao/PlanoAcaoPage";
import PlanoAcaoDetalharPage from "../pages/planoAcao/PlanoAcaoDetalharPage";
import DiagnosticoProcessosPage from "../pages/diagnostico/DiagnosticoProcessosPage";
import DiagnosticoPeriodoAvaliacaoPage from "../pages/diagnostico/DiagnosticoPeriodoAvaliacaoPage";
import DiagnosticoQuestionarioPage from "../pages/diagnostico/DiagnosticoQuestionarioPage";
import DiagnosticoAvaliacaoPage from "../pages/diagnostico/DiagnosticoAvaliacaoPage";
import DiagnosticoResultadoPage from "../pages/diagnostico/DiagnosticoResultadoPage";
import DiagnosticoRelatorioPage from "../pages/diagnostico/DiagnosticoRelatorioPage";


const gateway = Constants.getSigEndPoint();
export default class RootNavigator extends React.Component {
    state = {
        loading: true,
        routesAvaluable: false
    }

    async componentDidMount() {
        //aqui req, se req válida seta isso aqui, se não, routesAvaluable = false;
        const grantedAccess = await Sig.setSigSessionData();

        if (grantedAccess && document.referrer === `${gateway}/`) {
            this.setState({ routesAvaluable: true });
        }

        this.setState({ loading: false });
        window.parent.postMessage({ type: 'remove_element', selector: '#iframe-loading' }, '*');
    }

    getPeRoutes() {
        return [
            <Route key="swot" path="swot" element={<SwotPage />} />,
            <Route key="visao" path="visao" element={<VisaoPage />} />,
            <Route key="mapa_estrategico" path="mapa_estrategico" element={<MapaEstrategicoPage />} />,
            <Route key="projeto/criar" path="projeto/criar" element={<ProjetoPageCriar />} />,
            <Route key="projeto/detalhar" path="projeto/detalhar" element={<ProjetoPageDetalhar />} />,
            <Route key="canvas" path="canvas" element={<CanvasPage />} />,
        ]
    }

    getAtualizacoesRoutes() {
        return [
            <Route key="atualizacoes" path="atualizacoes" element={<AtualizacoesPage />} />,
        ]
    }

    getPlanoAcaoRoutes() {
        return [
            <Route key="planoAcao" path="planoAcao" element={<PlanoAcaoPage />} />,
            <Route key="planoAcao/detalhar" path="planoAcao/detalhar" element={<PlanoAcaoDetalharPage />} />,
        ]
    }

    getDiagnosticoRoutes() {
        return [
            <Route key="diagnostico" path="diagnostico" element={<DiagnosticoProcessosPage />} />,
            <Route key="diagnostico/periodoAvaliacao" path="diagnostico/periodoAvaliacao" element={<DiagnosticoPeriodoAvaliacaoPage />} />,
            <Route key="diagnostico/questionario" path="diagnostico/questionario" element={<DiagnosticoQuestionarioPage />} />,
            <Route key="diagnostico/avaliacao" path="diagnostico/avaliacao" element={<DiagnosticoAvaliacaoPage />} />,
            <Route key="diagnostico/resultado" path="diagnostico/resultado" element={<DiagnosticoResultadoPage />} />,
            <Route key="diagnostico/relatorio" path="diagnostico/relatorio" element={<DiagnosticoRelatorioPage />} />,
        ]
    }

    getHomeRoutes() {
        return [
            <Route key="/" path="/" element={<HomePage />} />,
        ]
    }

    getRoutes() {
        return [
            ...this.getHomeRoutes(),
            ...this.getPeRoutes(),
            ...this.getPlanoAcaoRoutes(),
            ...this.getAtualizacoesRoutes(),
            ...this.getDiagnosticoRoutes(),
        ]
    }

    renderRouter() {
        return (
            <BrowserRouter>
                <Routes>
                    {this.state.routesAvaluable ? this.getRoutes().map(route => (route)) : null}

                    <Route path="/404" element={<NotFoundPage height={'calc(100vh - 60px)'} />} />
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
                <ToastContainer />
            </BrowserRouter>
        );
    }

    renderLoading() {
        return null;
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderRouter();
    }
}
