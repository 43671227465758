import React from "react";

import { forwardRef } from "react";
import { Form } from "react-bootstrap";
import { debounce } from 'lodash';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMaximize, faMinimize } from "@fortawesome/free-solid-svg-icons";

import Highcharts from "highcharts/highcharts.js";
import accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from "highcharts-react-official";

import variablePie from "highcharts/modules/variable-pie.js";

import DatePicker from 'react-datepicker';
import moment from "moment";

import ChartHelper from "../../../../helper/inicio/ChartHelper";
import SessionHelper from "../../../../helper/SessionHelper";
import DefaultLoader from "../../../tools/DefaultLoader";
import DefaultButton from "../../../tools/DefaultButton";
import EssentialStyle from "../../../../style/EssentialStyle";
import Constants from "../../../../constants/Api";
import Colors from "../../../../constants/Colors";
import Sig from "../../../../api/Sig";
import AtividadeCard from "./AtividadeCard";
import LayoutHelper from "../../../../helper/LayoutHelper";
import Accordion from 'react-bootstrap/Accordion';

variablePie(Highcharts);
accessibility(Highcharts);

const gateway = Constants.getSigEndPoint();

export default class Atividades extends React.Component {
    constructor(props) {
        super(props);
        this.debouncedLegendItemClick = debounce(this.legendItemClick, 150);
    }

    state = {
        loading: true,
        me: false,
        expanded: false,
        data_inicio: moment().subtract('1', 'year').toDate(),
        data: [],
        atividades: {},
        prioridades: [],
        lista_atividades: [],
        filter: [],
        viewCard: null,
    }

    async componentDidMount() {
        if (this.props.userId) {
            this.setState({ me: (this.props.userId === SessionHelper.getData().id_usuario) });

            await this.getAtividades();
        }

        this.setState({ loading: false });
    }

    async componentDidUpdate(prevProps) {
        if (this.props.userId && (this.props.userId !== prevProps.userId || this.props.viewUserConfig !== prevProps.viewUserConfig)) {
            this.setState({
                loading: true,
                me: (this.props.userId === SessionHelper.getData().id_usuario),
            });

            await this.getAtividades();

            this.setState({ loading: false });
        }
    }

    setViewCardCallback = (id) => {
        this.setState({ viewCard: id });
    };

    getAtividades = async (silent = false) => {
        this.setState({ loading: !silent });

        let { atividades, lista_atividades, dadosGrafico } = await Sig.request('GET', 'inicio/getAtividades', { id_colaborador: this.props.userId, data_inicio: moment(this.state.data_inicio).unix() });

        dadosGrafico = dadosGrafico.map((item) => {
            return {
                ...item,
                color: ChartHelper.getColor(item.name)
            };
        });

        this.setState({
            data: dadosGrafico,
            atividades,
            lista_atividades
        }, () => {
            this.setState({ loading: false });
        });
    }

    renderChartHeader() {
        return (
            <div style={{
                ...EssentialStyle.rowSpaceBetween,
                width: '100%',
                paddingBottom: 4,
                height: 43,
                minHeight: 43,
                borderBottom: `1px solid ${Colors.homePage.line}`,
            }}>
                <span style={{ ...EssentialStyle.titleBoxHomePage, paddingLeft: 10 }}>
                    Minhas Atividades
                </span>

                <div style={{
                    ...EssentialStyle.rowFlexStart
                }}>
                    {this.renderDatePicker('data_inicio', () => { this.getAtividades() }, 'Atividades a partir de')}
                    <DefaultButton
                        leftIcon={!this.state.expanded ? <FontAwesomeIcon icon={faMaximize} /> : <FontAwesomeIcon icon={faMinimize} />}
                        color={'transparent'}
                        textColor={SessionHelper.getColor()}
                        onClick={() => { this.setState({ expanded: !this.state.expanded }, () => { this.props.expandCallbackPageZero(this.state.expanded ? 'atividade' : false) }); }}
                    />
                </div>

            </div>
        );
    }

    getCanEdit() {
        return (this.props.viewUserConfig && SessionHelper.getData().id_usuario == this.props.userId)
            || (!this.props.viewUserConfig);
    }

    renderDatePicker(stateProp, callback = () => { }, title) {
        const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
            <Form.Control
                onChange={() => { }}
                onClick={onClick}
                ref={ref}
                style={{ marginLeft: 10, fontSize: 14, color: Colors.dark, fontWeight: 'bold', cursor: 'pointer', boxShadow: 'none', backgroundColor: Colors.homePage.lightGrey, padding: 1, height: '100%', width: 100, textAlign: 'center' }}
                value={value} >
            </Form.Control>
        ));

        return (
            <div style={{
                ...EssentialStyle.rowFlexStart,
                color: Colors.dark,
                fontSize: 14
            }}>
                {title || ``}
                <DatePicker
                    selected={this.state[stateProp]}
                    type="date"
                    onChange={(event) => {
                        let novoInicio = moment(event);
                        if (novoInicio.toDate() !== this.state[stateProp]) {
                            this.setState({ [stateProp]: novoInicio.toDate() }, callback);
                        }
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    customInput={<DatePickerComponent />}
                    popperPlacement="top-start"
                />
            </div>
        )
    }

    legendItemClick(componentContext, context) {
        var series = context.series;
        var clickedPoint = context;
        var allPointsVisible = series.points.every(p => p.visible);
        var visiblePoints = series.points.filter(p => p.visible);


        if (allPointsVisible) {
            series.points.forEach(point => point.setVisible(point === clickedPoint));
        } else {
            if (clickedPoint.visible) {

                if (visiblePoints.length > 1) {
                    clickedPoint.setVisible(false);
                } else {
                    series.points.forEach(point => point.setVisible(true));
                }
            } else {
                clickedPoint.setVisible(true);
            }
        }

        visiblePoints = series.points.filter(p => p.visible);

        let filter = [];
        for (let point of visiblePoints) {
            if (point.visible && !filter.includes(point.name)) filter.push(point.name);
        }
        componentContext.setState({ filter });
    }

    renderPieChart() {
        let componentContext = this;

        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                }}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'chart'}
                        options={{
                            chart: {
                                type: 'variablepie',
                                backgroundColor: 'transparent',
                                margin: [25, null, 25, null]
                            },
                            title: {
                                text: '',
                                align: 'left',
                                style: {
                                    fontSize: '16px',
                                    fontWeight: '200'
                                }
                            },
                            tooltip: {
                                borderWidth: 0,
                                backgroundColor: 'none',
                                shadow: false,
                                style: {
                                    fontSize: '12px'
                                },
                                pointFormat: '<span style="font-size: 2em; text-align: center; color: {point.color}; font-weight: bold">{point.yPercent}%</span>',
                                positioner: function (labelWidth) {
                                    return {
                                        x: 0,
                                        y: 0
                                    };
                                }
                            },
                            legend: {
                                align: 'right',
                                verticalAlign: 'middle',
                                layout: 'vertical',
                                enabled: true,
                                labelFormatter: function () {
                                    return this.name + ': ' + this.y;
                                }
                            },
                            plotOptions: {
                                variablepie: {
                                    dataLabels: {
                                        enabled: false,
                                    },
                                    point: {
                                        events: {
                                            legendItemClick: function (e) {
                                                e.preventDefault();
                                                componentContext.debouncedLegendItemClick(componentContext, this);
                                            }
                                        }
                                    }
                                }
                            },
                            series: [{
                                minPointSize: 40,
                                showInLegend: true,
                                innerSize: '65%',
                                borderRadius: 1,
                                data: this.state.data
                            }],
                            credits: {
                                enabled: false
                            }
                        }}
                    />
                </div>
            </div>
        );
    }

    renderActivitiesList() {

        return (
            <div style={{
                ...EssentialStyle.columnStart,
                width: '100%',
                height: '100%',
                paddingLeft: 4,
                overflowY: 'auto'
            }}>
                <Accordion defaultActiveKey="0" style={{ width: '100%' }}>
                    {this.state.lista_atividades.map((item, index) => {
                        if (this.state.filter.length < 1 || this.state.filter.includes(item.atrasada ? 'Atrasada' : item.status)) {
                            return <AtividadeCard key={`atividade-${index}`} canEdit={this.getCanEdit()} atividade={item} updateCallback={this.getAtividades} setViewCardCallback={this.setViewCardCallback} viewCard={this.state.viewCard} />
                        }
                    })}
                </Accordion>
            </div>
        );
    }

    renderChartBody() {
        return (
            <div style={LayoutHelper.isSmallScreen() ? layout.bodyColumn : layout.bodyRow}>

                {this.state.lista_atividades.length === 0 ?
                    <div style={{
                        ...(LayoutHelper.isSmallScreen() ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter),
                        width: '100%',
                        height: '100%',
                        fontSize: 14
                    }}>
                        <img
                            src={`${gateway}/img/undraw/undraw_to_do_list.svg`}
                            alt="Pessoa de olhando uma lista de tarefas"
                            style={{
                                height: 140,
                                marginRight: 5,
                            }}
                        />

                        <div style={LayoutHelper.isSmallScreen() ? layout.undrawTextSmall : layout.undrawTextLarge}>
                            Ainda não há atividades por aqui! Crie-as em <span onClick={() => { window.parent.postMessage({ type: '_blank', url: `/planoAcao#sidebar-plano` }, '*'); }} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}>
                                Planos de Ação
                            </span> ou em <span onClick={() => { window.parent.postMessage({ type: '_blank', url: `/ataReuniao#sidebar-ata` }, '*'); }} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}>
                                Atas de Reunião
                            </span> para elas aparecerem aqui!
                        </div>

                    </div>
                    :
                    <>
                        <div style={LayoutHelper.isSmallScreen() ? layout.chartSmall : layout.chartlarge}>
                            {this.renderPieChart()}
                        </div>

                        <div style={{ ...(LayoutHelper.isSmallScreen() ? layout.activitiesSmall : layout.activitiesLarge), height: LayoutHelper.isSmallScreen() ? (this.state.expanded ? '' : '50%') : '100%' }}>
                            {this.renderActivitiesList()}
                        </div>
                    </>
                }

            </div>
        );
    }

    renderChart() {
        return (
            <div style={{
                ...EssentialStyle.columnStart,
                width: '100%',
                height: '100%'
            }}>
                {this.renderChartHeader()}
                {this.renderChartBody()}
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: '100%'
            }}>
                <DefaultLoader />
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderChart();
    }
}

const layout = {

    bodyRow: {
        ...EssentialStyle.rowSpaceBetween,
        width: '100%',
        height: `calc(100% - 47px)`,
        paddingTop: 4
    },
    bodyColumn: {
        ...EssentialStyle.columnStart,
        width: '100%',
        height: `calc(100% - 47px)`,
        paddingTop: 4
    },
    chartSmall: {
        ...EssentialStyle.columnStart,
        width: '100%',
        height: '50%',
    },
    chartlarge: {
        ...EssentialStyle.columnStart,
        width: '40%',
        height: '100%',
    },
    activitiesSmall: {
        ...EssentialStyle.columnStart,
        width: '100%',
    },
    activitiesLarge: {
        ...EssentialStyle.columnStart,
        width: '60%',
        height: '100%'
    },
    undrawTextSmall: {
        ...EssentialStyle.titleBoxHomePage,
        marginTop: 10,
        padding: '10px 20px',
    },
    undrawTextLarge: {
        ...EssentialStyle.titleBoxHomePage,
        marginLeft: 5,
        maxWidth: 300,
    }
}