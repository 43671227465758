import React from "react";
import EssentialStyle from "../../style/EssentialStyle";
import Colors from "../../constants/Colors";
import Constants from "../../constants/Api";

const gateway = Constants.getSigEndPoint();

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ height: this.props.height ? this.props.height : '100vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: Colors.dark, fontWeight: 'bold' }}>
                    <img src={`${gateway}/img/data-not-found4.png`} alt="404" style={{ height: 200 }} />
                    <div style={{ fontSize: 25, marginTop: 20 }}>Oops! Ocorreu um erro desconhecido!</div>
                    <div style={{ fontSize: 16, marginTop: 10 }}>Aguarde alguns momentos e tente novamente, ou vá para a <span onClick={() => { window.parent.postMessage({ type: '_blank', url: `/` }, '*'); }} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}>Página Inicial</span></div>
                    <div style={{ fontSize: 14, marginTop: 5 }}>Caso o erro persista entre em contato conosco por e-mail em <a href="mailto:sig@grupoadvis.com.br" style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold', color: Colors.dark }}>sig@grupoadvis.com.br</a> </div>
                </div>
            )
        }

        return this.props.children;
    }
}