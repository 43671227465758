import React from 'react';
import Draggable from 'react-draggable';
import moment from "moment";
import PlanoAcaoHelper from '../../../helper/planoAcao/PlanoAcaoHelper';
import Colors from '../../../constants/Colors';

export default class ResizableTimelineElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      element: {...props.element},
      elementPosition: props.elementPosition,
      elementLength: props.elementLength,
      isResizing: false,
      isDragging: false,
      initialMouseX: 0,
      initialWidth: 0,
      initialLeft: 0,
      resizeSide: 'right', 
      timelineStart: 0,
      timelineEnd: 0,
      edit: this.props.edit,
      isSmallScreen: this.props.isSmallScreen,
      type: this.props.type,
      displayDates: false,
      dateFormat: this.props.type == "marco" ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD',
    };
  }

  componentDidMount() {
    this.setState({
      element: {...this.props.element},
      elementPosition: this.props.elementPosition,
      elementLength: this.props.elementLength,
      timelineStart: this.props.timelineStart,
      timelineEnd: this.props.timelineEnd,
      edit: this.props.edit,
      isSmallScreen: this.props.isSmallScreen
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.element !== this.props.element) {
      this.setState({ element: this.props.element });
    }
    if (prevProps.elementPosition !== this.props.elementPosition) {
      this.setState({ elementPosition: this.props.elementPosition });
    }
    if (prevProps.elementLength !== this.props.elementLength) {
      this.setState({ elementLength: this.props.elementLength });
    }
    if (prevProps.timelineStart !== this.props.timelineStart) {
      this.setState({ timelineStart: this.props.timelineStart});
    }
    if (prevProps.timelineEnd !== this.props.timelineEnd) {
      this.setState({ timelineEnd: this.props.timelineEnd});
    }
    if(prevProps.edit !== this.props.edit) {
      this.setState({ edit: this.props.edit });
    }
    if(prevProps.isSmallScreen !== this.props.isSmallScreen) {
      this.setState({ isSmallScreen: this.props.isSmallScreen });
    }
  }

  startResizing = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    if(this.state.edit && this.state.type === 'marco') 
      this.setEditId();

    const elementRect = this.draggableElement.getBoundingClientRect();
    const resizeSide = mouseDownEvent.clientX - elementRect.left < elementRect.width / 2 ? 'left' : 'right';

    this.setState({
      isResizing: true,
      initialMouseX: mouseDownEvent.clientX,
      resizeSide,
      initialWidth: this.state.elementLength,
      initialLeft: this.state.elementPosition,
    });

    mouseDownEvent.stopPropagation();

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.stopResizing);
  };

  stopResizing = () => {
    this.setState({ isResizing: false }, this.props.changeElementCallback(this.state.element));
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.stopResizing);
  };

  handleMouseMove = (mouseMoveEvent) => {
    var offsetLeft = 150;
    if(this.state.isSmallScreen) 
      offsetLeft = 100;

    if (!this.state.isResizing) return;
  
    const { initialMouseX, initialWidth, resizeSide, initialLeft } = this.state;
    const element = {...this.state.element};
    const currentMouseX = mouseMoveEvent.clientX;
    let widthChange = currentMouseX - initialMouseX;
    let newWidth = initialWidth + (resizeSide === 'left' ? -widthChange : widthChange);
    const scrollContainer = this.draggableElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    const nearRightEdge = (scrollContainer.offsetWidth + scrollContainer.offsetLeft) - (mouseMoveEvent.x) < 20;
    const nearLeftEdge = mouseMoveEvent.x - (scrollContainer.offsetLeft+offsetLeft) < 20;
    
    newWidth = Math.round(newWidth/this.props.daySize) * this.props.daySize;
    newWidth = Math.max(newWidth, this.props.daySize);

    if (nearRightEdge) {
      if((scrollContainer.scrollLeft + scrollContainer.clientWidth) - scrollContainer.scrollWidth < 5){ 
        scrollContainer.scrollLeft += 5;
      } 
    } else if (nearLeftEdge) {
      if(scrollContainer.scrollLeft > 0){ 
        scrollContainer.scrollLeft -= 5;
      }
    }

    if (resizeSide === 'left') {
      let newLeft = Math.round(initialLeft + (initialWidth - newWidth));
      if(newLeft < 0){
        newLeft = 0;
        newWidth = initialWidth + initialLeft;
      }

      element.data_inicio = moment(new Date(this.state.timelineStart)).add(Math.round(newLeft / this.props.daySize), 'days').format(this.state.dateFormat);
      element.data_fim = moment(new Date(this.state.timelineStart)).add(Math.round((newLeft + newWidth) / this.props.daySize)-1, 'days').format(this.state.dateFormat);
      //this.props.changeElementCallback(element);
      this.setState({ elementPosition: newLeft, elementLength: newWidth, element});
    }else{
      if(newWidth + initialLeft > this.draggableElement.parentElement.offsetWidth){
        newWidth = this.draggableElement.parentElement.offsetWidth - initialLeft;
      }

      element.data_fim = moment(new Date(this.state.timelineStart)).add(Math.round(initialLeft/this.props.daySize + newWidth/this.props.daySize)-1, 'days').format(this.state.dateFormat);
      //this.props.changeElementCallback(element);
      this.setState({ elementLength: newWidth, element});
    }
    
  };

  getMarcoColor() {
    var percentage = this.state.element.peso;
    let r, g, b = 0; 
    b = 100;
    if (percentage <= 50) {
        r = 255;
        g = Math.round(5.1 * percentage); 
    } else {
        g = 255;
        r = Math.round(510 - 5.1 * percentage); 
    }
    return `rgb(${r}, ${g}, ${b})`;
  }
  getEtapaColor() {
    return PlanoAcaoHelper.getEtapaStatus()[this.state.element.status].backgroundColor;
  }

  handleDrag = (e, ui) => {
    var { elementPosition, elementLength, initialMouseX } = this.state;
    const element = {...this.state.element};
    elementPosition += ui.x - initialMouseX;
    element.data_inicio = moment(new Date(this.state.timelineStart)).add(elementPosition / this.props.daySize, 'days').format(this.state.dateFormat);
    element.data_fim = moment(new Date(this.state.timelineStart)).add((elementPosition/this.props.daySize + elementLength/this.props.daySize)-1, 'days').format(this.state.dateFormat);
    this.setState({ elementPosition, initialMouseX: ui.x, element });
  };

  stopDrag = () => {
    this.setState({ isDragging: false }, () => this.props.changeElementCallback(this.state.element));
  }

  startDrag = (e, ui) =>{
    if(this.state.edit && this.state.type === 'marco') 
      this.setEditId();

    this.setState({
      isDragging: true,
      initialMouseX: ui.x
    })
  }

  setEditId = () => {
    this.props.setEditId(this.state.element.id);
  }

  clearEditId = () => {
    this.props.setEditId(null);
  }

  getElementColor = () => {
    if(this.state.type === 'marco') {
      return this.getMarcoColor();
    } else {
      return this.getEtapaColor();
    }
  }

  hideDates = () => {
    if(this.state.isDragging || this.state.isResizing){
      setTimeout(() => this.hideDates(), 10);
    } else {
      this.setState({displayDates: false});
    }
  }

  getDiasAtraso = () => {
    if(this.state.type != 'etapa') return 0;
    var dataFim = moment(this.state.element.data_fim);
    var dataFimReal = moment();

    if(this.state.element.status == 2 || this.state.element.status == 3)
      dataFimReal = moment(this.state.element.data_conclusao);

    if(dataFimReal.isAfter(dataFim)){
      return dataFimReal.diff(dataFim, 'days');
    }
    return 0;
  }

  render() {

    var diasAtraso = this.getDiasAtraso();

    return (
      <div style={{ display: "flex", position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
        {this.state.displayDates ? 
          <span style={{position: "absolute", fontSize: '10px', marginLeft: "-61px", color: Colors.homePage.grey, left: (this.state.elementPosition + 5), lineHeight: "100%", alignSelf: "center"}}>
            {moment(this.state.element.data_inicio).format('DD/MM/YYYY')}
          </span>
        : 
          null
        }
        <Draggable
          axis="x"
          bounds={"parent"}
          grid={[this.props.daySize, this.props.daySize]}
          position={{x: this.state.elementPosition, y: 0}}
          onStop={this.stopDrag}
          onStart={this.startDrag}
          onDrag={this.handleDrag}
          disabled={!this.state.edit}
        >
          <div
            ref={(el) => { this.draggableElement = el; }}
            onDoubleClick={this.state.type == "marco" ? () => {} : this.setEditId}
            onMouseEnter={() => this.setState({displayDates: true})}
            onMouseLeave={this.hideDates}
            style={{ position: 'relative', borderRadius: '3px', height: "25px", alignSelf: 'center', backgroundColor: this.getElementColor(), color: 'white', textAlign: 'center', width: `${this.state.elementLength}px`, zIndex: 2, filter: this.state.type == "marco" ? "brightness(90%)" : "unset" }}
          >
            {this.state.edit ?
            <>
              <div
                onMouseDown={(event) => {
                  this.startResizing(event)
                }}
                style={{ cursor: 'ew-resize', position: 'absolute', left: 0, top: 0, bottom: 0, width: '2px' }}
                />
              <div
                onMouseDown={(event) => {
                  this.startResizing(event)
                }}
                style={{ cursor: 'ew-resize', position: 'absolute', right: 0, top: 0, bottom: 0, width: '2px' }}
                />
            </>
            : null}
          </div>
        </Draggable>
        {diasAtraso > 0 ? <div style={{position: "absolute", alignSelf: 'center', left: this.state.elementPosition + this.state.elementLength - 3, width: (this.props.daySize*diasAtraso) + 3, height: "25px", backgroundColor: "#EE9E9E", border: "2px solid #EE9595", borderTopRightRadius: 3, borderBottomRightRadius: 3}}></div> : null}
        {this.state.displayDates ? 
          <span style={{position: "relative", fontSize: '10px', color: Colors.homePage.grey, left: (this.state.elementPosition + 5), lineHeight: "100%", alignSelf: "center"}}>
            {moment(this.state.element.data_fim).format('DD/MM/YYYY')}
          </span>
        : 
          null
        }
      </div>
    );
  }
}