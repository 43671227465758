import React from "react";
import Sig from "../../api/Sig";
import Select from 'react-select';
import DataHelper from "../../helper/DataHelper";
import SessionHelper from "../../helper/SessionHelper";
import LoadingComponent from "./LoadingComponent";

export default class GutMatrixEditItem extends React.Component {
  
    state = {
        value: null,
        loading: true,
    }

    async componentDidMount() {

        await this.getValue();

        this.setState({ loading: false });
    }

    async getValue() {

        if(this.props.idSwot){
            let item = await Sig.request('POST', 'pe/swot/getGutItem', 
                { 
                    id_pe_swot: this.props.idSwot,
                    id_pe_swot_quadrante: this.props.idQuadrante,
                    id_pe_gut_criterio: this.props.idCriterio,
                    id_pe_swot_quadrante_item: this.props.idItem
                }
            );

            this.setState({ value: item?.pontuacao });
        } else if(this.props.idPeMapa) {

            let item = await Sig.request('POST', 'pe/mapa/getGutItem', 
                { 
                    id_pe_mapa: this.props.idPeMapa,
                    id_pe_gut_criterio: this.props.idCriterio,
                    id_pe_iniciativa: this.props.idItem
                }
            );

            this.setState({ value: item?.pontuacao });
        } else if(this.props.projetos) {
            let item = await Sig.request('POST', 'pe/projeto/getGutItem', 
                { 
                    id_pe_gut_criterio: this.props.idCriterio,
                    id_pe_projeto: this.props.idItem
                }
            );

            this.setState({ value: item?.pontuacao });
        }
    }

    async updateValue(value, idQuadrante, idCriterio, idItem) {

        if(this.props.idSwot){
            await Sig.request('POST', 'pe/swot/updateGutItem',
                { 
                    id_pe_swot: this.props.idSwot,
                    id_pe_swot_quadrante: idQuadrante,
                    id_pe_gut_criterio: idCriterio,
                    id_pe_swot_quadrante_item: idItem,
                    id_pe_gut_pontuacao: value ? value : 0,
                }
            );
        } else if(this.props.idPeMapa) {

            await Sig.request('POST', 'pe/mapa/updateGutItem',
                { 
                    id_pe_mapa: this.props.idPeMapa,
                    id_pe_gut_criterio: idCriterio,
                    id_pe_iniciativa: idItem,
                    id_pe_gut_pontuacao: value ? value : 0,
                }
            );
        } else if(this.props.projetos) {
            await Sig.request('POST', 'pe/projeto/updateGutItem',
                { 
                    id_pe_gut_criterio: idCriterio,
                    id_pe_projeto: idItem,
                    id_pe_gut_pontuacao: value ? value : 0,
                }
            );
        }

        this.props.callback();
    }

    render() {
        
        return  this.state.loading ? <LoadingComponent /> : (

            <Select 
                defaultValue={this.state.value ? { label: `${this.state.value.valor} - ${this.state.value.nome}`, value: this.state.value.id } : null} 
                options={DataHelper.formatSelectData(this.props.criterios[this.props.row].pontuacao, 'id', 'valor', (row) => `${row.valor} - ${row.nome}`)} 
                placeholder={'Selecionar Opção'} 
                noOptionsMessage={DataHelper.getSelectEmptyMessage} 
                isClearable 
                isSearchable 
                onChange={(value) => { this.updateValue(value ? value.value : null, this.props.idQuadrante, this.props.idCriterio, this.props.idItem) }} />
        )
    }
}