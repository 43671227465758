import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faDownload, faFile, faFileAlt, faImage, faPlus, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "./DefaultButton";
import Colors from "../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import SessionHelper from "../../helper/SessionHelper";
import { toast } from "react-toastify";
import Constants from "../../constants/Api";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "./CustomConfirm";
import Sig from "../../api/Sig";
import EssentialStyle from "../../style/EssentialStyle";
import EllipsisText from "./EllipsisText";


export default class UploadFiles extends React.Component {
    state = {
        loading: true,
        loadingUpload: false,
        files: [],
        fileToUpload: null,
        fileToUploadPreview: null,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        const files = (await Sig.request("POST", this.props.action + "GetFiles", { id: this.props.id }))?.arquivos || [];

        this.setState({ files, loading: false });
    }

    removeFile = async (file) => {
        this.setState({ loadingUpload: true });

        const req = await Sig.request("POST", this.props.action+"RemoveFile", { file: JSON.stringify(file) });

        if (req.arquivo) {
            toast.success('Arquivo removido!');
            this.loadData();
            if(this.props.removeFileCallback) this.props.removeFileCallback(file);
        } else {
            toast.error('Ocorreu um erro ao remover o arquivo, tente novamente mais tarde.')
        }

        this.setState({ loadingUpload: false });
    }

    renderFile(file) {
        const prefix = `${Constants.getSigEndPoint()}/upload/geral`;

        return (
            <div key={`key-${file.nome}`} style={{ display: 'flex', flexDirection: 'column', width: '100%', cursor: 'default' }}>
                <div style={{ paddingLeft: 15, paddingRight: 15, borderRadius: 5, cursor: 'pointer', display: 'flex', flexDirection: 'row', height: 64, width: '100%', marginBottom: 10, backgroundColor: Colors.secondaryButton, alignContent: 'center', alignItems: 'center', justifyContent: 'space-between', cursor: 'default' }}>
                    <div style={{ paddingRight: 15, display: 'flex', flexDirection: 'row', width: 'calc(100% - 90px)', alignContent: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <FontAwesomeIcon icon={faFileAlt} color={Colors.dark} fontSize={20} style={{ marginRight: 8 }} />
                        <EllipsisText text={file.nome} />
                    </div>
                    <FontAwesomeIcon
                        title={'Baixar Arquivo'}
                        style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', width: 40 }}
                        onClick={() => {
                            window.parent.postMessage({ type: 'download', file, prefix }, '*');
                        }}
                        icon={faDownload}
                        color={Colors.dark}
                        fontSize={22}
                    />
                    {!this.props.hideAdd &&
                        <FontAwesomeIcon
                            title={'Remover Arquivo'}
                            style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', width: 40 }}
                            onClick={() => {
                                confirmAlert({
                                    customUI: ({ onClose }) => (
                                        <CustomConfirm
                                            title={'Remover este Anexo?'}
                                            message={'Essa ação é irreversível'}
                                            buttons={[
                                                {
                                                    label: 'Remover',
                                                    color: SessionHelper.getColor(),
                                                    textColor: Colors.light,
                                                    onClick: () => { this.removeFile(file); onClose(); }
                                                },
                                                {
                                                    label: 'Cancelar',
                                                    onClick: () => { onClose(); }
                                                },
                                            ]}
                                        />
                                    )
                                });
                            }}
                            icon={faTimes}
                            color={Colors.dark}
                            fontSize={22}
                        />
                    }
                </div>
            </div>
        );
    }

    handleFileUpload = async (v) => {
        this.setState({ loadingUpload: true });

        const file = v.target.files[0];
        const maxSize = 10000000;

        if (file && file.size <= maxSize) {
            this.setState({ fileToUpload: file, fileToUploadPreview: URL.createObjectURL(file), name: !this.state.name ? file.name.replace(/[\W_]+/g, "") : this.state.name });
        } else {
            if (file && file.size >= maxSize) {
                toast.warn('O arquivo é muito grande, use um de menos de 10 MB');
            } else {
                toast.warn('O arquivo não cumpre os requisitos necessários.');
            }
        }

        this.setState({ loadingUpload: false });
    }

    doUploadFile = async () => {
        this.setState({ loadingUpload: true });

        const formData = new FormData();
        formData.append('file', this.state.fileToUpload);
        formData.append('nome', this.state.name);
        formData.append('id_relation', this.props.id);

        const req = await Sig.request("POST", this.props.action+"UploadFile", formData, 'multipart/form-data');

        this.setState({ loadingUpload: false }, () => { if(this.props.uploadCallback) this.props.uploadCallback(this.state.fileToUpload); });

        if (req.referencia) {
            toast.success('Arquivo inserido com sucesso!');
            this.setState({ fileToUpload: null, fileToUploadPreview: null, name: '', addingNew: false });
            this.loadData();
        } else {
            toast.error('Ocorreu um erro ao fazer o upload, tente novamente mais tarde.');
            this.setState({ fileToUpload: null, fileToUploadPreview: null, name: '', addingNew: false });
        }
    }

    renderAddNew() {
        return this.state.addingNew ?
            (
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                    <input
                        multiple={false}
                        accept="image/*, .pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xlsx, .xls, .pptx, .ppt"
                        style={{ display: 'none' }}
                        id="upload-template"
                        name="upload-template"
                        type="file"
                        onChange={this.handleFileUpload}
                    />

                    {this.state.fileToUploadPreview ?
                        <div style={{ paddingLeft: 10, paddingRight: 10, borderRadius: 5, cursor: 'pointer', display: 'flex', flexDirection: 'row', height: 100, width: '100%', marginBottom: 10, backgroundColor: Colors.secondaryButton, alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '70%', minWidth: '70%', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon icon={faFileAlt} color={Colors.dark} fontSize={20} style={{ marginRight: 6, marginTop: 10 }} />
                                <a style={{ marginTop: 10, fontWeight: 'bold', color: Colors.dark, fontSize: 14, maxWidth: '' }}>{(this.state.fileToUpload.name).slice(0, 14)}...</a>
                            </div>
                            <FontAwesomeIcon
                                style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)' }}
                                onClick={() => { this.setState({ fileToUploadPreview: null, fileToUpload: null, name: `` }) }}
                                icon={faTimesCircle}
                                color={Colors.error}
                                fontSize={22}
                            />
                        </div>
                        :
                        <label htmlFor="upload-template">
                            <div onClick={() => { this.setState({ addingNew: true }) }} style={{ borderRadius: 5, cursor: 'pointer', display: 'flex', flexDirection: 'row', height: 80, width: '100%', marginBottom: 10, backgroundColor: Colors.secondaryButton, alignContent: 'center', alignItems: 'center', justifyContent: 'center', border: `1.5px ${Colors.dark} dashed`, padding: 5 }}>
                                <FontAwesomeIcon icon={faFileAlt} color={Colors.dark} fontSize={40} />
                                <div style={{ ...EssentialStyle.columnStart, marginLeft: 10 }}>
                                    <a style={{ color: Colors.dark, fontSize: 12 }}>Clique aqui para escolher um arquivo</a>
                                    <a style={{ marginTop: 2, color: Colors.dark, fontSize: 10 }}>Tamanho máximo (10 MB)</a>
                                </div>
                            </div>
                        </label>
                    }


                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome do Arquivo</Form.Label>
                            <Form.Control type="text" placeholder="Arquivo x..." defaultValue={this.state.name || ``} onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <DefaultButton loading={this.state.loadingUpload} color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} style={{ width: '49%' }} title={'Cancelar'} onClick={() => { this.setState({ fileToUpload: null, fileToUploadPreview: null, name: '', addingNew: false }) }} />
                        <DefaultButton loading={this.state.loadingUpload} disabled={!this.state.fileToUpload || !this.state.name} color={Colors.success} leftIcon={<FontAwesomeIcon icon={faImage} />} style={{ width: '49%' }} title={'Salvar'} onClick={this.doUploadFile} />
                    </div>
                </div>
            ) :
            (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DefaultButton
                        title={'Anexar Arquivo'}
                        color={Colors.tag}
                        textColor={Colors.dark}
                        style={{ marginBottom: 20, width: '100%' }}
                        disabled={(this.props.limit || 1) <= this.state.files.length}
                        loading={this.state.loading || this.state.loadingUpload}
                        leftIcon={<FontAwesomeIcon icon={faFileAlt} />}
                        onClick={() => {
                            this.setState({ addingNew: true })
                        }}
                    />
                </div>
            );
    }

    renderFiles() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {!this.props.hideAdd && this.renderAddNew()}
                {!this.props.print && <div style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto', marginBottom: 10 }}>
                    {this.state.files.map(template => (this.renderFile(template)))}
                </div>}
            </div>

        );
    }

    render() {
        return (
            <div style={{ minWidth: '100%', marginBottom: 10 }}>
                {this.renderFiles()}
            </div>
        );
    }
}