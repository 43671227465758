import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faCog, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "./DefaultButton";
import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';
import LoadingPage from "../../pages/LoadingPage";
import SwotHelper from "../../helper/pe/SwotHelper";
import GutMatrixEditItem from "./GutMatrixEditItem";
import GutMatrixCalculus from "./GutMatrixCalculus";
import GutMatrixConfig from "./GutMatrixConfig";
import Sig from "../../api/Sig";
import DataHelper from "../../helper/DataHelper";
import CustomTooltip from "./CustomTooltip";

export default class GutMatrix extends React.Component {
  
    state = {
        tipo : this.props.idSwot ? 'swot' : this.props.idPeMapa ? 'mapa' : 'projeto',
        idSwot: this.props.idSwot,
        idPeMapa: this.props.idPeMapa,
        projetos: this.props.idPeProjeto || this.props.gutProjeto ? true : false,
        loading: true,
        gutMatrix: [],
        criterios: [],
        quadrantes: [],
        target: null,
        tooltip: '',
        showEdit: false,
        acesso: null,
        permissao: null,
        temRestricoes: null,
        restricoes: [],
        isResponsavel: null,
        podeConfigurar:false,
    }

    async componentDidMount() {

        await this.load()
        this.setState({ loading: false });
    }
    
    async load() {
        await this.getGutMatrix();
        // await this.getQuadrantes();
        // await this.getCriterios();
    }

    async getGutMatrix() {
        if (this.state.idSwot) {
            let gutMatrix = await Sig.request('GET', 'pe/swot/getMatrizGut', { id: this.props.idSwot });
            
            if (gutMatrix) {
                this.setState({ gutMatrix: gutMatrix?.itens, podeConfigurar: gutMatrix.podeConfigurar }, () => {
                    this.getQuadrantes(gutMatrix?.quadrantes);
                    this.getCriterios(gutMatrix?.criterios);
                });
            }
        } else if (this.state.idPeMapa) {
            let gutMatrix = await Sig.request('POST', 'pe/mapa/getMatrizGut', { id: this.props.idPeMapa });
            this.setState({ gutMatrix, podeConfigurar: gutMatrix.podeConfigurar }, () => {
                this.getQuadrantes(gutMatrix?.objetivos);
                this.getCriterios(gutMatrix?.criterios);
            });
        } else if (this.state.projetos) {
            let gutMatrix = await Sig.request('POST', 'pe/projeto/getMatrizGut', { id: this.props.idPeProjeto });

            this.setState({ gutMatrix, podeConfigurar: gutMatrix.podeConfigurar }, () => {
                this.getQuadrantes(gutMatrix?.projetos);
                this.getCriterios(gutMatrix?.criterios);
            });
        }
    }

    async getQuadrantes(quadrantes) {
        if (this.state.idSwot) {
            let quad = [];

            if (quadrantes) {
                for (let index = 0; index < quadrantes.length; index++) {
                    quad[index] = quadrantes[index]?.quadrante;
                    quad[index].items = quadrantes[index]?.itens;
                }

                this.setState({ quadrantes: quad });
            }
        } else if(this.state.idPeMapa){
            let quad = [];

            if (quadrantes) {
                for (let index = 0; index < quadrantes.length; index++) {
                    quad[index] = quadrantes[index]?.objetivo;
                    quad[index].items = quadrantes[index]?.iniciativas;
                }
                this.setState({ quadrantes: quad });
            }
        } else if (this.state.projetos) {
            let quad = [
                {
                    nome: 'Projetos',
                    items: quadrantes
                }
            ];

            this.setState({ quadrantes: quad });
        }
    }

    async getCriterios(criterios) {
        if (this.state.idSwot || this.state.idPeMapa || this.state.projetos) {
            let crit = [];

            if (criterios) {
                for (let index = 0; index < criterios.length; index++) {
                    crit[index] = criterios[index]?.criterio;
                    crit[index].pontuacao = criterios[index]?.pontuacao;
                }

                this.setState({ criterios: crit });
            }
        }
    }

    async gutEditCallback() {

        this.setState({ showEdit: false, loading: true });

        await this.load();

        this.setState({ loading: false });
    }

    renderStepBack() {
        return (
            <div style={{  }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.props.gutCallback(); }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    renderLegend() {

        return (

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 20 }}>
                <p style={{ fontWeight: 'bold', alignSelf: 'center' }}>Matriz de Priorização</p>
                {
                    this.state.criterios.map((criterio, key) => {
                        
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>{`${criterio.nome}:`}</div>
                                <div>{`${criterio.conceito}`}</div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderHeader() {

        
        let podeConfigurar = this.state.podeConfigurar;
        let link;
        if (this.state.tipo === 'swot') {
            link = 'https://advis.freshdesk.com/support/solutions/articles/63000283187-prioriza%C3%A7%C3%A3o-swot';
        } else if (this.state.tipo === 'mapa') {
            link = '';
        } else {
            link = ''
        }

        return (

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-evenly', marginBottom: 20 }}>
                { this.renderStepBack() }
                { this.renderLegend() }
                {podeConfigurar ? 
                    <DefaultButton width={190} title={'Configurar'} leftIcon={<FontAwesomeIcon icon={faCog} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} disabled={!podeConfigurar} onClick={() => { this.setState({ showEdit: true }) }} style={{ }} />
                    :
                    <CustomTooltip placement={'bottom'} tooltip={"Sem Permissão Para Editar as Configurações"}>
                        <DefaultButton width={150} title={'Configurar'} leftIcon={<FontAwesomeIcon icon={faCog} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} disabled={!podeConfigurar} onClick={() => { this.setState({ showEdit: true }) }} style={{ }} />
                    </CustomTooltip>
                }

                {link != '' && 
                    <DefaultButton 
                        style={{ marginLeft: 10}}
                        width={"auto"}
                        height={37}
                        fontSize={16}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark} 
                        link={link}
                    />
                }
            </div>
            
        )
    }

    updateCalculus(idQuadrante, idItem) {

        let quadrante = this.state.quadrantes.find(item => item.id === idQuadrante);
        
        if (quadrante) {

            let item = quadrante.items.find(item => item.id === idItem);

            if (item && item.calculus) {
                item.calculus.getValue();
            }
        }
    }

    renderItemEdit(idQuadrante, idCriterio, idItem, row) {

        return (

            <td style={{ textAlign: 'center' }}>
                {
                    this.state.idSwot
                        ? <GutMatrixEditItem callback={() => { this.updateCalculus(idQuadrante, idItem) }} criterios={this.state.criterios} row={row} idSwot={this.props.idSwot}     idQuadrante={idQuadrante} idCriterio={idCriterio} idItem={idItem} />
                        : this.state.idPeMapa
                            ? <GutMatrixEditItem callback={() => { this.updateCalculus(idQuadrante, idItem) }} criterios={this.state.criterios} row={row} idPeMapa={this.props.idPeMapa} idQuadrante={idQuadrante} idCriterio={idCriterio} idItem={idItem} />
                            : <GutMatrixEditItem callback={() => { this.updateCalculus(idQuadrante, idItem) }} criterios={this.state.criterios} row={row} projetos idCriterio={idCriterio} idItem={idItem} />

                }
            </td>
        )
    }

    renderQuadranteFator(idQuadrante, items) {

        let length = this.state.criterios.length + 2;

        if (items && items.length) {

            let render = [];
    
            items.map((item, key) => {
                render.push(

                    <tr>
                        { 
                            this.state.idSwot
                                ? <td style={{ textAlign: 'left' }}>{item.descricao}</td>
                                : this.state.idPeMapa
                                    ? <td style={{ textAlign: 'left' }}>{item.nome}</td>
                                    : <td style={{ textAlign: 'left' }}>{item.nome}</td>
                        }
                        
                        {
                            this.state.criterios.map((criterio, criterioKey) => {
                                
                                return this.renderItemEdit(idQuadrante, criterio.id, item.id, criterioKey);
                            })
                        }

                        {
                            this.state.idSwot
                                ? <td style={{ textAlign: 'center' }}><GutMatrixCalculus ref={ref => items[key].calculus = ref} idSwot={this.props.idSwot} item={item.id}/></td>
                                : this.state.idPeMapa
                                    ? <td style={{ textAlign: 'center' }}><GutMatrixCalculus ref={ref => items[key].calculus = ref} idPeMapa={this.props.idPeMapa} item={item.id}/></td>
                                    : <td style={{ textAlign: 'center' }}><GutMatrixCalculus ref={ref => items[key].calculus = ref} projetos item={item.id}/></td>
                        }
                        
                    </tr>
                );
            });

            return render;

        } else {

            return [
                <tr>
                    <td colSpan={length} style={{ textAlign: 'left' }}>Nenhum item inserido</td>
                </tr>
            ];
        }
    }

    renderQuadrantes() {

        let render = [];
        let length = this.state.criterios.length + 2;

        this.state.quadrantes.map((quadrante, key) => {

            render.push(

                <tr>
                    {
                        this.state.idSwot 
                            ? <td colSpan={length} style={{ backgroundColor: Colors.swot[SwotHelper.getSwotKey(this.state.quadrantes[key].nome)], fontWeight: 'bold', textAlign: 'left' }}>{this.state.quadrantes[key].nome}</td>
                            : this.state.idPeMapa 
                                ? <td colSpan={length} style={{ backgroundColor: '#BCBCBC', fontWeight: 'bold', textAlign: 'left', color: 'black' }}>{DataHelper.removeHtmlTags(this.state.quadrantes[key].valor)}</td>
                                : <td colSpan={length} style={{ backgroundColor: '#BCBCBC', fontWeight: 'bold', textAlign: 'left', color: 'black' }}>{DataHelper.removeHtmlTags(this.state.quadrantes[key].nome)}</td> 
                    }
                </tr>
            );

            render.push(this.renderQuadranteFator(quadrante.id, quadrante.items));
        });

        return render;
    }

    renderCriterios() {

        return this.state.criterios.map((criterio, key) => {

            return (
                
                <th style={{ position: 'sticky', top: 0, zIndex: 1 }} key={key}>
                    {criterio.nome}
                    <FontAwesomeIcon onMouseLeave={(evt) => { this.setState({ target: null, tooltip: '' }) }} onMouseEnter={(evt) => { this.setState({ target: evt.currentTarget, tooltip: criterio.conceito }) }} style={{ marginLeft: 8 }} color={'grey'} icon={faQuestionCircle} />
                </th>
            )
        });
    }

    getMatrixHeader() {

        let text = [];
        let maxValue = 1;

        this.state.criterios.map((criterio, key) => {
            text.push(criterio.nome[0].toUpperCase());
        });

        return <th style={{ position: 'sticky', top: 0, zIndex: 1 }}>{text.join('x')}{` (Máximo: ${this.getmaxValue()})`}</th>;
    }

    getmaxValue() {

        let text = [];
        let maxValue = 1;

        this.state.criterios.map((criterio, key) => {

            text.push(criterio.nome[0].toUpperCase());

            if (criterio.pontuacao && criterio.pontuacao.length) {

                maxValue *= criterio.pontuacao[criterio.pontuacao.length - 1].valor;
            }
        });

        return maxValue;
    }

    renderTable() {

        return (

            <table style={{ marginTop: 20 }} className="gut-matrix">
                <thead>
                    <tr>
                        <th style={{ position: 'sticky', top: 0, zIndex: 1 }}></th>
                        { this.renderCriterios() }
                        { this.getMatrixHeader() }
                    </tr>
                </thead>
                <tbody>
                    { this.renderQuadrantes() }
                </tbody>
            </table>
        )
    }

    render() {
        
        if (this.state.loading) return <LoadingPage />
        if (this.state.showEdit && this.state.idSwot)   return <GutMatrixConfig gutEditCallback={() => { this.gutEditCallback() }} idSwot={this.props.idSwot}/>
        if (this.state.showEdit && this.state.idPeMapa) return <GutMatrixConfig gutEditCallback={() => { this.gutEditCallback() }} idPeMapa={this.props.idPeMapa}/>
        if (this.state.showEdit && this.state.projetos) return <GutMatrixConfig gutEditCallback={() => { this.gutEditCallback() }} projetos/>

        return (
            <div style={{ minWidth: '100vh', minHeight: '100vh', padding: 10 }}>
                { this.renderHeader() }
                { this.renderTable() }
                <Overlay target={this.state.target} show={this.state.target !== null && this.state.tooltip !== ''} placement="right">
                    {(props) => (
                    <Tooltip id={`tooltip`} {...props}>
                        {this.state.tooltip}
                    </Tooltip>
                    )}
                </Overlay>
            </div>
        )
    }
}