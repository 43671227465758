import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import LoadingPage from "../LoadingPage";
import { toast } from "react-toastify";
import AddCanvas from "../../components/forms/pe/add/AddCanvas";
import CanvasList from "../../components/forms/pe/list/CanvasList";
import Sig from "../../api/Sig";
import CanvasView from "../../components/modules/pe/canvas/CanvasView";

export default class CanvasPage extends React.Component {

    state = {
        canvas: null,
        loading: true,
        showWizard: false,
        showCanvasList: false,
        podeCriarCanvas: false,
    }

    async componentDidMount() {
        this.handleUrlParams();
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                const id = queryParams.get('id');

                if (!id) {
                    this.setState({ showCanvasList: true, loading: false});
                } else {
                    await this.loadData();
                }

                window.removeEventListener('message', listenerFunction);
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    async loadData() {
        this.setState({ loading: true });

        let {canvas, podeCriarCanvas} = await Sig.request('GET', 'pe/canvas/getLast');

        this.setState({ canvas, podeCriarCanvas, loading: false });
    }

    remove = async (id) => {
        this.setState({ loading: true });

        try {
            let request = await Sig.request('POST', `pe/canvas/delete`, { id });

            if(!request.success) throw new Error();
            toast.success(request.message);
        } catch (e) {
            toast.error('Erro ao remover');
        }

        this.loadData();
    }

    addCanvasCallback = async () => {
        this.setState({ showWizard: false, showCanvasList: true });
        await this.loadData();
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5, textAlign: 'center' }}>
                <DefaultButton style={{ position: 'absolute', top: 30, right: 10 }} link={"https://advis.freshdesk.com/support/solutions/articles/63000283269-canvas-de-projeto"}/>
                <h5>Até o momento, sua empresa ainda não criou nenhum <strong>Canvas de Projeto</strong> ou você não possui acesso a nenhum deles.</h5>
                <div style={{ marginTop: 20 }} />
                <DefaultButton 
                    color={Colors.success}
                    title={'Adicionar Canvas de Projeto'}
                    tooltip={!this.state.podeCriarCanvas ? 'Sem Permissão para Criar um novo Canvas de Projeto' : null}
                    leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} 
                    loading={this.state.loading}
                    disabled={!this.state.podeCriarCanvas}
                    onClick={() => { this.setState({ showWizard: true }) }} />
            </div>
        )
    }

    listCallback = async () => {
        this.setState({ showCanvasList: true });
        await this.loadData();
    }

    render() {
        
        if(this.state.loading) return <LoadingPage />
        if (this.state.showWizard) return <AddCanvas addCallback={this.addCanvasCallback} />
        if (this.state.showCanvasList) return <CanvasList callback={this.addCanvasCallback} addCallback={this.addCanvasCallback} listCallback={this.listCallback}/>

        return this.state.canvas && this.state.canvas.id ? <CanvasView id={this.state.canvas.id} remove={this.remove} listCallback={this.listCallback} /> : this.renderEmpty();
    }
}