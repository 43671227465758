import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LayoutHelper from '../../helper/LayoutHelper';
export default class CustomTooltip extends Component {

    state = {
        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    componentDidMount() {
        window.addEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    componentWillUnmount() {
        this.handleMouseLeave();
        window.removeEventListener('resize', this.verifyScreen);
    }

    handleMouseEnter = () => {
        if (document.documentElement.scrollHeight <= window.innerHeight) {
            document.body.style.overflow = 'hidden';
        }
    }

    handleMouseLeave = () => {
        if (document.documentElement.scrollHeight <= window.innerHeight) {
            document.body.style.overflow = 'auto';
        }
    }

    renderTooltip = (props) => {
        let id = `tooltip-${Math.floor(Math.random() * 1000000)}`;
        return (
            <Tooltip id={`${id}-id`} {...props}>
                <div style={{ maxWidth: this.state.isSmallScreen ? "80vw" : "500px" }}>
                    {this.props.tooltip}
                </div>
            </Tooltip>
        );
      }

    render() {

        const delay = { show: 500, hide: 100 };

        return (
            <OverlayTrigger
                placement={this.props.placement || 'auto'}
                overlay={this.renderTooltip}
                delay={delay}
            >
                <div
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    style={this.props.style}
                >
                    {this.props.children}
                </div>
            </OverlayTrigger>
        );
    }
}