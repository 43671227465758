import React, { Component } from 'react';
import Colors from '../constants/Colors';
import Constants from "../constants/Api";

const gateway = Constants.getSigEndPoint();
export default class NotFoundPage extends Component {

    render() {
        return ( 
            <div style={{ height: this.props.height ? this.props.height : '100vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: Colors.dark, fontWeight: 'bold' }}>
                <img src={`${gateway}/img/data-not-found4.png`} alt="404" style={{ height: 200 }} />
                <div style={{ fontSize: 25, marginTop: 20 }}>(404) Oops! Página não encontrada</div>
            </div>
        );
    }
}
