import React, { Component } from 'react';
import Colors from '../constants/Colors';
import Spinner from 'react-bootstrap/Spinner';
import SessionHelper from '../helper/SessionHelper';

export default class LoadingPage extends Component {

    render() {
        return (
            <div style={{ height: this.props.height ? this.props.height : '100vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: SessionHelper.getColor(), fontWeight: 'bold' }}>
                <Spinner animation="border" role="status" style={{}}>
                    <span className="visually-hidden">Carregando...</span>
                </Spinner>
            </div>
        );
    }
}
