import React from "react";
import Sig from "../../../../api/Sig";
import DefaultLoader from "../../../tools/DefaultLoader";
import { ProgressBar } from 'react-bootstrap'
import SwotHelper from "../../../../helper/pe/SwotHelper";
import Colors from "../../../../constants/Colors"; 
import DataHelper from "../../../../helper/DataHelper";

export default class ProjetoFatoresSowtCard extends React.Component {
    state = {
        loading: true,
        item: null,
        quadrante: null,
        swot: null,
        escala: null,
        progress: 100,
        shake: false,
        hover: false 
    }

    async componentDidMount() {
        await this.loadItem();
    }

    async loadItem() {
        this.setState({ loading: true });

        let { item, quadrante } = (await Sig.request('POST', 'pe/projeto/getQuadranteItens', { id: this.props.fator.id}));
        let { swot, escala } = await Sig.request('GET', `pe/swot/getSwot?id=${this.props.fator.id_pe_swot}`);
        let progress = await SwotHelper.getGutItemValue(this.props.fator.id_pe_swot, item.id);
        this.setState(
            { 
                color: Colors.swot[SwotHelper.getSwotKey(quadrante.nome)], 
                item, 
                quadrante, 
                swot, 
                escala, 
                progress, 
                loading: false
            }, ()  => { 
                this.setProgressBarColor();
            }
        );
    }

    setProgressBarColor() {
        if (this.progressBar) {
            const inner = this.progressBar.querySelector(".progress-bar");
            if (inner) inner.style.backgroundColor = this.state.color;
        }
    }

    shake(){
        this.setState({ shake: true });
        setTimeout(() => this.setState({ shake: false }), 500);
    }

    toggleHover = (state) => {
        this.setState({ hover: state });
      };

    render() {
        const style1 = this.state.hover ? 
            { height: 'auto', display: 'flex',} 
            : {height: '30%'};

        const style2 = this.state.hover ?
            { display: 'none' }
            : {display: 'flex', padding: 2};

        const style3 = this.state.hover ?
            { display: 'flex', justifyContent: 'center', alignItems: 'center'}
            : {};

        if (this.state.loading) return <div className={'fatoresSwotCard'} style={{ paddingTop: 30, height:'15vh' }}><DefaultLoader /></div>
        return (
            <div className={`fatoresSwotCard ${this.state.shake ? 'shake' : ''}`} 
                style={{...style3, minHeight: '15vh' }} 
                onClick={() => { this.shake() }}
                onMouseEnter={() => { if (this.state.item.descricao.length > 30) this.toggleHover(true) }}
                onMouseLeave={() => { if (this.state.item.descricao.length > 30) this.toggleHover(false) }}
            >
                <div style={{ ...style2, height: '35%', justifyContent: 'start' }} >
                    <div className={`quadrante`} style={{ backgroundColor: this.state.color }}>
                        { this.state.quadrante.nome }
                    </div>
                </div>

                <div style={{ ...style1, display: 'flex', alignSelf:'center', justifyContent: 'center', alignItems: 'center', fontSize: 15 }}>
                    {this.state.hover
                        ? <span style={{margin: 5, textAlign: 'justify'}}>{ this.state.item.descricao }</span> 
                        : DataHelper.limitarCaracteres(this.state.item.descricao, 30)          
                    }
                </div>

                <div style={{ ...style2, height: '35%', justifyContent: 'start'}} >
                    <div style={{ height: '100%', width: '100%', backgroundColor: 'white', borderRadius: 5}} >
                        <ProgressBar 
                            min={0} 
                            max={this.state.escala} 
                            animated={this.state.loading}
                            now={this.state.progress} 
                            label={this.state.progress}
                            ref={ref => this.progressBar = ref}
                            style={{ height: '100%', borderRadius: 5}}
                            /> 
                    </div>
                </div>
            </div>
        );
    }
}