import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEye, faPlusSquare, faStar, faTrash } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import LoadingPage from "../../../../pages/LoadingPage";
import DefaultTable from "../../../tools/DefaultTable";
import moment from "moment";
import DataHelper from "../../../../helper/DataHelper";
import DefaultTableSearch from "../../../tools/DefaultTableSearch";
import Colors from "../../../../constants/Colors";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import UserAvatar from "../../../tools/UserAvatar";
import CustomConfirm from "../../../tools/CustomConfirm";
import AddVisao from "../add/AddVisao";
import DrawDiagram from "../../../tools/DrawDiagram";
import Sig from "../../../../api/Sig";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import CustomTooltip from "../../../tools/CustomTooltip";
import EssentialStyle from "../../../../style/EssentialStyle";
import SessionHelper from "../../../../helper/SessionHelper";

const searchableFields = ['nome', 'responsavel', 'participants', 'data_atualizacao', 'data_cadastro' ];

export default class VisaoList extends React.Component {

    state = {
        visoes: [],
        loading: true,
        showWizard: false,
        showVisao: 0,
        quadrants: [
            "Forças",
            "Fraquezas",
            "Oportunidades",
            "Ameaças"
        ],
        modeView: 'read-only',

        acesso: null,
        permissao: null,
        temRestricoes: null,
    }

    async componentDidMount() {
       await this.loadData(true);
    }

    async loadData(loading = true) {
        if (loading) {
            this.setState({ loading: true });
        }

        let {visoes, acesso, permissao, temRestricoes} = (await Sig.request('GET', 'pe/visao/list', { relations: 1 })) || [];
        let rows = [];
        for (let visao of visoes) {
            let row = {
                ...visao.visao,
                participants: visao.participantes,
                responsavel: visao.responsavel
            };

            row.favorito = visao.favorito;

            rows.push(row);
        }

        this.setState({ visoes: rows, acesso, permissao, temRestricoes }, () => { this.setState({ loading: false }) });
    }

    remove = async (id) => {
        this.setState({ loading: true });

        try {
            await Sig.request('POST', 'pe/visao/delete', { id });
        } catch (e) {
            toast.error('Erro ao remover');
        }

        this.loadData();
    }

    handleFavorite = async (id, isFavorite) => {
        this.setState({ loading: true });

        await Sig.request('POST', `pe/visao/setFavorito`, { id: id, favorito: isFavorite ? 0 : 1 });
        await this.loadData();

        this.setState({ loading: false });
        toast.success('Alterado com sucesso');
    }


    addFromProject = async (id) => {
        if (!id) return toast.warn('Selecione uma Visão');

        let request = await Sig.request('POST', `pe/visao/clonar`, { id });

        if (request?.visao) {
            toast.success('Visão clonada com sucesso');

            this.loadData();
        }

        this.setState({ loading: false });
    }

    renderParticipants(participants) {

        if (participants && participants.length) {

            return <TooManyAvatars participantsTotal={participants.length} participantsVisiveis={3} arrayParticipants={participants} />;

        } else {

            return <div></div>
        }
    }
    renderHeader() {

        let podeAdicionar = this.state.acesso == 'pe_visao_administrador' || (this.state.permissao == 'pe_visao_editar' && !this.state.temRestricoes);

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 20 }}>
                {podeAdicionar ? (
                    <DefaultButton 
                        color={Colors.success} 
                        title={'Adicionar Visão'} 
                        leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} 
                        loading={this.state.loading} 
                        onClick={() => { 
                            if (podeAdicionar) {
                                this.setState({ showWizard: true })
                            }
                        }}
                        disabled={!podeAdicionar}
                    />
                ) : (
                    <CustomTooltip placement={'right'} tooltip={'Sem Permissão Para Adicionar Nova Visão de Futuro'}>
                        <DefaultButton 
                            color={Colors.success} 
                            title={'Adicionar Visão'} 
                            leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} 
                            loading={this.state.loading} 
                            disabled={!podeAdicionar}
                        />
                    </CustomTooltip>
                )}
                <div></div>
                <DefaultTableSearch fields={searchableFields} searchObjects={true} data={this.state.visoes} onDataUpdate={(data) => { this.setState({ visoes: data }) }} onEmpty={() => { this.loadData(false) }}/>
            </div>
        )
    }

    renderActions(row) {

        let podeFavoritar = this.state.permissao == 'pe_visao_editar';
        let podeClonar = this.state.permissao == 'pe_visao_editar' || row.isResponsavel;
        let podeRemover = this.state.permissao == 'pe_visao_editar' || row.isResponsavel;

        return (
            <div style={EssentialStyle.rowFlexStart}>
                <CustomTooltip placement={'bottom'} tooltip={podeFavoritar ? (row.favorito ? 'Remover Favorito' : 'Marcar como Favorito') : "Sem Permissão Para Favoritar Visões"}>
                    <FontAwesomeIcon style={{ color: row.favorito ? Colors.favorite : 'grey', fontSize: 17 }} icon={faStar} onClick={() => {
                        if (podeFavoritar) {
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={row.favorito ? 'Remover Favorito?' : 'Marcar Favorito?'}
                                        buttons={[
                                            {
                                                label: !row.favorito ? 'Sim' : 'Remover',
                                                color: !row.favorito ? Colors.success : Colors.error,
                                                textColor: Colors.light,
                                                onClick: () => { this.handleFavorite(row.id, row.favorito); onClose(); }
                                            },
                                            {
                                                label: !row.favorito ? 'Não' : 'Cancelar',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }
                    }}/>
                </CustomTooltip>

                <CustomTooltip placement={'bottom'} tooltip={(podeClonar) ? 'Clonar' : 'Sem Permissão Para Clonar essa Visão'}>
                    <FontAwesomeIcon style={{ marginLeft: 20, fontSize: 17, color: 'grey' }} icon={faCopy} onClick={() => {
                        if (podeClonar) {
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={'Clonar esta Visão?'}
                                        buttons={[
                                            {
                                                label: 'Sim',
                                                color: Colors.success,
                                                textColor: Colors.light,
                                                onClick: () => { this.addFromProject(row.id); onClose(); }
                                            },
                                            {
                                                label: 'Não',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }
                    }}/>
                </CustomTooltip>

                <CustomTooltip placement={'bottom'} tooltip={(podeRemover) ? 'Remover' : 'Sem Permissão Para Remover essa Visão'}>
                    <FontAwesomeIcon style={{ marginLeft: 20, fontSize: 17, color: Colors.error }} icon={faTrash} onClick={() => {

                        if (podeRemover) {
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={'Excluir esta Visão?'}
                                        message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                                        buttons={[
                                            {
                                                label: 'Remover',
                                                color: SessionHelper.getColor(),
                                                textColor: Colors.light,
                                                onClick: () => { this.remove(row.id); onClose(); }
                                            },
                                            {
                                                label: 'Cancelar',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }
                    }}/>
                </CustomTooltip>
            </div>
        )
    }

    renderVisoes() {
        return (
            <div>
                <DefaultTable
                    title={this.renderHeader()}
                    columns={[
                        { name: 'Visão', selector: row => row.nome, sortable: true },
                        { name: 'Responsável', cell: row => <UserAvatar id={row.id_responsavel} />, sortable: true, sortFunction: (a, b) => a.responsavel.nome > b.responsavel.nome },
                        { name: 'Participantes', cell: row => this.renderParticipants(row.participants), sortable: true, sortFunction: (a, b) => this.multiArraySort(a, b, 'participants') },
                        { name: 'Data Atualização', cell: row => moment(row.data_atualizacao).format('DD/MM/YYYY HH:mm'), sortable: true, sortFunction: (a, b) => DataHelper.multiArraySort(a, b, 'data_atualizacao') },
                        { name: 'Criação', selector: row => moment(row.data_cadastro).format('DD/MM/YYYY HH:mm'), sortable: true, sortFunction: (a, b) => DataHelper.multiArraySort(a, b, 'data_cadastro') },
                        { name: 'Ações', selector: row => this.renderActions(row) },
                    ]}
                    data={this.state.visoes}
                    onRowClicked={row => this.setState({ showVisao: row.id })}
                    pagination
                    pointerOnHover
                    highlightOnHover
                />
            </div>
        );
    }

    addVisaoCallback = async () => {
        this.setState({ showWizard: false, showVisao: 0, modeView: 'read-only' });
        await this.loadData();
    }

    viewNewVisaoCallback = (newVisaoId) => {
        this.setState({ showWizard: false, showVisao: newVisaoId, modeView: 'edit' });
    }

    render() {
        if (this.state.loading) return <LoadingPage />
        if (this.state.visoes.length === 0) return this.props.callback();
        if (this.state.showWizard) return <AddVisao addCallback={(newVisaoId) => {this.viewNewVisaoCallback(newVisaoId)}} />
        
        if (this.state.showVisao) {
            return (
                <DrawDiagram
                    table={'PeVisao'}
                    historyTable={'PeVisaoHistorico'} 
                    elementsTable={'PeVisaoElemento'} 
                    relationField={'id_pe_visao'} 
                    delete={() => { this.remove(this.state.showVisao) }}
                    handleFavorite={this.handleFavorite}
                    id={this.state.showVisao} 
                    callback={this.addVisaoCallback}
                    modeView={this.state.modeView}
                />
            );
        } 

        return this.renderVisoes();
    }
}