import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faPlusSquare, faSave } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "./DefaultButton";
import Colors from "../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import DataHelper from "../../helper/DataHelper";
import { toast } from 'react-toastify';
import KeyboardHelper from "../../helper/KeyboardHelper";
import Sig from "../../api/Sig";


export default class AddTag extends React.Component {
    state = {
        size: this.props.fontSize ? this.props.fontSize : null,
        loading: true,
        tags: [],
        adding: false,
        tagName: '',
        selected: []
    }

    async componentDidMount() {
        await this.loadTags();
    }

    async loadTags() {
        this.setState({ loading: true });

        const { tags } = await Sig.request('GET', 'tag/getTags');

        if (this.props.selected && this.props.selected.length) {
            let selectedTagsIds = this.props.selected.map(tag => (tag.id));
            let selected = [];

            tags.forEach(tag => {
                if (selectedTagsIds.includes(tag.id)) {
                    selected.push(tag);
                }
            });

            this.props.onChange(DataHelper.formatSelectData(selected, 'id', 'nome'));
            this.setState({ selected: DataHelper.formatSelectData(selected, 'id', 'nome') });
        }

        this.setState({ tags }, () => { this.setState({ loading: false }) });
    }

    addTag = async () => {
        if (!this.state.tagName) return toast.info('Informe o nome da TAG');

        this.setState({ loading: true });

        let new_tag = await Sig.request('POST', `tag/adicionarTag`, { nome: this.state.tagName });

        this.setState({selected: [...this.state.selected, { value: new_tag.id, label: new_tag.nome }]});

        this.setState({ tagName: '', adding: false }, () => { this.loadTags() });
    }

    renderSelecting() {
        return (
            <div className="d-flex justify-content-between mb-3">
                <Select
                    isOptionDisabled={(option) => this.state.loading}
                    value={this.state.selected}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    className={this.state.size == "xsm" ? "form-label-xsm w-100 me-1" : "w-100 me-1"}
                    options={DataHelper.formatSelectData(this.state.tags, 'id', 'nome').map(option => ({...option, label: option.label.trim()}))}
                    placeholder={'Selecione as Tags'}
                    isClearable isSearchable isMulti
                    onChange={(value) => { this.setState({ selected: value }); this.props.onChange(value); }} 
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: this.props.zIndex || base.zIndex }) }}
                />
                <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} title={''} loading={this.state.loading} onClick={() => { this.setState({ adding: true }) }} />
            </div>
        );
    }

    renderAdding() {
        return (
            <div className="mb-3 d-flex flex-row">
                <Form.Control className={this.state.size == "xsm" ? "form-label-xsm w-100 me-1" : "w-100 me-1"} type="text" placeholder="Nome da nova TAG" onChange={(event) => { this.setState({ tagName: event.target.value }) }} onKeyDown={(evt) => { KeyboardHelper.handleShortcut(evt, ["Enter", "Escape"], [this.addTag, (e) => { e.preventDefault(); this.setState({ tagName: '', adding: false }); }]) }} />
                <DefaultButton className="me-1" leftIcon={<FontAwesomeIcon icon={faCancel} />} title={''} loading={this.state.loading} color={Colors.error} onClick={() => { this.setState({ tagName: '', adding: false }) }} />
                <DefaultButton leftIcon={<FontAwesomeIcon icon={faSave} />} title={''} loading={this.state.loading} color={Colors.success} onClick={this.addTag} />
            </div>
        );
    }

    render() {
        return (
            <Form.Group as={Col}>
                <Form.Label className={this.state.size == "xsm" ? "form-label-xsm" : ""} >Tags</Form.Label>
                {this.state.adding ? this.renderAdding() : this.renderSelecting()}
            </Form.Group>
        );
    }
}