import React from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from '../../../../../style/EssentialStyle';
import DefaultLoader from '../../../../tools/DefaultLoader';
import { Collapse } from "react-bootstrap";
import Colors from "../../../../../constants/Colors";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SessionHelper from '../../../../../helper/SessionHelper';
import moment from 'moment';
import DefaultButton from '../../../../tools/DefaultButton';
import UserAvatar from '../../../../tools/UserAvatar';
import ChartHelper from '../../../../../helper/inicio/ChartHelper';

export default class PlanoAcaoRelatorioColaboradores extends React.Component {

    state={
        contadores: {},
        expanded: false,
        status: [
            "Não Iniciado",
            "Em Desenvolvimento",
            "Concluído",
            "Atrasado",
            "Cancelado",
        ],
        statusEtapa: [
            "Não Iniciada",
            "Em Desenvolvimento",
            "Concluída",
            "Atrasada",
            "Cancelada",
        ],
        activeTab: 'planos',
        tabs: [
            { name: 'planos', title: 'Planos de Ação' },
            { name: 'etapas', title: 'Etapas' },
        ],
        loading: true,
    }

    componentDidMount(){
        this.calculaContadores();
    }

    componentDidUpdate(prevProps){
        if(prevProps.planos !== this.props.planos){
            this.calculaContadores();
        }
    }

    calculaContadores = () => {

        this.setState({ loading: true });

        var defaultPlanoCount = {
            total: 0,
            "Não Iniciado": 0,
            "Em Desenvolvimento": 0,
            "Concluído": 0,
            "Atrasado": 0,
            "Cancelado": 0,
        }
        var defaultEtapaCount = {
            total: 0,
            "Não Iniciada": 0,
            "Em Desenvolvimento": 0,
            "Concluída": 0,
            "Atrasada": 0,
            "Cancelada": 0,
        }

        var contadores = {
            colaboradores: [],
        }

        if(!this.props.planos || this.props.planos.length === 0) {
            this.setState({ loading: false, contadores });
            return;
        } 

        var planos = this.props.planos;

        planos.forEach(plano => {
            var colaboradores = [];

            if(plano.responsavel) {
                var indexColaborador = contadores.colaboradores.findIndex(colaborador => colaborador.id == plano.responsavel.id);
                if(indexColaborador == -1) {
                    contadores.colaboradores.push(plano.responsavel);
                }
                if(!colaboradores.includes(plano.responsavel.id)){
                    colaboradores.push(plano.responsavel.id);
                    let index = indexColaborador == -1 ? (contadores.colaboradores.length - 1) : indexColaborador;
                    if(!contadores.colaboradores[index].planos){
                        contadores.colaboradores[index].planos = {...defaultPlanoCount};
                    }
                    contadores.colaboradores[index].planos.total++;
                    contadores.colaboradores[index].planos[plano.status]++;
                }
            }  
            
            if(plano.etapas && plano.etapas.length > 0){

                plano.etapas.forEach(etapa => {
                    var colaboradoresEtapa = [];
                    if(etapa.colaborador) {
                        let indexColaborador = contadores.colaboradores.findIndex(colaborador => colaborador.id == etapa.colaborador.id);
                        if(indexColaborador == -1) {
                            contadores.colaboradores.push(etapa.colaborador);
                        }
                        if(!colaboradoresEtapa.includes(etapa.colaborador.id)){
                            colaboradoresEtapa.push(etapa.colaborador.id);
                            let index = indexColaborador == -1 ? (contadores.colaboradores.length - 1) : indexColaborador;
                            if(!contadores.colaboradores[index].etapas){
                                contadores.colaboradores[index].etapas = {...defaultEtapaCount};
                            }
                            contadores.colaboradores[index].etapas.total++;
                            if(etapa.atrasada && etapa.status != 2 && etapa.status != 3){
                                contadores.colaboradores[index].etapas['Atrasada']++;
                            } else {
                                contadores.colaboradores[index].etapas[etapa.status_text]++;
                            }

                            if(!contadores.colaboradores[index].atrasoTotal){
                                contadores.colaboradores[index].atrasoTotal = 0;
                            }
                            contadores.colaboradores[index].atrasoTotal += this.getDiasAtrasoEtapa(etapa);
                            
                        }
                        
                    }

                    
                });
                
            }
        });
        this.setState({ contadores, loading: false });
    }

    getDiasAtrasoEtapa = (etapa) => {
        var dataFim = moment(etapa.data_fim);
        var dataFimReal = moment();
    
        if(etapa.status == 2 || etapa.status == 3 && etapa.data_conclusao){
          dataFimReal = moment(etapa.data_conclusao);
        }
    
        if(dataFimReal.isAfter(dataFim)){
          return dataFimReal.diff(dataFim, 'days');
        }
        return 0;
    }

    renderBarra(responsavel, tipo) {
        if(tipo != "etapas" && tipo != "planos" || !responsavel) return null;
        const status = tipo == "etapas" ? this.state.statusEtapa : this.state.status;

        const statusCounts = responsavel[tipo];

        const total = responsavel[tipo].total;

        const renderDiv = (status) => (
           
            <div 
                key={status}
                onClick={() => this.props.filterCallback({view: tipo, values: [{ field: tipo == "planos" ? 'status' : 'status_text', value: status }, {field: 'responsavel', value: responsavel.id}]})}
                style={{ 
                    ...EssentialStyle.rowFlexStart,
                    height: 35,
                    width: `${(statusCounts[status] / total) * 100}%`,
                    minWidth: statusCounts[status] > 0 ? 15 : 0,
                    backgroundColor: ChartHelper.getColor(status),
                    transition: 'height 0.5s ease, boxShadow 0.5s ease',
                    overflow: 'visible',
                    cursor: "pointer"
                }}
            >
                <span style={{ 
                    color: 'white', 
                    fontWeight: '500', 
                    marginLeft: 5, 
                    opacity: 1, 
                    transition: 'opacity 0.3s ease-in-out' 
                }}>
                    {responsavel[tipo][status]}
                </span>
            </div>
        );

        return (
            <div style={{...EssentialStyle.rowSpaceBetween, width: '100%', height: 35, borderRadius: 5, marginLeft: 5, overflow: 'hidden'}}>
                {status.map(renderDiv)}
            </div>
        );
    }

    renderColaboradoresTabs() {
        return (
            <div style={{ ...EssentialStyle.rowSpaceBetween, backgroundColor: Colors.secondaryButton, placeSelf: "center", borderRadius: 5}}>
                {this.state.tabs.map((tab, index) => {
                    return (
                        <DefaultButton
                            key={`tab-${index}`}
                            title={tab.title}
                            color={this.state.activeTab === tab.name ? Colors.dark : Colors.secondaryButton}
                            textColor={this.state.activeTab === tab.name ? Colors.white : Colors.dark}
                            onClick={() => { this.handleTabChange(tab) }}
                            style={{ width: this.state.isSmallScreen ? '40vw' : 90, maxWidth: 150 }}
                        />
                    );
                })}
            </div>
        )
    }

    handleTabChange = (tab) => {
        if(this.state.activeTab === tab.name) return;
        this.setState({ activeTab: tab.name });
    }

    renderColaboradores = () => {
        if(this.state.loading) {
            return (
            <div style={{...EssentialStyle.columnCenter, width: "100%", height: "auto", justifyContent: 'center', alignItems: 'center'}} >
                <DefaultLoader />
            </div>
           )
        }

        return (
            <div className="pa-colaboradores-component" style={{ ...EssentialStyle.columnCenter, width: '100%', backgroundColor: SessionHelper.getBackgroundColor(), paddingTop: "3px", position: 'sticky', left: 0}}>
                <div style={{...EssentialStyle.columnCenter, minHeight: 35, boxShadow: `0px 1px 10px 0px  rgba(50, 50, 50, 0.1)`, borderRadius: 8, backgroundColor: Colors.white, width: '100%', overflow: "hidden"}}>

                    <div style={{ ...EssentialStyle.rowFlexCenter, cursor: 'pointer', minHeight: 35 }} onClick={() => this.setState({expanded: !this.state.expanded})} >
                        {this.state.expanded ?
                            <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: 8 }} /> :
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                        }
                        {
                            this.state.expanded ? 'Ocultar Colaboradores' : 'Colaboradores'
                        }
                    </div>

                    <Collapse in={this.state.expanded} style={{ width: '100%', transition: "all 0.2s ease" }}>
                        <div style={{...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowFlexCenter), width: '100%'}}>
                            <div style={{...EssentialStyle.columnStart, width: '100%', height: 300, paddingTop: 0, gap: 15}}>
                                {this.state.loading ? 
                                    <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%', height: "100%"}}>
                                        <DefaultLoader />
                                    </div>
                                : 
                                    <div style={{...EssentialStyle.columnStart, paddingBottom: 5, width: "100%"}}>
                                        {this.state.isSmallScreen ? this.renderColaboradoresTabs() : null}
                                        <div style={{...EssentialStyle.rowFlexCenter, height: 40, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", textAlign: 'center'}}>
                                            <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.state.isSmallScreen ? "45%" : "35%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Responsável</div>
                                            {!this.state.isSmallScreen || this.state.activeTab === "planos" ?
                                                <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.state.isSmallScreen ? "55%" : "25%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Planos de Ação</div>
                                            : null
                                            }
                                            {!this.state.isSmallScreen || this.state.activeTab === "etapas" ?
                                            <>
                                                <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.state.isSmallScreen ? "35%" : "25%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Etapas</div>
                                                <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.grey, width: this.state.isSmallScreen ? "20%" : "15%", height: "100%", alignContent: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>Atraso (média)</div>
                                            </> : null
                                            }
                                        </div>
                                        <div style={{...EssentialStyle.columnStart, width: "100%", overflowY: "auto", maxHeight: 250, height: "auto"}}>
                                            {this.state.contadores.colaboradores.map((colaborador, index) => {
                                                return (
                                                    <div key={"colaborador-"+index} style={{...EssentialStyle.rowFlexCenter, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`, width: "100%", height: 40, minHeight: 40}}>
                                                        <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.state.isSmallScreen ? "45%" : "35%", height: "100%", alignContent: "center", paddingLeft: 10}}>
                                                            <UserAvatar user={colaborador} size={35} showNameAdaptative showName />
                                                        </div>
                                                        {!this.state.isSmallScreen || this.state.activeTab === "planos" ?
                                                            <div style={{...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.state.isSmallScreen ? "55%" : "25%", height: "100%", alignContent: "center"}}>
                                                                {colaborador.planos ? 
                                                                    <>
                                                                        <div style={{...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)"}}>
                                                                            {this.renderBarra(colaborador, "planos")}
                                                                        </div>
                                                                        <div
                                                                            onClick={() => this.props.filterCallback({view: "planos", values: [{ field: 'responsavel', value: colaborador.id }]})} 
                                                                            style={{...EssentialStyle.rowFlexCenter, height: 30, width: 40, marginLeft: 5, borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`, cursor: "pointer"}}
                                                                        >
                                                                            {colaborador.planos.total}
                                                                        </div>
                                                                    </>

                                                                : 
                                                                    <div style={{...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)"}}>
                                                                        -
                                                                    </div>
                                                                }
                                                            </div>
                                                        : null
                                                        }
                                                        {!this.state.isSmallScreen || this.state.activeTab === "etapas" ?
                                                        <>
                                                            <div style={{...EssentialStyle.rowFlexStart, fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.state.isSmallScreen ? "35%" : "25%", height: "100%", alignContent: "center"}}>
                                                                {colaborador.etapas ? 
                                                                    <>    
                                                                        <div style={{...EssentialStyle.rowFlexStart, height: "100%", width: "calc(100% - 45px)"}}>
                                                                            {this.renderBarra(colaborador, "etapas")}
                                                                        </div>
                                                                        <div
                                                                            onClick={() => this.props.filterCallback({view: "etapas", values: [{ field: 'responsavel', value: colaborador.id }]})} 
                                                                            style={{...EssentialStyle.rowFlexCenter, height: 30, width: 40, marginLeft: 5, borderLeft: `2px solid ${Colors.homePage.extraLightGrey}`, cursor: "pointer" }}
                                                                        >
                                                                            {colaborador.etapas.total}
                                                                        </div>
                                                                    </>
                                                                :
                                                                    <div style={{...EssentialStyle.rowFlexCenter, height: "100%", width: "calc(100% - 45px)"}}>
                                                                        -
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div style={{fontSize: 16, fontWeight: 500, color: Colors.homePage.darkGrey, width: this.state.isSmallScreen ? "20%" : "15%", height: "100%", alignContent: "center", textAlign: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                                                                {colaborador.atrasoTotal && colaborador.atrasoTotal > 0 ? Math.round((colaborador.atrasoTotal / colaborador.etapas.total)*10)/10 < 1.5 ? "1 dia" : Math.round((colaborador.atrasoTotal / colaborador.etapas.total)*10)/10 + " dias" : "Em dia"}
                                                            </div>
                                                        </> : null
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }


    render() {
        return (
            this.renderColaboradores()
        )
    }
}