import FuncionalidadesHelper from "./FuncionalidadesHelper";
import { faUser, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default class AnalyticsHelper {
    
    static getModulos = async (idEmpresas) => {
        let funcionalidadesReturn = [];

        let funcionalidades  = await FuncionalidadesHelper.getFuncionalidades(idEmpresas);

        if (funcionalidades.length === 0) {
            for (let key in funcionalidadesAnalytics) {
                funcionalidadesReturn.push(funcionalidadesAnalytics[key]);
            }
            return funcionalidadesReturn;
        }

        for (let key in funcionalidades) {
            let referencia = funcionalidades[key].referencia;
            if (funcionalidadesAnalytics[referencia]) {
                funcionalidadesReturn.push(funcionalidadesAnalytics[referencia]);
            }
        }
        return funcionalidadesReturn;
    }

    static getStatesColaboradores () {
        return [
            "Bloqueado", 
            "Ativo", 
            "Inativo"
        ];
    }

    static getPermissoesColaboradores () {
        return {
            todos: "Todos Níveis",
            administradorMaster: "Administrador Master",
            consultorAdvis: "Consultor Advis",
            administrador: "Administrador",
            gestor: "Gestor da Empresa",
            colaborador: "Colaborador",
        }
    }

    static getPermissoesColaboradoresClientes () {
        return {
            todos: "Todos Níveis",
            administrador: "Administrador",
            gestor: "Gestor da Empresa",
            colaborador: "Colaborador",
        }
    }

}

const funcionalidadesAnalytics = {
    "config": {
        "id": "config",
        "title": "Colaboradores",
        "name": "Colaboradores",
        "icon": faUser,
    },
    "pe_swot": {
        "id": "pe_swot",
        "title": "SWOT",
        "name": "Análises SWOT",
        "icon": faEyeSlash,
    },
    "pe_visao": {
        "id": "pe_visao",
        "title": "Visões",
        "name": "Visões de Futuro",
        "icon": faEyeSlash,
    },
    "pe_mapa": {
        "id": "pe_mapa",
        "title": "Mapas",
        "name": "Mapas Estratégicos",
        "icon": faEyeSlash,
    },
    "pe_projeto": {
        "id": "pe_projeto",
        "title": "Projetos",
        "name": "Projetos",
        "icon": faEyeSlash,
    },
    "pe_canvas": {
        "id": "pe_canvas",
        "title": "Canvas",
        "name": "Canvas de Projetos",
        "icon": faEyeSlash,
    },
    "gmr": {
        "id": "gmr",
        "title": "GMR",
        "name": "Gestão de Metas e Resultados",
        "icon": faEyeSlash,
    },
    "gd": {
        "id": "gd",
        "title": "GD",
        "name": "Gestão de Desempenho",
        "icon": faEyeSlash,
    },
    "plano_acao": {
        "id": "plano_acao",
        "title": "Planos",
        "name": "Planos de Ação",
        "icon": faEyeSlash,
    },
}