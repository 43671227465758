import React from "react";
import CustomTooltip from "./CustomTooltip";
import Tag from "./Tag";
import EssentialStyle from "../../style/EssentialStyle";
import Colors from "../../constants/Colors";

export default class TooManyTags extends React.Component {
    state = {
    }

    getTagsStyle() {
        return {
            ...EssentialStyle.rowFlexStart,
            maxWidth: this.props.maxWidth || '20vw',
            paddingLeft: 3
        };
    }

    renderTags(tags) {
        let max = (this.props.max || 2);

        if (tags && tags.length) {
            if (tags.length > max) {
                return (
                    <CustomTooltip placement={'bottom'} tooltip={
                        <ul style={{ paddingLeft: 20, marginBottom: 5 }}>
                            {tags.map((tag, i) => (
                                <li key={i + Math.random()} style={{ marginRight: 5 }}>
                                    {tag.nome}
                                </li>
                            ))}
                        </ul>
                    }>
                        <div style={this.getTagsStyle()}>
                            {tags.slice(0, max).map((tag, i) => (
                                <Tag key={Math.random()} tag={tag} />
                            ))}
                            <Tag tag={{ id: Math.random(), nome: `+ ${tags.length - max}` }} />
                        </div>
                    </CustomTooltip>
                );
            } else {
                return (
                    <div style={this.getTagsStyle()}>
                        {tags.map((tag, i) => <Tag key={Math.random() * 1000} tag={tag} />)}
                    </div>
                );
            }
        } else {
            return <div></div>;
        }
    }

    render() {
        return this.renderTags(this.props.tags);
    }
}