import React from "react";

import './GraficoResultado.css';
import DiagnosticoHelper from "../../../../../helper/diagnostico/DiagnosticoHelper";
import GraficoMapaCalor from "./GraficoMapaCalor/GraficoMapaCalor";

const HEATMAP = 1;

export default class GraficoResultado extends React.Component {

    state = {

    }

    render() {
        switch (this.props.tipoGrafico) {
            case HEATMAP:
                return <GraficoMapaCalor idPeriodoAvaliacao={this.props.idPeriodoAvaliacao} lang={this.props.lang} isSmallScreen={this.props.isSmallScreen} />;
            default:
                return DiagnosticoHelper.renderNotSelected(
                    [
                        { id: 1, text: "Selecione um tipo de gráfico" },
                        { id: 2, text: "Select a chart type" },
                        { id: 3, text: "Seleccione un tipo de gráfico" },
                    ],
                    this.props.lang
                );
        }
    }
}