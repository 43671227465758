import React from "react";
import Colors from "../../constants/Colors";

export default class Tag extends React.Component {
    state = {
    }

    getTagStyle() {
        return {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            backgroundColor: Colors.tag,
            color: Colors.dark,
            fontSize: 12,
            minWidth: 'auto',
            padding: 5,
            borderRadius: 5,
            marginRight: 2
        }
    }

    render() {
        return <div key={`tag-${this.props.tag.id}`} style={this.getTagStyle()}>{this.props.tag.nome}</div>;
    }
}