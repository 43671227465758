export default class KeyboardHelper {
    static handleShortcut(evt, key, callback) {
        evt.stopPropagation();

        key.forEach((key, index) => {
            if (evt.key === key) {
                callback[index](evt);
            }
        });
    }
}