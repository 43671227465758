import React from "react";
import EssentialStyle from "../../style/EssentialStyle";
import DiagnosticoHelper from "../../helper/diagnostico/DiagnosticoHelper";
import LayoutHelper from "../../helper/LayoutHelper";
import LoadingPage from "../LoadingPage";
import RelatorioProcesso from "../../components/modules/diagnostico/RelatoriosProcesso/RelatoriosProcesso";

export default class DiagnosticoPeriodoAvaliacaoPage extends React.Component {

    state = {
        construction: true,
        loading: true,

        idProcesso: null,

        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        this.handleUrlParams();
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('message', this.handleMessage);
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                window.removeEventListener('message', listenerFunction);

                // Extract the last segment from the pathname
                const pathSegments = urlObj.pathname.split('/');
                const lastSegment = pathSegments[pathSegments.length - 1];

                this.setState({ idProcesso: parseInt(lastSegment) }, () => {
                    // this.loadData(lastSegment);
                });
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    handleMessage = (event) => {
        if (event.data.type === 'popstate') {
            if (event.data.url === this.state.lastPopState) {
                this.setState({ showDetailsModal: null, lastPopState: null });
                return;
            }

            this.setState({ lastPopState: event.data.url });
            this.handleUrlParams();
        }
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    render() {
        if (this.state.construction) return DiagnosticoHelper.renderConstruction();
        if (this.state.loading) return <LoadingPage />;
        if (this.state.idProcesso === null) return <div style={EssentialStyle.pageContainer}>Erro ao carregar página</div>; // Enviar para página de "Ver todos os processos"
        return <RelatorioProcesso idProcesso={this.state.idProcesso} />;
    }
}