import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faEye, faPlusSquare, faStar, faTrash } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import LoadingPage from "../../../../pages/LoadingPage";
import DefaultTable from "../../../tools/DefaultTable";
import moment from "moment";
import DataHelper from "../../../../helper/DataHelper";
import DefaultTableSearch from "../../../tools/DefaultTableSearch";
import Colors from "../../../../constants/Colors";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import UserAvatar from "../../../tools/UserAvatar";
import CustomConfirm from "../../../tools/CustomConfirm";
import AddMapa from "../add/AddMapa";
import MapaView from "../../../modules/pe/mapa/MapaView";
import Sig from "../../../../api/Sig";
import TooManyAvatars from "../../../tools/TooManyAvatars";
import CustomTooltip from "../../../tools/CustomTooltip";
import EssentialStyle from "../../../../style/EssentialStyle";
import SessionHelper from "../../../../helper/SessionHelper";

const searchableFields = ['nome', 'responsavel', 'participants', 'data_atualizacao', 'data_cadastro'];

export default class MapaList extends React.Component {

    state = {
        mapas: [],
        loading: true,
        showWizard: false,
        showMapa: 0,
        modeView: 'read-only', 

        acesso: null, 
        permissao: null, 
        temRestricoes: null,
        restricoes: null,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData(loading = true) {
        if (loading) {
            this.setState({ loading: true });
        }

        let {mapas, acesso, permissao, temRestricoes, restricoes} = (await Sig.request('GET', 'pe/mapa/list', { relations: 1 })) || [];
        let visoes = (await Sig.request('GET', 'pe/visao/list', { relations: 1 }))?.visoes || [];
                
        let rows = [];

        for (let mapa of mapas) {

            let id_visao = mapa.mapa.id_pe_visao;
            let nome_visao;

            for (let visao of visoes) {
                if (visao.visao.id == id_visao) {
                    nome_visao = visao.visao.nome;
                    break;
                }
            }
            let row = {
                ...mapa.mapa,
                participants: mapa.participantes,
                responsavel: mapa.responsavel,  
                visao: nome_visao,          
            };
            row.data_atualizacao = mapa.data_atualizacao;
            row.favorito = mapa.favorito;

            rows.push(row);
        }


        this.setState({ mapas: rows, acesso, permissao, temRestricoes, restricoes: (restricoes ? Object.values(restricoes) : []) }, () => { this.setState({ loading: false }) });
    }

    remove = async (id) => {
        this.setState({ loading: true });

        try {
            await Sig.request('POST', 'pe/mapa/delete', { id });
            toast.success('Mapa removido com sucesso!');
        } catch (e) {
            toast.error('Erro ao remover');
        }

        this.loadData();
    }

    handleFavorite = async (id, isFavorite) => {
        this.setState({ loading: true });

        await Sig.request('POST', `pe/mapa/setFavorito`, { id: id, favorito: isFavorite ? 0 : 1 });
        await this.loadData();

        this.setState({ loading: false });
        toast.success('Alterado com sucesso');
    }

    addFromProject =  async (id) => {
        if (!id) return toast.warn('Erro ao clonar mapa');

        let request = await Sig.request('POST', `pe/mapa/clonar`, { id });

        if (request?.mapa) {
            toast.success('Mapa clonado com sucesso');

            this.loadData();
        }

        this.setState({ loading: false });
    }

    renderParticipants(participants) {
        if (participants && participants.length) {
            return <TooManyAvatars participantsTotal={participants.length} participantsVisiveis={3} arrayParticipants={participants} />;
        } else {
            return <div></div>
        }
    }

    renderHeader() {

        return (

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 20 }}>
                <DefaultButton color={Colors.success} title={'Adicionar Mapa Estratégico'} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} loading={this.state.loading} onClick={() => { this.setState({ showWizard: true }) }} />
                <div></div>
                <DefaultTableSearch fields={searchableFields} searchObjects={true} data={this.state.mapas} onDataUpdate={(data) => { this.setState({ mapas: data }) }} onEmpty={() => { this.loadData(false) }} />
            </div>
        )
    }

    renderActions(row) {

        let podeFavoritar = this.state.acesso == 'pe_mapa_administrador' || (this.state.permissao == 'pe_mapa_editar' && !this.state.temRestricoes);
        let podeClonar =    this.state.acesso == 'pe_mapa_administrador' || row.isResponsavel || (this.state.permissao == 'pe_mapa_editar' && (!this.state.temRestricoes || this.state.restricoes.includes(row.id)));
        let podeRemover =   this.state.acesso == 'pe_mapa_administrador' || row.isResponsavel || (this.state.permissao == 'pe_mapa_editar' && (!this.state.temRestricoes || this.state.restricoes.includes(row.id)));

        return (

            <div style={EssentialStyle.rowFlexStart}>
                <CustomTooltip placement={'bottom'} tooltip={!podeFavoritar ? 'Sem Permissão Para Favoritar Mapas' : (row.favorito ? 'Remover Favorito' : 'Marcar como Favorito')}>
                    <FontAwesomeIcon style={{ color: row.favorito ? Colors.favorite : 'grey', fontSize: 17 }} icon={faStar} onClick={() => {
                        if (podeFavoritar) {
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={row.favorito ? 'Remover Favorito?' : 'Marcar Favorito?'}
                                        buttons={[
                                            {
                                                label: !row.favorito ? 'Sim' : 'Remover',
                                                color: !row.favorito ? Colors.success : Colors.error,
                                                textColor: Colors.light,
                                                onClick: () => { this.handleFavorite(row.id, row.favorito); onClose(); }
                                            },
                                            {
                                                label: !row.favorito ? 'Não' : 'Cancelar',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }
                    }} />
                </CustomTooltip>
                <CustomTooltip placement={'bottom'} tooltip={!podeClonar ? 'Sem Permissão Para Clonar esse Mapa': 'Clonar'}>
                    <FontAwesomeIcon style={{ marginLeft: 20, fontSize: 17, color: 'grey' }} icon={faCopy} onClick={() => {
                        if (podeClonar) {
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={'Clonar este Mapa Estratégico?'}
                                        buttons={[
                                            {
                                                label: 'Sim',
                                                color: Colors.success,
                                                textColor: Colors.light,
                                                onClick: () => { this.addFromProject(row.id); onClose(); }
                                            },
                                            {
                                                label: 'Não',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }
                    }} />
                </CustomTooltip>
                <CustomTooltip placement={'bottom'} tooltip={!podeRemover ? 'Sem Permissão Para Remover esse Mapa' : 'Remover'}>
                    <FontAwesomeIcon style={{ marginLeft: 20, fontSize: 17, color: Colors.error }} icon={faTrash} onClick={() => {
                        if (podeRemover) {
                            confirmAlert({
                                customUI: ({ onClose }) => (
                                    <CustomConfirm
                                        title={'Excluir este Mapa?'}
                                        message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                                        buttons={[
                                            {
                                                label: 'Remover',
                                                color: SessionHelper.getColor(),
                                                textColor: Colors.light,
                                                onClick: () => { this.remove(row.id); onClose(); }
                                            },
                                            {
                                                label: 'Cancelar',
                                                onClick: () => { onClose(); }
                                            },
                                        ]}
                                    />
                                )
                            });
                        }
                    }} />
                </CustomTooltip>
            </div>
        )
    }

    renderVisoes() {
        return (
            <div>
                <DefaultTable
                    title={this.renderHeader()}
                    columns={[
                        { name: 'Mapa', selector: row => (
                            <div style={{
                              maxWidth: 'calc(100%)', // Define a largura máxima para o conteúdo da célula
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              direction: 'rtl', // Faz o texto fluir da direita para a esquerda
                              textAlign: 'left' // Mantém o texto alinhado à esquerda
                            }}>
                              {row.nome}
                            </div>
                          ), sortable: true, width: '25%'},
                        { name: 'Responsável', cell: row => <UserAvatar id={row.id_responsavel} />, sortable: true, sortFunction: (a, b) => a.responsavel.nome > b.responsavel.nome, width: 'calc(75%/6)' },
                        { name: 'Participantes', cell: row => this.renderParticipants(row.participants), sortable: true, sortFunction: (a, b) => DataHelper.multiArraySort(a, b, 'participants'), width: 'calc(75%/6)' },
                        { name: 'Visão', 
                            cell: row =>
                                <div title={row.visao}>
                                    {DataHelper.limitarCaracteres(row.visao, 20) || '--'}
                                </div>
                            , 
                            sortable: true, width: 'calc(75%/6)'
                    },

                        { name: 'Data Atualização', cell: row => moment(row.data_atualizacao).format('DD/MM/YYYY HH:mm'), sortable: true, sortFunction: (a, b) => DataHelper.multiArraySort(a, b, 'data_atualizacao'), width: 'calc(75%/6)' },
                        { name: 'Criação', selector: row => moment(row.data_cadastro).format('DD/MM/YYYY HH:mm'), sortable: true, sortFunction: (a, b) => DataHelper.multiArraySort(a, b, 'data_cadastro'), width: 'calc(75%/6)' },
                        { name: 'Ações', selector: row => this.renderActions(row), width: 'calc(75%/6)' },
                    ]}
                    data={this.state.mapas}
                    onRowClicked={row => this.setState({ showMapa: row.id })}
                    pagination
                    pointerOnHover
                    highlightOnHover
                />
            </div>
        );
    }

    viewNewMapaCallback = async (id) => {
        this.setState({ showWizard: false, showMapa: id, modeView: 'edit'});
        await this.loadData();
    }


    render() {
        if (this.state.loading) return <LoadingPage />
        if (this.state.mapas.length === 0) return this.props.callback();
        if (this.state.showWizard) return <AddMapa addCallback={ (id) => {this.viewNewMapaCallback(id)}} />
        if (this.state.showMapa) return <MapaView callback={() => { this.setState({ showMapa: 0 }); this.loadData(); }} id={this.state.showMapa} modeView={this.state.modeView} remove={this.remove}/>

        return this.renderVisoes();
    }
}