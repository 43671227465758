import React, { Component } from 'react';
import Sig from '../../api/Sig';
import DefaultButton from './DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlusSquare, faClose, faTimes, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../constants/Colors';
import LoadingPage from '../../pages/LoadingPage';
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import GutMatrixConfigFavorite from './GutMatrixConfigFavorite';
import CustomConfirm from './CustomConfirm';
import SessionHelper from '../../helper/SessionHelper';

export default class GutMatrixConfig extends Component {

    state = {
        loading: true,
        criterios: [],
        pontuacao: [],
        editingCriterio: false,
        editingPontuacao: false,
        hoveredPontuacaoItem: null,
        hoveredPontuacaoFlag: false,
        hoveredCriterioItem: null,
        hoveredCriterioFlag: false,
    }

    async componentDidMount() {
        await this.load()

        this.setState({ loading: false });
    }

    async load() {
        if (this.props.idSwot) {
            let matriz = await Sig.request('GET', 'pe/swot/getMatrizGut', { id: this.props.idSwot });

            await this.getPontuacao(matriz);
            await this.getCriterios(matriz);

        } else if(this.props.idPeMapa) {
            let matriz = await Sig.request('POST', 'pe/mapa/getMatrizGut', { id: this.props.idPeMapa });

            await this.getPontuacao(matriz);
            await this.getCriterios(matriz);

        } else if(this.props.projetos) {
            let matriz = await Sig.request('GET', 'pe/projeto/getMatrizGut');

            await this.getPontuacao(matriz);
            await this.getCriterios(matriz);
        }
    }

    async getPontuacao(matriz) {
        let req = [];

        if (matriz) {
            for (let criterio of matriz.criterios) {
                for (let pontuacao of criterio.pontuacao) {
                    req.push(pontuacao);
                }
            }
        }

        if (req) {

            let rows = {};

            for (let index = 0; index < req.length; index++) {

                let element = req[index];

                if (!rows[element.valor]) { rows[element.valor] = [] };
                rows[element.valor].push(req[index]);
            }

            this.setState({ pontuacao: rows });
        }
    }

    async getCriterios(matriz) {
        
        if (this.props.idSwot || this.props.idPeMapa || this.props.projetos) {
            let crits = [];

            for(let criterio of matriz.criterios) {
                crits.push({ ...criterio.criterio });
            }
            
            this.setState({ criterios: crits });
        }
    }

    validateTable(criterios = [], pontuacao = []) {

        let keys = Object.keys(pontuacao);
        let valid = true;

        for (let index = 0; index < criterios.length; index++) {

            if (!valid) { break };

            if (criterios[index].editing && (!criterios[index].nome || !criterios[index].conceito)) {

                valid = false;
            }
        }

        for (let index = 0; index < keys.length; index++) {

            if (!valid) { break };

            if (pontuacao[keys[index]] && pontuacao[keys[index]].length) {

                for (let indexPontuacao = 0; indexPontuacao < pontuacao[keys[index]].length; indexPontuacao++) {

                    let item = pontuacao[keys[index]][indexPontuacao];

                    if (item.editing) {

                        let criterio = criterios[indexPontuacao];

                        if (!criterio || !item.nome || isNaN(parseInt(keys[index]))) {

                            valid = false;
                        }
                    }
                }
            }
        }

        if (!valid) {

            toast.warn('Existem dados inválidos na tabela');
        }

        return valid;
    }

    async save() {

        try {

            this.setState({ loading: true });

            let criterios = this.state.criterios;
            let pontuacao = this.state.pontuacao;
            let keys = Object.keys(pontuacao);

            if (this.validateTable(criterios, pontuacao)) {

                for (let index = 0; index < criterios.length; index++) {

                    if (criterios[index].editing) {
                        let criterioInsert;

                        if(this.props.idSwot) {
                            criterioInsert = await Sig.request('POST', 'pe/swot/setGutCriterio', 
                                { 
                                    id_pe_swot: this.props.idSwot,
                                    id_pe_gut_criterio: criterios[index].id ? criterios[index].id : 0,
                                    nome: criterios[index].nome,
                                    conceito: criterios[index].conceito,
                                    ordem: criterios.length
                                }
                            );
                        } else if(this.props.idPeMapa) {
                            criterioInsert = await Sig.request('POST', 'pe/mapa/setGutCriterio', 
                                { 
                                    id_pe_mapa: this.props.idPeMapa,
                                    id_pe_gut_criterio: criterios[index].id ? criterios[index].id : 0,
                                    nome: criterios[index].nome,
                                    conceito: criterios[index].conceito,
                                    ordem: criterios.length
                                }
                            );
                        } else if(this.props.projetos) {
                            criterioInsert = await Sig.request('POST', 'pe/projeto/setGutCriterio', 
                                { 
                                    id_pe_gut_criterio: criterios[index].id ? criterios[index].id : 0,
                                    nome: criterios[index].nome,
                                    conceito: criterios[index].conceito,
                                    ordem: criterios.length
                                }
                            );
                        }

                        if(!criterios[index].id) criterios[index].id = criterioInsert?.gut_criterio?.id;
                    }
                }

                for (let index = 0; index < keys.length; index++) {

                    if (pontuacao[keys[index]] && pontuacao[keys[index]].length) {

                        for (let indexPontuacao = 0; indexPontuacao < pontuacao[keys[index]].length; indexPontuacao++) {

                            let item = pontuacao[keys[index]][indexPontuacao];

                            if (item && item.editing && item.nome) {

                                let criterio = criterios[indexPontuacao];

                                if(this.props.idSwot) {
                                    await Sig.request('POST', 'pe/swot/setGutPontuacao',
                                        {
                                            id: item.id ? item.id : 0,
                                            id_pe_swot: this.props.idSwot,
                                            id_criterio: criterio.id,
                                            nome: item.nome,
                                            valor: parseInt(keys[index])
                                        }
                                    );
                                } else if(this.props.idPeMapa) {
                                    await Sig.request('POST', 'pe/mapa/setGutPontuacao',
                                        {
                                            id: item.id ? item.id : 0,
                                            id_pe_mapa: this.props.idPeMapa,
                                            id_criterio: criterio.id,
                                            nome: item.nome,
                                            valor: parseInt(keys[index])
                                        }
                                    );
                                } else if(this.props.projetos) {
                                    await Sig.request('POST', 'pe/projeto/setGutPontuacao',
                                        {
                                            id: item.id ? item.id : 0,
                                            id_criterio: criterio.id,
                                            nome: item.nome,
                                            valor: parseInt(keys[index])
                                        }
                                    );
                                }
                            }
                        }
                    }
                }

                await this.load();

                this.setState({ loading: false, editingCriterio: false, editingPontuacao: false });
                toast.success('Alterações salvas com sucesso');

            } else {

                this.setState({ loading: false });
            }

        } catch (error) {

            this.setState({ loading: false });
            toast.error('Houve um problema ao salvar as alterações');
        }
    }

    async removePontuacao() {

        try {

            this.setState({ loading: true });

            let item = this.state.hoveredPontuacaoItem;
            if (isNaN(parseInt(item))) { throw new Error('Missing Param') };

            let pontuacao = this.state.pontuacao;
            let keys = Object.keys(pontuacao);
            let items = pontuacao[keys[item]].map((item) => { return item.id });

            let req;
            if(this.props.idSwot) {
                req = await Sig.request('POST', 'pe/swot/deleteGutPontuacao', { ids: items });
            } else if(this.props.idPeMapa) {
                req = await Sig.request('POST', 'pe/mapa/deleteGutPontuacao', { ids: items });
            } else if(this.props.projetos) {
                req = await Sig.request('POST', 'pe/projeto/deleteGutPontuacao', { ids: items });
            }

            if(req.status == 200) {
                await this.load();

                this.setState({ loading: false });
                toast.success('Item removido com sucesso');
            } else {
                throw new Error('Erro ao remover');
            }

        } catch (error) {

            this.setState({ loading: false });
            toast.error('Houve um problema ao remover esse item');
        }
    }

    async removeCriterio() {
        try {

            this.setState({ loading: true });

            let item = this.state.hoveredCriterioItem;
            if (isNaN(parseInt(item))) { throw new Error('Missing Param') };

            let criterios = this.state.criterios;

            if (criterios[item] && criterios[item].id) {
                if(this.props.idSwot) {
                    await Sig.request('POST', 'pe/swot/deleteGutCriterio', { id_criterio: criterios[item].id });
                } else if(this.props.idPeMapa) {
                    await Sig.request('POST', 'pe/mapa/deleteGutCriterio', { id_criterio: criterios[item].id });
                } else if(this.props.projetos) {
                    await Sig.request('POST', 'pe/projeto/deleteGutCriterio', { id_criterio: criterios[item].id });
                }
            }

            await this.load();

            this.setState({ loading: false });
            toast.success('Item removido com sucesso');

        } catch (error) {

            this.setState({ loading: false });
            toast.error('Houve um problema ao remover esse item');
        }
    }

    renderStepBack() {
        return (
            <div style={{}}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.props.gutEditCallback(); }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    renderHeader() {

        return (

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', marginBottom: 20 }}>
                {this.renderStepBack()}
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} />
                <GutMatrixConfigFavorite onChange={() => { this.load() }} setLoading={(flag) => { this.setState({ loading: flag }) }} {...this.props} />
            </div>
        )
    }

    renderCriterios() {

        return this.state.criterios.map((criterio, key) => {

            return (

                <th onMouseLeave={() => { this.handleHover(false, key, 'criterio') }} onMouseEnter={() => { this.handleHover(true, key, 'criterio') }} style={{ textAlign: 'left', backgroundColor: 'grey', color: 'white' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                        {this.state.hoveredCriterioItem === key && this.state.hoveredCriterioFlag === true && !this.state.editingCriterio && !this.state.editingPontuacao ?

                            <DefaultButton
                                color={Colors.error}
                                loading={this.state.loading}
                                leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                style={{ height: 25, width: 25, borderRadius: 25 / 2, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }}
                                onClick={() => {
                                    confirmAlert({
                                        customUI: ({ onClose }) => (
                                            <CustomConfirm
                                                title={'Remover este item?'}
                                                message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                                                buttons={[
                                                    {
                                                        label: 'Remover',
                                                        color: SessionHelper.getColor(),
                                                        textColor: Colors.light,
                                                        onClick: () => { this.removeCriterio(); onClose(); }
                                                    },
                                                    {
                                                        label: 'Cancelar',
                                                        onClick: () => { onClose(); }
                                                    },
                                                ]}
                                            />
                                        )
                                    });
                                }}

                            /> : null}

                        {criterio.editing ?

                            <Form.Control placeholder={'Informe o nome...'} type="text" defaultValue={criterio.nome} onBlur={(event) => {

                                let criterios = this.state.criterios;
                                criterios[key].nome = event.target.value;

                                this.setState({ criterios });
                            }} />

                            : criterio.nome}
                    </div>
                </th>
            )
        });
    }

    renderConcept() {

        return this.state.criterios.map((criterio, key) => {

            return (

                <th onMouseLeave={() => { this.handleHover(false, key, 'criterio') }} onMouseEnter={() => { this.handleHover(true, key, 'criterio') }} style={{ textAlign: 'left', fontWeight: 'normal' }}>

                    {criterio.editing ?

                        <Form.Control placeholder={'Informe o conceito...'} type="text" defaultValue={criterio.conceito} onBlur={(event) => {

                            let criterios = this.state.criterios;
                            criterios[key].conceito = event.target.value;

                            this.setState({ criterios });
                        }} />

                        : criterio.conceito}

                </th>
            );
        });
    }

    renderPontuacao() {

        let keys = Object.keys(this.state.pontuacao);
        let render = [];

        keys.map((pontuacao, key) => {

            let backgroundColor = key % 2 === 0 ? 'white' : 'lightgrey';
            let items = [];

            let element =

                <td onMouseLeave={() => { this.handleHover(false, key, 'pontuacao') }} onMouseEnter={() => { this.handleHover(true, key, 'pontuacao') }} style={{ backgroundColor: backgroundColor, textAlign: 'left' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {this.state.hoveredPontuacaoItem === key && this.state.hoveredPontuacaoFlag === true && !this.state.editingCriterio && !this.state.editingPontuacao ?

                            <DefaultButton
                                color={Colors.error}
                                loading={this.state.loading}
                                leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                style={{ height: 25, width: 25, borderRadius: 25 / 2, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }}
                                onClick={() => {
                                    confirmAlert({
                                        customUI: ({ onClose }) => (
                                            <CustomConfirm
                                                title={'Remover este item?'}
                                                message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                                                buttons={[
                                                    {
                                                        label: 'Remover',
                                                        color: SessionHelper.getColor(),
                                                        textColor: Colors.light,
                                                        onClick: () => { this.removePontuacao(); onClose(); }
                                                    },
                                                    {
                                                        label: 'Cancelar',
                                                        onClick: () => { onClose(); }
                                                    },
                                                ]}
                                            />
                                        )
                                    });
                                }}

                            /> : null}
                        {pontuacao}
                    </div>
                </td>

            items.push(element);

            this.state.pontuacao[pontuacao].map((item, itemKey) => {

                let element = null;

                if (item.editing) {

                    element =

                        <td style={{ backgroundColor: backgroundColor, textAlign: 'left' }}>
                            <Form.Control placeholder={'Informe o nome...'} type="text" defaultValue={item.nome} onBlur={(event) => {

                                let docs = this.state.pontuacao;
                                docs[pontuacao][itemKey].nome = event.target.value;

                                this.setState({ pontuacao: docs });
                            }} />
                        </td>

                } else {

                    element = <td onMouseLeave={() => { this.handleHover(false, key, 'pontuacao') }} onMouseEnter={() => { this.handleHover(true, key, 'pontuacao') }} style={{ backgroundColor: backgroundColor, textAlign: 'left' }}>{item.nome}</td>;
                }

                items.push(element);
            });

            render.push(<tr>{items}</tr>);
        });

        return render;
    }

    handleHover(flag, key, type) {

        if (type === 'pontuacao') {

            this.setState({ hoveredPontuacaoItem: key, hoveredPontuacaoFlag: flag });

        } else {

            this.setState({ hoveredCriterioItem: key, hoveredCriterioFlag: flag });
        }
    }

    handleAddCriterio() {

        let criterios = this.state.criterios;
        criterios.push({ editing: true });

        let pontuacao = this.state.pontuacao;

        Object.keys(pontuacao).forEach((item, key) => {

            pontuacao[item].push({ editing: true });
        });

        this.setState({ criterios, pontuacao, editingCriterio: true })
    }

    handleAddPontuacao() {

        let pontuacao = this.state.pontuacao;

        let keys = Object.keys(pontuacao);
        let index = parseInt(keys[keys.length - 1]) + 1;

        pontuacao[index] = [];

        this.state.criterios.forEach((criterio, key) => {

            pontuacao[index].push({ editing: true });
        });

        this.setState({ pontuacao, editingPontuacao: true });
    }

    async handleCancelAdd() {

        await this.load();

        this.setState({ editingCriterio: false, editingPontuacao: false });
    }

    handleEditCriterio() {

        let criterios = this.state.criterios;

        criterios.forEach((criterio, key) => {

            criterios[key].editing = true;
        });

        this.setState({ criterios, editingCriterio: true });
    }

    handleEditPontuacao() {

        let pontuacao = this.state.pontuacao;

        Object.keys(pontuacao).forEach((item, key) => {

            if (pontuacao[item] && pontuacao[item].length) {

                pontuacao[item].forEach((item2, key) => {

                    pontuacao[item][key].editing = true;
                });
            }
        });

        this.setState({ pontuacao, editingPontuacao: true });
    }

    renderAddCriterio() {

        return (

            this.state.editingCriterio ?

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DefaultButton textColor={Colors.dark} color={Colors.secondaryButton} title={''} leftIcon={<FontAwesomeIcon icon={faClose} />} loading={this.state.loading} onClick={() => { this.handleCancelAdd() }} style={{ marginLeft: 8 }} />
                    <div style={{ marginTop: 8 }} />
                    <DefaultButton title={''} color={Colors.success} leftIcon={<FontAwesomeIcon icon={faCheck} />} loading={this.state.loading} onClick={() => { this.save() }} style={{ marginLeft: 8 }} />
                </div>

                :

                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                    <DefaultButton color={Colors.success} disabled={this.state.editingPontuacao} title={''} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} loading={this.state.loading} onClick={() => { this.handleAddCriterio() }} />
                    <DefaultButton color={Colors.info} disabled={this.state.editingPontuacao} title={''} leftIcon={<FontAwesomeIcon icon={faEdit} />} loading={this.state.loading} onClick={() => { this.handleEditCriterio() }} style={{ marginTop: 8 }} />
                </div>
        )
    }

    renderAddPontuacao() {

        return (

            this.state.editingPontuacao ?

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DefaultButton textColor={Colors.dark} color={Colors.secondaryButton} title={''} leftIcon={<FontAwesomeIcon icon={faClose} />} loading={this.state.loading} onClick={() => { this.handleCancelAdd() }} style={{ marginRight: 8 }} />
                    <div style={{ marginTop: 8 }} />
                    <DefaultButton color={Colors.success} title={''} leftIcon={<FontAwesomeIcon icon={faCheck} />} loading={this.state.loading} onClick={() => { this.save() }} style={{ marginRight: 8 }} />
                </div>

                :
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DefaultButton color={Colors.success} disabled={this.state.editingCriterio} title={''} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} loading={this.state.loading} onClick={() => { this.handleAddPontuacao() }} />
                    <DefaultButton color={Colors.info} disabled={this.state.editingCriterio} title={''} leftIcon={<FontAwesomeIcon icon={faEdit} />} loading={this.state.loading} onClick={() => { this.handleEditPontuacao() }} style={{ marginLeft: 8 }} />
                </div>
        )
    }

    renderTable() {

        return (

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginBottom: 8 }}>
                    <table className="gut-matrix">
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'left', backgroundColor: 'grey', color: 'white' }}>Critério</th>
                                {this.renderCriterios()}
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left', backgroundColor: 'grey', color: 'white' }}>Conceito</th>
                                {this.renderConcept()}
                            </tr>
                            <tr>
                                <th colSpan={this.state.criterios.length + 1} style={{ textAlign: 'center', backgroundColor: 'grey', color: 'white' }}>Pontuação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderPontuacao()}
                        </tbody>
                    </table>
                    {this.renderAddCriterio()}
                </div>
                {this.renderAddPontuacao()}
            </div>
        )
    }

    render() {

        if (this.state.loading) return <LoadingPage />

        return (
            <div style={{ minWidth: '100vh', minHeight: '100vh', padding: 10 }}>
                {this.renderHeader()}
                {this.renderTable()}
            </div>
        );
    }
}
