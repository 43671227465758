export default class ChartHelper {
    static getHeight(percentage = 100) {
        if(percentage <= 0) return 0;
        
        return (window.innerHeight * (percentage / 100)) - this.getHeaderHeight();
    }

    static getWidth(percentage = 100) {
        if(percentage <= 0) return 0;

        return (window.innerWidth * (percentage / 100));
    }

    static getHeaderHeight() {
        return 65;
    }

    static getColor(key) {
        switch(key) {
            case 'Não Iniciado':
            case 'Não Iniciada':
                return 'rgb(149,206,255)';
            case 'Em Desenvolvimento':
                return '#6c757d';
            case 'Concluído':
            case 'Concluída':
                return '#28a745';
            case 'Atrasado':
            case 'Atrasada':
                return '#dc3545';
            case 'Cancelado':
            case 'Cancelada':
                return '#ffc107';
            default:
                return '#007bff';
        }
    }
}