import React from "react";

import Colors from "../../../../../constants/Colors";
import EssentialStyle from "../../../../../style/EssentialStyle";
import DefaultLoader from "../../../../tools/DefaultLoader";
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";

export default class OrcamentosPlanoAcao extends React.Component {

    state = {
        data: null,
        id_colaborador: this.props.id_colaborador,
        loading: true,
        transform: 'translateX(+100%)',
        opacity: 0,
        expanded: this.props.expandedPageOne,
        etapas: this.props.etapas,
        isSmallScreen: this.props.isSmallScreen,
    }

    componentDidMount() {
        if(this.state.etapas.length > 0) {   
            this.calculaGrafico();
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    calculaGrafico(){
        let series = [];
        let type = 'column';
        let moeda = 'R$';
        let yAxis = [];
        yAxis = [
            {
                labels: {
                    formatter: function () {
                        return moeda + " " + this.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                    },
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: "Valor",
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
            }];

        series.push({
            name: 'Previsto',
            type: type,
            innerSize: '65%',
            yAxis: 0,
            data: this.state.etapas.map((key) => ({
                y: parseFloat(key.orcamento_previsto),
                yFormated: moeda + " " + key.orcamento_previsto.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
            })),
            color: Colors.graphics[0]
        })
        series.push({
            name: 'Realizado',
            type: type,
            innerSize: '65%',
            yAxis: 0,
            data: this.state.etapas.map((key) => ({
                y: parseFloat(key.orcamento_realizado),
                yFormated: moeda + " " + key.orcamento_realizado.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
            })),
            color: Colors.graphics[1]
        })

        this.setState({ data: series , yAxis, loading: false});
    }

    renderLoading(bloco) {
        const trackColors = Highcharts.getOptions().colors.map(color =>
            new Highcharts.Color(color).setOpacity(0.3).get()
        );

        if (bloco === 2) {
            return (
                <div style={{...EssentialStyle.columnStart, width: '100%', overflow: 'hidden'}}>
                    {Array.from({ length: this.props.numberSubordinados }).map((elem, index) => (
                        <div key={`plhdr-${index}`} style={{ marginTop: 5, width: '100%' }}>
                            {this.renderLoadingPlaceholder()}
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                    <DefaultLoader size={50} />
                </div>
            );
        }
    }

    renderGrafico() {

        return(
            <div style={{
                width: "100%",
                height: "100%", 
            }}>
                { this.state.data == null ? 
                    <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                        <div style={{...EssentialStyle.columnCenter, width: '100%', height: '100%'}}>
                            Nenhum valor disponível
                        </div>
                    </div>
                :
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'chart'}
                        containerProps={{ style: { height: "100%", width: "100%" } }}
                        options={{
                        chart: {
                            type: 'column',
                            backgroundColor: 'transparent',
                            spacing: [35, 0, 0, 0],
                        },
                        title: {
                            text: '',
                            y: 9,
                        },
                        xAxis: {
                            categories: this.state.etapas.map((_, key) => (key+1)),
                            accessibility: {
                                description: 'Orçamentos por etapa do plano de ação'
                            }
                        },
                        yAxis: this.state.yAxis,
                        legend: {
                            enabled: true,
                            verticalAlign: 'top',
                            layout: 'horizontal',
                            y: -40,
                            floating: true
                        },
                        tooltip: {
                            shared: true,
                            useHTML: true,
                            headerFormat: '<table>',
                            pointFormat: '<tr><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yFormated}</b></tr><br/>',
                            footerFormat: '</table>',
                        },
                        plotOptions: {
                            spline: {
                                marker: {
                                    radius: 4,
                                },
                                lineWidth: 2,
                                dashStyle: 'none'
                            }
                        },
                        credits: {
                            enabled: false
                        },
                        series: this.state.data
                        }}
                    />
                }
            </div>
        )
    }

    renderContent() {
        
        return (
            <div style={{...EssentialStyle.rowFlexStart, width: '100%', height: '100%'}}>
                <div style={{ display: 'flex', flexDirection: "column", overflow: "hidden", padding: "10px 10px 10px 10px",
                    height: `${this.props.expandedPageOne === "MeusPlanosAcao" ? "95%" : "100%" }`, width:`${this.props.expandedPageOne === "MeusPlanosAcao" ? "95%" : "100%" }`
                }}>
                    {this.renderGrafico()}
                </div>
            </div>
        )
    }

    render() {
        if(this.state.expanded !== this.props.expandedPageOne)
            this.setState({ expanded: this.props.expandedPageOne });

        return (
            <div style={{ ...this.props.animations, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "100%", height: "100%" }}>
                {this.state.loading ? this.renderLoading(1) : this.renderContent()}
            </div>
        )
    }
}
