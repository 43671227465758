import { Component } from "react"
import Colors from "../../constants/Colors";
import { Spinner } from 'react-bootstrap'
import EssentialStyle from "../../style/EssentialStyle";
import SessionHelper from "../../helper/SessionHelper";

export default class DefaultLoader extends Component {
    render() {
        return (
            <div style={{ ...EssentialStyle.rowSpaceEvenly, color: this.props.color ? this.props.color : SessionHelper.getColor() }}>
                <Spinner animation="border" role="status" style={{ height: this.props.size || 32, width: this.props.size || 32, marginRight: this.props.title ? 8 : 0 }}>
                    <span className="visually-hidden">Carregando...</span>
                </Spinner>

                {this.props.title ? this.props.title : null}
            </div>
        );
    }
}