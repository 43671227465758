import Colors from "../constants/Colors";
import SessionHelper from "../helper/SessionHelper";

export default {
    columnStart: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start'
    },
    columnEnd: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        alignContent: 'flex-end'
    },
    columnSpaceEvenly: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        alignContent: 'center'
    },
    columnCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    columnSpaceBetween: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center'
    },
    columnSpaceAround: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        alignContent: 'center'
    },
    rowFlexStart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center'
    },
    rowFlexEnd: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignContent: 'center'
    },
    rowFlexCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    rowReverseFlexStart: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center'
    },
    rowSpaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center'
    },
    rowSpaceAround: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        alignContent: 'center'
    },
    rowSpaceEvenly: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        alignContent: 'center'
    },
    primaryButton: {
        color: Colors.light,
        backgroundColor: SessionHelper.getColor(),
        borderColor: SessionHelper.getColor()
    },
    secondaryButton: {
        color: Colors.dark,
        backgroundColor: Colors.secondaryButton,
        borderColor: Colors.secondaryButton
    },
    card: {
        backgroundColor: Colors.white,
        boxShadow: Colors.boxShadow,
        borderRadius: 8,
        border: `0.3px solid ${Colors.lightGrey}`
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: Colors.homePage.lightGrey, 
        marginBottom: 0,
        paddingBottom: 10,
        width: '100%',
    },
    titleBoxHomePage: {
        color: Colors.homePage.title,
        fontSize: 16,
        fontWeight: 400,
    }
}