import React from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from "../../../../../style/EssentialStyle";
import PlanoAcaoCardRelatorio from "../PlanoAcaoCardRelatorio";
import PlanoAcaoEtapaCard from "../../PlanoAcaoEtapaCard/PlanoAcaoEtapaCard";
import DefaultLoader from "../../../../tools/DefaultLoader";

export default class PlanoAcaoRelatorioLista extends React.Component {

    render() {
        if(this.props.loading){
            return (
                <div style={{...EssentialStyle.columnCenter, width: '100%', height: '20vh'}}>
                    <DefaultLoader/>
                </div>
            )
        }

        if(this.props.view === 'planos') {
            if(!this.props.elements || this.props.elements.length === 0) return null;
            return (
                <div style={{...EssentialStyle.columnStart, width: '100%', gap: 5, maxHeight: "100%", flex: 1}}>
                    {this.props.elements.map((plano, index) => {
                        return (
                            <PlanoAcaoCardRelatorio key={plano.id} plano={plano} onClick={(codigo) => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${codigo}` }, '*')} isSmallScreen={this.props.isSmallScreen} noMargin/>
                        )
                    })}
                </div>
            )
        }
        if(this.props.view === 'etapas') {
            if(!this.props.elements || this.props.elements.length === 0) return null;
            return (
                <div style={{...EssentialStyle.columnStart, width: '100%', gap: 5, maxHeight: "100%", flex: 1}}>
                    {this.props.elements.map((etapa, index) => {
                        return (
                            <PlanoAcaoEtapaCard key={"etapa-"+index} softBorders etapa={etapa} resumed={this.props.isSmallScreen} participantes={[etapa.colaborador]} onClick={() => console.log(etapa.id)} noEdit/>
                        )
                    })}
                </div>
            )
        }
    }
}