import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faFile, faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import SessionHelper from "../../../../helper/SessionHelper";
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import SwotHelper from "../../../../helper/pe/SwotHelper";
import SelectVisaoTemplate from "../../../modules/pe/visao/SelectVisaoTemplate";
import Sig from "../../../../api/Sig";

export default class AddVisao extends React.Component {
    state = {
        step: 0,
        loading: true,
        colabs: [],
        name: '',
        responsable: null,
        participants: [],
        description: '',
        templateImage: null,
        templateId: null,
        newVisaoId: null,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        const colabs = await Sig.request('GET', 'config/colaborador/getColaboradores');

        this.setState({ colabs }, () => { this.setState({ loading: false }) });
    }

    addNew = async () => {
        if (!this.state.name || !this.state.responsable || !this.state.participants || this.state.participants.length < 1) return toast.warn('Informe todos os campos obrigatórios (*)');
        if (!this.state.templateImage) return toast.warn('Selecione o Template!');

        this.setState({ loading: true });

        let req = await Sig.request(
            'POST',
            'pe/visao/add',
            {
                nome: this.state.name,
                id_responsavel: this.state.responsable,
                template: this.state.templateImage,
                id_template: this.state.templateId,
                colaboradores: this.state.participants.map((p) => (p.value))
            }
        );

        if (req?.visao) {
            this.setState({ newVisaoId: req.visao.id }, this.step(req.visao.id));
            toast.success('Visão inserida com sucesso!');
        }

        this.setState({ loading: false });
    }

    renderForm() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form className="w-75">
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome da visão de futuro*</Form.Label>
                            <Form.Control type="text" placeholder="Visão X..." onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Responsável*</Form.Label>
                            <Select options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione o responsável'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable onChange={(value) => { this.setState({ responsable: value ? value.value : null }) }} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Participantes*</Form.Label>
                            <Select 
                            isDisabled={this.state.responsable ? false : true}                                
                            options={DataHelper.removeColaborador(DataHelper.formatSelectData(this.state.colabs, 'id', 'nome'), this.state.responsable)} placeholder={'Selecione os participantes'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable isMulti onChange={(value) => { this.setState({ participants: value }) }} />
                        </Form.Group>
                    </Row>

                    <SelectVisaoTemplate onTemplateSelect={(id, image) => { this.setState({ templateImage: image, templateId: id }) }}/>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} title={'Adicionar'} loading={this.state.loading} onClick={this.addNew} />
                        </Form.Group>
                    </div>
                </Form>

            </div>
        );
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.step() }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    step(id = 0) {
        this.setState({ loading: true });
        this.props.addCallback(id);
        this.setState({ loading: false });
    }


    renderSteps() {
        let stepContent;

        stepContent = this.renderForm();

        return stepContent;
    }

    render() {
        return (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderStepBack()}
                {this.renderSteps()}
            </div>
        );
    }
}