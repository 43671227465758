import { faTimesCircle, faInfinity } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import Colors from "../../constants/Colors";
import DefaultButton from "./DefaultButton";
import { TwitterPicker } from 'react-color';
import DefaultLoader from "./DefaultLoader";

export default class BarraDemonstracao extends React.Component {
    state = {
        infinito: false,
    }

    async componentDidMount() {

    }

    renderLoading(){
        return (
            <div style={{ height: 45, border: '0.3px solid #cecbcb', borderRadius: 5, width: this.props.width, display: 'flex', overflow: 'hidden', marginTop: 20 }}>
                <DefaultLoader/>
            </div>
        )
    }

    render() {
        if (this.props.loading) return this.renderLoading();
        let sortedIntervals = this.props.intervals.sort((a, b) => a.from - b.from);
        return this.props.intervals == null ? null : (
           <div style={{ height: 45, border: '0.3px solid #cecbcb', borderRadius: 5, width: this.props.width, display: 'flex', overflow: 'hidden', marginTop: this.props.marginTop ? this.props.marginTop : 20 }}>
                { sortedIntervals.map((interval, index) => {
                    let width = interval.to - interval.from;
                    return (
                        <div style={{ width: `${interval.infinito == 1 ? '50' : width}%`, minWidth: interval.from < 100 ? 40 : 45, backgroundColor: interval.color, color: 'white', display: 'flex', justifyContent: 'left', alignItems: 'center', paddingLeft: 10 }}>
                            <span>{interval.from} </span> {interval.infinito == 1 ? <span style={{ marginLeft: 3 }}> ao <FontAwesomeIcon icon={faInfinity} /></span> : ''}
                        </div>
                    );
                })}
           </div>
        );
    }
}