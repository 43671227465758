import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faFile, faChevronLeft, faCheck, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import SessionHelper from "../../../../helper/SessionHelper";
import DataHelper from "../../../../helper/DataHelper";
import AddTag from "../../../tools/AddTag";
import { toast } from "react-toastify";
import LoadingPage from "../../../../pages/LoadingPage";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../tools/CustomConfirm";
import DefaultButton from "../../../tools/DefaultButton";
import Sig from "../../../../api/Sig";

export default class EditSwot extends React.Component {
    state = {
        loading: true,
        colabs: [],
        name: '',
        tags: [],
        responsable: null,
        participants: [],
        description: '',
        previousData: {}
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        const swot = await Sig.request('GET', 'pe/swot/getSwot', { id: this.props.idSwot });
        const colabs = await Sig.request('GET', 'config/colaborador/getColaboradores');
        const tags = swot.tags;
        const participants = swot.participantes;

        if (swot?.swot) {
            if (swot?.swot?.nome) this.setState({ name: swot.swot.nome });
            if (swot?.swot?.descricao) this.setState({ description: swot.swot.descricao });

            if (colabs?.length) {
                let selectedParticipantsIds = participants.map(p => (p.id));
                let selectedParticipants = [];
                let responsable = null;

                colabs.forEach(participant => {
                    if (selectedParticipantsIds.includes(participant.id)) {
                        selectedParticipants.push(participant);
                    }
                    if (swot.swot.id_responsavel === participant.id) {
                        responsable = DataHelper.formatSelectData([participant], 'id', 'nome')[0];
                    }
                });

                this.setState({ responsable, participants: DataHelper.formatSelectData(selectedParticipants, 'id', 'nome') });
            }
        }

        this.setState({ colabs, tags }, () => { this.setState({ loading: false }) });
    }

    edit = async () => {
        if (!this.state.name || !this.state.responsable) return toast.warn('Informe todos os campos obrigatórios (*)');

        this.setState({ loading: true });

        
        await Sig.request('POST', 'pe/swot/update', {
            id: this.props.idSwot,
            nome: this.state.name,
            descricao: this.state.description,
            id_responsavel: this.state.responsable.value,
            tags: this.state.tags.map(t => (t.value)),
            participantes: this.state.participants.map(p => (p.value))
        });

        toast.success('Editado com sucesso');

        await this.loadData();
    }

    remove = async () => {
        this.setState({ loading: true });

        try {
            await Sig.request('POST', 'pe/swot/delete', { id: this.props.idSwot });
        } catch (e) {
            toast.error('Erro ao remover');
        }

        if (this.props.deleteCallback) this.props.deleteCallback();
    }

    renderForm() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form className="w-75">
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome da Análise SWOT*</Form.Label>
                            <Form.Control value={this.state.name} type="text" placeholder="Análise SWOT X..." onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>

                        <AddTag selected={this.state.tags} onChange={(value) => { this.setState({ tags: value }) }} />
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Responsável pela Análise*</Form.Label>
                            <Select value={this.state.responsable} options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione o responsável'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable onChange={(value) => { this.setState({ responsable: value }) }} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Participantes*</Form.Label>
                            <Select value={this.state.participants} options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione os participantes'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable isMulti onChange={(value) => { this.setState({ participants: value }) }} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control value={this.state.description} as="textarea" placeholder="Descrição da Análise SWOT" onChange={(event) => { this.setState({ description: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTrash} />} title={'Excluir'} loading={this.state.loading} onClick={() => {
                                confirmAlert({
                                    customUI: ({ onClose }) => (
                                        <CustomConfirm
                                            title={'Excluir este SWOT?'}
                                            message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação não pode ser desfeita</p>}
                                            buttons={[
                                                {
                                                    label: 'Remover',
                                                    color: SessionHelper.getColor(),
                                                    textColor: Colors.light,
                                                    onClick: () => { this.remove(); onClose(); }
                                                },
                                                {
                                                    label: 'Cancelar',
                                                    onClick: () => { onClose(); }
                                                },
                                            ]}
                                        />
                                    )
                                });
                            }} />
                            <DefaultButton className="ms-1" color={Colors.success} leftIcon={<FontAwesomeIcon icon={faSave} />} title={'Salvar Alterações'} loading={this.state.loading} onClick={this.edit} />
                        </Form.Group>
                    </div>
                </Form>

            </div>
        );
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.props.settingsCallback() }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    render() {
        return this.state.loading ? <LoadingPage /> : (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderStepBack()}
                {this.renderForm()}
            </div>
        );
    }
}