import React, { Component } from 'react';
import Colors from '../../constants/Colors';
import Spinner from 'react-bootstrap/Spinner';
import SessionHelper from '../../helper/SessionHelper';

export default class LoadingComponent extends Component {

    render() {
        return (
            <div style={{ color: SessionHelper.getColor() }}>
                <Spinner size='sm' animation="border" role="status" style={{}}>
                </Spinner>
            </div>
        );
    }
}
