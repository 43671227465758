import React from "react";
import EssentialStyle from "../../style/EssentialStyle";
import DiagnosticoHelper from "../../helper/diagnostico/DiagnosticoHelper";
import DataHelper from "../../helper/DataHelper";
import LayoutHelper from "../../helper/LayoutHelper";
import LoadingPage from "../LoadingPage";
import Colors from "../../constants/Colors";

import Select from 'react-select';
import { Form } from "react-bootstrap";
import DefaultButton from "../../components/tools/DefaultButton";

// import Resultados from "../../components/modules/diagnostico/Resultados/Resultados";
import './DiagnosticoResultadoPage.css';
import ListTiposResultados from "../../components/modules/diagnostico/Resultados/ListTiposResultados/ListTiposResultados";
import GraficoResultado from "../../components/modules/diagnostico/Resultados/GraficoResultado/GraficoResultado";
export default class DiagnosticoResultadoPage extends React.Component {

    state = {
        construction: false,
        loading: true,

        periodos: [], 
        idPeriodoAvaliacao: null,
        isEmpresaAvaliadora: false,
        idProcesso: null,

        linguagens: [
            { value: 1, label: 'Português', locale: 'pt' },
            { value: 2, label: 'English', locale: 'en' },
            { value: 3, label: 'Español', locale: 'es' },
        ],
        lang: 1,

        tipoGrafico: 1,

        isSmallScreen: LayoutHelper.isSmallScreen(),
    }

    async componentDidMount() {
        this.handleUrlParams();
        window.addEventListener('resize', this.verifyScreen);
        window.addEventListener('message', this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        window.removeEventListener('message', this.handleMessage);
    }

    handleUrlParams = () => {
        window.parent.postMessage({ type: 'get_url' }, '*');

        const listenerFunction = async (event) => {
            if (event.data.type === 'return_url') {
                const urlObj = new URL(event.data.url);
                const queryParams = new URLSearchParams(urlObj.search);
                window.removeEventListener('message', listenerFunction);

                // Extract the last segment from the pathname
                const pathSegments = urlObj.pathname.split('/');
                const lastSegment = pathSegments[pathSegments.length - 1];

                this.setState({ idProcesso: parseInt(lastSegment) }, () => {
                    this.loadData();
                });
            }
        };

        window.addEventListener('message', listenerFunction);
    }

    handleMessage = (event) => {
        if (event.data.type === 'popstate') {
            if (event.data.url === this.state.lastPopState) {
                this.setState({ showDetailsModal: null, lastPopState: null });
                return;
            }

            this.setState({ lastPopState: event.data.url });
            this.handleUrlParams();
        }
    }

    verifyScreen = () => {
        this.setState({ isSmallScreen: LayoutHelper.isSmallScreen() });
    }

    async loadData() {
        this.setState({ loading: true });

        let periodos = await DiagnosticoHelper.getPeriodosAvaliacao(this.state.idProcesso);
        let idPeriodoAvaliacao = periodos.length > 0 ? periodos[0].id : null;
        let isEmpresaAvaliadora = await DiagnosticoHelper.isEmpresaAvaliadora(this.state.idProcesso);

        this.setState({ periodos, idPeriodoAvaliacao, isEmpresaAvaliadora, loading: false });
    }

    onSelectTipo(tipo) {
        this.setState({ tipoGrafico: tipo });
    }

    renderPage() {
        return (
            <div className="resultadoPage">
                {this.renderHeader()}
                {this.state.idPeriodoAvaliacao ? this.renderBody() : DiagnosticoHelper.renderPeriodoNotSelected(this.state.lang)}
            </div>
        );
    }

    renderHeader() {

        let localStyle = {
            boxShadow: Colors.boxShadow,
            ...(this.state.isSmallScreen ? EssentialStyle.columnCenter : EssentialStyle.rowSpaceBetween)
        }

        return (
            <div className="header" style={localStyle}>
                <div className="left" style={{ width: this.state.isSmallScreen ? '100%' : '' }}>
                    <Form.Group>
                        <Select
                            styles={{ control: (base) => ({ ...base, border: 'none', minWidth: '40vh', marginRight: 2 }) }}
                            options={DataHelper.formatSelectData(this.state.periodos, 'id', 'nome')}
                            value={DataHelper.formatSelectData(this.state.periodos, 'id', 'nome').find(option => option.value === this.state.idPeriodoAvaliacao)}
                            onChange={(valueSelected) => { this.setState({ idPeriodoAvaliacao: valueSelected.value }) }}
                            placeholder={'Selecione um Período de Avaliação'}
                            noOptionsMessage={DataHelper.getSelectEmptyMessage}
                            isSearchable
                            menuPortalTarget={document.body}
                        />
                    </Form.Group>

                    <Select
                        styles={{ control: (base) => ({ ...base, border: 'none', width: '9rem' }) }}
                        options={this.state.linguagens}
                        value={this.state.linguagens.find(option => option.value === this.state.lang)}
                        onChange={(valueSelected) => { this.setState({ lang: valueSelected.value }) }}
                        noOptionsMessage={DataHelper.getSelectEmptyMessage}
                        isSearchable
                        menuPortalTarget={document.body}
                    />

                </div>

                <div className="right" style={{ width: this.state.isSmallScreen ? '100%' : '' }}>

                    {/* {this.renderTabs('activeTabRelatorio', 'tabsRelatorios')} */}

                    <DefaultButton
                        style={{ width: 38, height: 38, marginLeft: 8 }}
                        fontSize={14}
                        color={Colors.secondaryButton}
                        textColor={Colors.dark}
                        link={"https://advis.freshdesk.com/support/solutions"} />
                </div>
            </div>
        )
    }

    renderBody() {

        if (this.state.idPeriodoAvaliacao === null) return (<div className="body">{DiagnosticoHelper.renderPeriodoNotSelected(this.state.lang)}</div>);

        return (
            <div className="body">
                <div className="listTiposBody">
                    <ListTiposResultados lang={this.state.lang} onSelectTipo={(tipo) => {this.onSelectTipo(tipo)}} selected={this.state.tipoGrafico}/>
                </div>
                
                <div className="graficosBody">
                    <GraficoResultado idPeriodoAvaliacao={this.state.idPeriodoAvaliacao} tipoGrafico={this.state.tipoGrafico} lang={this.state.lang} isSmallScreen={this.state.isSmallScreen} />
                </div>
            </div>
        )
    }

    render() {
        if (this.state.construction) return DiagnosticoHelper.renderConstruction();
        if (this.state.loading) return <LoadingPage />;
        if (this.state.idProcesso === null) return <div style={EssentialStyle.pageContainer}>Erro ao carregar página</div>; // Enviar para página de "Ver todos os processos"
        return this.renderPage();
    }
}