import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward, faBackward, faChevronUp, faChevronDown, faEquals, faClock, faEye } from "@fortawesome/free-solid-svg-icons";

import Select, { components } from 'react-select';

import Colors from "../../../../constants/Colors";
import EssentialStyle from "../../../../style/EssentialStyle";
import DefaultLoader from "../../../tools/DefaultLoader";
import DefaultButton from "../../../tools/DefaultButton";
import CustomTooltip from "../../../tools/CustomTooltip";
import ChartHelper from "../../../../helper/inicio/ChartHelper";
import Sig from "../../../../api/Sig";
import DataHelper from "../../../../helper/DataHelper";
import { Collapse } from "react-bootstrap";

export default class AtividadeCard extends React.Component {
    state = {
        loading: false,
        selectOpen: false,
        atividade: this.props.atividade,
        viewCard: this.props.viewCard,
    }

    renderCard() {

        let url = this.state.atividade.url;

        return (
                <div 
                    key={this.state.atividade.id+"-my-atv-card"} 
                    style={{
                        width: '100%',
                        cursor: 'pointer', 
                        borderRadius: 5,
                        transition: 'background-color 0.5s ease',
                        borderBottom: `1px solid ${Colors.homePage.line}`,
                        backgroundColor: this.state.atividade.id == this.props.viewCard ? Colors.homePage.extraLightGrey : 'transparent',
                    }}
                    onClick={() => {this.props.setViewCardCallback(this.props.viewCard == this.state.atividade.id ? null : this.state.atividade.id)}}
                >
                    <div
                        style={{
                            ...EssentialStyle.rowSpaceBetween,
                            width: '100%',
                            height: 50,
                            padding: 5,
                            color: Colors.dark,
                            fontSize: 16,
                            fontWeight: 'bold'
                        }}
                    >
                        <div
                            style={{
                                ...EssentialStyle.rowFlexStart,
                                width: `calc(100% - ${this.state.selectOpen ? `270` : `170`}px)`,
                            }}
                        >
                            {this.renderStatusChangingButtons()}
                            {this.renderTitle()}
                        </div>
                        <div
                            style={{
                                ...EssentialStyle.rowSpaceBetween,
                                width: this.state.selectOpen ? 270 : 170,
                            }}
                        >
                            {this.renderSelectPrioridade()}
                            {this.renderPrazoStatus()}
                            {this.renderPrazo()}
                        </div>
                    </div>
                    <Collapse in={this.state.atividade.id == this.props.viewCard}>
                        <div style={{ transition: "height 0.5s ease" }}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <div style={{color: Colors.homePage.grey, marginLeft: "15px", fontSize: 14}}>
                                        Atividade
                                    </div>
                                    <div style={{marginLeft: "10px", marginRight: "10px", height: "12px", flexGrow: 1, borderBottom: `0.3px solid ${Colors.homePage.grey}`}}>
                                    </div>
                                    <div style={{width: "5%", marginRight: "10px", maxWidth: "35px", display: "flex", justifyContent: "space-arround", alignItems: "center", justifyContent: "center"}}>
                                        <DefaultButton
                                            color={Colors.dark}
                                            tooltip={"Ir para Atividade"}
                                            tooltipPlacement={"top"}
                                            leftIcon={<FontAwesomeIcon icon={faEye} size="xs"/>}
                                            style={{ width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, margin: 0}}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                window.parent.postMessage({ type: '_blank', url: url }, '*');
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{display: "flex", margin: "5px 20px 10px 15px"}}>
                                    <div style={{color: Colors.homePage.darkGrey, fontSize: 14, fontWeight: "400"}}>
                                        <span dangerouslySetInnerHTML={{ __html: this.state.atividade.descricao }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
            );
    }

    toggleSelect = () => {
        this.setState({ selectOpen: !this.state.selectOpen });
    }

    renderTitle() {
        let title = this.state.atividade.descricao;

        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    marginLeft: 10,
                    fontSize: 16,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    display: 'inline-block',
                    maxHeight: 40
                }}>
                <span>{new DOMParser().parseFromString(title, 'text/html').body.textContent || ''}</span>
            </div>
        );
    }

    renderPrazoStatus() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    fontSize: 12,
                    backgroundColor: this.state.atividade.atrasada ? Colors.error : ChartHelper.getColor("Concluída"),
                    borderRadius: 5,
                    color: 'white',
                    textAlign: 'center',
                    width: 20,
                    height: 20
                }}
            >
                {
                    this.state.atividade.atrasada ? 
                    <CustomTooltip tooltip={"Atrasado"}>
                        <FontAwesomeIcon icon={faClock} style={{ fontSize: 12 }} />
                    </CustomTooltip>
                    :
                    <CustomTooltip tooltip={"Dentro do Prazo"}>
                        <FontAwesomeIcon icon={faClock} style={{ fontSize: 12 }} />
                    </CustomTooltip>
                }
            </div>
        )
    }

    renderPrazo() {
        return (
            <div
                style={{
                    ...EssentialStyle.columnCenter,
                    fontSize: 14,
                    backgroundColor: ChartHelper.getColor(this.state.atividade.status),
                    borderRadius: 5,
                    color: Colors.background,
                    textAlign: 'center',
                    width: 90,
                    height: 20,
                }}
            >
                {this.state.atividade.prazo}
            </div>
        )
    }

    renderSelectPrioridade() {
        const DropdownIndicator = (props) => {
            const { selectProps } = props;
            const isMenuOpen = selectProps.menuIsOpen;

            if(isMenuOpen) return null;

            return (
                <components.DropdownIndicator {...props}>
                    {this.getPrioridadeIcon()[this.state.atividade.prioridade]}
                </components.DropdownIndicator>
            );
        };

        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart,
                    fontSize: 14
                }}
                onClick={(e) => { e.stopPropagation()}}
            >
                <Select
                    ref={ref => this.selectPrioridade = ref}
                    menuIsOpen={this.props.canEdit ? undefined : false}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            ...EssentialStyle.titleBoxHomePage,
                            border: 'none',
                            width: state.isFocused ? '140px' : '47px',
                            boxShadow: state.isFocused ? '0 0 0 1px blue' : null,
                            backgroundColor: "transparent",
                        })
                    }}
                    value={this.state.atividade.prioridade ? { value: this.state.atividade.prioridade, label: this.state.atividade.prioridade } : null}
                    options={[
                        { value: 'Urgente', label: 'Urgente' },
                        { value: 'Alta', label: 'Alta' },
                        { value: 'Média', label: 'Média' },
                        { value: 'Baixa', label: 'Baixa' },
                    ]}
                    formatOptionLabel={({ label }) => (
                        <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 8 }}>
                            <div style={{ marginRight: 8 }}>{this.getPrioridadeIcon()[label]}</div> {label}
                        </div>
                    )}
                    placeholder={''}
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    isSearchable={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    onChange={(value) => {
                        if(this.props.canEdit === false) return;

                        this.setState({
                            atividade: {
                                ...this.state.atividade,
                                prioridade: value.value
                            }
                        }, () => {
                            if (this.selectPrioridade) this.selectPrioridade.blur();
                            this.updatePrioridade();
                        });
                    }}
                />

            </div>
        );
    }

    updatePrioridade = async () => {
        await Sig.request('POST', 'inicio/updateAtividadePrioridade', {
            id: this.state.atividade.id,
            model: this.state.atividade.model,
            prioridade: this.state.atividade.prioridade
        });
    }

    getPrioridadeIcon() {
        return {
            'Baixa': <FontAwesomeIcon icon={faChevronDown} style={{ color: Colors.homePage.atividades.baixa }} />,
            'Média': <FontAwesomeIcon icon={faEquals} style={{ color: Colors.homePage.atividades.media }} />,
            'Alta': <FontAwesomeIcon icon={faChevronUp} style={{ color: Colors.homePage.atividades.alta }} />,
            'Urgente': <div style={{ ...EssentialStyle.columnCenter, color: Colors.homePage.atividades.urgente }}><FontAwesomeIcon icon={faChevronUp} style={{ marginBottom: -8 }} /><FontAwesomeIcon icon={faChevronUp} /></div>
        }
    }

    updateStatus = async (forward = true) => {
        if(this.props.canEdit === false) return;
        
        this.setState({ loading: true });

        let { atividade } = await Sig.request('POST', 'inicio/updateAtividadeStatus', {
            id: this.state.atividade.id,
            model: this.state.atividade.model,
            avanca: forward ? 1 : 0
        });

        this.props.updateCallback(true);

        this.setState({ atividade, loading: false });
    }

    renderStatusChangingButtons() {
        return (
            <div
                style={{
                    ...EssentialStyle.rowFlexStart
                }}
            >
                <DefaultButton
                    style={{ marginRight: 5 }}
                    leftIcon={<FontAwesomeIcon icon={faBackward} />}
                    disabled={this.state.atividade?.status === 'Não Iniciada' || !this.props.canEdit}
                    color={Colors.error}
                    onClick={(e) => { 
                        e.stopPropagation();
                        this.updateStatus(false) 
                    }}
                />
                <DefaultButton
                    leftIcon={<FontAwesomeIcon icon={faForward} />}
                    disabled={this.state.atividade?.status === 'Concluída' || !this.props.canEdit}
                    color={Colors.success}
                    onClick={(e) => { 
                        e.stopPropagation();
                        this.updateStatus(true) 
                    }}
                />
            </div>
        );
    }

    renderLoading() {
        return (
            <div style={{
                ...EssentialStyle.columnCenter,
                width: '100%',
                height: 50,
                borderBottom: `1px solid ${Colors.homePage.line}`,
                padding: 5,
            }}>
                <DefaultLoader />
            </div>
        );
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderCard();
    }
}