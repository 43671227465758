export default class DragAndDropHelper {
    static checkTop(e, relativeDropPositionSmallerThan = 0.5) {
        const dropPosition = e.pageY;
        const elementHeight = e.target.offsetHeight;
        const relativeDropPosition = (dropPosition - e.target.offsetTop) / elementHeight;

        let top = false;

        if (relativeDropPosition < relativeDropPositionSmallerThan) {
            top = true;
        } else {
            top = false;
        }

        return top;
    }

    static checkTopTimeline(e, relativeDropPositionSmallerThan = 0.5, offset = 0) {
        const offsetTop = e.currentTarget.parentNode.parentNode.offsetTop + offset;
        const dropPosition = e.pageY;
        const elementHeight = e.currentTarget.parentNode.offsetHeight;
        const relativeDropPosition = (dropPosition - (e.currentTarget.parentNode.offsetTop + offsetTop)) / elementHeight;

        let top = false;

        if (relativeDropPosition < relativeDropPositionSmallerThan) {
            top = true;
        } else {
            top = false;
        }

        return top;
    }
}