import React from "react";

import DiagnosticoHelper from "../../../../helper/diagnostico/DiagnosticoHelper";
import DefaultLoader from "../../../tools/DefaultLoader";
import Colors from "../../../../constants/Colors";
import { toast } from 'react-toastify';
import UserAvatar from "../../../tools/UserAvatar";
import DefaultButton from "../../../tools/DefaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import './ListRelatorios.css';

export default class ListRelatorios extends React.Component {
    state = {
        loading: true,
        idPeriodoAvaliacao: this.props.idPeriodoAvaliacao,
        relatorios: [],
        countRelatorios: 0, // Alterar para o numero real quando tiver o uso de filtro
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.idPeriodoAvaliacao !== this.props.idPeriodoAvaliacao) {
            this.setState({ idPeriodoAvaliacao: this.props.idPeriodoAvaliacao }, () => {
                this.loadData();
            });
        }

        if (prevProps.status !== this.props.status) {
            await this.loadData();
        }
    }

    async loadData() {
        this.setState({ loading: true });
        await this.loadRelatorios();
        this.setState({ loading: false })
    }

    async loadRelatorios() {
        let paramsRelatorios = {
            idPeriodoAvaliacao: this.state.idPeriodoAvaliacao,
            status: this.props.status,
            relations: 1,
            lang: this.props.lang
        }

        if(this.props.isEmpresaAvaliadora) {
            paramsRelatorios.avaliador = true;
            paramsRelatorios.avaliado = false;
        } else {
            paramsRelatorios.avaliador = false;
            paramsRelatorios.avaliado = true;
        }

        let relatorios = await DiagnosticoHelper.getRelatorios(
            paramsRelatorios.idPeriodoAvaliacao, 
            paramsRelatorios.status, 
            paramsRelatorios.avaliador, 
            paramsRelatorios.avaliado, 
            paramsRelatorios.relations, 
            paramsRelatorios.lang
        );

        this.setState({ relatorios: relatorios });
    }

    renderLoading() {
        return (
            <div className="notice">
                <DefaultLoader />
            </div>
        )
    }

    renderEmpty() {
        let message = [
            {id: 1, text: 'Nenhum relatório encontrado'},
            {id: 2, text: 'No report found'},
            {id: 3, text: 'No se encontró ningún informe'}
        ]
        return (
            <div className="notice">
                {DiagnosticoHelper.renderEmpty(message.find(m => m.id == this.props.lang).text)}
            </div>
        )
    }

    renderEmptyFiltro() {
        return (
            <div className="notice">
                Nenhum relatório encontrado com os filtros selecionados.
            </div>
        )
    }

    renderList() {
        let disabled = (this.props.status == 0 && !this.props.isEmpresaAvaliadora);
        let message = [
            {id: 1, delete: 'Excluir'},
            {id: 2, delete: 'Delete'},
            {id: 3, delete: 'Eliminar'}
        ]

        return (
            <div className={"listRelatorios"}>
                {this.state.relatorios.map((relatorio) => (
                    <div 
                        key={relatorio.id} 
                        className={`relatorio ${disabled ? ` relatorio-disabled` : ``}`}
                        style={{boxShadow: Colors.boxShadow}}
                        onClick={() => {
                            if(disabled) return;
                            this.props.openRelatorio(relatorio.id);
                        }}
                    >
                        <div className="line">
                            <UserAvatar id={relatorio.id_colaborador} size={40} showName={this.state.expandedMinhaEquipe} placement={'right'}/>

                        </div>
                        <div className="line" style={{marginBottom: 0}}>
                            <span style={{paddingTop: 10}}>{relatorio.nome}</span>
                            { relatorio.canDelete &&
                                <DefaultButton
                                    title={message.find(m => m.id == this.props.lang).delete}
                                    leftIcon={<FontAwesomeIcon icon={faTimes} />}
                                    color={Colors.error}
                                    onClick={async (e) =>  {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (!await DiagnosticoHelper.deleteRelatorio(relatorio.id)) {
                                            toast.error("Erro ao excluir relatório - Error deleting report - Error al eliminar el informe");
                                        } else {
                                            await this.loadData();
                                        }
                                    }}
                                />
                            }
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    render() {
        if (this.state.loading) return this.renderLoading();
        if (this.state.relatorios.length == 0 && this.state.countRelatorios == 0) return this.renderEmpty();
        if (this.state.relatorios.length == 0) return this.renderEmptyFiltro();

        return this.renderList();
    }
}