import React, { Component } from 'react';
import DefaultButton from './DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faChevronDown, faClose, faTimes, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import Colors from '../../constants/Colors';
import LoadingPage from '../../pages/LoadingPage';
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import SessionHelper from '../../helper/SessionHelper';
import { Dropdown, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import DataHelper from '../../helper/DataHelper';
import SwotHelper from '../../helper/pe/SwotHelper';
import CustomConfirm from './CustomConfirm';
import Sig from '../../api/Sig';

export default class GutMatrixConfigFavorite extends Component {

    state = {
        loading: true,
        favorites: [],
        add: false,
        name: '',
        hoveredItem: null,
    }

    async componentDidMount() {

        await this.load()

        this.setState({ loading: false });
    }

    async load() {
        await this.getFavorites();
    }

    async getFavorites() {
        // procura as favoritas, idependente do controller
        let favorites = (await Sig.request('GET', 'pe/swot/getGutsFavoritos'))?.favoritos;
        this.setState({ favorites });
    }

    async save() {

        if (this.state.name) {

            try {

                this.setState({ loading: true });

                let idSwot = this.props.idSwot;
                let idPeMapa = this.props.idPeMapa;
                let projetos = this.props.projetos;

                if (!idSwot && !idPeMapa && !projetos) { throw new Error('Missing ID Param') };

                let req;
                if(idSwot) {
                    req = await Sig.request('POST', 'pe/swot/addGutFavorito', { id_swot: idSwot, nome: this.state.name });
                } else if(idPeMapa) {
                    req = await Sig.request('POST', 'pe/mapa/addGutFavorito', { id_pe_mapa: idPeMapa, nome: this.state.name });
                } else if(projetos) {
                    req = await Sig.request('POST', 'pe/projeto/addGutFavorito', { nome: this.state.name });
                }

                if(req.status != 200) {
                    throw new Error('Erro ao salvar favorito');
                }

                await this.load();
                this.setState({ loading: false, add: false, name: '' });
                toast.success('Favorito salvo com sucesso');

            } catch (error) {
                
                this.setState({ loading: false });
                toast.error('Houve um problema ao salvar');
            }

        } else {

            toast.warn('Informe o nome do favorito');
        }
    }

    async useFavorite(favorite) {
        
        try {

            this.setState({ loading: true });
            this.props.setLoading(true);

            let idSwot = this.props.idSwot;
            let idPeMapa = this.props.idPeMapa;
            let projetos = this.props.projetos;
            if (!idSwot && !idPeMapa && !projetos) { throw new Error('Missing ID') };

            let req;
            if(idSwot) {
                req = await Sig.request('POST', 'pe/swot/usarGutFavorito', { id_swot: idSwot, id_favorito: favorite.id });
            } else if(idPeMapa) {
                req = await Sig.request('POST', 'pe/mapa/usarGutFavorito', { id_pe_mapa: idPeMapa, id_favorito: favorite.id });
            } else if(projetos) {
                req = await Sig.request('POST', 'pe/projeto/usarGutFavorito', { id_favorito: favorite.id });
            }

            if(req.status != 200) {
                throw new Error('Erro ao usar o favorito');
            }

            this.props.onChange();
            this.props.setLoading(false);
            this.setState({ loading: false });

            toast.success('Matriz alterada com sucesso');

        } catch (error) {

            this.setState({ loading: false });
            this.props.setLoading(false);
            toast.error('Houve um problema ao alterar a Matriz');
        }
    }

    async backToDefault() {
        
        try {

            this.setState({ loading: true });
            this.props.setLoading(true);

            let idSwot = this.props.idSwot;
            let idPeMapa = this.props.idPeMapa;
            let projetos = this.props.projetos;
            if (!idSwot && !idPeMapa && !projetos) { throw new Error('Missing ID') };

            let req
            if(idSwot) {
                req = await Sig.request('POST', 'pe/swot/voltarGutPadrao', { id_swot: idSwot });
            } else if(idPeMapa) {
                req = await Sig.request('POST', 'pe/mapa/voltarGutPadrao', { id_pe_mapa: idPeMapa });
            } else if(projetos) {
                req = await Sig.request('POST', 'pe/projeto/voltarGutPadrao');
            }

            if(req.status != 200) {
                throw new Error('Erro ao resetar a Matriz');
            }

            this.props.onChange();
            this.props.setLoading(false);
            this.setState({ loading: false });

            toast.success('Matriz alterada com sucesso');

        } catch (error) {

            this.setState({ loading: false });
            this.props.setLoading(false);
            toast.error('Houve um problema ao resetar a Matriz');
        }
    }

    async remove(id) {
        
        try {

            this.setState({ loading: true });

            let idSwot = this.props.idSwot;
            let idPeMapa = this.props.idPeMapa;
            let projetos = this.props.projetos;
            if (!idSwot && !idPeMapa && !projetos) { throw new Error('Missing ID') };
            if (!id) { throw new Error('Missing ID') };

            let req
            if(idSwot){
                req = await Sig.request('POST', 'pe/swot/deleteGutFavorito', { id });
            } else if(idPeMapa) {
                req = await Sig.request('POST', 'pe/mapa/deleteGutFavorito', { id });
            } else if(projetos) {
                req = await Sig.request('POST', 'pe/projeto/deleteGutFavorito', { id });
            }

            if(req.status != 200) {
                throw new Error('Erro ao remover favorito');
            }

            await this.load();
            this.setState({ loading: false });

            toast.success('Favorito removido com sucesso');

        } catch (error) {

            this.setState({ loading: false });
            toast.error('Houve um problema ao remover o favorito');
        }
    }

    handleUseFavorite(favorite) {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Usar esse Favorito?'}
                    buttons={[
                        {
                            label: 'Sim',
                            color: Colors.success,
                            textColor: Colors.light,
                            onClick: () => { this.useFavorite(favorite); onClose(); }
                        },
                        {
                            label: 'Não',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    handleBackToDefault() {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Voltar ao Padrão?'}
                    buttons={[
                        {
                            label: 'Sim',
                            color: Colors.success,
                            textColor: Colors.light,
                            onClick: () => { this.backToDefault(); onClose(); }
                        },
                        {
                            label: 'Não',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    handleRemove(id) {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Remover Favorito?'}
                    buttons={[
                        {
                            label: 'Remover',
                            color: SessionHelper.getColor(),
                            textColor: Colors.light,
                            onClick: () => { this.remove(id); onClose(); }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    renderAdd() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Control placeholder={'Informe o nome...'} type="text" onBlur={(event) => { this.setState({ name: event.target.value }) }} />
                <DefaultButton textColor={Colors.dark} color={Colors.secondaryButton} title={''} leftIcon={<FontAwesomeIcon icon={faClose} />} loading={this.state.loading} onClick={() => { this.setState({ add: false, name: '' }) }} style={{ marginLeft: 8 }} />
                <div style={{ marginTop: 8 }}/>
                <DefaultButton title={''} color={Colors.success} leftIcon={<FontAwesomeIcon icon={faCheck} />} loading={this.state.loading} onClick={() => { this.save() }} style={{ marginLeft: 8 }} />
            </div>
        )
    }

    renderFavorites() {
        return (
            <Dropdown.Menu>
                { !this.state.favorites.length ? <Dropdown.Item style={{ textAlign: 'center', color: 'grey', fontSize: 14 }}>{'Nenhum favorito adicionado.'}</Dropdown.Item> : null }
                {
                    this.state.favorites.map((favorite, key) => {

                        return (
                            <Dropdown.Item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} onMouseEnter={() => { this.setState({ hoveredItem: favorite.id, hover: true }) }} onMouseLeave={() => { this.setState({ hover: false }) }} onClick={() => { this.handleUseFavorite(favorite) }}>
                                {favorite.nome}
                                {this.state.hoveredItem === favorite.id && this.state.hover ? <FontAwesomeIcon onClick={(event) => { event.stopPropagation(); this.handleRemove(favorite.id) }} icon={faTimes} style={{ backgroundColor: Colors.error, color: 'white', height: 20, width: 20, borderRadius: 20 / 2 }}/> : null}
                            </Dropdown.Item>
                        )
                    })
                }
                <Dropdown.Divider></Dropdown.Divider>
                <Dropdown.Item onClick={() => { this.handleBackToDefault() }} style={{ fontWeight: 'bold' }}>Voltar ao Padrão</Dropdown.Item>
            </Dropdown.Menu>
        )
    }

    renderMenu() {

        return (
            <Dropdown as={ButtonGroup}>
                <Button onClick={() => { this.setState({ add: true }) }} style={{ backgroundColor: Colors.favorite, borderColor: Colors.favorite, width: 150, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRight: `0.3px solid ${Colors.dark}` }}>
                    {!this.state.loading ? <FontAwesomeIcon style={{ marginRight: 8, color: Colors.dark }} icon={faStar} /> : null}
                    {this.state.loading ? <Spinner style={{ color: Colors.dark, marginRight: 8 }} animation="border" role="status" size="sm"></Spinner> : null}
                    <div style={{ color: Colors.dark }}>Favoritos</div>
                </Button>
                <Dropdown.Toggle split style={{ backgroundColor: Colors.favorite, borderColor: Colors.favorite, color: Colors.dark, borderLeft: `0.3px solid ${Colors.dark}` }}/>
                { this.renderFavorites() }
            </Dropdown>
        )
    }

    render() {

        return (

            <div>
                { this.state.add ? this.renderAdd() : this.renderMenu() }
            </div>
        )
    }
}