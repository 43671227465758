import moment from "moment";
import Sig from "../api/Sig";
import SessionHelper from "./SessionHelper";

export default class DataHelper {
    static formatSelectData(dbData, valueField, labelField, customRender = null) {
        if (dbData === undefined) {
            return [];
        }

        let selectFormattedData = [];
        
        dbData.forEach(row => {
            selectFormattedData.push({ value: row[valueField] ?? null, label: customRender ? customRender(row) : row[labelField] || `` });
        });

        return selectFormattedData;
    }

    static formatMentionData(dbData, valueField, labelField) {
        if (dbData === undefined) {
            return [];
        }

        let selectFormattedData = [];
        
        let userId = SessionHelper.getData().id_usuario || null;

        if(userId) {
            dbData = dbData.filter(row => row[valueField] != userId);
        }

        dbData.forEach(row => {
            selectFormattedData.push({ id: row[valueField] ?? null, value: row[labelField] || ``, original: row });
        });

        return selectFormattedData;
    }

    static transformArrayToSelectData(array) {
        if (array === undefined) {
            return [];
        }

        let selectFormattedData = [];
        
        array.forEach((row, index) => {
            selectFormattedData.push({ value: index, label: row });
        });

        return selectFormattedData;
    }

    static formatSelectedData(dbData, valueField, labelField, customRender = null) {
        if(dbData?.[valueField]) {
            return ({ value: dbData[valueField] || null, label: customRender ? customRender(dbData) : dbData[labelField] || `` });
        } else {
            return null;
        }
    }

    static async formatSelectIdUserData(listIds, valueField, labelField, customRender = null) {
        try {
            const colabs = await Sig.request('GET', 'config/colaborador/getColaboradoresListId', { ids: listIds });
            let selectFormattedData = [];
            
            colabs.forEach(row => {
                selectFormattedData.push({ value: row[valueField] || null, label: customRender ? customRender(row) : row[labelField] || `` });
            });
            
            return selectFormattedData;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    static async formatSelectIdIndicadorData(listIds, valueField, labelField, customRender = null) {
        try {
            let indicador = await Sig.request('GET', 'indicador/getIndicadores', { ids: listIds });
            let selectFormattedData = [];
            
            indicador.forEach(row => {
                selectFormattedData.push({ value: row[valueField] || null, label: customRender ? customRender(row) : row[labelField] || `` });
            });

            return selectFormattedData;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    static getSelectEmptyMessage() {
        return 'Nenhuma opção encontrada';
    }

    static getDefaultDbDateFormat(date) {
        return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss');
    }

    static dateSort(a, b, field) {

        return moment(b[field]).toDate() - moment(a[field]).toDate();
    }

    static multiArraySort(a, b, field) {

        if (a[field] && b[field]) {

            let aArray = [];
            let bArray = [];
    
            for (let index = 0; index < a[field].length; index++) {
                
                if (a[field][index]) {
                    aArray.push(a[field][index].nome);
                }

                if (b[field][index]) {
                    bArray.push(b[field][index].nome);
                }
            }
    
            return aArray.join(' ') > bArray.join(' ');
        }
    }

    static isDateString(string) {

        return moment(string).isValid();
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
   
    static removeHtmlTags(str) {
        if (str === null) {
            return '--';
        }
        // Remove HTML tags
        str = str.replace(/<\/?[^>]+(>|$)/g, "");
        // Remove HTML entities
        str = str.replace(/&[^;]+;/g, "");
        return str;
    }

    static limitarCaracteres(str, limite) {
        if (str?.length > limite) {
            return str.substring(0, limite) + "...";
        } else {
            return str;
        }
    }

    static removeColaborador(participantes, id_responsavel){

        if(id_responsavel === null){
            return participantes;
        }else{
            let Array = participantes;
            Array = Array.filter((item) => item.value !== id_responsavel);
            return Array;
        }   

    }

    static sortObjectsByName(objects) {
        return objects.sort((a, b) => {
            if (a.nome < b.nome) {
                return -1;
            } else if (a.nome > b.nome) {
                return 1;
            } else {
                return 0;
            }
        });
    }
    
}