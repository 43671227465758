import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import Sig from "../../api/Sig";
import DefaultButton from "../../components/tools/DefaultButton";
import AddSwot from "../../components/forms/pe/add/AddSwot";
import LoadingPage from "../LoadingPage";
import SwotView from "../../components/modules/pe/swot/SwotView";
import SwotList from "../../components/forms/pe/list/SwotList";

export default class SwotPage extends React.Component {

    state = {
        swot: null,
        loading: true,
        showSwots: null,
        showWizard: false,
        
        acesso: null,
        permissao: null
    }

    async componentDidMount() {
       await this.loadData();
    }

    async loadData() {

        this.setState({ loading: true });

        const favoriteOrLastSwot = await Sig.request('GET', 'pe/swot/getFavoriteSwot');

        this.setState({ swot: favoriteOrLastSwot?.swot, 
                        acesso: favoriteOrLastSwot?.acesso, 
                        permissao: favoriteOrLastSwot?.permissao, 
                        loading: false });
    }

    addSwotCallback = async (newSwotId) => {
        this.setState({ showSwots: false, showWizard: false });
        await this.loadData();
    }

    showSwotsCallback = async () => {
        this.setState({ showSwots: true });
        await this.loadData();
    }

    renderDefaultSwot() {

        return <SwotView id={this.state.swot.id} callback={this.showSwotsCallback}/>
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5, textAlign: 'center' }}>
                <DefaultButton style={{ position: 'absolute', top: 30, right: 10 }} link={"https://advis.freshdesk.com/support/solutions/articles/63000283129-swot"}/>
                <h5>Até o momento, sua empresa ainda não conduziu análises <strong>SWOT</strong> ou você não possui acesso a nenhuma delas.</h5>
                <div style={{ marginTop: 20 }} />
                <DefaultButton color={Colors.success} title={'Adicionar SWOT'} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} loading={this.state.loading} onClick={() => { this.setState({ showWizard: true }) }} />
            </div>
        )
    }

    render() {
        if(this.state.loading) return <LoadingPage />
        if (this.state.showWizard) return <AddSwot addSwotCallback={(newSwotId) => {this.addSwotCallback(newSwotId)}} />
        if(this.state.showSwots) return <SwotList callback={this.addSwotCallback}/>

        return this.state.swot ? this.renderDefaultSwot() : this.renderEmpty();
    }
}