import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faCheck, faCheckCircle, faEdit, faFileAlt, faPen, faTimes, faTimesCircle, faBars } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../tools/CustomConfirm";
import DragAndDropHelper from "../../../../helper/DragAndDropHelper";
import KeyboardHelper from "../../../../helper/KeyboardHelper";
import DataHelper from "../../../../helper/DataHelper";
import SessionHelper from "../../../../helper/SessionHelper";

export default class AddPerspectivas extends React.Component {
    state = {
        editing: null,
        editingText: '',
        loading: true,
        perspectivas: this.props.value ? this.props.value : [{ name: 'Financeira', id: 0, new: true }, { name: 'Clientes', id: 1, new: true }, { name: 'Processos Internos', id: 2, new: true }, { name: 'Aprendizado e Crescimento', id: 3, new: true }],
        draggingItem: null,
        draggingOverItem: null,
        mouseOverItem: null,
        top: false,
        falseId: 4,
    }

    componentDidMount() {

        this.props.callback(this.state.perspectivas);
    }

    removePerspectiva = async (perspectiva) => {
        let perspectivas = this.state.perspectivas;
        let index = perspectivas.indexOf(perspectiva);

        if (index > -1) {
            perspectivas.splice(index, 1);
            this.setState({ perspectivas });
        }

        this.props.callback(perspectivas);
    }

    renderEdit() {
        return (
            <Form.Control 
                style={{ marginRight: 10 }} 
                type="text" 
                placeholder="Perspectiva X..." 
                defaultValue={this.state.perspectivas[this.state.editing] ? this.state.perspectivas[this.state.editing].name : ''} 
                onChange={(event) => { this.setState({ editingText: event.target.value }) }}                
            />
        )
    }

    onDragStartItem = async (e, item, key) => {
        this.setState({ draggingItem: item, draggingOverItem: null });
        setTimeout(() => { e.target.style.display = "none" }, 5);
    }

    onDragEndItem = async (e, item, key) => {
        e.target.style.display = "block";

        let perspectivas = [...this.state.perspectivas];
        let draggingOverItem = this.state.draggingOverItem;
        
        if (draggingOverItem !== null) {
            perspectivas.splice(perspectivas.indexOf(this.state.draggingItem), 1);

            let indexToInsert = perspectivas.indexOf(draggingOverItem);

            if(this.state.top) {
                perspectivas.splice(indexToInsert, 0, item);
            } else {
                perspectivas.splice(indexToInsert + 1, 0, item);
            }
        }

        this.setState({ perspectivas }, () => {
            this.props.callback(perspectivas);
            this.setState({ draggingItem: null, draggingOverItem: null, top: false });
        });
    }

    onDragEnterItem = async (e, item, key) => {

        let top = DragAndDropHelper.checkTop(e);

        this.setState({ top });

        if (item.id !== this.state.draggingItem.id) {
            this.setState({ draggingOverItem: item });
        }
    }

    renderPerspectiva(perspectiva, key) {
        return (
            <div
                style={{
                    marginTop: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    border: this.state.dragging ? `2px dashed ${Colors.notSelected}` : '0.3px solid lightgrey',
                    padding: 10,
                    borderRadius: 5,
                    marginTop: this.state.draggingOverItem && this.state.draggingOverItem.id === perspectiva.id && this.state.top ? 80 : 10,
                    marginBottom: this.state.draggingOverItem && this.state.draggingOverItem.id === perspectiva.id && !this.state.top ? 80 : 0
                }}
                key={`${key}-perspectiva`}
                onDragStart={(e) => { this.onDragStartItem(e, perspectiva, key) }}
                onDragEnter={(e) => {this.onDragEnterItem(e, perspectiva, key) }}
                onDragEnd={(e) => { this.onDragEndItem(e, perspectiva, key) }}
                draggable={this.state.editing === null}
                onMouseOver={() => { if (this.state.mouseOverItem !== key) this.setState({ mouseOverItem: key }); }}
                onMouseLeave={() => { this.setState({ mouseOverItem: null }); }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {this.state.editing === null && this.state.mouseOverItem === key && key > -1 &&
                            <FontAwesomeIcon icon={faBars}
                                style={{ cursor: 'move', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginRight: 12 }}
                                color={Colors.dark}
                                fontSize={20}
                            />
                        }
                        {this.state.editing !== null && this.state.editing === key ?
                         this.renderEdit() 
                         : <Form.Label style={{ marginLeft: 5, marginBottom: 0 }}>{DataHelper.removeHtmlTags(perspectiva.name)}</Form.Label>}
                    </div>

                    {this.state.editing !== null && this.state.editing === key ?

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FontAwesomeIcon
                                onClick={() => {
                                    if(this.state.editingText != null && this.state.editingText != ''){
                                        let perspectivas = this.state.perspectivas;
                                        perspectivas[this.state.editing].name = this.state.editingText; 
                                        this.setState({ perspectivas});                                       
                                    }
                                    this.setState({editing: null, editingText: '' });     
                                }}
                                icon={faCheckCircle}
                                style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginRight: 12 }}
                                color={Colors.success}
                                fontSize={28}
                            />
                            <FontAwesomeIcon                               
                                onClick={() => { this.setState({ editingText: '', editing: null }) }}
                                icon={faTimesCircle}
                                style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)' }}
                                color={Colors.error}
                                fontSize={28}
                            />
                        </div>

                        :

                        <div>
                            <FontAwesomeIcon
                                onClick={() => { this.setState({ editing: key }) }}
                                icon={faPen}
                                style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginRight: 12 }}
                                color={Colors.success}
                                fontSize={25}
                            />
                            <FontAwesomeIcon
                                onClick={() => {
                                    confirmAlert({
                                        customUI: ({ onClose }) => (
                                            <CustomConfirm
                                                title={'Remover essa Perspectiva?'}
                                                message={<p style={{ marginTop: 10, marginBottom: 10 }}>Tem certeza que deseja descartar essa Perspectiva?</p>}
                                                buttons={[
                                                    {
                                                        label: 'Remover',
                                                        color: SessionHelper.getColor(),
                                                        textColor: Colors.light,
                                                        onClick: () => { this.removePerspectiva(perspectiva); onClose(); }
                                                    },
                                                    {
                                                        label: 'Cancelar',
                                                        onClick: () => { onClose(); }
                                                    },
                                                ]}
                                            />
                                        )
                                    });
                                }}
                                icon={faTimesCircle}
                                style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)' }}
                                color={Colors.error}
                                fontSize={28}
                            />
                        </div>
                    }

                </div>
            </div>
        )
    }

    addPerspectiva = () => {
        let perspectivas = [...this.state.perspectivas, { content: null, name: this.state.name, id: this.state.falseId, new: true}];
        this.setState({ falseId: this.state.falseId + 1 });
        this.setState({ perspectivas }, () => { this.setState({ addingNew: false, name: '' }) });
        this.props.callback(perspectivas);
    }

    renderAddNew() {
        return this.state.addingNew ?
            (
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', height: 200, width: 400, alignContent: 'center', justifyContent: 'center' }}>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome da Perspectiva</Form.Label>
                            <Form.Control type="text" placeholder="Perspectiva X..." onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <DefaultButton loading={this.state.loadingUpload} color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} style={{ width: '49%' }} title={'Cancelar'} onClick={() => { this.setState({ name: '', addingNew: false }) }} />
                        <DefaultButton loading={this.state.loadingUpload} disabled={!this.state.name} color={Colors.success} leftIcon={<FontAwesomeIcon icon={faFileAlt} />} style={{ width: '49%' }} title={'Adicionar'} onClick={this.addPerspectiva} />
                    </div>
                </div>
            ) :
            (
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', width: 50, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <div onClick={() => { this.setState({ addingNew: true, editing: null, editingText: '' }) }} style={{ borderRadius: 5, cursor: 'pointer', display: 'flex', height: 50, width: 50, marginBottom: 10, backgroundColor: Colors.info, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <FontAwesomeIcon icon={faAdd} color={Colors.background} fontSize={25} />
                    </div>
                    <Form.Label style={{ marginLeft: 0 }}>Novo</Form.Label>
                </div>
            );
    }

    renderPerspectivas() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
                {this.state.perspectivas.map((perspectiva, key) => (this.renderPerspectiva(perspectiva, key)))}
                {this.renderAddNew()}
            </div>
        );
    }

    render() {
        return (
            <div style={{ minWidth: '80%', marginBottom: 40 }}>
                <Form.Label>Perspectivas:*</Form.Label>
                {this.renderPerspectivas()}
            </div>
        );
    }
}