import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faChevronLeft, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import Sig from "../../../../api/Sig";
import EssentialStyle from "../../../../style/EssentialStyle";

export default class AddCanvas extends React.Component {
    state = {
        step: 0,
        loading: true,
        colabs: [],
        responsable: null,
        id_pe_projeto: null,
        nameNewProjeto: '',
        projetos: [],
        adding: false,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        let projetos = (await Sig.request('GET', 'pe/projeto/list', { relations: 0, adicionarCanvas: true }))?.projetos || [];
        let colabs = await Sig.request('GET', 'config/colaborador/getColaboradores') || [];

        this.setState({ colabs, projetos }, () => { this.setState({ loading: false }) });
    }

    addNew = async () => {
        if (!this.state.responsable) return toast.warn('Informe todos os campos obrigatórios (*)');
        if (this.state.adding && !this.state.nameNewProjeto) return toast.warn('Informe o nome do Projeto.');
        if (!this.state.adding && !this.state.id_pe_projeto) return toast.warn('Informe o Projeto.');

        this.setState({ loading: true });

        let req = await Sig.request(
            'POST',
            'pe/canvas/add',
            {
                id_responsavel: this.state.responsable,
                id_pe_projeto: this.state.adding ? null : this.state.id_pe_projeto,
                nome_projeto: !this.state.adding ? null : this.state.nameNewProjeto,
            }
        );

        if (req?.canvas) {
            this.props.addCallback();
            toast.success('Canvas de projeto inserido com sucesso!');
        }

        this.setState({ loading: false });
    }

    renderProjetos() {

        const selectProjeto = () => {
            return (
                <Select
                    noOptionsMessage={DataHelper.getSelectEmptyMessage}
                    className={this.state.size == "xsm" ? "form-label-xsm w-100 me-1" : "w-100 me-1"}
                    options={DataHelper.formatSelectData(this.state.projetos, 'id', 'nome')}
                    placeholder={'Selecione o projeto'}
                    isClearable
                    isSearchable
                    onChange={(value) => {
                        this.setState({
                            id_pe_projeto: value ? value.value : null,
                            selectedOption: value ? (this.state.colabs.find(colab => colab.id === this.state.projetos.find(projeto => projeto.id === value.value)?.id_responsavel)) : null,
                            responsable: value ? this.state.projetos.find(projeto => projeto.id === value.value)?.id_responsavel : null,
                        })
                    }}
                    menuPortalTarget={document.body}
                />
            )
        }

        const inputNameProjeto = () => {
            return (
                <Form.Control
                    className={this.state.size == "xsm" ? "form-label-xsm w-100 me-1" : "w-100 me-1"}
                    type="text"
                    placeholder="Digite o nome do projeto"
                    value={this.state.inputValue}
                    onChange={(event) => { this.setState({ nameNewProjeto: event.target.value }) }}
                />
            )
        }

        return (
            <Form.Group as={Col}>
                <Form.Label>Vincular com projeto*</Form.Label>

                <div className="d-flex justify-content-between mb-3">
                    {this.state.adding ? inputNameProjeto() : selectProjeto()}
                    <DefaultButton
                        color={Colors.success}
                        leftIcon={this.state.adding ? <FontAwesomeIcon icon={faCaretDown}/> : <FontAwesomeIcon icon={faPlusSquare}/>}
                        style={{ width: 40 }}
                        title={''}
                        loading={this.state.loading}
                        tooltip={this.state.adding ? 'Selecionar Projeto Existente' : 'Cria um novo Projeto sem Vínculo com Mapa Estratégico' }
                        onClick={() => { this.setState({ adding: !this.state.adding }) }} />
                </div>
            </Form.Group>
        )
    }

    renderForm() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form className="w-75">

                    <Row className="mb-3">
                        {this.renderProjetos()}
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Responsável*</Form.Label>
                            <Select
                                options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')}
                                placeholder={'Selecione o responsável'}
                                noOptionsMessage={DataHelper.getSelectEmptyMessage}
                                isClearable
                                isSearchable
                                onChange={(value) => {
                                    this.setState({
                                        responsable: value ? value.value : null,
                                        selectedOption: value ? this.state.colabs.find(colab => colab.id === value.value) : null
                                    })
                                }}
                                value={this.state.selectedOption ? DataHelper.formatSelectData([this.state.selectedOption], 'id', 'nome')[0] : null}
                            />
                        </Form.Group>
                    </Row>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} title={'Adicionar'} loading={this.state.loading} onClick={this.addNew} />
                        </Form.Group>
                    </div>
                </Form>
            </div>
        );
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.step(false) }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    step(forward) {
        this.setState({ loading: true });

        if (this.state.step < 1 && !forward) {
            this.props.addCallback();
        } else {
            let step = forward ? ++this.state.step : --this.state.step;
            this.setState({ step });
        }

        this.setState({ loading: false });
    }


    renderSteps() {
        let stepContent;

        stepContent = this.renderForm();

        return stepContent;
    }

    render() {
        return (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderStepBack()}
                {this.renderSteps()}
            </div>
        );
    }
}