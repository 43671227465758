import React from "react";
import Colors from '../../constants/Colors';
import DefaultLoader from "./DefaultLoader";

export default class CustomSuspense extends React.Component {
    render() {
        return (
            <div style={{ height: this.props.height ? this.props.height : '100vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: Colors.dark, fontWeight: 'bold' }}>
                <DefaultLoader />
            </div>
        );
    }
}