import React from "react";
import '../PlanoAcaoRelatorio.css';

import EssentialStyle from "../../../../../style/EssentialStyle";
import UserAvatar from "../../../../tools/UserAvatar";
import Colors from "../../../../../constants/Colors";
import { Collapse } from "react-bootstrap";
import moment from 'moment';
import { ProgressBar } from "react-bootstrap";
import TooManyAvatars from '../../../../tools/TooManyAvatars';
import DataHelper from '../../../../../helper/DataHelper';
import TooManyTags from '../../../../tools/TooManyTags';
import DefaultLoader from '../../../../tools/DefaultLoader';
import PlanoAcaoHelper from '../../../../../helper/planoAcao/PlanoAcaoHelper';
import CustomTooltip from '../../../../tools/CustomTooltip';

export default class PlanoAcaoRelatorioLinhaTabela extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            progressBarHovered: false,
        }
        this.progressBar = null;
    }

    renderVinculos(vinculosPlano) {
        let vinculos = [
            { color: Colors.planoAcao.vinculo.backgroundColor[1], text: `GMR`, description: `Gestão de Metas e Resultados`, key: 1, textColor: Colors.light },
            { color: Colors.planoAcao.vinculo.backgroundColor[3], text: `PCO`, description: `Pesquisa Organizacional`, key: 3, textColor: Colors.light },
            { color: Colors.planoAcao.vinculo.backgroundColor[4], text: `AC`, description: `Avaliação de Competências`, key: 4, textColor: Colors.light },
            { color: Colors.planoAcao.vinculo.backgroundColor[5], text: `ANÁLISE`, description: `Análise de Problema`, key: 5, textColor: Colors.dark },
            { color: Colors.planoAcao.vinculo.backgroundColor[6], text: `FEEDBACKS`, description: `Feedbacks`, key: 6, textColor: Colors.light },
            { color: Colors.planoAcao.vinculo.backgroundColor[7], text: `MARCO`, description: `Marco de Projeto`, key: 7, textColor: Colors.light },
            { color: Colors.planoAcao.vinculo.backgroundColor[8], text: `DIAGNÓSTICO`, description: `Diagnóstico`, key: 8, textColor: Colors.light },
        ];

        let showVinculos = [];

        if (vinculosPlano.length > 1) {
            vinculosPlano.sort((a, b) => {
                return a.tipo - b.tipo;
            });
        }

        vinculosPlano.forEach(vinculo => {
            let found = vinculos.find(v => v.key == vinculo.tipo);
            if (found) {
                if (!showVinculos.includes(found)) showVinculos.push(found);
            }
        });

        var width = 100;
        if(showVinculos.length > 2) 
            var width = 200;

        return (
            <div style={{ ...EssentialStyle.rowSpaceAround, minHeight: 28, width: width, flexWrap: 'wrap', padding: "5px 0px", gap: 2}}>
                {showVinculos.map((vinculo, key) => {
                    return (
                        <CustomTooltip key={key + Math.random()} placement={'bottom'} tooltip={vinculo.description}>
                            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                                <div style={{ backgroundColor: vinculo.color, color: vinculo.textColor, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>{vinculo.text}</div>
                            </div>
                        </CustomTooltip>
                    );
                })}
            </div>
        );
    }

    renderStatusPlano(status) {
        let propStatus = status || 'Não Iniciado';

        return (
            <div style={{ ...EssentialStyle.rowFlexCenter }}>
                <div style={{ backgroundColor: Colors.planoAcao.status.backgroundColor[propStatus], color: Colors.planoAcao.status.color[propStatus], fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600, minWidth: 124 }}>{propStatus}</div>
            </div>
        );
    }

    renderStatusEtapa(status, atrasada, status_text) {

        return (
            <div style={{ ...EssentialStyle.rowFlexCenter}}>
                <div style={{ backgroundColor: atrasada ? Colors.error : PlanoAcaoHelper.getEtapaStatus()[status].backgroundColor, color: atrasada ? Colors.white : PlanoAcaoHelper.getEtapaStatus()[status].color, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600, minWidth: 124 }}>{status_text}</div>
            </div>
        );
    }

    renderProggressBar(etapas) {
        let min = 1;
        let max = 100;
        let progress = 0;
        let total = 0;
        let done = 0;

        if (etapas && etapas.length > 0) {
            total = etapas.filter(e => e.status_value != 2).length;
            done = etapas.filter(e => e.status_value == 3).length;
            progress = parseInt((done / total) * 100);
        }

        if(this.progressBar && this.state.progressBarHovered) {
            this.progressBar.querySelector('.progress-bar').style.minWidth = '124px';
        } else if(this.progressBar && !this.state.progressBarHovered) {
            this.progressBar.querySelector('.progress-bar').style.minWidth = '0px';
        }

        let label = this.state.progressBarHovered ?
            (<div style={{ paddingLeft: 4, paddingRight: 4 }}>{done} de {total} concluídas</div>)
            : (<div style={{ paddingLeft: 4, paddingRight: 4 }}>{progress}%</div>);

        return (
            <div
                style={{ width: "100%" }}
                onMouseEnter={() => this.setState({ progressBarHovered: true })}
                onMouseLeave={() => this.setState({ progressBarHovered: false })}
            >
                <ProgressBar
                    min={min}
                    max={max}
                    ref={ref => this.progressBar = ref}
                    now={progress}
                    label={label}
                    style={{ height: 20 }}
                    className="progress-bar-tabela"
                />
            </div>
        );
    }

    renderPlano() {

        var bordersRows = {borderBottom: `1px solid ${Colors.homePage.lightGrey}`, borderRight:`1px solid ${Colors.homePage.lightGrey}`}

        return (
            <>
                <tr key={"plano-"+this.props.item.id} onClick={() => this.setState({expanded: !this.state.expanded})} style={{height: 60, backgroundColor: Colors.homePage.extraLightGrey, borderBottom: `1px solid ${Colors.homePage.extraLightGrey}`}}>
                    <td colSpan={3} className={"sticky-column"} style={{ minWidth: 900, maxWidth: 900, left: 0, backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", overflow: 'hidden', cursor: "pointer"}} onClick={(e) => {e.stopPropagation(); window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${this.props.item.codigo}` }, '*')}}>
                        <div style={{width: "100%"}}>
                            <div title={this.props.item.nome} style={{width: "100%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                                {this.props.item.nome}
                            </div>
                            {this.props.item.tags && this.props.item.tags.length > 0 ?
                                <div style={{minHeight: 30}}>
                                    <TooManyTags max={4} maxWidth={"900px"} tags={this.props.item.tags || []} />
                                </div>
                            : null
                            }
                        </div>
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "wrap", maxWidth: 130}}>
                        {this.props.item.tipoName}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap", maxWidth: 200}}>
                        {this.props.item.vinculos && this.props.item.vinculos.length > 0 && this.renderVinculos(this.props.item.vinculos)}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>
                        <div style={{...EssentialStyle.columnCenter, width: "100%", gap: 2}}>
                            {this.renderStatusPlano(this.props.item.status)}
                            {this.renderProggressBar(this.props.item.etapas)}
                        </div>
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>
                        {this.props.item.data_inicio && this.props.item.data_inicio != 0 ? moment(this.props.item.data_inicio).format("DD/MM/yy") : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>
                        {this.props.item.data_fim && this.props.item.data_fim != 0 ? moment(this.props.item.data_fim).format("DD/MM/yy") : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>
                        {this.props.item.orcamento_previsto && this.props.item.orcamento_previsto != 0 ? parseFloat(this.props.item.orcamento_previsto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>
                        {this.props.item.orcamento_realizado && this.props.item.orcamento_realizado != 0 ? parseFloat(this.props.item.orcamento_realizado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap", justifyContent: "center"}}>
                        <div style={{...EssentialStyle.rowFlexCenter}}>
                            <div style={{width: 40, height: 40}}>
                                <UserAvatar user={this.props.item.responsavel} size={40}/>
                            </div>
                        </div>
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap", justifyContent: "center"}}>
                        {this.props.item.superior ? 
                            <div style={{...EssentialStyle.rowFlexCenter}}>
                                <div style={{width: 40, height: 40}}>
                                    <UserAvatar user={this.props.item.superior} size={40}/> 
                                </div>
                            </div>
                        : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap", justifyContent: "center"}}>
                        {this.props.item.participantes && this.props.item.participantes.length > 0 ? 
                            <div style={{...EssentialStyle.rowFlexCenter}}>
                                <TooManyAvatars participantsTotal={this.props.item.participantes.length} participantsVisiveis={3} arrayParticipants={this.props.item.participantes}/> 
                            </div>    
                        : "--"}
                    </td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, ...bordersRows, padding: "0px 5px", whiteSpace: "nowrap"}}>--</td>
                    <td style={{backgroundColor: Colors.homePage.extraLightGrey, borderBottom: `1px solid ${Colors.homePage.lightGrey}`, padding: "0px 5px", whiteSpace: "nowrap"}}>--</td>
                </tr>
                {this.props.item.etapas && this.props.item.etapas.length > 0 ?
                    this.props.item.etapas.map((etapa) => {
                        return(
                            <PlanoAcaoRelatorioLinhaTabela key={"etapa-"+etapa.id} item={etapa} type={'etapa'} isSmallScreen={this.props.isSmallScreen} expanded={this.state.expanded}/>
                        );
                    })
                    : 
                    null  }
            </>
        );
    }

    renderEtapa() {
        var bordersRows = {borderBottom: `1px solid ${Colors.homePage.lightGrey}`, borderRight:`1px solid ${Colors.homePage.lightGrey}`}

        return (
            <Collapse in={this.props.expanded} key={"etapa-"+this.props.item.id} style={{transition: "all 0.5s ease", height: 30}}>
                <tr style={{height: 30}}>
                    <td title={DataHelper.removeHtmlTags(this.props.item.atividade)} className={"sticky-column"} style={{ left: 0, maxWidth: 300, width: 300, padding: "0px 5px", ...bordersRows, cursor: "pointer"}} 
                    onClick={() => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${this.props.item.codigo}?etapa=${this.props.item.id}` }, '*')}
                    >
                        <div style={{width: "100%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>
                            {DataHelper.removeHtmlTags(this.props.item.atividade)}
                        </div>
                    </td>
                    <td title={DataHelper.removeHtmlTags(this.props.item.descricao)} className={"sticky-column"} style={{ left: 300, maxWidth: 300, width: 300, padding: "0px 5px", ...bordersRows}}>
                        <div style={{width: "100%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>
                            {DataHelper.removeHtmlTags(this.props.item.descricao)}
                        </div>
                    </td>
                    <td title={DataHelper.removeHtmlTags(this.props.item.justificativa)} className={"sticky-column"} style={{ left: 600, maxWidth: 300, width: 300, padding: "0px 5px", ...bordersRows}}>
                        <div style={{width: "100%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: "vertical"}}>
                            {DataHelper.removeHtmlTags(this.props.item.justificativa)}
                        </div>
                    </td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>--</td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>--</td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {this.renderStatusEtapa(this.props.item.status, this.props.item.atrasada, this.props.item.status_text)}
                    </td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {moment(this.props.item.data_inicio).format("DD/MM/yy")}
                    </td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {moment(this.props.item.data_fim).format("DD/MM/yy")}
                    </td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {this.props.item.orcamento_previsto && this.props.item.orcamento_previsto != 0 ? parseFloat(this.props.item.orcamento_previsto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "--"}
                    </td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {this.props.item.orcamento_realizado && this.props.item.orcamento_realizado != 0 ? parseFloat(this.props.item.orcamento_realizado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "--"}
                    </td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", justifyContent: "center"}}>
                        <div style={{...EssentialStyle.rowFlexCenter}}>
                            <div style={{width: 40, height: 40}}>
                                <UserAvatar user={this.props.item.colaborador} size={40}/>
                            </div>
                        </div>
                    </td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap", justifyContent: "center"}}>--</td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {this.props.item.participantes && this.props.item.participantes.length > 0 ?
                            <div style={{...EssentialStyle.rowFlexCenter}}>
                                <TooManyAvatars participantsTotal={this.props.item.participantes.length} participantsVisiveis={3} arrayParticipants={this.props.item.participantes}/> 
                            </div>
                        : "--"}
                    </td>
                    <td style={{padding: "0px 5px", ...bordersRows, whiteSpace: "nowrap"}}>
                        {PlanoAcaoHelper.getPrioridadeIcon()[this.props.item.prioridade_text]}
                    </td>
                    <td style={{padding: "0px 5px", borderBottom: `1px solid ${Colors.homePage.lightGrey}`, whiteSpace: "nowrap"}}>
                        {this.props.item.qtdComentarios ? this.props.item.qtdComentarios : "--"}
                    </td>
                </tr>
            </Collapse>
        );
    }

    render() {
        
        if(this.props.item == null) return null;

        return (
            <>
                {this.props.type === 'plano' ? this.renderPlano() : this.props.type === 'etapa' ? this.renderEtapa() : null}
            </>
        );
    }
}