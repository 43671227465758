import moment from 'moment';

export default class CacheHelper {
    static _userAvatars = [];

    static addUserAvatar(user) {
        let cachedUser = {lastUpdated: moment(), ...user};

        if(this.getUserAvatar(user.id)) return;
        this._userAvatars = [...this._userAvatars, cachedUser];
    }

    static getUserAvatar(id) {
        if (id && this._userAvatars?.length) {
            let cachedUser = this._userAvatars.find(u => u.id == id);

            if(cachedUser && cachedUser.lastUpdated) {
                let now = moment();
                let lastUpdated = moment(cachedUser.lastUpdated);

                if(now.diff(lastUpdated, 'hours') > 2) {
                    this._userAvatars = this._userAvatars.filter(u => u.id != id);
                    return false;
                }
            }

            return cachedUser;
        }

        return false;
    }
}