import React from "react";
import './PlanoAcaoCardRelatorio.css';

import EssentialStyle from "../../../../style/EssentialStyle";
import Tag from "../../../tools/Tag";
import UserAvatar from "../../../tools/UserAvatar";
import Colors from "../../../../constants/Colors";
import CustomTooltip from "../../../tools/CustomTooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals, faChevronUp, faChevronDown, faCalendar, faExclamationTriangle, faEye } from '@fortawesome/free-solid-svg-icons'
import { ProgressBar } from "react-bootstrap";
import moment from 'moment';
import { Collapse } from "react-bootstrap";
import PlanoAcaoEtapaCard from "../PlanoAcaoEtapaCard/PlanoAcaoEtapaCard";
import DefaultButton from "../../../tools/DefaultButton";

export default class PlanoAcaoCardRelatorio extends React.Component {
    state = {
        progressBarHovered: false,
        displayEtapas: false,
        isSmallScreen: this.props.isSmallScreen,
    }

    async componentDidMount() {
        if(this.props.plano)
            this.setProgressBarColor();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.plano !== this.props.plano && this.props.plano) {
            this.setProgressBarColor();
        }

        if(prevProps.isSmallScreen !== this.props.isSmallScreen) {
            this.setState({ isSmallScreen: this.props.isSmallScreen });
        }
    }

    setProgressBarColor() {
        if (this.progressBar) {
            const inner = this.progressBar.querySelector(".progress-bar");
            if (inner) {
                inner.style.backgroundColor = Colors.dark;
            }
        }
    }

    renderTendencia() {
        if (this.props.plano?.tendencia) {
            let icon = null;

            if (this.props.plano?.tendencia?.value == -1) icon = <FontAwesomeIcon icon={faChevronDown} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />
            if (this.props.plano?.tendencia?.value == 1) icon = <FontAwesomeIcon icon={faChevronUp} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />
            if (this.props.plano?.tendencia?.value == null) icon = <FontAwesomeIcon icon={faEquals} style={{ color: this.props.plano?.tendencia?.color, fontSize: 18, marginRight: 4 }} />

            return (
                <CustomTooltip tooltip={this.props.plano?.tendencia?.msg} style={{ marginLeft: 8 }}>
                    {icon}
                </CustomTooltip>
            );
        }
    }


    renderTitle() {

        let hasEtapas = this.props.plano.etapas && this.props.plano.etapas.length > 0;

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, paddingLeft: 3, paddingBottom: 0, flex: 1, width: "calc(100% - 45px)"}}>
                <Tag tag={{ id: `${this.props.plano.codigo}-pa-code`, nome: this.props.plano.codigo }} />
                {!hasEtapas &&
                    <CustomTooltip tooltip={"Plano de Ação sem Etapas definidas"} placement="right">
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: 10 }} className={'icon warning'} />
                    </CustomTooltip>
                }
                {this.renderTendencia()}
                <span style={{
                    marginLeft: 6,
                    fontSize: 18,
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: "auto",
                    flex: 1,
                }}>
                    {this.props.plano.nome}
                </span>
            </div>
        );
    }

    renderLeft() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, width: this.props.isSmallScreen ? "100%" : "unset", overflow: "hidden", flex: 1 }}>
                <UserAvatar user={this.props.plano.responsavel} />
                {this.renderTitle()}
            </div>
        );
    }

    renderStatus() {
        let propStatus = this.props.plano?.status || 'Não Iniciado';

        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2 }}>
                <div style={{ backgroundColor: Colors.planoAcao.status.backgroundColor[propStatus], color: Colors.planoAcao.status.color[propStatus], fontSize: 12, padding: 5, borderRadius: 5, marginRight: 2, fontWeight: 600 }}>{this.props.plano?.status}</div>
            </div>
        );
    }

    renderPeriodo() {
        return (
            <div style={{ ...EssentialStyle.rowFlexStart, marginLeft: 2, backgroundColor: Colors.tag, color: Colors.dark, fontSize: 12, padding: 5, borderRadius: 5, fontWeight: 600 }}>
                <FontAwesomeIcon icon={faCalendar} style={{ color: Colors.dark, fontSize: 14, marginRight: 4 }} />
                <span>{this.props.plano?.data_inicio != 0 ? moment(this.props.plano?.data_inicio).format('DD/MM/YY') : "- -"}{this.props.plano?.data_fim ? `~ ${moment(this.props.plano?.data_fim).format('DD/MM/YY')}` : ``}</span>
            </div>
        );
    }

    renderProggressBar() {
        let min = 1;
        let max = 100;
        let progress = 0;
        let total = 0;
        let done = 0;

        if (this.props.plano.etapas && this.props.plano.etapas.length > 0) {
            total = this.props.plano.etapas.filter(e => e.status_value != 2).length;
            done = this.props.plano.etapas.filter(e => e.status_value == 3).length;

            if(total > 0)
                progress = parseInt((done / total) * 100);
            else
                progress = 0;
        }

        if(this.progressBar && this.state.progressBarHovered) {
            this.progressBar.querySelector('.progress-bar').style.minWidth = '130px';
        } else if(this.progressBar && !this.state.progressBarHovered) {
            this.progressBar.querySelector('.progress-bar').style.minWidth = '0px';
        }

        let label = this.state.progressBarHovered ?
            (<div style={{ paddingLeft: 4, paddingRight: 4 }}>{done} de {total} concluídas</div>)
            : (<div style={{ paddingLeft: 4, paddingRight: 4 }}>{progress}%</div>);

        return (
            <div
                style={{ width: this.props.isSmallScreen ? 100 : 250 }}
                onMouseEnter={() => this.setState({ progressBarHovered: true })}
                onMouseLeave={() => this.setState({ progressBarHovered: false })}
            >
                <ProgressBar
                    min={min}
                    max={max}
                    ref={ref => this.progressBar = ref}
                    now={progress}
                    label={label}
                    style={{ height: 26 }}
                />
            </div>
        );
    }

    renderRight() {
        let style = { height: 50, gap: 5 };

        if (this.props.isSmallScreen) {
            style = { ...EssentialStyle.rowSpaceBetween, alignItems: 'center', marginTop: 20, ...style, width: '100%' };
        } else {
            style = { ...EssentialStyle.rowSpaceBetween, alignItems: 'center', ...style };
        }

        return (
            <div style={style}>
                {this.renderStatus()}
                {this.renderPeriodo()}
                {this.renderProggressBar()}
                <DefaultButton leftIcon={<FontAwesomeIcon icon={faEye}/>} style={{ height: 25, width: 25, padding: 0 }} onClick={(e) => {e.stopPropagation(); this.props.onClick(this.props.plano?.codigo);}}/>
            </div>
        );
    }

    getPeople = () => {
        let people = [];

        if(!this.props.plano) return people;

        if (this.props.plano?.responsavel) people.push(this.props.plano.responsavel);
        if (this.props.plano?.superior) people.push(this.props.plano.superior);
        if (this.props.plano?.participantes) people = people.concat(this.props.plano.participantes);

        people.sort((a, b) => {
            return a.nome.localeCompare(b.nome);
        });

        return people;
    }

    renderCard() {
        let cardStyle = {
            ...EssentialStyle.card,
            marginTop: this.props.noMargin ? 0 : 10,
            padding: 10,
            cursor: 'pointer',
            width: "100%",
            ...EssentialStyle.columnStart
        };

        if (this.props.isSmallScreen) {
            var planoStyle = {
                ...EssentialStyle.columnStart,
                width: "100%",
            };
        } else {
            var planoStyle = {
                ...EssentialStyle.rowSpaceBetween,
                width: "100%",
            }
        }

        return (
            <div
                key={"id-plano-"+this.state.plano}
                style={cardStyle}
                className="plano-acao-card"
            >
                <div style={planoStyle} onClick={() => this.setState({ displayEtapas: !this.state.displayEtapas })}>
                    {this.renderLeft()}
                    {this.renderRight()}
                </div>
                <Collapse in={this.state.displayEtapas}>
                    <div style={{width: "100%"}}>
                        <div style={{ ...EssentialStyle.columnSpaceEvenly, width: "100%", marginTop: 10, borderBottom: this.props.plano?.etapas?.length > 0 ? "1px solid "+ Colors.homePage.extraLightGrey : "unset" }}>
                            {this.state.displayEtapas ? this.props.plano?.etapas?.length > 0 ? this.props.plano.etapas?.map((etapa, index) => {
                                return (
                                    <PlanoAcaoEtapaCard key={"etapa-"+index} etapa={etapa} resumed={this.props.isSmallScreen} participantes={this.getPeople()} onClick={(idEtapa) => window.parent.postMessage({ type: '_blank', url: `/planoAcao/detalhar/${this.props.plano.codigo}?etapa=${idEtapa}` }, '*')} noEdit/>
                                )
                            })
                            : 
                                <div style={{...EssentialStyle.columnCenter, width: "100%", padding: 10, fontSize: 18, fontWeight: 500}}>
                                    Nenhuma etapa cadastrada
                                </div>
                            : null} 
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }

    render() {
        return this.renderCard();
    }
}