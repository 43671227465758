import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faFile, faImage, faPlus, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import SessionHelper from "../../../../helper/SessionHelper";
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import Constants from "../../../../constants/Api";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../../tools/CustomConfirm";
import Sig from "../../../../api/Sig";


export default class SelectVisaoTemplate extends React.Component {
    state = {
        selectedTemplateImage: this.props.selectedTemplateImage,
        loading: true,
        images: [],
        imageToUpload: null,
        imageToUploadPreview: null,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        const defaultImages = [
            { nome: 'Em Branco', image: 'blank.png', default: true },
            { nome: 'Escuro', image: 'dark.png', default: true },
            { nome: 'Escalada do Crescimento Inteligente - 3 Anos', image: 'mountain3.png', default: true },
            { nome: 'Escalada do Crescimento Inteligente - 5 Anos', image: 'mountain5.png', default: true },
            { nome: 'Caminho do Crescimento Inteligente - 3 Anos', image: 'roadmap3.png', default: true },
            { nome: 'Caminho do Crescimento Inteligente - 5 Anos', image: 'roadmap5.png', default: true },
            { nome: 'Escada do Crescimento Inteligente - 3 Anos', image: 'stairs3.png', default: true },
            { nome: 'Escada do Crescimento Inteligente - 5 Anos', image: 'stairs5.png', default: true }
        ];

        const images = (await Sig.request('GET', 'pe/visao/getTemplates'))?.templates || [];

        this.setState({ images: [...images, ...defaultImages] }, () => { this.setState({ loading: false }) });
    }

    removeTemplate = async (id, image) => {
        this.setState({ loadingUpload: true });

        const req = await Sig.request("POST", "pe/visao/removeTemplate", { id: id });

        if (req && req?.template?.id) {
            toast.success('Template removido!');
            this.loadData();
        } else {
            toast.error('Ocorreu um erro ao remover o template, tente novamente mais tarde.')
        }

        this.setState({ loadingUpload: false });
    }

    canRemoveTemplate(id_colaborador) {

        let canRemove = false;

        if (id_colaborador == SessionHelper.getData().id_usuario) {

            canRemove = true;
        
        } else {

            let allowedPermissions = ['Administrador', 'Consultor', 'Gerente'];

            if (allowedPermissions.includes(SessionHelper.getPermissionType())) {

                canRemove = true;
            }
        }

        return canRemove;
    }

    renderImage(template) {
        const prefix = `${Constants.getSigEndPoint()}/${template.default ? `img/templates` : `upload/templates`}`;

        return (
            <div style={{ margin: 10, display: 'flex', flexDirection: 'column' }}>
                <div
                    onClick={() => { this.setState({ selectedTemplateImage: template.image, selectedTemplateId: template.id ? template.id : null }, () => { this.props.onTemplateSelect(this.state.selectedTemplateId, this.state.selectedTemplateImage) }); }}
                    style={{ backgroundSize: 'cover', borderRadius: 5, border: this.state.selectedTemplateImage === template.image ? `5px solid ${Colors.info}` : `0`, backgroundImage: `url(${prefix}/${template.image})`, display: 'flex', flexDirection: 'row-reverse', height: 200, width: 400, marginBottom: 10, padding: 10, justifyContent: 'flex-start' }}>
                    {!template.default && this.canRemoveTemplate(template.id_colaborador) ?
                        <FontAwesomeIcon
                            onClick={() => {
                                confirmAlert({
                                    customUI: ({ onClose }) => (
                                        <CustomConfirm
                                            title={'Remover este template?'}
                                            message={<p style={{ marginTop: 10, marginBottom: 10 }}>Podem existir visões que usam este template como fundo. Caso seja o caso, o fundo ficará vazio.</p>}
                                            buttons={[
                                                {
                                                    label: 'Remover',
                                                    color: SessionHelper.getColor(),
                                                    textColor: Colors.light,
                                                    onClick: () => { this.removeTemplate(template.id, template.image); onClose(); }
                                                },
                                                {
                                                    label: 'Cancelar',
                                                    onClick: () => { onClose(); }
                                                },
                                            ]}
                                        />
                                    )
                                });
                            }}
                            icon={faTimesCircle}
                            style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)' }}
                            color={Colors.background}
                            fontSize={32}
                        />
                    : null }
                    {this.state.selectedTemplateImage === template.image &&
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)', marginRight: 10 }}
                            color={Colors.info}
                            fontSize={32}
                        />
                    }
                </div>
                <Form.Label style={{ marginLeft: 5 }}>{template.nome}</Form.Label>
            </div>
        );
    }

    handleFileUpload = async (v) => {
        this.setState({ loadingUpload: true });

        const file = v.target.files[0];
        const maxSize = 10000000;

        if (file && file.size <= maxSize) {
            this.setState({ imageToUpload: file, imageToUploadPreview: URL.createObjectURL(file) });
        } else {
            if (file && file.size >= maxSize) {
                toast.warn('A imagem é muito grande, use uma imagem de menos de 10 MB');
            } else {
                toast.warn('A imagem não cumpre os requisitos necessários.');
            }
        }

        this.setState({ loadingUpload: false });
    }

    doUploadFile = async () => {
        this.setState({ loadingUpload: true });

        const formData = new FormData();
        formData.append('file', this.state.imageToUpload);
        formData.append('nome', this.state.name);
        formData.append('id_empresa', SessionHelper.getData().id_empresa);
        formData.append('id_colaborador', SessionHelper.getData().id_usuario);

        const req = await Sig.request("POST", "pe/visao/uploadTemplate", formData, 'multipart/form-data');

        if (req && req.id) {
            toast.success('Novo template criado com sucesso!');
            this.setState({ imageToUpload: null, imageToUploadPreview: null, name: '', addingNew: false });
            this.loadData();
        } else {
            toast.error('Ocorreu um erro ao fazer o upload, tente novamente mais tarde.')
            this.setState({ imageToUpload: null, imageToUploadPreview: null, name: '', addingNew: false });
        }

        this.setState({ loadingUpload: false });
    }

    renderAddNew() {
        return this.state.addingNew ?
            (
                <div style={{ margin: 10, display: 'flex', flexDirection: 'column' }}>
                    <input
                        multiple={false}
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="upload-template"
                        name="upload-template"
                        type="file"
                        onChange={this.handleFileUpload}
                    />

                    {this.state.imageToUploadPreview ?
                        <div
                            style={{ backgroundSize: 'cover', borderRadius: 5, backgroundImage: `url(${this.state.imageToUploadPreview})`, display: 'flex', flexDirection: 'row-reverse', height: 200, width: 400, marginBottom: 10, padding: 10, justifyContent: 'flex-start' }}>
                            <FontAwesomeIcon
                                onClick={() => { this.setState({ imageToUploadPreview: null, imageToUpload: null }) }}
                                icon={faTimesCircle}
                                style={{ cursor: 'pointer', boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)' }}
                                color={Colors.background}
                                fontSize={32}
                            />
                        </div>
                        :
                        <label htmlFor="upload-template">
                            <div onClick={() => { this.setState({ addingNew: true }) }} style={{ borderRadius: 5, cursor: 'pointer', display: 'flex', flexDirection: 'column', height: 200, width: 400, marginBottom: 10, backgroundColor: Colors.secondaryButton, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon icon={faImage} color={Colors.background} fontSize={60} />
                                <a style={{ marginTop: 10, fontWeight: 'bold', color: Colors.background }}>Clique aqui para escolher uma imagem</a>
                                <a style={{ marginTop: 2, fontWeight: 'bold', color: Colors.background, fontSize: 12 }}>Tamanho máximo (10 MB)</a>
                                <a style={{ marginTop: 2, fontWeight: 'bold', color: Colors.background, fontSize: 12 }}>Resolução recomendada (1920px x 1080px)</a>
                            </div>
                        </label>
                    }


                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome do Template</Form.Label>
                            <Form.Control type="text" placeholder="Template Y..." onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <DefaultButton loading={this.state.loadingUpload} color={Colors.error} leftIcon={<FontAwesomeIcon icon={faTimes} />} style={{ width: '49%' }} title={'Cancelar'} onClick={() => { this.setState({ imageToUpload: null, imageToUploadPreview: null, name: '', addingNew: false }) }} />
                        <DefaultButton loading={this.state.loadingUpload} disabled={!this.state.imageToUpload || !this.state.name} color={Colors.success} leftIcon={<FontAwesomeIcon icon={faImage} />} style={{ width: '49%' }} title={'Salvar'} onClick={this.doUploadFile} />
                    </div>
                </div>
            ) :
            (
                <div style={{ margin: 10, display: 'flex', flexDirection: 'column' }}>
                    <div onClick={() => { this.setState({ addingNew: true }) }} style={{ borderRadius: 5, cursor: 'pointer', display: 'flex', height: 200, width: 400, marginBottom: 10, backgroundColor: Colors.secondaryButton, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <FontAwesomeIcon icon={faPlus} color={Colors.background} fontSize={60} />
                    </div>
                    <Form.Label style={{ marginLeft: 5 }}>Novo</Form.Label>
                </div>
            );
    }

    renderImages() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
                {this.renderAddNew()}
                {this.state.images.map(template => (this.renderImage(template)))}
            </div>
        );
    }

    render() {
        return (
            <div style={{ minWidth: '80%', marginBottom: 40 }}>
                <Form.Label>Template:*</Form.Label>
                {this.renderImages()}
            </div>
        );
    }
}