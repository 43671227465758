import React from "react";
import Colors from "../../../constants/Colors";
import Sig from "../../../api/Sig";
import EssentialStyle from "../../../style/EssentialStyle";
import SessionHelper from "../../../helper/SessionHelper";
import DefaultLoader from "../../tools/DefaultLoader";
import moment from "moment";
import DefaultButton from "../../tools/DefaultButton";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import CustomConfirm from "../../tools/CustomConfirm";

export default class AtualizacoesTimeline extends React.Component {
    state = {
        loading: true,
        data: [],
        page: 1,
        hasMore: true,
        firstLoad: true
    }

    async componentDidMount() {
        await this.loadData();

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            if (!this.state.loading && this.state.hasMore) {
                this.loadData(this.state.page + 1);
            }
        }
    }

    async loadData(page = 1) {
        let timeout = null;

        if (this.state.firstLoad) {
            this.setState({ loading: true, firstLoad: false });
        } else {
            timeout = setTimeout(() => {
                this.setState({ loading: true });
            }, 300);
        }

        let req = await Sig.request('POST', 'ajuda/atualizacoes/list', { page, limit: 10 });

        if (timeout) {
            clearTimeout(timeout);
        }

        if (req.length < 10) {
            this.setState({ hasMore: false });
        }

        this.setState(prevState => ({
            data: page === 1 ? req.data : [...prevState.data, ...req.data],
            loading: false,
            page
        }));

    }

    renderTimeline(index, id) {
        return (
            <div style={{ width: '50px', height: '100%', minHeight: 100, paddingLeft: 20 }}>
                <div style={{ width: 3, height: 'inherit', minHeight: 'inherit', backgroundColor: SessionHelper.getColor() }} />
            </div>
        );
    }

    doRemove = async (id) => {
        let req = await Sig.request('POST', 'ajuda/atualizacoes/delete', { id });

        if (req?.success) {
            this.loadData();
            toast.success('Nota de Atualização removida com sucesso!');
        }
    }

    remove = async (id) => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <CustomConfirm
                    title={'Apagar Nota de Atualização?'}
                    message={<p style={{ marginTop: 10, marginBottom: 10 }}>Essa ação apagará essa Nota de Atualização e não poderá ser revertida.</p>}
                    buttons={[
                        {
                            label: 'Apagar',
                            color: Colors.error,
                            textColor: Colors.light,
                            onClick: () => { this.doRemove(id); onClose(); }
                        },
                        {
                            label: 'Cancelar',
                            onClick: () => { onClose(); }
                        },
                    ]}
                />
            )
        });
    }

    renderAtualizacaoCard(item, index) {
        let id = `at-card-${item.id}`;

        return (
            <div key={`${id}-key`} style={{ ...EssentialStyle.rowFlexStart, height: 'auto', display: 'flex', borderLeft: '2px solid black', marginLeft: 23 }}>
                <div style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: -11, backgroundColor: SessionHelper.getColor() }} />
                <div style={{ ...EssentialStyle.columnCenter, fontSize: 12, fontWeight: 'bold', width: 115, color: Colors.disabled }}>{moment(item.data).format('DD/MM/YYYY HH:mm')}</div>

                <div style={{ ...EssentialStyle.card, width: 'calc(100% - 19px - 115px)', padding: 10, marginBottom: 10, marginLeft: 10 }}>
                    <div style={{ fontWeight: 'bold', fontSize: 16 }}>{item.titulo}</div>
                    <div style={{ fontSize: 14, maxWidth: '100%', overflowWrap: 'break-word', wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: item.valor }} />
                    {this.props.canCreate &&
                        <div style={{ ...EssentialStyle.rowFlexStart }}>
                            <DefaultButton title={'Editar'} onClick={() => { this.props.editCallback(item.id); }}/>
                            <div style={{ width: 10 }} />
                            <DefaultButton title={'Apagar'} color={Colors.error} onClick={() => { this.remove(item.id); }}/>
                        </div>
                    }
                </div>
            </div>
        );
    }

    render() {
        return (
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                {this.state.data.map((item, index) => this.renderAtualizacaoCard(item, index))}
                {this.state.loading && <div style={{ ...EssentialStyle.columnCenter, padding: 10, width: '100%' }}><DefaultLoader /></div>}
            </div>
        );
    }
}