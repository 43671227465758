import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import DataHelper from "../../../../helper/DataHelper";
import { toast } from "react-toastify";
import Sig from "../../../../api/Sig";
import EssentialStyle from "../../../../style/EssentialStyle";
import LoadingPage from "../../../../pages/LoadingPage";

export default class AddProjeto extends React.Component {
    state = {
        step: 0,
        loading: true,
        colabs: [],
        nome: null,
        responsable: null,
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        let colabs = await Sig.request('GET', 'config/colaborador/getColaboradores') || [];

        this.setState({ colabs }, () => { this.setState({ loading: false }) });
    }

    addNew = async () => {

        if (!this.state.nome || !this.state.responsable) {
            toast.error('Preencha todos os campos!');
            return;
        }

        this.setState({ loading: true });

        let response = await Sig.request('POST', 'pe/projeto/addProjeto',
            {
                id_pe_projeto_iniciativa: null,
                nome: this.state.nome,
                id_responsavel: this.state.responsable
            }
        );

        if (response?.projeto) {
            toast.success('Projeto inserido com sucesso!');
            this.props.addCallback();
        }

        this.setState({ loading: false });
    }

    renderForm() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '100%', minHeight: '100vh' }}>
                { this.props.backButton && <div style={{ position: 'absolute', top: 10, left: 10 }}>
                    <DefaultButton 
                        title={''} 
                        leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} 
                        color={Colors.secondaryButton} 
                        textColor={Colors.dark} 
                        loading={this.state.loading} 
                        onClick={() => { this.props.backButton() }} 
                        style={{ marginRight: 8 }} />
                </div>}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <Form className="w-75">
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} xxl={6} className={"mb-1"}>
                                <Form.Label>Nome do Projeto *</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nome do Projeto"
                                    required
                                    onChange={(event) => { this.setState({ nome: event.target.value }) }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={12} xxl={6} className={"mb-1"}>
                                <Form.Label>Responsável *</Form.Label>
                                <Select
                                    options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')}
                                    placeholder="Selecione um Responsável"
                                    onChange={(option) => { this.setState({ responsable: option.value }) }}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
                <div style={{ ...EssentialStyle.rowFlexEnd, padding: '0 calc(25%/2)' }}>
                    <DefaultButton
                        color={Colors.success}
                        leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                        title={'Adicionar'}
                        loading={this.state.loading}
                        onClick={() => {
                            this.addNew();
                        }}
                    />
                </div>
            </div>
        )
    }

    render() {
        if (this.state.loading) return <LoadingPage />
        return this.renderForm();
    }
}