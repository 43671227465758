import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faFile, faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import DefaultButton from "../../../tools/DefaultButton";
import Colors from "../../../../constants/Colors";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select'
import SessionHelper from "../../../../helper/SessionHelper";
import DataHelper from "../../../../helper/DataHelper";
import AddTag from "../../../tools/AddTag";
import { toast } from "react-toastify";
import SwotHelper from "../../../../helper/pe/SwotHelper";
import Sig from "../../../../api/Sig";
export default class AddSwot extends React.Component {
    state = {
        step: 0,
        loading: true,
        fromProjectId: null,
        colabs: [],
        swots: [],
        name: '',
        tags: [],
        responsable: null,
        participants: [],
        description: '',
        newSwotId: null,
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        this.setState({ loading: true });

        const { colaboradores, swots } = await Sig.request('GET', 'pe/swot/getSwotsWithColaboradores');

        let step = this.state.step;
        if (swots.length > 0) { step = 1 };

        this.setState({ colabs: colaboradores, swots, step }, () => { this.setState({ loading: false }) });
    }

    addNew = async () => {
        if (!this.state.name || !this.state.responsable) return toast.warn('Informe todos os campos obrigatórios (*)');

        this.setState({ loading: true });

        let req = await Sig.request(
            'POST',
            'pe/swot/add',
            {
                nome: this.state.name,
                id_responsavel: this.state.responsable,
                descricao: this.state.description,
                tags: this.state.tags.map((tag) => (tag.value)),
                colaboradores: this.state.participants.map((p) => (p.value))
            }
        );

        if (req?.swot) {
            
            this.setState({ newSwotId: req.swot.id }, this.step(req.swot.id));
            toast.success('SWOT inserido com sucesso!');
        }

        this.setState({ loading: false });
    }

    renderForm() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center', minHeight: 'inherit' }}>
                <Form className="w-75">
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Nome da Análise SWOT*</Form.Label>
                            <Form.Control type="text" placeholder="Análise SWOT X..." onChange={(event) => { this.setState({ name: event.target.value }) }} />
                        </Form.Group>

                        <AddTag onChange={(value) => { this.setState({ tags: value }) }} />
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Responsável pela Análise*</Form.Label>
                            <Select options={DataHelper.formatSelectData(this.state.colabs, 'id', 'nome')} placeholder={'Selecione o responsável'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable onChange={(value) => { this.setState({ responsable: value ? value.value : null }) }} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Participantes*</Form.Label>
                            <Select 
                                isDisabled={this.state.responsable ? false : true}                                
                                options={DataHelper.removeColaborador(DataHelper.formatSelectData(this.state.colabs, 'id', 'nome'), this.state.responsable)} placeholder={'Selecione os participantes'} noOptionsMessage={DataHelper.getSelectEmptyMessage} isClearable isSearchable isMulti onChange={(value) => { this.setState({ participants: value }) }} />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control as="textarea" placeholder="Descrição da Análise SWOT" onChange={(event) => { this.setState({ description: event.target.value }) }} />
                        </Form.Group>
                    </Row>

                    <div className="mb-3 d-flex flex-row-reverse">
                        <Form.Group>
                            <DefaultButton color={Colors.success} leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} title={'Adicionar'} loading={this.state.loading} onClick={this.addNew} />
                        </Form.Group>
                    </div>
                </Form>

            </div>
        );
    }

    renderStepBack() {
        return (
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
                <DefaultButton title={''} leftIcon={<FontAwesomeIcon icon={faChevronLeft} />} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loading} onClick={() => { this.step() }} style={{ marginRight: 8 }} />
            </div>
        );
    }

    step(id = 0) {
        this.setState({ loading: true });
        this.props.addSwotCallback(id);
        this.setState({ loading: false });
    }

    render() {
        return (
            <div style={{ minWidth: '100%', minHeight: '100vh' }}>
                {this.renderStepBack()}
                {this.renderForm()}
            </div>
        );
    }
}