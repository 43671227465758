import Sig from "../../api/Sig";
export default class MapaHelper {

    static async getGutItemValue(id_pe_mapa, id_pe_iniciativa) {
        let value = parseInt(
            (await Sig.request('GET', 'pe/mapa/getGutItemSomatorio', 
                { id_pe_mapa, 
                id_pe_iniciativa })).somatorio);

        return value;
    }

}