import React, { Component } from 'react';
import Colors from '../../constants/Colors';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CustomTooltip from './CustomTooltip';
import SessionHelper from '../../helper/SessionHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export default class DefaultButton extends Component {

    state = {
        hover: false,
    }

    getBackgroundColor() {
        return this.props.disabled == true || this.props.loading ? Colors.notSelected : (this.props.color ? this.props.color : SessionHelper.getColor());
    }

    getColor() {
        return this.props.disabled == true || this.props.loading ? Colors.dark : this.props.textColor || Colors.light;
    }

    renderButton() {
        const passAlongProps = { ...this.props };

        delete passAlongProps.leftIcon;
        delete passAlongProps.textColor;
        delete passAlongProps.tooltip;
        delete passAlongProps.tooltipPlacement;
        delete passAlongProps.color;
        delete passAlongProps.loading;
        delete passAlongProps.title;
        delete passAlongProps.rightIcon;
        
        if (passAlongProps.link) {
            return (
                <Button
                    {...passAlongProps}
                    style={{ 
                        cursor: 'pointer', 
                        height: this.props.height ? this.props.height : 30, 
                        width: this.props.width ? this.props.width : 30,
                        backgroundColor: this.getBackgroundColor(), 
                        color: this.getColor(), 
                        borderColor: this.getBackgroundColor(), 
                        marginBottom: 0, ...this.props.style, 
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    disabled={this.props.disabled == true}
                    onClick={() => {
                        if (!this.props.loading) {
                            window.open(this.props.link, '_blank');
                        } else {
                            toast.info('Ainda realizando ação, aguarde..');
                        }
                    }}
                    onMouseEnter={() => {this.setState({ hover: true })}}
                    onMouseLeave={() => {this.setState({ hover: false })}}
                >
                    {this.state.hover ? <FontAwesomeIcon icon={faExternalLinkAlt} style={{fontSize: this.props.fontSize ? this.props.fontSize : 12}} /> : <FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: this.props.fontSize ? this.props.fontSize : 12}}/>}
                </Button>
            )
        }

        return (
            <Button
                {...passAlongProps}
                style={{ cursor: 'pointer', height: this.props.height ? this.props.height : 'auto', width: this.props.width ? this.props.width : 'auto', backgroundColor: this.getBackgroundColor(), color: this.getColor(), borderColor: this.getBackgroundColor(), marginBottom: 0, ...this.props.style }}
                disabled={this.props.disabled == true}
                onClick={(evt) => {
                    if (!this.props.loading) {
                        this.props.onClick(evt);
                    } else {
                        toast.info('Ainda realizando ação, aguarde..');
                    }
                }}
            >
                {this.props.leftIcon && !this.props.loading ? this.props.leftIcon : null}
                {this.props.loading ? <Spinner animation="border" role="status" size="sm"><span className="visually-hidden">Carregando...</span></Spinner> : null}
                <span style={{ marginLeft: (this.props.leftIcon && this.props.title) || (this.props.loading && this.props.title) ? 8 : 0, marginRight: this.props.rightIcon && this.props.title ? 8 : 0 }}>{this.props.title}</span>
                {this.props.rightIcon && this.props.rightIcon}
            </Button>
        )
    }

    renderWithTooltip() {
        return (
            <CustomTooltip style={{ height: this.props.height ? this.props.height : 'auto' }} placement={this.props.tooltipPlacement || 'top'} tooltip={this.props.tooltip}>
                {this.renderButton()}
            </CustomTooltip>
        );
    }

    render() {
        return this.props.tooltip ? this.renderWithTooltip() : this.renderButton();
    }
}
