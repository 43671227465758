import React, { useRef, useEffect } from 'react';
import EssentialStyle from "../../../style/EssentialStyle";
import Colors from "../../../constants/Colors";
import UserAvatar from "../../tools/UserAvatar";
import moment from "moment";
import DefaultButton from "../../tools/DefaultButton";
import TextEditor from "../../tools/TextEditor/TextEditor";
import KeyboardHelper from "../../../helper/KeyboardHelper";
import CustomTooltip from "../../tools/CustomTooltip";
import DataHelper from "../../../helper/DataHelper";

export default class PlanoAcaoEtapaComentarioCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            oldDescricao: this.props.comentario?.descricao,
            descricao: this.props.comentario?.descricao,
            data_edicao: this.props.comentario?.data_edicao,
            savingEditing: false
        };
        this.comentarioRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.selectedUrl) {
            this.comentarioRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            window.parent.postMessage({type: 'delete_url_param', param: "comentario"}, '*');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.comentario?.data_edicao !== this.props.comentario?.data_edicao) {
            this.setState({ data_edicao: this.props.comentario?.data_edicao });
        }
    }

    renderView() {
        return (
            <div style={{ ...EssentialStyle.columnStart, width: '100%', height: 'auto' }}>
                <div style={{ whiteSpace: "nowrap" }}>
                    <span style={{ display: "flex", width: "100%", flexWrap: "wrap", whiteSpace: "break-spaces" }} dangerouslySetInnerHTML={{ __html: this.state.descricao }} />
                </div>
                <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', marginBottom: 8 }}>
                    {this.props.comentario?.can_edit &&
                        <div style={{ ...EssentialStyle.rowFlexStart }}>
                            <DefaultButton
                                title={'Editar'}
                                color={Colors.tag}
                                textColor={Colors.dark}
                                style={{ height: 26, ...EssentialStyle.rowFlexCenter, marginRight: 8 }}
                                onClick={() => {
                                    this.setState({ editing: true }, () => {
                                        if (this.editComentarioInput) this.editComentarioInput.focus();
                                        const length = this.editComentarioInput.value?.length || 0;
                                        this.editComentarioInput.editor.setSelection(length, 0);
                                    });
                                }}
                            />
                            <DefaultButton
                                title={'Remover'}
                                color={Colors.tag}
                                textColor={Colors.dark}
                                style={{ height: 26, ...EssentialStyle.rowFlexCenter }}
                                onClick={() => {
                                    if (this.props.removeCallback) this.props.removeCallback(this.props.comentario);
                                }}
                            />
                        </div>
                    }

                    {this.state.data_edicao &&
                        <div style={{ ...EssentialStyle.rowFlexEnd, width: '100%' }}>
                            <CustomTooltip
                                tooltip={`Editado em ${moment(this.state.data_edicao).format("D/MM/YYYY [às] HH:mm")}`}
                            >
                                <span style={{ fontSize: ".7rem", fontWeight: "bold", color: Colors.homePage.grey, marginLeft: 8 }}>
                                    Editado
                                </span>
                            </CustomTooltip>
                        </div>
                    }
                </div>
            </div>
        );
    }

    renderEdit() {
        return (
            <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: 'auto' }}>
                <TextEditor
                    ref={ref => this.editComentarioInput = ref}
                    defaultValue={this.state.descricao}
                    placeholder={"Editar comentário..."}
                    onChange={(value) => {
                        this.setState({ descricao: value })
                    }}
                    hideToolbar={false}
                    maxHeight={300}
                    noMargin={false}
                    mentions={DataHelper.formatMentionData(this.props.participantes, 'id', 'nome')}
                    onKeyDown={(evt) => {
                        KeyboardHelper.handleShortcut(
                            evt,
                            ["Enter", "Escape"],
                            [() => {
                                if (!(evt.shiftKey || evt.ctrlKey || evt.metaKey)) return;

                                this.setState({ savingEditing: true });
                                if (this.props.editCallback) this.props.editCallback({ ...this.props.comentario, descricao: this.state.descricao });
                                this.setState({ oldDescricao: this.state.descricao, editing: false, savingEditing: false, data_edicao: moment() });
                            }, (e) => {
                                e.preventDefault();
                                this.setState({ descricao: this.state.oldDescricao, editing: false });
                            }]
                        )
                    }}
                />
                <div style={{ ...EssentialStyle.rowFlexStart, width: '100%', marginBottom: 8 }}>
                    <DefaultButton
                        title={'Salvar'}
                        color={Colors.success}
                        textColor={Colors.white}
                        loading={this.state.savingEditing}
                        style={{ height: 26, ...EssentialStyle.columnCenter, marginRight: 8 }}
                        onClick={() => {
                            this.setState({ savingEditing: true });
                            if (this.props.editCallback) this.props.editCallback({ ...this.props.comentario, descricao: this.state.descricao });
                            this.setState({ oldDescricao: this.state.descricao, editing: false, savingEditing: false, data_edicao: moment() });
                        }}
                    />
                    <DefaultButton
                        title={'Cancelar'}
                        color={Colors.tag}
                        textColor={Colors.dark}
                        loading={this.state.savingEditing}
                        style={{ height: 26, ...EssentialStyle.columnCenter }}
                        onClick={() => {
                            this.setState({ descricao: this.state.oldDescricao, editing: false });
                        }}
                    />
                </div>
            </div>
        );
    }

    render() {
        let participante = this.props.participantes?.find(p => p.id === this.props.comentario?.id_colaborador);

        return (
            <div
                id={`comentario-${this.props.comentario?.id}`}
                ref={this.comentarioRef}
                style={{
                    ...EssentialStyle.columnStart,
                    width: '100%',
                    borderBottom: !this.props.last ? `1px solid ${Colors.homePage.extraLightGrey}` : `none`,
                    backgroundColor: this.props.selectedUrl ? 'hsl(0, 0%, 95%)' : 'transparent',
                    borderRadius: this.props.selectedUrl ? 8 : 0,
                    padding: "10px 5px"
                }}>
                <div style={{ ...EssentialStyle.columnCenter, width: '100%', height: 'auto' }}>
                    <div style={{ ...EssentialStyle.rowSpaceBetween, width: '100%', marginBottom: 8 }}>
                        <div style={{ width: 'calc(100% - 125px)' }}>
                            {participante ? <UserAvatar user={participante} showNameAdaptative showName /> : <UserAvatar id={this.props.comentario?.id_colaborador} showNameAdaptative showName />}
                        </div>
                        <span style={{ display: "flex", width: "125px", justifyContent: "flex-end", fontSize: "0.7rem", fontWeight: "bold", color: Colors.homePage.grey }}>{moment(this.props.comentario?.data).format("D/MM/YYYY [às] HH:mm")}</span>
                    </div>
                    {this.state.editing ? this.renderEdit() : this.renderView()}
                </div>
            </div>
        );
    }
}