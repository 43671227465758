import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Colors from "../../constants/Colors";
import DefaultButton from "../../components/tools/DefaultButton";
import SessionHelper from "../../helper/SessionHelper";
import LoadingPage from "../LoadingPage";
import DrawDiagram from "../../components/tools/DrawDiagram";
import VisaoList from "../../components/forms/pe/list/VisaoList";
import { toast } from "react-toastify";
import AddVisao from "../../components/forms/pe/add/AddVisao";
import Sig from "../../api/Sig";
import CustomTooltip from "../../components/tools/CustomTooltip";

export default class VisaoPage extends React.Component {

    state = {
        visao: null,
        loading: true,
        showVisoes: null,
        showWizard: false,
        modeView: 'read-only',
        podeCriar: null,
    }

    async componentDidMount() {
       await this.loadData();

       this.setState({ loading: false });
    }

    async loadData() {
        this.setState({ loading: true });

        const favoriteOrLastVisao = await Sig.request('GET', 'pe/visao/getFavorite');

        this.setState({ visao: favoriteOrLastVisao?.visao,
                        acesso: favoriteOrLastVisao?.acesso, 
                        podeCriar: favoriteOrLastVisao?.podeCriar, 
                        loading: false });
    }

    remove = async (id) => {
        this.setState({ loading: true });

        try {
            await Sig.request('POST', 'pe/visao/delete', { id });
        } catch (e) {
            toast.error('Erro ao remover');
        }

        this.loadData();
    }

    addVisaoCallback = async () => {
        this.setState({ showVisoes: false, showWizard: false, modeView: 'edit' });
        await this.loadData();
    }

    showVisoesCallback = async () => {
        this.setState({ showVisoes: true });
        await this.loadData();
    }
    
    handleFavorite = async (id, isFavorite) => {
        this.setState({ loading: true });

        await Sig.request('POST', `pe/visao/setFavorito`, { id: id, favorito: isFavorite ? 0 : 1 });
        await this.loadData();

        this.setState({ loading: false });
    
    }

    renderDefaultVisao() {
        return <DrawDiagram 
                    handleFavorite={this.handleFavorite} 
                    id={this.state.visao.id} 
                    delete={() => { this.remove(this.state.visao.id) }} 
                    table={'PeVisao'} 
                    historyTable={'PeVisaoHistorico'} 
                    elementsTable={'PeVisaoElemento'} 
                    relationField={'id_pe_visao'} 
                    callback={this.showVisoesCallback} 
                    modeView={this.state.modeView}
                />
    }

    renderEmpty() {
        return (
            <div style={{ padding: 50, backgroundColor: 'white', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', borderRadius: 5, textAlign: 'center' }}>
                <DefaultButton style={{ position: 'absolute', top: 30, right: 10 }} link={"https://advis.freshdesk.com/support/solutions/articles/63000283184-vis%C3%A3o-de-futuro"}/>
                <h5>Até o momento, sua empresa ainda não criou nenhuma <strong>Visão de Futuro</strong> ou você não possui acesso a nenhuma delas.</h5>
                <div style={{ marginTop: 20 }} />
                {this.state.podeCriar ? (
                    <DefaultButton 
                        color={Colors.success} 
                        title={'Adicionar Visão de Futuro'} 
                        leftIcon={<FontAwesomeIcon icon={faPlusSquare} />} 
                        loading={this.state.loading} 
                        onClick={() => this.setState({ showWizard: true })}
                    />
                ) : (
                    <CustomTooltip placement={'right'} tooltip={'Sem Permissão Para Adicionar Nova Visão de Futuro'}>
                        <DefaultButton 
                            color={Colors.success} 
                            title={'Adicionar Visão de Futuro'} 
                            leftIcon={<FontAwesomeIcon icon={faPlusSquare} />}
                            loading={this.state.loading} 
                            onClick={() => this.setState({ showWizard: true })}
                            disabled={true}
                        />
                    </CustomTooltip>
                )}
            </div>
        )
    }

    render() {
        if(this.state.loading) return <LoadingPage />
        if (this.state.showWizard) return <AddVisao addCallback={this.addVisaoCallback} />
        if(this.state.showVisoes) return <VisaoList callback={this.addVisaoCallback}/>

        return this.state.visao ? this.renderDefaultVisao() : this.renderEmpty();
    }
}