import Sig from "../../api/Sig";
export default class SwotHelper {
    static getSwotKey(name) {
        let key = '';

        switch (name) {
            case 'Forças':
                key = 'forcas';
                break;
            case 'Fraquezas':
                key = 'fraquezas';
                break;
            case 'Oportunidades':
                key = 'oportunidades';
                break;
            case 'Ameaças':
                key = 'ameacas';
                break;
            default:
                break;
        }

        return key;
    }

    static async getGutItemValue(id_swot, id_pe_swot_quadrante_item) {
        let value = parseInt((await Sig.request('GET', 'pe/swot/getGutItemSomatorio', { id_swot, id_pe_swot_quadrante_item })).somatorio);
        return value;
    }

}