import React from 'react';
import CustomTooltip from './CustomTooltip';

class EllipsisText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originalText: props.text == null ? "" : props.text,
            displayText: props.text,
            tooltip: true,
            align: "center",
        };
        this.textRef = React.createRef();
        this.boxRef = React.createRef();
        this.observer = null;
    }

    updateTextEllipsis = () => {
        if(this.textRef.current) {
            let words = this.state.originalText.split(' ');
            let newText = this.state.originalText;
            if(this.props.tab && this.props.tab > 0) {
                words = [" ".repeat(this.props.tab), ...words];
                newText = " ".repeat(this.props.tab) + newText;
            }
            this.setState({ displayText: newText});

            this.textRef.current.textContent = newText;

            while (this.textRef.current.scrollHeight > this.textRef.current.offsetHeight && words.length > 0) {
                words.pop();
                newText = `${words.join(' ')}...`;

                this.textRef.current.textContent = newText;
            }
            if(this.textRef.current.scrollWidth > this.boxRef.current.offsetWidth) {
                while (this.textRef.current.scrollWidth > this.boxRef.current.offsetWidth && newText.length > 0) {
                    newText = newText.slice(0, newText.length - 4);
                    newText = newText.length != 0 ? `${newText}...` : newText;
                    this.textRef.current.textContent = newText;
                }
            }

            this.setState({ displayText: newText });
        }
    }

    componentDidMount() {
        this.setState({
            dimensions: {
                width: this.boxRef.current.offsetWidth,
                height: this.boxRef.current.offsetHeight,
            },
            align: this.props.align ? this.props.align : "center",
        });
        this.observer = new ResizeObserver(this.updateTextEllipsis);
        this.observer.observe(this.boxRef.current);
        window.addEventListener('resize', this.verifyScreen);
    }

    verifyScreen = () => {
        this.setState({ displayText: this.state.originalText }, this.updateTextEllipsis);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.verifyScreen);
        this.observer.disconnect();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.text !== this.props.text) {
            this.setState({ originalText: this.props.text }, () => {
                this.updateTextEllipsis();
            });
        }
 
    }

    renderText() {
        return(
            <div ref={this.textRef} style={{ whiteSpace: this.props.tab ? "pre-wrap" : "unset", overflow: 'hidden', maxWidth: "100%", maxHeight: "100%" }}>
                {this.state.displayText}
            </div>
        )
    }

    render() {
        return (
            <>
                <div ref={this.boxRef} style={{minWidth: "100%", minHeight: "100%", maxWidth: "100%", maxHeight: "100%", overflow: 'hidden', position: "relative", display: "flex", justifyContent: "center" }}>
                    { this.state.originalText !== this.state.displayText && this.state.displayText !== " ".repeat(this.props.tab) + this.state.originalText ?
                            <CustomTooltip tooltip={this.state.originalText} style={{minWidth: "100%", minHeight: "100%", overflow: 'hidden', position: "relative", display: "inline", alignContent: this.state.align }}>
                                {this.renderText()}
                            </CustomTooltip>
                        :
                        <div style={{minWidth: "100%", minHeight: "100%", overflow: 'hidden', position: "relative", display: "inline", alignContent: this.state.align }}>    
                            {this.renderText()}
                        </div>
                    }
                </div>
            </>
        );
    }
}

export default EllipsisText;